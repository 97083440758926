import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import gql from "graphql-tag";
import { showToast } from "../Helpers/HelperFns";

import {
  Box,
  Divider,
  Container,
  Typography,
  Checkbox as MuiCheckbox,
} from "@mui/material";
import Loader from "../Components/Loader";
import DataTable from "react-data-table-component";
import { ColorButton } from "../Components/Buttons";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ErrorView, SuccessView } from "../Components/Feedback";

const GET_NOTIFICATIONS = gql`
  query getNotifications($token: String!) {
    email_notifications_settings(notificationToken: $token) {
      id
      name
      manager_flag
      email_status
      phone_status
    }
  }
`;

const UPDATE_NOTIFICATIONS_SETTINGS = gql`
  mutation updateNotificationsSettings(
    $token: String!
    $input: [BulkUserNotificationInput]
  ) {
    upsert_email_notification_settings(
      input: $input
      notificationToken: $token
    ) {
      status
      message
    }
  }
`;

const formatSaveData = (data) => {
  return [...data?.mine, ...data?.managed]
    .filter((d) => d?.id)
    .map((note) => ({
      id: note?.id,
      phone_status: +note?.phone_status,
      email_status: +note?.email_status,
    }));
};

const UnsubscribeNotificationsPage = () => {
  const { token } = useParams();
  const { t } = useTranslation();

  // Local State
  const [feedbackView, setFeedbackView] = React.useState(null);
  const [data, setData] = React.useState({ mine: [], managed: [] });
  const [enableAll, setEnableAll] = React.useState({
    phone: false,
    email: false,
    managedPhone: false,
    managedEmail: false,
  });

  // Server State
  const [updateNotificationsSettings, { loading: updateNotificationsLoading }] =
    useMutation(UPDATE_NOTIFICATIONS_SETTINGS);
  const { loading: getNotificationsLoading } = useQuery(GET_NOTIFICATIONS, {
    variables: { token: token },
    onCompleted: ({ email_notifications_settings }) => {
      const data = {
        mine: email_notifications_settings?.filter(
          ({ manager_flag }) => !manager_flag
        ),
        managed: email_notifications_settings?.filter(
          ({ manager_flag }) => manager_flag
        ),
      };

      setEnableAllState(data);
      setData({
        mine: [
          { name: "all", enableAllColumn: true, managed: false },
          ...data.mine.map((d) => ({ ...d, managed: false })),
        ],
        managed: [
          { name: "all", enableAllColumn: true, managed: true },
          ...data.managed.map((d) => ({ ...d, managed: true })),
        ],
      });
    },
    onError: () => {
      setFeedbackView("error");
    },
  });

  // Constants
  const columns = [
    { name: "name", selector: "name", wrap: true, grow: 5 },
    {
      name: "Email",
      center: true,
      cell: ({ id, email_status, enableAllColumn, managed }) =>
        enableAllColumn ? (
          <Checkbox
            onChange={() => handleSwitchAll(managed, "email")}
            checked={managed ? enableAll.managedEmail : enableAll.email}
          />
        ) : (
          <Checkbox
            checked={email_status}
            onChange={() => handleSwitch(id, managed, "email")}
          />
        ),
    },
    {
      name: "Mobile",
      center: true,
      cell: ({ id, phone_status, enableAllColumn, managed }) =>
        enableAllColumn ? (
          <Checkbox
            onChange={() => handleSwitchAll(managed, "phone")}
            checked={managed ? enableAll.managedPhone : enableAll.phone}
          />
        ) : (
          <Checkbox
            checked={phone_status}
            onChange={() => handleSwitch(id, managed, "phone")}
          />
        ),
    },
  ];

  /* ↓ Helpers ↓ */

  const setEnableAllState = (notifications) => {
    const phone = notifications?.mine?.every((note) => note.phone_status);
    const email = notifications?.mine?.every((note) => note.email_status);
    const managedPhone = notifications?.managed?.every(
      (note) => note.manager_flag && note.phone_status
    );
    const managedEmail = notifications?.managed?.every(
      (note) => note.manager_flag && note.email_status
    );

    setEnableAll({ managedPhone, managedEmail, phone, email });
  };

  const handleSwitch = (id, isManged, type) => {
    const isPhone = type === "phone";
    if (isManged) {
      setData((prev) => ({
        ...prev,
        managed: prev.managed.map((p) =>
          p.id === id
            ? {
                ...p,
                ...(isPhone
                  ? { phone_status: !p.phone_status }
                  : { email_status: !p.email_status }),
              }
            : p
        ),
      }));
    } else {
      setData((prev) => ({
        ...prev,
        mine: prev.mine.map((p) =>
          p.id === id
            ? {
                ...p,
                ...(isPhone
                  ? { phone_status: !p.phone_status }
                  : { email_status: !p.email_status }),
              }
            : p
        ),
      }));
    }
  };

  const handleSwitchAll = (isManged, type) => {
    const isPhone = type === "phone";

    if (isManged) {
      const newState = isPhone
        ? !enableAll.managedPhone
        : !enableAll.managedEmail;
      setEnableAll((prev) => ({
        ...prev,
        ...(isPhone ? { managedPhone: newState } : { managedEmail: newState }),
      }));
      setData((prev) => ({
        ...prev,
        managed: prev.managed.map((p) => ({
          ...p,
          ...(isPhone
            ? { phone_status: newState }
            : { email_status: newState }),
        })),
      }));
    } else {
      const newState = isPhone ? !enableAll.phone : !enableAll.email;
      setEnableAll((prev) => ({
        ...prev,
        ...(isPhone ? { phone: newState } : { email: newState }),
      }));
      setData((prev) => ({
        ...prev,
        mine: prev.mine.map((p) => ({
          ...p,
          ...(isPhone
            ? { phone_status: newState }
            : { email_status: newState }),
        })),
      }));
    }
  };

  const handleSubmit = () => {
    updateNotificationsSettings({
      variables: { token, input: formatSaveData(data) },
      onCompleted: ({ upsert_email_notification_settings }) => {
        upsert_email_notification_settings?.status === "success" &&
          setFeedbackView("success");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  return !feedbackView ? (
    <Container className="pb-4">
      {updateNotificationsLoading ? <Loader fixed /> : null}

      <header>
        <div className="mt-5 mb-4 text-center">
          <img
            src="assets/img/logo.svg"
            width="230"
            height="230"
            className="img-fluid mb-3"
            alt="Mawared Logo"
          />
        </div>

        <h1 className="mb-3 side-title" style={{ fontSize: 20 }}>
          {t("Unsubscribe Notifications")}
        </h1>

        <Typography sx={{ color: "#898A8D", textTransform: "initial" }}>
          {t("Select boxes below for notification you would like to receive.")}
        </Typography>

        <Divider sx={{ mt: 3, mb: 2 }} />
      </header>

      {getNotificationsLoading ? (
        <Loader inner />
      ) : (
        <main>
          <Box
            className="d-flex flex-wrap justify-content-center justify-content-xl-between"
            sx={{
              rowGap: 2,
              columnGap: 3,
              ".rdt_TableCol:first-of-type": { pl: 0 },
              ".rdt_TableCell:first-of-type": { pl: 0 },
              '[role="columnheader"]': { fontSize: 14 },
              '[role="heading"]': { px: 0, fontSize: 18 },
            }}
          >
            {data.mine.length > 1 ? (
              <div className="flex-fill">
                <DataTable
                  title={t("Notify Me When")}
                  columns={columns}
                  data={data.mine}
                />
              </div>
            ) : null}
            {data.managed.length > 1 ? (
              <div className="flex-fill">
                <DataTable
                  title={t("Notify Me When someone I manage")}
                  columns={columns}
                  data={data.managed}
                />
              </div>
            ) : null}
          </Box>

          <ColorButton
            color="#009EFB"
            variant="contained"
            label={t("Update Preferences")}
            sx={{
              mt: 2,
              width: 1,
              mx: "auto",
              fontSize: 17,
              fontWeight: 500,
              display: "block",
            }}
            onClick={handleSubmit}
          />
        </main>
      )}
    </Container>
  ) : (
    <FeedbackViews type={feedbackView} />
  );
};

export default UnsubscribeNotificationsPage;

const FeedbackViews = ({ type }) => {
  return type === "success" ? <SuccessView /> : <ErrorView />;
};

const Checkbox = (props) => (
  <MuiCheckbox
    size="small"
    icon={<UncheckedIcon />}
    checkedIcon={<CheckBoxIcon sx={{ color: "#009EFB" }} />}
    {...props}
  />
);
const UncheckedIcon = () => (
  <div
    style={{
      width: 16,
      height: 16,
      padding: 1.5,
    }}
  >
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#D7DEDD",
        borderRadius: 3,
        margin: 0,
        border: 0.5,
        borderColor: "#D7DEDD",
      }}
    />
  </div>
);

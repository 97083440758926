import React, { useEffect, useState } from "react";
import { InputForm } from "form-builder";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../Components/Pagination";
import {
  faAngleDown,
  faAngleUp,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fetchWorkTimingsListAction,
  showWorkTimingModalAction,
  editFetchWorkTimeAction,
  deleteWorkTimeAction,
  cloneWorkTimeAction,
  viewWorkTimeAction,
  onInputChangeAction,
  onFormResetAction,
} from "../../Store/Actions";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import Loader from "../../Components/Loader";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";

import WorkTimingForm from "./WorkTimingForm";
import DataTable from "react-data-table-component";
import moment from "moment";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import Constants from "../../Constants";
import EditWorkTimePreview from "../../Components/AttProfileSections/EditWorkTimePreview";
import { Tab, Tabs, Tooltip } from "@mui/material";
import IconButtonWithTooltip, {
  CopyIconButton,
  RemoveIconButton,
  EditIconButton,
  TimesIconButton,
  AcceptIconButton,
} from "../../Components/IconButtonWithTooltip";
import ArchiveIcon from "@mui/icons-material/Archive";
import { archiveWorkTimmingMutation } from "../../Graphql/mutation";
import { useMutation } from "@apollo/client";
import { RadioboxForm } from "form-builder";
import UnarchiveIcon from "@mui/icons-material/Unarchive";

const dataTableRef = "workTimingsList";

const WorkTimingsListPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Reducer State
  const workTimingsList = useSelector((state) => state.super[dataTableRef]);
  const isEditFetchWorkTime = useSelector(
    (state) => state.super.isEditFetchWorkTime
  );
  const isPreviewModalLoading = useSelector(
    (state) => state.super.isViewWorkTimeLoading
  );
  const authPlanLimits = useSelector(
    (state) =>
      state.auth?.userProfile?.company?.activeSubscription?.plan?.features
  );

  /* ↓ State  Effects ↓ */

  useEffect(() => {
    dispatch(
      fetchWorkTimingsListAction({
        ref: dataTableRef,
        [dataTableRef]: workTimingsList,
      })
    );
    return () => {
      dispatch(onFormResetAction(dataTableRef));
    };
  }, []);

  const handleFilter = () => {
    dispatch(
      fetchWorkTimingsListAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...workTimingsList,
          pagination: {
            ...workTimingsList.pagination,
            currentPage: 1,
          },
          only_archive: workTimingsList.only_archive,
        },
      })
    );
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    workTimingsList?.filter,
    workTimingsList?.work_timing_profile_type,
    workTimingsList.only_archive,
  ]);

  const [archiveWorkTimming, { loading: archiveWorkTimmingLoading }] =
    useMutation(archiveWorkTimmingMutation);

  const handleArchive = (id) => {
    swal({
      width: "auto",
      title: t("are you sure"),
      text: t("archive this work timing"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        archiveWorkTimming({
          variables: {
            id,
          },
          onCompleted: () => {
            showToast("success", t("done"));
            dispatch(
              fetchWorkTimingsListAction({
                ref: dataTableRef,
                [dataTableRef]: workTimingsList,
              })
            );
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors[0]?.extensions?.reason ?? err?.message
            );
          },
        });
      }
    });
  };

  // Constants
  const workTimingsLimit =
    authPlanLimits?.find((feature) => feature?.id === "5")?.pivot?.limits ??
    Infinity;
  const columns = [
    {
      name: t("name"),
      wrap: true,
      grow: 1.4,
      align: "center",
      selector: "name",
      className: "name-col",
      cell: (row) => (
        <HasPrivileges
          reqireMain={[Privilages.PREVIEW_WORK_TIMINGS]}
          allowBP
          dir={HelperFns.isStringRTL(row?.name) ? "rtl" : "ltr"}
          altChildren={
            <span>
              {row?.archived
                ? `${row?.name} (${moment(row?.created_at).format(
                  "DD-MM-YYYY"
                )} ~ ${moment(row?.updated_at).format("DD-MM-YYYY")})`
                : row?.name}
            </span>
          }
        >
          <button
            style={{ textAlign: "start" }}
            onClick={() => handleViewWorkTime(row)}
            dir={HelperFns.isStringRTL(row?.name) ? "rtl" : "ltr"}
            className="bg-none border-0 sec-color font-weight-bold"
            aria-label={`${t("Preview")} ${row?.name} ${t("Work timing")}`}
          >
            {row?.archived
              ? `${row?.name} (${moment(row?.created_at).format(
                "DD-MM-YYYY"
              )} ~ ${moment(row?.updated_at).format("DD-MM-YYYY")})`
              : row?.name}
          </button>
        </HasPrivileges>
      ),
    },
    {
      name: t("sign in range"),
      className: "sign-range-col h-50px",
      wrap: true,
      center: true,
      align: "center",
      selector: "sign_in_start_time",
      cell: (row) => (
        <span>
          {moment(row?.sign_in_start_time, "HH:mm").format("hh:mm A")} -{" "}
          {moment(row?.sign_in_end_time, "HH:mm").format("hh:mm A")}
        </span>
      ),
    },
    {
      name: t("work hours"),
      wrap: true,
      center: true,
      align: "center",
      cell: (row) => {
        return <span>{row?.work_hours}</span>;
      },
    },
    {
      name: t("early sign in limit"),
      wrap: true,
      center: true,
      align: "center",
      cell: (row) => {
        const timeDifference = moment(row.sign_in_start_time, "HH:mm")
          .subtract(
            Math.ceil(
              (
                HelperFns.timeToHours(row.time_allowed_before_sign_in ?? 0) * 60
              )?.toFixed(4)
            ),
            "minutes"
          )
          .format("hh:mm A");

        return <span>{timeDifference}</span>;
      },
    },
    {
      name: t("Facial Recognition"),
      wrap: true,
      center: true,
      align: "center",
      cell: (row) => {
        return (
          <span>
            {row?.face_rekognition ? (
              <AcceptIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            ) : (
              <TimesIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            )}
          </span>
        );
      },
    },
    {
      name: t("overtime"),
      wrap: true,
      center: true,
      grow: 0.5,
      align: "center",
      cell: (row) => {
        return (
          <span>
            {row?.overtime_permissible ? (
              <AcceptIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            ) : (
              <TimesIconButton
                iconProps={{
                  size: "lg",
                }}
              />
            )}
          </span>
        );
      },
    },
    {
      name: t("No Show Violations"),
      wrap: true,
      align: "center",
      center: true,
      cell: (row) =>
        row?.absent_violations?.length ? (
          row?.absent_violations?.map(({ value, quantity_unit }, i) => (
            <span>
              {i !== 0 && ","} {value}{" "}
              {quantity_unit ? t(quantity_unit) : t("days")}
            </span>
          ))
        ) : (
          <span> _______ </span>
        ),
    },
    {
      name: t("Early Sign Out Violations"),
      wrap: true,
      align: "center",
      center: true,
      cell: (row) =>
        row?.allow_early_signout ? (
          row?.early_sign_out_violations?.map(({ value, quantity_unit }, i) => (
            <span>
              {i !== 0 && ","} {value}{" "}
              {quantity_unit ? t(quantity_unit) : t("days")}
            </span>
          ))
        ) : (
          <span> _______ </span>
        ),
    },
    {
      name: t("Missing Sign Out Violation"),
      wrap: true,
      align: "center",
      center: true,
      cell: (row) =>
        row?.apply_missing_sign_out ? (
          row?.missing_sign_out_violations?.map(
            ({ value, quantity_unit }, i) => (
              <span>
                {i !== 0 && ","} {+value}{" "}
                {quantity_unit ? t(quantity_unit) : t("days")}
              </span>
            )
          )
        ) : (
          <span> _______ </span>
        ),
    },
    {
      name: t("actions"),
      wrap: true,
      align: "center",
      selector: "id",
      center: true,
      grow: 1.5,
      cell: (row) =>
        !row?.archived ? (
          <div className="cards_table_actions justify-content-center">
            <HasPrivileges
              reqireMain={[Privilages.ADD_EDIT_CLONE_WORK_TIMINGS]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={<CopyIconButton />}
            >
              <CopyIconButton
                onClick={() =>
                  dispatch(
                    cloneWorkTimeAction({
                      id: row?.id,
                      ref: "list",
                    })
                  )
                }
              />
            </HasPrivileges>

            <HasPrivileges
              reqireMain={[Privilages.ADD_EDIT_CLONE_WORK_TIMINGS]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={<IconButtonWithTooltip label={row?.hidden ? "uhnarchive" : "archive"}
                icon={row?.hidden ? <UnarchiveIcon fontSize="small" /> : <ArchiveIcon fontSize="small" />} />}
            >
              <IconButtonWithTooltip
                fontSize="small"  
                onClick={() => handleArchive(row?.id)}
                label={row?.hidden ? "uhnarchive" : "archive"}
                icon={row?.hidden ? <UnarchiveIcon /> : <ArchiveIcon />}
              />
            </HasPrivileges>

            <HasPrivileges
              reqireMain={[Privilages.ADD_EDIT_CLONE_WORK_TIMINGS]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={<EditIconButton />}
            >
              <EditIconButton onClick={() => hanldeEditWorkTiming(row?.id)} />
            </HasPrivileges>

            <HasPrivileges
              reqireMain={[Privilages.DELETE_WORK_TIMINGS]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={<RemoveIconButton />}
            >
              <RemoveIconButton
                onClick={() => {
                  swal({
                    width: "auto",
                    title: t("are you sure"),
                    text: t("defaut_warning_messsage"),
                    icon: "warning",
                    className: "swal-warning-style",
                    dangerMode: true,
                    buttons: [t("Cancel"), t("OK")],
                  }).then((willDelete) => {
                    if (willDelete) {
                      dispatch(deleteWorkTimeAction(row?.id));
                    }
                  });
                }}
              />
            </HasPrivileges>
          </div>
        ) : null,
      ignoreRowClick: true,
    },
  ];

  /* ↓ Helpers ↓ */

  const hanldeEditWorkTiming = (id) => {
    dispatch(editFetchWorkTimeAction(id));
  };

  const handleViewWorkTime = (row) => {
    dispatch(viewWorkTimeAction(row?.id));
  };

  const handlePaginate = (page = workTimingsList.pagination.currentPage) => {
    dispatch(
      fetchWorkTimingsListAction({
        [dataTableRef]: {
          ...workTimingsList,
          pagination: {
            ...workTimingsList.pagination,
            currentPage: page,
          },
        },
        ref: dataTableRef,
      })
    );
  };

  const handleAddWorkTimings = () => {
    if (workTimingsList?.total >= workTimingsLimit) {
      return;
    }
    dispatch(showWorkTimingModalAction());
  };

  const handleTypeFilter = (event, value) => {
    let e = {
      target: {
        value,
        name: "work_timing_profile_type",
      },
    };
    dispatch(onInputChangeAction(dataTableRef, e));
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <div
        style={{
          border: "1px dashed #ccc",
          textAlign: "center",
        }}
      >
        <DataTable
          key={data.id}
          className="cards_table employee-att-logs-expand-collapse-wrapper"
          columns={columns}
          data={data.oldVersions}
          noHeader
          noTableHead
          expandableRows={true}
          expandableRowDisabled={(row) => true}
        />
      </div>
    );
  };

  return (
    <>
      {isPreviewModalLoading ||
        isEditFetchWorkTime ||
        archiveWorkTimmingLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="mt-2 content">
        <div className="d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row gap-10">
          <div className="custom-tardiness-container justify-content-start align-items-center adjust-tabs">
            <Tabs
              indicatorColor="primary"
              className="adjust-tabs"
              name="work_timing_profile_type"
              value={workTimingsList?.work_timing_profile_type}
              onChange={handleTypeFilter}
              centered
              variant="fullWidth"
            >
              {Constants.WORK_TIME_PROFILE_TYPES.map((opt) => (
                <Tab
                  key={opt?.label}
                  label={t(opt?.label?.toLowerCase())}
                  value={opt?.value}
                />
              ))}
            </Tabs>
          </div>

          <div className="my-3 col-6 row px-0 align-items-center">
            <InputForm
              name="filter"
              formName={dataTableRef}
              placeholder={t("search")}
              containerStyle=" "
              inputStyle="search_input"
              inputContainerStyle="search_container"
              rootStyle="col-12"
              icon="search"
            />
          </div>
          <div className="my-3 row px-0 align-items-center w-auto ">
            <RadioboxForm
              formName={dataTableRef}
              options={[
                { value: false, label: "Unarchived" },
                { value: true, label: "Archived" },
              ]}
              name="only_archive"
              type="radio"
              containerStyle="my-2 my-lg-0"
              labelStyle="label-style"
              optionsContainerStyle="optionsContainerStyle"
              optionItemStyle="optionItemStyle"
              optionInputStyle=" "
              optionLabelStyle="optionLabelStyle"
            />
          </div>

          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_CLONE_WORK_TIMINGS]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <div className="mb-4 pl-3 text-right my-0 my-md-3">
                <button
                  className="btn add_new_btn_style"
                  type="button"
                  disabled
                >
                  <FontAwesomeIcon className="mr-2" icon={faPlus} />
                  <span>{t("add new")}</span>
                </button>
              </div>
            }
          >
            <div className="mb-4 pl-3 text-right my-md-3">
              <Tooltip
                title={
                  workTimingsList?.total >= workTimingsLimit
                    ? t("you have reached the maximum number of Work Timings")
                    : ""
                }
              >
                <button
                  className="btn add_new_btn_style"
                  type="button"
                  onClick={handleAddWorkTimings}
                  disabled={workTimingsList?.total >= workTimingsLimit}
                >
                  <FontAwesomeIcon className="mr-2" icon={faPlus} />
                  <span>{t("add new")}</span>
                </button>
              </Tooltip>
            </div>
          </HasPrivileges>
        </div>

        <DataTable
          className="cards_table employee-att-logs-expand-collapse-wrapper"
          noDataComponent={<div className="p-4"> {t("no_records")} </div>}
          columns={columns}
          data={workTimingsList.data}
          paginationComponent={() => (
            <Pagination
              tableRef={dataTableRef}
              styleWraper=""
              onPaginate={handlePaginate}
            />
          )}
          noHeader
          pagination={true}
          paginationServer={true}
          progressPending={workTimingsList.isLoading}
          progressComponent={<Loader />}
          expandableRows={true}
          expandableRowsComponent={<ExpandedComponent />}
          expandableRowDisabled={(row) => !row?.oldVersions?.length} // disabled if not archived
          expandableIcon={{
            expanded: <FontAwesomeIcon icon={faAngleUp} />,
            collapsed: <FontAwesomeIcon icon={faAngleDown} />,
          }}
          customStyles={{
            tableWrapper: { style: { width: "1400px", minWidth: "100%" } },
          }}
        />
      </div>

      <WorkTimingForm />

      {/* Start of work timing preview modal */}
      <EditWorkTimePreview isWorkTimingList />
      {/* End of work timing preview modal */}
    </>
  );
};

export default WorkTimingsListPage;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { InputForm, CheckboxBooleanForm } from "form-builder";
import PlanFeatureSection from "./PlanFeatureSection";
import SystemPriviligesTree from "../../Components/SystemPlanComponents/SystemPriviligesTree";
import { Spinner } from "reactstrap";

import {
  upsertSystemPlanAction,
  resetSystemPlanForm,
  editSystemPlanAction,
  toogleViewPlanPrivileges,
} from "../../Store/Actions";
import Loader from "../../Components/Loader";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";

const formName = "systemPlan";
const formNameValidation = "systemPlanValidation";
const formServerValidation = "systemPlanServerValidation";

const EditSystemPlanPage = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { planId } = useParams();

  const [formSubmitting, setFormSubmitting] = useState(false);

  const formProps = {
    formName,
    formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  useEffect(() => {
    props.editSystemPlanAction(planId);
    return () => {
      props.resetSystemPlanForm();
    };
  }, []);

  const handleGoBack = (planId) => {
    if (!props.showPrivilegesOnly) {
      history.push(`/admin-panel?tab=plans`);
    } else {
      props.toogleViewPlanPrivileges();
    }
  };

  const handleSubmitBtn = () => {
    setFormSubmitting(true);
    if (props.systemPlanValidation?.length) {
      return;
    }
    const { is_free, ...systemPlan } = props.systemPlan;
    let submitPlan = {
      ...systemPlan,
      trial_value: is_free ? null : +props.systemPlan?.trial_value,
    };
    // console.log(submitPlan);
    props.upsertSystemPlanAction(submitPlan, handleGoBack);
  };

  return (
    <div className="px-5 pt-4 pb-2">
      {!props.showPrivilegesOnly ? (
        <>
          <div className="col-12 px-0">
            <h4 className="page-title">{t("edit plan")}</h4>
          </div>
          <div className="">
            <div className="row d-flex align-items-center">
              <div className="col">
                <div>
                  <InputForm
                    {...formProps}
                    validateBy="textRequired"
                    name="name"
                    type="text"
                    placeholder={t("name")}
                    reducer="admin"
                    labelStyle="mb-2"
                    containerStyle="mt-0 mb-3"
                    inputContainerStyle=" "
                  />
                </div>
                <PlanFeatureSection label="number of offices" featureId="2" />
                <PlanFeatureSection label="number of profiles" featureId="6" />
              </div>
              <div
                className="mx-4"
                style={{
                  height: "120px",
                  width: "1px",
                  backgroundColor: "#ccc",
                }}
              />
              <div className="col">
                <div className="d-flex flex-row flex-wrap justify-content-between">
                  <CheckboxBooleanForm
                    {...formProps}
                    options={["free plan"]}
                    name="is_free"
                    validationName="input.absent_early_sign_out"
                    type="checkbox"
                    reducer="admin"
                  />
                  {props.systemPlan.is_free !== 1 ? (
                    <InputForm
                      labelStyle="mr-5"
                      {...formProps}
                      containerStyle="container-style-default ml-auto"
                      validateBy="textRequired"
                      label="free trial period"
                      name="trial_value"
                      reducer="admin"
                      validationName="input.sign_out_cuttoff_time"
                      type="text"
                      hasSuffix
                      suffixTitle="Days"
                    />
                  ) : null}
                </div>

                <PlanFeatureSection
                  label="number of normal work timings"
                  featureId="5"
                />
                <PlanFeatureSection label="number of employees" featureId="1" />
              </div>
            </div>
            <div className="invalid-container-style">
              <div className="validity-msg-style">
                {props?.systemPlanServerValidation?.[
                  "input.features"
                ]?.toString() ?? ""}
              </div>
            </div>
          </div>
          <hr />
        </>
      ) : null}

      {props.systemPlanLoading && !props?.system_privileges ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : (
        <div className="view-system-privileges">
          <SystemPriviligesTree
            parents={JSON.parse(props?.system_privileges)}
            edit={
              props.showPrivilegesOnly &&
              !HelperFns.checkPrivileges({
                privileges: [Privilages.SUPER_PRIVILEGE],
              })
            }
          />
          <div className="invalid-container-style">
            <div className="validity-msg-style">
              {props?.systemPlanServerValidation?.[
                "input.selectedPrivileges"
              ]?.toString() ?? ""}
            </div>
          </div>
        </div>
      )}
      {HelperFns.checkPrivileges({
        privileges: [Privilages.SUPER_PRIVILEGE],
      }) || !props.showPrivilegesOnly ? (
        <div className="d-flex justify-content-end mt-4 pt-2">
          <button
            className="save-button"
            onClick={handleSubmitBtn}
            type="submit"
          >
            {props.systemPlanActions?.isLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                size="sm"
                color="#fff"
              />
            ) : (
              t("submit")
            )}
          </button>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    systemPlan: state.admin?.systemPlan,
    systemPlanActions: state.admin?.systemPlanActions,
    system_privileges: state?.admin?.system_privileges,
    systemPlanLoading: state.admin?.loadingEditPlan,
    systemPlanValidation: state.admin?.systemPlanValidation,
    systemPlanServerValidation: state.admin?.systemPlanServerValidation,
  };
};

export default connect(mapStateToProps, {
  upsertSystemPlanAction,
  resetSystemPlanForm,
  editSystemPlanAction,
  toogleViewPlanPrivileges,
})(EditSystemPlanPage);

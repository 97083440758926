import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { InputForm, CheckboxForm, BSelect, RadioboxForm, DateTimePickerForm } from "form-builder";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  dismissApplicationModalAction,
  upsertApplicationAction,
  fetchIntakeFormAction
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../Components/Loader";
import ViewFromBuilder from "../ViewFormBuilder";
const formName = "applicationModal";
const formNameValidation = "applicationModalValidation";
const formServerValidation = "applicationModalServerValidation";



const ApplicationModal = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [showAltPhone, setShowAltPhone] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  // initail vlaue for requestFiles set to empty array
  const [requestFiles, setRequestFiles] = useState([]);

  // function to add object to requestFiles array
  const addRequestFileObject = (file) => {
    setRequestFiles((requestFiles) => [...requestFiles, file]);
  };

  // function to remove an object from an requestFiles array
  const removeRequestFileObject = (id) => {
    setRequestFiles((requestFiles) =>
      requestFiles.filter((file) => file?.id !== id)
    );
  };

  // handle uploader image change
  const handleImageChange = ({ file, meta }, status) => {
    // to convert image to base64 string
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      let base64 = reader.result.split(",");
      let fileType = file?.type.split("/");

      if (status == "done") {
        let target = {
          file: base64[1],
          extension: fileType[1],
          id: meta?.id,
        };
        addRequestFileObject(target);
      }
    };

    if (status == "removed") {
      removeRequestFileObject(meta?.id);
    }
    return;
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    if (props.modalValidation.length) {
      return;
    }

    // to send file and extenstion only without id key in the array
    let formAttachments = requestFiles?.map(({ file, extension }) => ({
      file,
      extension,
    }));
    let answers = intakeFormFields?.sort((a, b) => a.order - b.order)?.map((field) => ({
      field_id: field.id,
      type: field.type,
      suggested_field_type: field.suggested_field_type,
      value: !field?.options?.length && field?.type?.toLowerCase() !== "attachment" ? jobPostIntakeForm?.[field.id] ?? null : null,
      options_id: field?.options?.length && field?.type?.toLowerCase() !== "attachment" && jobPostIntakeForm?.[field.id] ? [jobPostIntakeForm?.[field.id]]?.flat(Infinity) : [],
      attached_file: field?.type?.toLowerCase() === "attachment" && typeof (jobPostIntakeForm?.[field.id]) !== "string" && jobPostIntakeForm?.[field.id] ? jobPostIntakeForm?.[field.id] : null,
    }));

    props.upsertApplicationAction({
      data: {
        job_post_id: props?.applicationModal?.job_post_id,
        intake_form_id: props?.applicationModal?.intake_form_id,
        answers,
      },


      ref: "applicationModalActions",
    });
  };

  const toggleModal = () => {
    props.dismissApplicationModalAction({
      ref: "applicationModalActions",
      formName,
    });
  };

  // updates 

  const intakeFormFields = useSelector((state) => state.recruitment.jobPostIntakeFormFields);
  const jobPostIntakeForm = useSelector((state) => state.recruitment.jobPostIntakeForm);
  const isFetchJobPostIntakeFormLoading = useSelector((state) => state.recruitment.isFetchJobPostIntakeFormLoading);

  const fetchIntakeForm = () => {
    dispatch(fetchIntakeFormAction(props?.applicationModal?.job_post_id));
  }


  useEffect(() => {
    setIsSubmitting(false);
    setShowAltPhone(false);
    if (props?.modalData?.isVissible) {
      fetchIntakeForm()
    }

    setRequestFiles([]);
    return () => { };
  }, [props?.modalData?.isVissible]);


  if (isFetchJobPostIntakeFormLoading) { return <Loader /> }
  return (
    <MainModal
      isOpen={props?.modalData?.isVissible}
      toggle={toggleModal}
      modalTitle={t("New Application")}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={props?.modalData?.isLoading}
      className="application_form_wrapper_style"
    >
      <ViewFromBuilder formServerValidation={"applicationModalServerValidation"} formName="jobPostIntakeForm" formFields={intakeFormFields} />
      {props?.serverVaildation?.["input.intake_form_id"] ? <div className='errors-wrapper'>
        <span className='error-color'>{props?.serverVaildation?.["input.intake_form_id"]?.toString()}</span>
      </div> : null}
      {/* <div className="row">
          <div className="col-12">
            <InputForm
              label="Applicant Name"
              labelStyle="mb-2"
              {...FormProps}
              reducer="recruitment"
              validateBy="textRequired"
              name="name"
              validationName={`input.name`}
              containerStyle=""
              inputContainerStyle="w-100"
            />
          </div>
        </div> */}

      {/* <div className="row">
          <div className="col-12">
            <InputForm
              label="email"
              labelStyle="mb-2"
              {...FormProps}
              reducer="recruitment"
              validateBy="textRequired"
              name="email"
              validationName={`input.email`}
              containerStyle=""
              inputContainerStyle="w-100"
            />
          </div>
        </div> */}

      {/* <div className="row mb-1">
          <div className="col-12">
            <InputForm
              label="phone number"
              labelStyle="mb-2"
              {...FormProps}
              reducer="recruitment"
              validateBy="textRequired"
              name="phone"
              validationName={`input.phone`}
              containerStyle=""
              inputContainerStyle="w-100"
            />
          </div>
        </div> */}

      {/* <div className="row mb-2">
          <div className="col-12">
            <p
              className="mb-0 fit-width"
              onClick={() => setShowAltPhone(!showAltPhone)}
            >
              {t("Add Another Phone Number")}
            </p>
          </div>
        </div> */}

      {/* {showAltPhone ? (
          <div className="row">
            <div className="col-12">
              <InputForm
                label="Alt. Phone Number"
                labelStyle="mb-2"
                {...FormProps}
                reducer="recruitment"
                name="alt_phone"
                validationName={`input.alt_phone`}
                containerStyle=""
                inputContainerStyle="w-100"
              />
            </div>
          </div>
        ) : null} */}

      {/* <div className="row mt-2">
          <div
            className={`col-12 mt-1 mb-2 ${props?.serverVaildation &&
              "input.files" in props?.serverVaildation
              ? "invalid-container-style"
              : ""
              }`}
          >
            <label className="mb-2 validity-label-style">{t("CV / Resume")}</label>

            <div>
              <Dropzone
                onChangeStatus={handleImageChange}
                canRemove
                accept="image/*,.pdf,.doc,.docx,.xlsm,.xls,.xlsx"
                name="attachments"
                multiple={true}
                inputContent={t("face_placeholder")}
              />
            </div>

            <span className="validity-msg-style">
              {props?.serverVaildation &&
                "input.files" in props?.serverVaildation
                ? props?.serverVaildation["input.files"]
                : null}
            </span>
          </div>
        </div> */}

      {/* (Start) Error Message */}
      {props?.modalData?.modalMessage && isSubmitting && (
        <div className="warnig-msg-style">
          {props?.modalData?.modalMessage}
        </div>
      )}
      {/* (End) Error Message */}
    </MainModal >
  );
};

const mapStateToProps = (state) => ({
  data: state.recruitment,
  modalData: state.recruitment.applicationModalActions,
  modalValidation: state.recruitment[formNameValidation],
  applicationModal: state.recruitment[formName],
  serverVaildation: state.recruitment[formServerValidation],
});

export default connect(mapStateToProps, {
  dismissApplicationModalAction,
  upsertApplicationAction,
})(ApplicationModal);

import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import BlockUi from "react-block-ui";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AttendanceProfileForm from "../../Containers/AttendanceProfileForm";

const AttendanceProfilePage = () => {
  const { t } = useTranslation();

  // Reducer State
  const isEditLoading = useSelector(
    (state) => state.super.editAttProfileLoading
  );
  const isUpsertLoading = useSelector(
    (state) => state.super.upsertAttProfileLoading
  );
  const attProfileNormalServerValidation = useSelector(
    (state) => state.super.attProfileNormalServerValidation
  );

  return (
    <BlockUi tag="div" blocking={isUpsertLoading || isEditLoading}>
      <div className="content px-5 pb-5">
        <Link
          to="/settings?tab=att-profile"
          className="back-btn d-flex align-items-center position-sticky mt-3"
        >
          <KeyboardBackspaceIcon className="back-icon" />
          <span>{t("back")}</span>
        </Link>
        <div
          className={`edit_attendance_profile_header ${
            attProfileNormalServerValidation?.["input.id"]
              ? "border-danger error-color"
              : ""
          } `}
        >
          {t("attendance profile")}
        </div>

        <AttendanceProfileForm />
      </div>
    </BlockUi>
  );
};

export default AttendanceProfilePage;

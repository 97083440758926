import React from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";

import MainModal from "../MainModal";
import { DownloadFileIconButton } from "../IconButtonWithTooltip";

const CheckNotesModal = ({ data, onClose }) => {
  const { t } = useTranslation();

  // Constants
  const attachments = { in: data?.checkInFiles, out: data?.checkOutFiles };
  const fields = {
    in: data?.checkInFormAnswers?.map(({ checkInOutFormField, answer }) => ({
      answer,
      name: checkInOutFormField?.name,
    })),
    out: data?.checkOutFormAnswers?.map(({ checkInOutFormField, answer }) => ({
      answer,
      name: checkInOutFormField?.name,
    })),
  };

  return (
    <MainModal isOpen modalTitle={t("Check in/out Notes")} toggle={onClose}>
      <div className="d-flex align-items justify-content-between mb-4">
        <strong className="dark-color text-16">{data?.employeeName}</strong>
        <time dateTime={data?.date} className="font-weight-bold">
          {moment(data?.date).format("dddd DD - M - Y")}
        </time>
      </div>

      {["in", "out"].map((type) =>
        data?.[`check_${type}_time`] ? (
          <React.Fragment key={type}>
            <div className="d-flex align-items justify-content-between blue-color mb-2">
              <strong className="text-16">{t(`Check ${type}`)}</strong>
              <div className="d-flex gap-10">
                <time
                  dateTime={moment(data?.[`check_${type}_time`]).format(
                    "HH:mm"
                  )}
                >
                  {moment(data?.[`check_${type}_time`]).format("hh:mm A")}
                </time>
                {
                  data?.[
                    `check${type[0].toUpperCase() + type.slice(1)}Location`
                  ]?.name
                }
              </div>
            </div>

            {fields[type]?.length ? (
              fields[type]?.map((field) => (
                <div>
                  <strong className="d-block mb-2" style={{ color: "#5d6979" }}>
                    {field?.name}
                  </strong>
                  <p>{field?.answer}</p>
                </div>
              ))
            ) : (
              <>
                <strong className="d-block mb-2" style={{ color: "#5d6979" }}>
                  {t("description")}
                </strong>
                <p className="pt-2 px-4" style={{ backgroundColor: "f8f8f8" }}>
                  {data[`check_${type}_description`]}
                </p>
              </>
            )}

            {attachments[type]?.length ? (
              <>
                <strong className="d-block mb-2">Attachments</strong>
                <div className="d-flex gap-20">
                  {attachments[type]?.map((attachment) => (
                    <DownloadFileIconButton
                      key={attachment?.id}
                      href={attachment?.path}
                    />
                  ))}
                </div>
              </>
            ) : null}
          </React.Fragment>
        ) : null
      )}
    </MainModal>
  );
};

export default CheckNotesModal;

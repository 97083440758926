import React from "react";
import { useTranslation } from "react-i18next";

import HelperFns from "../../Helpers/HelperFns";

export default function DashboardWidget(props) {
  const { t } = useTranslation();

  const {
    dashContainerStyle = "dash-widget",
    bgImageClass = "",
    dashInfoStyle = "dash-widget-info",
    titleStyle = "widget-title",
    btnStyle = "show_all_btn_style",
    status,
    value = 0,
  } = props;

  return (
    <div className={`${dashContainerStyle} ${bgImageClass}`}>
      <div className={dashInfoStyle}>
        <h3>{props?.value}</h3>
        <span className={titleStyle}>{props?.title}</span>
        <div
          className={`${btnStyle} ${value != 0 ? " " : "invisible"}`}
          onClick={() => props?.handleShowAllModal(status)}
        >
          {t("show_all")}
        </div>
      </div>
    </div>
  );
}

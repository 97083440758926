import React from "react";
import { useSelector } from "react-redux";

const useAllowCostCenter = () => {
  const allowCostCenter = useSelector(
    (state) => state?.auth?.userProfile?.company?.allow_cost_center
  );

  return {
    allowCostCenter,
  };
};

export default useAllowCostCenter;

import React from "react";
import { BSelect, InputForm, DateTimePickerForm } from "form-builder";
import { useSelector, useDispatch } from "react-redux";
import {
  childInputChange,
  onInputResetWithValueAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import PaymentFactors from "../../Constants/PaymentFactors";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

const OvertimeCalculation = ({ i, FormProps, totalGross, totalNet }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const salaryConfigForm = useSelector((state) => state.super.salaryConfigForm);
  const overtimeConfiguration = salaryConfigForm?.overtimeConfiguration;
  const paymentFactors = useSelector((state) => state.super.paymentFactors);
  const salaryConfigFormValidation = useSelector(
    (state) => state.super?.[FormProps?.formServerValidation]
  );

  const renderCalcValue = (overtime_factor) => {
    let base = +salaryConfigForm?.base_salary || 0;
    let gross = totalGross();
    let net = totalNet();
    if (salaryConfigForm?.calculate_salary_by == "hourly") {
      return "";
    }
    switch (overtimeConfiguration?.paymentFactor) {
      case PaymentFactors.BASE_PERCENTAGE:
        return !!base && !!overtimeConfiguration?.amount && overtime_factor
          ? "=" +
              (
                (+base / +overtimeConfiguration?.amount) *
                +overtime_factor
              ).toFixed(2)
          : 0;
      case PaymentFactors.GROSS_PERCENTAGE:
        return !!gross && !!overtimeConfiguration?.amount && overtime_factor
          ? "=" +
              (
                (+gross / +overtimeConfiguration?.amount) *
                overtime_factor
              ).toFixed(2)
          : 0;
      case PaymentFactors.NET_PERCENTAGE:
        return !!net && !!overtimeConfiguration?.amount && overtime_factor
          ? "=" +
              (
                (+net / +overtimeConfiguration?.amount) *
                overtime_factor
              ).toFixed(2)
          : 0;
      default:
        return !!overtimeConfiguration?.amount && !!overtime_factor
          ? "=" + (overtimeConfiguration?.amount * overtime_factor).toFixed(2)
          : 0;
    }
  };

  const handleAddOvertimeSettingInput = () => {
    handleChangeOvertimeConfiguration("advancedOvertimeSettings", [
      ...overtimeConfiguration?.advancedOvertimeSettings,
      { from: "00:00", to: "00:00", factor: "1" },
    ]);
  };

  const handleDeleteOvertimeSettingInput = (index) => {
    handleChangeOvertimeConfiguration(
      "advancedOvertimeSettings",
      overtimeConfiguration?.advancedOvertimeSettings?.filter(
        (_, i) => i != index
      )
    );
  };

  const resetOvertimeSettings = () => {
    handleChangeOvertimeConfiguration("advancedOvertimeSettings", []);
  };

  const handleAdvancedOvertime = () => {
    if (overtimeConfiguration?.advancedOvertimeSettings?.length) {
      resetOvertimeSettings();
    } else {
      handleAddOvertimeSettingInput();
    }
  };

  if (
    salaryConfigForm?.calculate_all_work_hours &&
    salaryConfigForm?.calculate_salary_by == "hourly"
  ) {
    return null;
  }

  const handleChangeOvertimeConfiguration = (name, value) => {
    dispatch(
      onInputResetWithValueAction(
        FormProps?.formName,
        "overtimeConfiguration",
        {
          ...overtimeConfiguration,
          [name]: value,
        }
      )
    );
  };

  const handleChangeAdvancedOvertimeSettings = (index, name, value) => {
    const advancedOvertimeSettingsArray = [
      ...(overtimeConfiguration?.advancedOvertimeSettings ?? []),
    ];

    advancedOvertimeSettingsArray[index] = {
      ...advancedOvertimeSettingsArray?.[index],
      [name]:
        name == "from" || name == "to" ? moment(value).format("HH:mm") : value,
    };

    handleChangeOvertimeConfiguration("advancedOvertimeSettings", [
      ...advancedOvertimeSettingsArray,
    ]);
  };

  return (
    <>
      <div className="d-flex flex-column flex-fill">
        <div className="d-flex flex-fill flex-row align-items-end">
          <div
            className={`col p-0 ${
              salaryConfigForm?.calculate_salary_by == "hourly" ? "d-none" : ""
            }`}
          >
            <BSelect
              {...FormProps}
              label="Overtime Hour"
              labelStyle="font-weight-bold mb-0"
              name="paymentFactor"
              optionLabel="label"
              optionValue="value"
              options={paymentFactors}
              keepDefaultStyle
              containerStyle="my-0"
              placeholder={t("Select Payment Factor")}
              inputContainerStyle="w-100"
              validateBy="textRequired"
              optDependType="equal"
              optDependKey="calculation"
              optDependValue={["1", 1]}
              value={paymentFactors?.find(
                (opt) => opt?.value == overtimeConfiguration?.paymentFactor
              )}
              onChange={(value) =>
                handleChangeOvertimeConfiguration("paymentFactor", value?.value)
              }
              validationName={`input.user_salary_config_input.salary_calculations.${i}.payment_factor_id`}
              isDisabled={
                salaryConfigForm?.calculate_salary_by !== "payment_interval"
              }
              icon="money"
            />
          </div>
          <div className="col-8">
            <div className="d-flex align-items-end">
              {salaryConfigForm?.calculate_salary_by == "hourly" ? (
                <label className="font-weight-bold mb-0 mr-2 my-2">
                  {t("Overtime Hour")}
                </label>
              ) : (
                <>
                  {overtimeConfiguration?.paymentFactor !==
                  PaymentFactors.FIXED_AMOUNT ? (
                    <span className="min-w-85">
                      {overtimeConfiguration?.paymentFactor ==
                      PaymentFactors.BASE_PERCENTAGE
                        ? `${t("base_salary")} /`
                        : overtimeConfiguration?.paymentFactor ==
                          PaymentFactors.GROSS_PERCENTAGE
                        ? `${t("gross_salary")} /`
                        : `${t("net_salary")} /`}
                    </span>
                  ) : null}
                  <div className="col px-0">
                    <InputForm
                      name="value"
                      type="number"
                      onWheelCapture={(e) => {
                        e.target.blur();
                      }}
                      {...FormProps}
                      label={
                        overtimeConfiguration?.paymentFactor ==
                        PaymentFactors.FIXED_AMOUNT
                          ? t("amount")
                          : null
                      }
                      placeholder={
                        overtimeConfiguration?.paymentFactor ==
                        PaymentFactors.FIXED_AMOUNT
                          ? t("amount")
                          : t("month hours")
                      }
                      labelStyle="mb-0"
                      containerStyle=""
                      inputContainerStyle="overtime_hours_wrapper_input_style w-100"
                      validateBy="textRequired"
                      onChange={(e) => {
                        handleChangeOvertimeConfiguration(
                          "amount",
                          e?.target?.value
                        );
                      }}
                      value={overtimeConfiguration?.amount}
                      validationName={`input.user_salary_config_input.salary_calculations.${i}.value`}
                      icon={
                        overtimeConfiguration?.paymentFactor ==
                        PaymentFactors.FIXED_AMOUNT
                          ? "money"
                          : null
                      }
                    />
                  </div>
                </>
              )}
              {overtimeConfiguration?.advancedOvertimeSettings
                ?.length ? null : (
                <>
                  <div className="col px-0 mx-2">
                    <InputForm
                      name="factor"
                      type="number"
                      onWheelCapture={(e) => {
                        e.target.blur();
                      }}
                      {...FormProps}
                      placeholder={t("factor")}
                      label={t("factor")}
                      labelStyle="mb-0"
                      containerStyle=""
                      inputContainerStyle="w-100"
                      validateBy="textRequired"
                      value={overtimeConfiguration?.factor}
                      onChange={(e) =>
                        handleChangeOvertimeConfiguration(
                          "factor",
                          e?.target?.value
                        )
                      }
                      validationName={`input.user_salary_config_input.salary_calculations.${0}.overtimeSettings.overtime_payment_factor`} // should change this
                    />
                  </div>
                  <div>
                    <span className="font-weight-bold mx-2">
                      {renderCalcValue(overtimeConfiguration?.factor)}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <label
          className={`col fit-width ${
            overtimeConfiguration?.advancedOvertimeSettings?.length
              ? "blue-color"
              : "gray-color"
          } h5 my-2`}
          role="button"
          onClick={handleAdvancedOvertime}
        >
          {t("Advanced Overtime Settings")}
        </label>

        {overtimeConfiguration?.advancedOvertimeSettings?.length ? (
          <>
            <div className="mx-3 mt-1">
              <div className="d-flex align-items-baseline">
                <div className="mr-3 mb-2">
                  <p className="dark-color fa-1x h5">
                    {t("Overtime Hour Factor Time Range")}
                  </p>
                </div>

                <div>
                  <button
                    className="btn add_new_btn_style bg-dark"
                    type="button"
                    onClick={handleAddOvertimeSettingInput}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>
              {overtimeConfiguration?.advancedOvertimeSettings?.map(
                (overtimeSetting, index, data) => {
                  // const fromIndex = index == 0 ? index : index - 1;
                  const fromKey = data[index - 1]?.to;
                  return (
                    <div className="row align-items-center">
                      <div className="col">
                        <InputForm
                          name="factor"
                          type="number"
                          onWheelCapture={(e) => {
                            e.target.blur();
                          }}
                          {...FormProps}
                          placeholder={t("overtime payment factor")}
                          labelStyle="mb-0"
                          containerStyle=""
                          inputContainerStyle="w-100"
                          validateBy="textRequired"
                          value={overtimeSetting?.factor}
                          onChange={(e) =>
                            handleChangeAdvancedOvertimeSettings(
                              index,
                              "factor",
                              e?.target?.value
                            )
                          }
                          validationName={`input.user_salary_config_input.salary_calculations.${index}.overtimeSettings.overtime_payment_factor`}
                        />
                      </div>
                      <div className="align-items-center col row">
                        {index == 0 ? (
                          <div className="w-185">
                            <DateTimePickerForm
                              timeOnly
                              requestFormat="HH:mm"
                              {...FormProps}
                              name={"from"}
                              labelStyle="mx-1"
                              label="from"
                              onChange={(value) =>
                                handleChangeAdvancedOvertimeSettings(
                                  index,
                                  "from",
                                  value
                                )
                              }
                              onSelect={(value) =>
                                handleChangeAdvancedOvertimeSettings(
                                  index,
                                  "from",
                                  value
                                )
                              }
                              value={
                                Boolean(overtimeSetting?.from)
                                  ? moment(overtimeSetting?.from, "HH:mm")
                                  : null
                              }
                              validationName={`input.user_salary_config_input.salary_calculations.${index}.overtimeSettings.from`}
                            />
                          </div>
                        ) : (
                          <div className="w-185">
                            <DateTimePickerForm
                              timeOnly
                              requestFormat="HH:mm"
                              {...FormProps}
                              name="to"
                              labelStyle="mx-1"
                              label="from"
                              validationName={`input.user_salary_config_input.salary_calculations.${
                                index - 1
                              }.overtimeSettings.to`}
                              onChange={(value) =>
                                handleChangeAdvancedOvertimeSettings(
                                  index - 1,
                                  "to",
                                  value
                                )
                              }
                              onSelect={(value) =>
                                handleChangeAdvancedOvertimeSettings(
                                  index - 1,
                                  "to",
                                  value
                                )
                              }
                              value={
                                Boolean(fromKey)
                                  ? moment(fromKey, "HH:mm")
                                  : null
                              }
                            />
                          </div>
                        )}

                        <div className="w-185">
                          <DateTimePickerForm
                            timeOnly
                            requestFormat="HH:mm"
                            {...FormProps}
                            name="to"
                            labelStyle="mx-1"
                            label="to"
                            validationName={`input.user_salary_config_input.salary_calculations.${index}.overtimeSettings.to`}
                            onChange={(value) =>
                              handleChangeAdvancedOvertimeSettings(
                                index,
                                "to",
                                value
                              )
                            }
                            onSelect={(value) =>
                              handleChangeAdvancedOvertimeSettings(
                                index,
                                "to",
                                value
                              )
                            }
                            value={
                              Boolean(overtimeSetting?.to)
                                ? moment(overtimeSetting?.to, "HH:mm")
                                : null
                            }
                          />
                        </div>

                        <span className="font-weight-bold mx-2">
                          {renderCalcValue(overtimeSetting?.factor)}
                        </span>

                        {overtimeConfiguration?.advancedOvertimeSettings
                          ?.length ? (
                          <div className="mx-1">
                            <button
                              className="btn delete_btn_style"
                              type="button"
                              onClick={() =>
                                handleDeleteOvertimeSettingInput(index)
                              }
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </>
        ) : null}
        {salaryConfigFormValidation?.[
          `input.user_salary_config_input.salary_calculations.${i}.overtimeSettings`
        ] ? (
          <span className="error-color">
            {
              salaryConfigFormValidation?.[
                `input.user_salary_config_input.salary_calculations.${i}.overtimeSettings`
              ]
            }
          </span>
        ) : null}
      </div>
    </>
  );
};

export default OvertimeCalculation;

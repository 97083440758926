import React from "react";
import { useTranslation } from "react-i18next";
import { BSelect } from "form-builder";
import { useQuery } from "@apollo/client";
import {
  COST_CENTERS_REPORT,
  COST_CENTERS_REPORT_FILTERS_OPTIONS,
} from "../../Graphql/query";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import Constants from "../../Constants";
import Loader from "../../Components/Loader";
import ExpandedCostCenterReport from "./ExpandedCostCenterReport";

const formProps = {
  formName: "costCentersReport",
};

const CostCentersReport = () => {
  const { t } = useTranslation();

  const filters = useSelector((state) => state?.super?.[formProps.formName]);

  const {
    data: costCentersReportFiltersOptionsQueryData,
    loading: optionsLoading,
  } = useQuery(COST_CENTERS_REPORT_FILTERS_OPTIONS);

  const { data: costCentersReportQueryData, loading: reportLoading } = useQuery(
    COST_CENTERS_REPORT,
    {
      variables: {
        id: filters?.costCenter?.toString(),
        userId: filters?.employees?.map(Number),
        currencyId: +filters?.currencyId,
        month: +filters?.month,
        year: +filters?.year,
        paid_at: filters?.week,
      },
    }
  );

  const costCenterReportData =
    costCentersReportQueryData?.payroll_v2_cost_center ?? [];

  // const costCenterReportData = [
  //   {
  //     id: 1,
  //     name: "cost center A",
  //     salary: 20000,
  //     allowance: 1000,
  //     bonus: 1000,
  //     overtime: 1000,
  //     penalty: 1000,
  //     claim: 1000,
  //     medical: 1000,
  //     social: 1000,
  //     tax: 1000,
  //     otherDeduction: 1000,
  //     otherExtra: 1000,
  //     total: 1000,
  //     json: JSON.stringify([
  //       {
  //         ID: 1,
  //         NAME: "employee A",
  //         SALARY: 10000,
  //         ALLOWANCE: 1000,
  //         BONUS: 350,
  //         OVERTIME: 345,
  //         PENALTY: 200,
  //         SOCIAL: 500,
  //         CLAIM: 300,
  //         MEDICAL: 200,
  //         TAX: 200,
  //         OTHER_DEDUCTION: 1250,
  //         OTHER_EXTRA: null,
  //         TOTAL: 1400,
  //       },
  //       {
  //         ID: 1,
  //         NAME: "employee A",
  //         SALARY: 10000,
  //         ALLOWANCE: 1000,
  //         BONUS: 350,
  //         OVERTIME: 345,
  //         PENALTY: 200,
  //         SOCIAL: 500,
  //         CLAIM: 300,
  //         MEDICAL: 200,
  //         TAX: 200,
  //         OTHER_DEDUCTION: 1250,
  //         OTHER_EXTRA: null,
  //         TOTAL: 1400,
  //       },
  //     ]),
  //   },
  // ];

  const costCentersOptions =
    costCentersReportFiltersOptionsQueryData?.cost_centers?.data ?? [];
  const currenciesOptions =
    costCentersReportFiltersOptionsQueryData?.currencies ?? [];
  const employeesOptions =
    costCentersReportFiltersOptionsQueryData?.users_by_role?.data ?? [];

  const columns = [
    {
      name: t("cost center"),
      // wrap: true,
      sortable: false,
      width: "150px",
      style: {
        backgroundColor: "#fff",
      },
      cell: (row) => <>{row?.name}</>,
    },
    {
      name: t("base"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(35, 170, 235, 0.14) !important",
      },
      cell: (row) => <>{row?.salary}</>,
    },
    {
      name: t("allowances"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18) !important",
      },

      cell: (row) => <>{row?.allowance}</>,
    },

    {
      name: t("overtime"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18) !important",
      },

      cell: (row) => <>{row?.overtime}</>,
    },
    {
      name: t("bonuses"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18) !important",
      },

      cell: (row) => <>{row?.bonus}</>,
    },
    {
      name: t("claims"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18) !important",
      },

      cell: (row) => <>{row?.claim}</>,
    },
    {
      name: t("penalties"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },
      cell: (row) => <>{row?.penalty}</>,
    },
    {
      name: t("social"),
      wrap: true,
      sortable: false,
      grow: 0.5,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },
      cell: (row) => <>{row?.social}</>,
    },
    {
      name: t("medical"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },
      cell: (row) => <>{row?.medical}</>,
    },
    {
      name: t("taxes"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },

      cell: (row) => <>{row?.tax}</>,
    },
    {
      name: t("others"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },

      cell: (row) => <div>{row?.otherDeduction}</div>,
    },
    {
      name: t("total"),
      wrap: true,
      sortable: false,
      grow: 1.4,
      // style: {
      //   backgroundColor: "#f8f9fa !important",
      //   minWidth: "150px",
      // },

      cell: (row) => <div>{row?.total}</div>,
    },
  ];

  return (
    <div>
      <div className="row mb-3">
        <BSelect
          {...formProps}
          name="costCenter"
          label={t("cost center")}
          keepDefaultStyle
          placeholder={t("select option")}
          options={costCentersOptions}
          icon="money"
          isLoading={optionsLoading}
          rootStyle="col-4"
        />
        <BSelect
          {...formProps}
          name="employees"
          label={t("employees")}
          keepDefaultStyle
          placeholder={t("select option")}
          options={employeesOptions}
          icon="person"
          isLoading={optionsLoading}
          rootStyle="col-4"
          isMulti
        />
        <BSelect
          {...formProps}
          name="currencyId"
          label={t("currency")}
          keepDefaultStyle
          placeholder={t("select option")}
          options={currenciesOptions}
          icon="currency"
          isLoading={optionsLoading}
          rootStyle="col-4"
        />
        <BSelect
          {...formProps}
          name="year"
          label={t("year")}
          keepDefaultStyle
          placeholder={t("select option")}
          options={Constants.YearData}
          icon="calendar"
          isLoading={optionsLoading}
          rootStyle="col-4"
          isClearable
          optionLabel="value"
          optionValue="value"
        />
        <BSelect
          {...formProps}
          name="month"
          label={t("month")}
          keepDefaultStyle
          placeholder={t("select option")}
          options={Constants.MonthsData}
          icon="calendar"
          isLoading={optionsLoading}
          rootStyle="col-4"
          isClearable
          optionLabel="value"
          optionValue="key"
        />

        <BSelect
          {...formProps}
          name="week"
          label={t("week")}
          keepDefaultStyle
          placeholder={t("select option")}
          options={[]}
          icon="calendar"
          isLoading={optionsLoading}
          rootStyle="col-4"
        />
      </div>

      <DataTable
        className="requests-profile-data-list payroll_datatable_wrapper_style cost-center-report-styles"
        columns={columns}
        data={costCenterReportData}
        noHeader
        progressPending={reportLoading}
        progressComponent={<Loader />}
        expandableRows
        expandableRowsComponent={<ExpandedCostCenterReport />}
      />
    </div>
  );
};

export default CostCentersReport;

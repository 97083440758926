import React from "react";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import gql from "graphql-tag";
import { showToast } from "../../Helpers/HelperFns";
import { authUserFragment } from "../../Graphql/fragments";

import Loader from "../../Components/Loader";
import LoginIcon from "@mui/icons-material/Login";
import IconButtonWithTooltip from "../../Components/IconButtonWithTooltip";

const LOGIN_ON_BEHALF = gql`
  mutation loginOnBehalf($id: ID) {
    signInAsBusinessPartner(user_id: $id) {
      access_token
      user {
        ...authUser
      }
    }
  }
  ${authUserFragment}
`;

const LoginOnBehalfButton = ({ id }) => {
  const dispatch = useDispatch();

  // Reducer State
  const token = useSelector((state) => state?.auth?.token);
  const userProfile = useSelector((state) => state?.auth?.userProfile);

  // Server State
  const [loginOnBehalf, { loading }] = useMutation(LOGIN_ON_BEHALF);

  /* ↓ Helpers ↓ */

  const handleLogin = () => {
    loginOnBehalf({
      variables: { id },
      onCompleted: (res) => {
        dispatch({
          type: "LOG_ON_OUT_BEHALF",
          payload: {
            tokenRef: token,
            token: res?.signInAsBusinessPartner?.access_token,
            userProfileRef: userProfile,
            userProfile: res?.signInAsBusinessPartner?.user,
          },
        });
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  return (
    <>
      {loading ? <Loader fixed /> : null}
      <IconButtonWithTooltip
        onClick={handleLogin}
        label="Login as a business partner"
        icon={<LoginIcon color="success" fontSize="small" />}
      />
    </>
  );
};

export default LoginOnBehalfButton;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { InputForm, CheckboxBooleanForm } from "form-builder";
import PlanFeatureSection from "./PlanFeatureSection";
import SystemPriviligesTree from "../../Components/SystemPlanComponents/SystemPriviligesTree";
import { Spinner } from "reactstrap";

import {
  upsertSystemPlanAction,
  resetSystemPlanForm,
  fetchSystemPrivilegesAction,
} from "../../Store/Actions";
import Loader from "../../Components/Loader";
import { KeyboardBackspace } from "@mui/icons-material";

const formName = "systemPlan";
const formNameValidation = "systemPlanValidation";
const formServerValidation = "systemPlanServerValidation";

const SystemPlanPage = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [formSubmitting, setFormSubmitting] = useState(false);

  const formProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting,
  };

  useEffect(() => {
    props.fetchSystemPrivilegesAction();
    return () => {
      props.resetSystemPlanForm();
      setFormSubmitting(false);
    };
  }, []);

  const handleSubmitBtn = () => {
    setFormSubmitting(true);
    if (props.systemPlanValidation?.length) {
      return;
    }
    const { is_free, ...systemPlan } = props.systemPlan;
    let submitPlan = {
      ...systemPlan,
      trial_value: is_free ? null : +props.systemPlan?.trial_value,
    };
    // console.log(submitPlan);
    props.upsertSystemPlanAction(submitPlan, (planId) =>
      history.push(`/admin-panel/system-plans/${planId}/roles`)
    );
  };

  return (
    <div className="content px-5 pt-3 pb-2">
      <div className="d-fex mb-3">
        <Link to="/admin-panel?tab=plans">
          <div className="back-btn d-flex align-items-center position-sticky ">
            <KeyboardBackspace className="back-icon" />
            <span>{t("back")}</span>
          </div>
        </Link>
      </div>
      <div className="col-12 px-0">
        <h4 className="page-title">{t("new plan")}</h4>
      </div>

      <div className="">
        <div className="row d-flex align-items-center">
          <div className="col">
            <div>
              <InputForm
                {...formProps}
                validateBy="textRequired"
                name="name"
                type="text"
                placeholder={t("name")}
                reducer="admin"
                labelStyle="mb-2"
                containerStyle="mt-0 mb-3"
                inputContainerStyle=" "
                validationName="input.name"
              />
            </div>
            <PlanFeatureSection label="number of offices" featureId="2" />
            <PlanFeatureSection label="number of profiles" featureId="6" />
          </div>
          <div
            className="mx-4"
            style={{
              height: "120px",
              width: "1px",
              backgroundColor: "#ccc",
            }}
          />
          <div className="col">
            <div className="d-flex flex-row flex-wrap justify-content-between">
              <CheckboxBooleanForm
                {...formProps}
                options={["free plan"]}
                name="is_free"
                type="checkbox"
                reducer="admin"
                validationName="input.trial_value"
              />
              {props.systemPlan.is_free !== 1 ? (
                <InputForm
                  labelStyle="mr-5"
                  {...formProps}
                  containerStyle="container-style-default ml-auto"
                  validateBy="textRequired"
                  label="free trial period"
                  name="trial_value"
                  reducer="admin"
                  type="text"
                  hasSuffix
                  suffixTitle="Days"
                  validationName="input.trial_value"
                />
              ) : null}
            </div>

            <PlanFeatureSection
              label="number of normal work timings"
              featureId="5"
            />
            <PlanFeatureSection label="number of employees" featureId="1" />
          </div>
        </div>
        <div className="invalid-container-style">
          <div className="validity-msg-style">
            {props?.systemPlanServerValidation?.[
              "input.features"
            ]?.toString() ?? ""}
          </div>
        </div>
      </div>

      <hr />

      {props.systemPlanLoading && !props?.system_privileges ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : (
        <div>
          <SystemPriviligesTree
            parents={JSON.parse(props?.system_privileges)}
          />
          <div className="invalid-container-style">
            <div className="validity-msg-style">
              {props?.systemPlanServerValidation?.[
                "input.selectedPrivileges"
              ]?.toString() ?? ""}
            </div>
          </div>
        </div>
      )}

      <div className="d-flex justify-content-end mt-4 pt-2">
        <button className="save-button" onClick={handleSubmitBtn} type="submit">
          {props.systemPlanActions?.isLoading ? (
            <Spinner
              style={{ width: "1rem", height: "1rem" }}
              size="sm"
              color="#fff"
            />
          ) : (
            t("next")
          )}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    systemPlan: state.admin?.systemPlan,
    systemPlanValidation: state.admin?.systemPlanValidation,
    systemPlanServerValidation: state.admin?.systemPlanServerValidation,
    systemPlanActions: state.admin?.systemPlanActions,
    system_privileges: state?.admin?.system_privileges,
    systemPlanLoading: state.admin?.systemPlanLoading,
  };
};

export default connect(mapStateToProps, {
  upsertSystemPlanAction,
  resetSystemPlanForm,
  fetchSystemPrivilegesAction,
})(SystemPlanPage);

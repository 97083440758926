import React from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

import { onInputResetAction } from "../../Store/Actions";
import { fetchCheckInFormsQuery } from "../../Graphql/query";

import { AddButton } from "../Buttons";
import { EditIconButton } from "../IconButtonWithTooltip";
import { CheckboxBooleanForm, BSelect } from "form-builder";
import MultipleCheckInOutForm from "../SharedModals/MultipleCheckInOutForm";

const modalInitState = {
  type: null,
  formID: null,
  isOpen: false,
  isEdit: false,
};

const MultipleCheckIn = ({
  name,
  isShifts,
  formProps,
  customCheckFormIDs,
  allow_customize_check_ins,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [modalState, setModalState] = React.useState(modalInitState);

  // Server State
  const {
    data: formsData,
    loading: formsDataLoading,
    refetch: refetchFormsData,
  } = useQuery(fetchCheckInFormsQuery, {
    skip: !allow_customize_check_ins,
    notifyOnNetworkStatusChange: true,
  });

  /* ↓ State Effects ↓ */

  useDidUpdateEffect(() => {
    if (!allow_customize_check_ins) {
      dispatch(onInputResetAction(formProps.formName, "check_in_form_id"));
      dispatch(onInputResetAction(formProps.formName, "check_out_form_id"));
    }
  }, [allow_customize_check_ins]);

  return (
    <>
      <CheckboxBooleanForm
        name={name}
        {...formProps}
        label={isShifts ? "Check-ins" : ""}
        validationName={`input.${name}`}
        labelStyle="font-weight-bold blue-color mb-2"
        options={[
          `Allow multiple check-ins during ${
            isShifts ? "shifts" : "the work day"
          }`,
        ]}
        {...(isShifts && {
          dependOn: "attendanceType",
          dependancyType: "equal",
          dependancyValue: ["shifts"],
        })}
      />
      <CheckboxBooleanForm
        {...formProps}
        dependOn={name}
        dependancyType="equal"
        dependancyValue={[1]}
        options={["Customize Check-in Form"]}
        name="allow_customize_check_ins"
      />

      {allow_customize_check_ins ? (
        <>
          <div className="d-flex flex-wrap gap-20">
            {["in", "out"].map((type) => (
              <div key={type} className="d-flex flex-fill gap-20 mt-2">
                <div className="flex-fill">
                  <div className="d-flex flex gap-10 mb-2">
                    <span>{t(`Check-${type} Forms`)}</span>
                    <AddButton
                      isIconOnly
                      onClick={() =>
                        setModalState((prev) => ({
                          ...prev,
                          isOpen: true,
                          type,
                        }))
                      }
                    />
                  </div>
                  <div className="d-flex gap-10">
                    <BSelect
                      isClearable
                      {...formProps}
                      optionValue="id"
                      optionLabel="name"
                      name={`check_${type}_form_id`}
                      isLoading={formsDataLoading}
                      placeholder={t("select form")}
                      dependOn="allow_customize_check_ins"
                      dependancyType="equal"
                      dependancyValue={[1]}
                      rootStyle="flex-1"
                      options={
                        formsDataLoading ? [] : formsData?.checkInOutForms
                      }
                    />
                    {customCheckFormIDs[type] ? (
                      <EditIconButton
                        buttonProps={{ disabled: formsDataLoading }}
                        onClick={() =>
                          setModalState({
                            type,
                            isEdit: true,
                            isOpen: true,
                            formID: customCheckFormIDs[type],
                          })
                        }
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <MultipleCheckInOutForm
            state={modalState}
            formName={formProps.formName}
            refetchFormsData={refetchFormsData}
            formsData={formsData?.checkInOutForms}
            onClose={() => setModalState(modalInitState)}
          />
        </>
      ) : null}
    </>
  );
};

export default MultipleCheckIn;

import gql from "graphql-tag";

/*
  Attendance History
*/

/*
  Penalties
*/

/*
  Assignments
*/

export const upsertAssignmentMutation = gql`
  mutation upsertAssignment($input: UpdateOrCreateAssignmentInput) {
    updateOrCreateAssignment(input: $input) {
      ... on Assignment {
        id
        name
        from
        to
        __typename
      }
      ... on GeneralException {
        message
        __typename
      }
    }
  }
`;

export const deleteAssignmentMutation = gql`
  mutation deleteAssignment($id: ID!,$force_delete:Boolean) {
    deleteAssignment(id: $id,force_delete:$force_delete) {
        status
        message
        __typename
      }
    
  }
`;

/*
  Monthly Report
*/

/*
  Yearly Report
*/

/*
  Facial Report
*/

import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { showToast } from "../../Helpers/HelperFns";
import { paginatorFragment } from "../../Graphql/fragments";

import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";

const GET_USERS = gql`
  query getUsers($first: Int, $page: Int) {
    users: foodicsUsers(first: $first, page: $page) {
      data {
        id
        foodics_id
        name
        pin
        number
        email
        phone
        lang
        is_owner
      }
      paginatorInfo {
        ...paginator
      }
    }
  }
  ${paginatorFragment}
`;
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const UsersList = () => {
  const { t } = useTranslation();

  // Local State
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = React.useState(paginationInitState);

  // Server State
  const { loading } = useQuery(GET_USERS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: pagination.perPage,
      page: pagination.currentPage,
    },
    onCompleted: ({ users }) => {
      setData(users?.data || []);
      setPagination(users?.paginatorInfo);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  // Constants
  const columns = [
    { name: t("foodics id"), selector: "foodics_id" },
    { name: t("name"), selector: "name", grow: 2 },
    { name: t("email"), selector: "email" },
    { name: t("phone"), selector: "phone" },
  ];

  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  return (
    <>
      {/* List */}
      <DataTable
        noHeader
        data={data}
        columns={columns}
        className="cards_table mt-4"
        progressPending={loading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />
    </>
  );
};

export default UsersList;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  upsertWorkTimingsAction,
  dismissWorkTimingModalAction,
  upsertSetupWizardWorkTimingAttempt,
  fetchSuggestedHalfWorkTimingData,
} from "../../../Store/Actions";
import Swal from "sweetalert2";
import HelperFns from "../../../Helpers/HelperFns";
import formValidations from "../../../Helpers/Validations";

import { Spinner, Button } from "reactstrap";
import WorkTimingsTab from "./WorkTimingsTab";
import Loader from "../../../Components/Loader";
import MainModal from "../../../Components/MainModal";
import WorkTimingSignInStep from "./WorkTimingSignInStep";
import WorkTimingSignOutStep from "./WorkTimingSignOutStep";
import WorkTimingSignInSettings from "./WorkTimingSignInSettings";
import WorkTimingBreakSettings from "./WorkTimingBreakSettings";

const formName = "workTimings";
const formNameValidation = "workTimingsValidation";
const formServerValidation = "workTimingsServerValidation";

const WorkTimingForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [
    showSuggestedHalfWorkTimingSection,
    setShowSuggestedHalfWorkTimingSection,
  ] = useState(true);
  const [step, setStep] = useState(0);
  const [formSubmitting, setFormSubmitting] = useState(false);

  // Reducer State
  const data = useSelector((state) => state.super.workTimings);
  const tempWorkTimings = useSelector((state) => state.super.tempWorkTimings);
  const workTimingsModal = useSelector((state) => state.super.workTimingsModal);
  const workTimingsServerValidation = useSelector(
    (state) => state?.super[formServerValidation]
  );
  const setupWizardLoading = useSelector(
    (state) => state.wizard.setupWizardLoading
  );
  const isSuggestedHalfWorkTimingTemplateLoading = useSelector(
    (state) => state?.super?.suggestedHalfWorkTimingTemplateLoading
  );

  // Constants
  const commonProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting,
  };

  /* ↓ State Effects ↓ */

  useEffect(() => {
    setFormSubmitting(false);
    setShowSuggestedHalfWorkTimingSection(true);
    setStep(0);
    return () => {
      setFormSubmitting(false);
      setShowSuggestedHalfWorkTimingSection(true);
      setStep(0);
    };
  }, [workTimingsModal.isVissible]);

  /* ↓ Helpers ↓ */

  // Start of handling fetch suggested half work timing data
  const handleAccpetSuggestedHalfDayWorkTimingTemplate = () => {
    setShowSuggestedHalfWorkTimingSection(false);
    dispatch(
      fetchSuggestedHalfWorkTimingData(
        workTimingsModal?.normal_work_timing_id,
        workTimingsModal?.which_half
      )
    );
  };
  // End of handling fetch suggested half work timing data

  const redirectToErrorStep = (errorStep) => {
    if (errorStep == "FIRST") {
      setStep(0);
    }
    if (errorStep == "SECOND") {
      setStep(2);
    }
    if (errorStep == "THIRD") {
      setStep(3);
    }
    if (errorStep == "FORTH") {
      setStep(1);
    }
  };

  const handleChangeStep = (step) => {
    setStep(step);
  };

  const handleSubmit = () => {
    const { name: newName, dummy_range, ...current } = data;
    const { name: oldName, ...old } = tempWorkTimings;

    let isDirty = +(
      data?.id && JSON.stringify(current) !== JSON.stringify(old)
    );

    setFormSubmitting(true);
    formValidations.workTimingsValidation
      .validate(data, { abortEarly: true })
      .then((valid) => {
        if (props?.setupWizard) {
          dispatch(
            upsertSetupWizardWorkTimingAttempt(
              HelperFns.serializedSubmitedWorkTiming(data)
            )
          );
        } else {
          if (isDirty && data?.getActiveAttendanceProfiles?.length) {
            Swal.fire({
              title: t("warning"),
              html: `
                <div>
                  <h4 class="work-timing-conirmation-warning">
                    ${t(
                      "This work timing is the active work timing in the following attendance profiles and the changes will affect any records that depend on it. In addition to any records in the past where this work timing was applicable"
                    )}
                  </h4>
                  <ul class="text-left my-3">
                  ${data?.getActiveAttendanceProfiles?.map((attProfile) => {
                    return `<li key=${attProfile?.id}>${attProfile?.name}</li>`;
                  })}
                  </ul>
                  <h3 class="work-timing-final-confirmation-message">${t(
                    "are you sure you want to continue ?"
                  )}</h3>
                </div>
              `,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#009efb",
              confirmButtonText: t("Confirm"),
              reverseButtons: true,
              cancelButtonText: HelperFns.localizeFns("Cancel"),
              cancelButtonColor: "#ff6a6a",
            }).then(({ isConfirmed }) => {
              if (isConfirmed) {
                dispatch(
                  upsertWorkTimingsAction(
                    HelperFns.serializedSubmitedWorkTiming({
                      ...data,
                      isDirty,
                    }),
                    workTimingsModal?.parentFormName,
                    workTimingsModal?.workTimeIndex
                  )
                );
              }
            });
          } else {
            dispatch(
              upsertWorkTimingsAction(
                HelperFns.serializedSubmitedWorkTiming({
                  ...data,
                  isDirty,
                }),
                workTimingsModal?.parentFormName,
                workTimingsModal?.workTimeIndex
              )
            );
          }
        }
      })
      .catch((err) => {
        redirectToErrorStep(err.message);
      });
  };

  const handleDismiss = () => {
    dispatch(dismissWorkTimingModalAction());
  };

  const handleNext = () => {
    if (step < 3) {
      setStep(step + 1);
    }
    return;
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
    return;
  };

  const renderActiveTab = () => {
    switch (step) {
      case 0:
        return (
          <WorkTimingSignInStep
            {...commonProps}
            onNext={handleNext}
            setupWizard={props?.setupWizard}
          />
        );
      case 1:
        return (
          <WorkTimingBreakSettings
            {...commonProps}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 2:
        return (
          <WorkTimingSignOutStep
            {...commonProps}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 3:
        return (
          <WorkTimingSignInSettings
            {...commonProps}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
    }
  };

  const modalSubmit = () => {
    if (step == 3) {
      return handleSubmit;
    }
    if (step == 0 && !data?.sign_in_req) {
      return handleSubmit;
    }
    return null;
  };

  if (props?.setupWizard) {
    return (
      <div className="work_timing_form">
        {data?.sign_in_req ? (
          <WorkTimingsTab active={step} onChangeStep={handleChangeStep} />
        ) : null}

        {renderActiveTab()}
        <hr />
        {(step == 0 && !data?.sign_in_req) || step == 3 ? (
          <div className="mt-2 mr-3 text-right">
            <button
              className="btn btn-primary submit_btn_style"
              onClick={() => handleSubmit()}
            >
              {setupWizardLoading ? (
                <Spinner
                  style={{ width: "1rem", height: "1rem", color: "#fff" }}
                />
              ) : (
                t("save")
              )}
            </button>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <MainModal
      isOpen={workTimingsModal?.isVissible}
      size="lg"
      btnOnClick={modalSubmit()}
      btnOnCancelClick={step == 2 ? handleDismiss : null}
      toggle={handleDismiss}
      btnSubmitLoading={workTimingsModal?.isLoading}
      modalTitle={t("work timings")}
      className="work_timing_form_wrapper_style"
    >
      {isSuggestedHalfWorkTimingTemplateLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      {props?.inAttProfile &&
      workTimingsModal?.normal_work_timing_id &&
      workTimingsModal?.which_half !== "normal" ? (
        showSuggestedHalfWorkTimingSection ? (
          <div className="suggested_half_work_timing_wrapper d-flex align-items-baseline justify-content-between p-3">
            <label>
              {t(
                `Suggest ${
                  workTimingsModal?.which_half === "first" ? "1st" : "2nd"
                } Half Work Timing Based On Normal Work Timing Selected?`
              )}
            </label>

            <div>
              <Button
                className="mx-3"
                color="success"
                type="submit"
                onClick={handleAccpetSuggestedHalfDayWorkTimingTemplate}
              >
                {t("Yes")}
              </Button>

              <Button
                color="danger"
                onClick={() =>
                  setShowSuggestedHalfWorkTimingSection(
                    !showSuggestedHalfWorkTimingSection
                  )
                }
              >
                {t("No")}
              </Button>
            </div>
          </div>
        ) : null
      ) : null}

      {data?.sign_in_req ? (
        <WorkTimingsTab active={step} onChangeStep={handleChangeStep} />
      ) : null}

      <div className="px-3">{renderActiveTab()}</div>
      {workTimingsServerValidation["input.id"] ? (
        <p className="border-bottom border-danger error-color fit-width my-0 px-2 text-center">
          {workTimingsServerValidation["input.id"]}
        </p>
      ) : null}
    </MainModal>
  );
};

export default WorkTimingForm;

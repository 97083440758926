import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { BSelect, InputForm, DateTimePickerForm } from "form-builder";
import { connect } from "react-redux";
import {
  dismissPositionModalAction,
  updatePositionAction,
  createPositionAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";

const formName = "positionModal";
const formNameValidation = "positionModalValidation";

const PositionModal = (props) => {
  const { t } = useTranslation();

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }

    if (props.positionModal?.type == "edit") {
      props.updatePositionAction({
        data: props.positionModal,
        ref: "positionModalActions",
      });
    }
    if (props.positionModal?.type == "add") {
      props.createPositionAction({
        data: props.positionModal,
        ref: "positionModalActions",
      });
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;
  const toggleModal = () => {
    props.dismissPositionModalAction({ ref: "positionModalActions", formName });
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, [isModalVissible]);

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={t(`${props.positionModal?.type} position`)}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
    >
      <div>
        <InputForm
          name="name"
          formName={formName}
          formNameValidation={formNameValidation}
          formSubmitting={isSubmitting}
          placeholder={t("name")}
          label="name"
          labelStyle="mb-0"
          containerStyle=" "
          inputContainerStyle="my-2"
          validateBy="textRequired"
          icon="person"
        />
        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.positionModalActions,
  modalValidation: state.super[formNameValidation],
  positionModal: state.super[formName],
  user: state.auth.userProfile,
});

export default connect(mapStateToProps, {
  dismissPositionModalAction,
  updatePositionAction,
  createPositionAction,
})(PositionModal);

import React from "react";

import { Skeleton } from "@mui/material";

const ProfileCardPlaceholder = () => {
  return (
    <div style={{ height: 200 }} className="d-flex flex-wrap gap-20">
      <div className="mt-4">
        <Skeleton variant="circular" width={100} height={100} />
        <Skeleton variant="text" width={50} style={{ marginInline: "auto" }} />
      </div>

      <div className="flex-fill">
        <div
          style={{ borderBottom: "1px solid #eaeef0" }}
          className="d-flex justify-content-between pb-3 mb-3"
        >
          <div>
            <Skeleton variant="text" width={150} />
            <Skeleton variant="text" width={100} />
            <Skeleton variant="text" width={50} />
            <Skeleton variant="text" width={30} />
          </div>

          <Skeleton
            variant="rectangular"
            width={140}
            height={25}
            style={{ borderRadius: 5 }}
          />
        </div>

        <div className="d-flex flex-wrap justify-content-between">
          <div className="d-flex gap-10">
            <div>
              <Skeleton variant="text" width={70} />
              <Skeleton variant="text" width={40} />
            </div>
            <div>
              <Skeleton variant="text" width={70} />
              <Skeleton variant="text" width={40} />
            </div>
            <div>
              <Skeleton variant="text" width={70} />
              <Skeleton variant="text" width={40} />
            </div>
          </div>

          <Skeleton variant="text" width={150} height={30} />
        </div>
      </div>
    </div>
  );
};

export default ProfileCardPlaceholder;

import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import {
  saveMeetingMutation,
  changeMeetingMutation,
} from "../../Graphql/mutation";
import moment from "moment";
import { showToast } from "../../Helpers/HelperFns";
import Privileges from "../../Constants/Privilages";
import { meetingFormQuery } from "../../Graphql/query";

import Loader from "../Loader";
import MainModal from "../MainModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import LeadsServerRestSelect from "../CRM/LeadsServerRestSelect";
import { RadioboxForm, BSelect, DateTimePickerForm } from "form-builder";

const formNameValidation = "meetingClientValidation";

const UpsertMeetingModal = ({ data, onClose, refetchList }) => {
  const { t } = useTranslation();
  const isEdit = Boolean(data?.id);

  // Local State
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formData, setFormData] = React.useState({
    type: "lead",
    date: null,
    lead: null,
    user: null,
    company: null,
  });

  // Reducer State
  const clientValidation = useSelector(
    (state) => state.crm?.[formNameValidation]
  );

  // Server Status
  const [saveMeeting, { loading: saveLoading }] =
    useMutation(saveMeetingMutation);
  const [changeMeeting, { loading: changeLoading }] = useMutation(
    changeMeetingMutation
  );
  const { data: options, loading } = useQuery(meetingFormQuery, {
    variables: { id: data?.id, includeMeeting: isEdit },
    onCompleted: ({ meeting }) => {
      if (isEdit) {
        setFormData({
          type: meeting?.lead ? "lead" : "company",
          date: meeting?.date ? moment(meeting?.date) : null,
          lead: meeting?.lead || null,
          user: meeting?.user || null,
          company: meeting?.company || null,
        });
      }
    },
  });

  /* ↓ Helpers ↓ */

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectChange = (val, { name: key }) => {
    setFormData((prev) => ({ ...prev, [key]: val }));
  };

  const handleDateChange = (name, val) => {
    setFormData((prev) => ({ ...prev, [name]: val }));
  };

  const handleUpsert = () => {
    setFormSubmitting(true);
    if (clientValidation.length) return;

    const upsert = isEdit ? changeMeeting : saveMeeting;
    upsert({
      variables: {
        ...(formData?.type === "lead"
          ? { lead_id: +formData?.lead?.id, company_id: null }
          : { company_id: +formData?.company?.id, lead_id: null }),
        date: formData?.date?.format("YYYY-MM-DD HH:mm"),
        user_id: formData?.user?.id ? +formData?.user?.id : undefined,
        id: data?.id,
      },
      onCompleted: () => {
        refetchList();
        onClose();
        showToast("success");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      btnOnClick={handleUpsert}
      btnSubmitLoading={saveLoading || changeLoading}
      modalTitle={isEdit ? "Edit Meeting" : "Add Meeting"}
    >
      {loading ? <Loader fixed /> : null}

      <RadioboxForm
        name="type"
        // label="type"
        value={formData.type}
        rootStyle="mb-3"
        optionInputStyle=" "
        containerStyle="mb-0"
        onChange={handleChange}
        optionItemStyle="d-inline-block mx-2"
        optionsContainerStyle="d-flex gap-10"
        options={[
          { label: "lead", value: "lead" },
          { label: "company", value: "company" },
        ]}
      />
      {formData.type === "lead" ? (
        <LeadsServerRestSelect
          type="Meetings"
          name="lead"
          label="lead"
          value={formData.lead}
          onChange={handleSelectChange}
          validateBy="textRequired"
          rootStyle="flex-1"
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
        />
      ) : (
        <BSelect
          label="company"
          name="company"
          value={formData.company}
          onChange={handleSelectChange}
          options={options?.companies || []}
          validateBy="textRequired"
          rootStyle="flex-1"
          isLoading={loading}
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
        />
      )}

      <HasPrivileges scope="all" requireScope={[Privileges.VIEW_MEETINGS]}>
        <BSelect
          name="user"
          label="user"
          isClearable
          value={formData.user}
          onChange={handleSelectChange}
          options={options?.users || []}
          rootStyle="flex-1"
          isLoading={loading}
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
        />
      </HasPrivileges>

      <DateTimePickerForm
        hasIcon
        dateTime
        label="date"
        rootStyle="flex-fill"
        value={formData.date}
        datePickerContainer="w-100"
        onChange={(val) => handleDateChange("date", val)}
        onSelect={(val) => handleDateChange("date", val)}
        validateBy="textRequired"
        formSubmitting={formSubmitting}
        formNameValidation={formNameValidation}
      />
    </MainModal>
  );
};

export default UpsertMeetingModal;

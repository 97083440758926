import React from "react";

import Box from "@mui/material/Box";
import { Add, ChatBubble } from "@mui/icons-material";
import IconButtonWithTooltip from "../IconButtonWithTooltip";

const CommentCell = ({ comments, onClick }) => {
  return (
    <Box
      sx={{
        position: "relative",
        span: {
          top: 9,
          left: 12,
          position: "absolute",
          pointerEvents: "none",
        },
      }}
    >
      <IconButtonWithTooltip
        onClick={onClick}
        label="add comment"
        sx={{ width: 1, height: 1 }}
        icon={<ChatBubble fontSize="large" />}
      />
      <span>
        {comments.length ? (
          <b className="text-white px-2">{comments.length}</b>
        ) : (
          <Add fontSize="small" sx={{ color: "white" }} />
        )}
      </span>
    </Box>
  );
};

export default CommentCell;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMutation, useLazyQuery } from "@apollo/client";
import moment from "moment";
import { useParams } from "react-router-dom";
import { fetchCompanyActivitesQuery } from "../../../Graphql/query";
import {
  formatOptions,
  openSwalConfirm,
  showToast,
} from "../../../Helpers/HelperFns";
import Privileges from "../../../Constants/Privilages";
import { EditIconButton, RemoveIconButton } from "../../IconButtonWithTooltip";
import Loader from "../../Loader";
import Pagination from "../../Pagination";
import { AddButton } from "../../Buttons";
import DataTable from "react-data-table-component";
import { CommentCell, ContactTooltip } from "../../CRM";
import ReactShowMoreText from "react-show-more-text";
import CompanyCommentModal from "../CompanyCommentModal";
import { BSelect, DateTimePickerForm } from "form-builder";
import { deleteCompanyActivityMutation } from "../../../Graphql/mutation";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import CompanyActivityAndReminderModal from "../CompanyActivityAndReminderModal";

const activityTypeOptions = ["Call", "Message", "Email", "Meeting"];
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const CompanyActivitiesList = () => {
  const { companyId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState(paginationInitState);
  const [filters, setFilters] = useState({
    to: "",
    from: "",
    type: "",
    account_manager: null,
  });

  const [isAddActivityModalOpen, setIsAddActivityModalOpen] = useState(false);
  const [commentModal, setCommentModal] = useState({
    isOpen: false,
    data: {},
  });

  const handleOpenCommentModal = (data) => {
    setCommentModal({
      isOpen: true,
      data,
    });
  };

  const handleCloseCommentModal = () => {
    setCommentModal({
      isOpen: false,
      data: {},
    });
  };

  const handleOpenActivityModal = () => {
    setIsAddActivityModalOpen(true);
  };

  const handleCloseActivityModal = () => {
    setIsAddActivityModalOpen(false);
  };

  const handleEditActivityModal = (data) => {
    const { company, account_manager, contact, comments, ...filterdData } =
      data;
    dispatch({
      type: "UPDATE_ALL_FORM_DATA",
      payload: {
        formName: "comapnyActivityForm",
        formData: {
          ...filterdData,
          contact_id: data?.contact?.id,
          savedAttachments: data?.attachments,
          attachments: [],
        },
      },
    });
    setIsAddActivityModalOpen(true);
  };

  const [deleteCompanyActivity, { loading: deleteCompanyActivityIsLoading }] =
    useMutation(deleteCompanyActivityMutation);

  const [
    fetchCompanyActivites,
    { data: companyActivitesData, loading: fetchCompanyActivitesIsLoading },
  ] = useLazyQuery(fetchCompanyActivitesQuery, {
    onCompleted: (data) => {
      setPagination(data?.company_activities_list?.paginatorInfo);
    },
    onError(err) {
      showToast(
        "error",
        err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
      );
    },
  });

  const handleDeleteCompanyActivity = (activityId) => {
    deleteCompanyActivity({
      variables: {
        id: activityId,
      },
      onCompleted: (data) => {
        if (data?.delete_companies_activities?.status === "success") {
          handleFetchCompanyActivites();
          showToast("success", data?.delete_companies_activities?.message);
        }
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ??
            err?.graphQLErrors[0]?.message ??
            err?.message
        );
      },
    });
  };

  const handleFetchCompanyActivites = () => {
    fetchCompanyActivites({
      variables: {
        input: {
          to: filters?.to ? filters?.to + " 00:00:00" : "",
          start_from: filters?.from ? filters?.from + " 00:00:00" : "",
          type: filters?.type?.name || "",
          account_manager_id: filters?.account_manager?.id,
          company_id: companyId,
        },
        first: 20,
        page: pagination.currentPage || 1,
      },
    });
  };

  useEffect(() => {
    handleFetchCompanyActivites();
  }, [
    filters?.to,
    filters?.from,
    filters?.type,
    filters?.account_manager,
    pagination.currentPage,
  ]);

  // Data table columns
  const columns = [
    { name: t("type"), cell: ({ type }) => <>{type}</> },
    { name: t("contact"), cell: ({ contact = {} }) => ContactTooltip(contact) },
    {
      name: t("date"),
      cell: ({ date }) => <>{moment(date).format("DD - MM - YYYY")}</>,
    },
    {
      name: t("account managers"),
      cell: ({ account_manager }) => <>{account_manager?.name}</>,
    },
    {
      name: t("notes"),
      cell: ({ notes }) => (
        <ReactShowMoreText lines={1}>{notes}</ReactShowMoreText>
      ),
    },
    {
      name: t("comments"),
      cell: ({ id, comments = [] }) => (
        <CommentCell
          comments={comments}
          onClick={() =>
            handleOpenCommentModal({
              id,
              comments,
              type: "CompaniesActivities",
            })
          }
        />
      ),
    },
    {
      cell: (data) => (
        <>
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.ADD_EDIT_COMPANY_ACTIVITIES,
            ]}
          >
            <EditIconButton onClick={() => handleEditActivityModal(data)} />
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.DELETE_COMPANY_ACTIVITIES,
            ]}
          >
            <RemoveIconButton onClick={() => handleRemoveActivity(data?.id)} />
          </HasPrivileges>
        </>
      ),
    },
  ];

  // handle data table pagination function
  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  // handle data table select filters function
  const handleSelectFilters = (val, { name }) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  // handle data table date filters function
  const handleDateFiltersChange = (name, val) => {
    setFilters((prev) => ({
      ...prev,
      [name]: val ? val.format("YYYY-MM-DD") : null,
    }));
  };

  // handle delete company activity function
  const handleRemoveActivity = (activityId) => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        handleDeleteCompanyActivity(activityId);
      }
    });
  };

  return (
    <>
      {/* Filters */}
      <div className="d-flex flex-wrap justify-content-between align-items-end gap-20">
        <BSelect
          isClearable
          name="type"
          label="type"
          icon="employee"
          keepDefaultStyle
          rootStyle="flex-1"
          value={filters.type}
          onChange={handleSelectFilters}
          options={formatOptions(activityTypeOptions)}
        />
        <DateTimePickerForm
          hasIcon
          isClearable
          label="from"
          labelStyle="w-100"
          rootStyle="flex-1"
          value={filters.from ? moment(filters.from, "YYYY-MM-DD") : null}
          datePickerContainer="w-100"
          onIntercept={(val) => handleDateFiltersChange("from", val)}
        />
        <DateTimePickerForm
          hasIcon
          label="to"
          isClearable
          rootStyle="flex-1"
          labelStyle="w-100"
          value={filters.to ? moment(filters.to, "YYYY-MM-DD") : null}
          datePickerContainer="w-100"
          onIntercept={(val) => handleDateFiltersChange("to", val)}
        />
        <HasPrivileges
          reqireMain={[
            Privileges.SUPER_PRIVILEGE,
            Privileges.ADD_EDIT_COMPANY_ACTIVITIES,
          ]}
        >
          <AddButton label="activity" onClick={handleOpenActivityModal} />
        </HasPrivileges>
      </div>

      {/* List */}
      <DataTable
        noHeader
        data={companyActivitesData?.company_activities_list?.data}
        columns={columns}
        className="cards_table my-3"
        progressPending={
          fetchCompanyActivitesIsLoading || deleteCompanyActivityIsLoading
        }
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {isAddActivityModalOpen ? (
        <CompanyActivityAndReminderModal
          isAddReminder={false}
          refetchList={handleFetchCompanyActivites}
          onClose={handleCloseActivityModal}
          isOpen={isAddActivityModalOpen}
        />
      ) : null}

      {commentModal.isOpen ? (
        <CompanyCommentModal
          data={commentModal.data}
          onClose={handleCloseCommentModal}
          refetchList={handleFetchCompanyActivites}
        />
      ) : null}
    </>
  );
};

export default CompanyActivitiesList;

import * as REQUESTS from "./Requests";

export * from "./CRM";
export * from "./Attendance";

export const workPlaceSettingOptions = [
	{
		value: "ANY_PLACE",
		label: "Anywhere",
	},
	{
		value: "ALL_COMPANY_WORKPLACES",
		label: "Any work place in the company",
	},
	{
		value: "DEFAULT",
		label: "Specific work places",
	},
];

export const EgyptId = "64";

export const numbersRegex = /^[0-9\b]+$/;

const WeekendDays = [
	{ label: "saturday", value: "Saturday" },
	{ label: "sunday", value: "Sunday" },
	{ label: "monday", value: "Monday" },
	{ label: "tuesday", value: "Tuesday" },
	{ label: "wednesday", value: "Wednesday" },
	{ label: "thursday", value: "Thursday" },
	{ label: "friday", value: "Friday" },
];
const Days = ["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
const WeekDays = [
	{ label: "saturday", value: "1" }, //6
	{ label: "sunday", value: "2" }, //0
	{ label: "monday", value: "3" },
	{ label: "tuesday", value: "4" },
	{ label: "wednesday", value: "5" },
	{ label: "thursday", value: "6" },
	{ label: "friday", value: "7" }, //5
];

const PolarOptions = [
	{ label: "yes", value: "1" },
	{ label: "no", value: "0" },
];

const penaltiesDeducted = [
	{ label: "normal leaves", value: "Leaves" },
	{ label: "salary", value: "Salary" },
];

const HalfDay = [
	{ label: "First Half", value: "First" },
	{ label: "Second Half", value: "Second" },
];

const timeZones = [
	{ label: "(GMT-12:00) International Date Line West", value: "Etc/GMT+12" },
	{ label: "(GMT-11:00) Midway Island, Samoa", value: "Pacific/Midway" },
	{ label: "(GMT-10:00) Hawaii", value: "Pacific/Honolulu" },
	{ label: "(GMT-09:00) Alaska", value: "US/Alaska" },
	{
		label: "(GMT-08:00) Pacific Time (US & Canada)",
		value: "America/Los_Angeles",
	},
	{ label: "(GMT-08:00) Tijuana, Baja California", value: "America/Tijuana" },
	{ label: "(GMT-07:00) Arizona", value: "US/Arizona" },
	{
		label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
		value: "America/Chihuahua",
	},
	{ label: "(GMT-07:00) Mountain Time (US & Canada)", value: "US/Mountain" },
	{ label: "(GMT-06:00) Central America", value: "America/Managua" },
	{ label: "(GMT-06:00) Central Time (US & Canada)", value: "US/Central" },
	{
		label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
		value: "America/Mexico_City",
	},
	{ label: "(GMT-06:00) Saskatchewan", value: "Canada/Saskatchewan" },
	{
		label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
		value: "America/Bogota",
	},
	{ label: "(GMT-05:00) Eastern Time (US & Canada)", value: "US/Eastern" },
	{ label: "(GMT-05:00) Indiana (East)", value: "US/East-Indiana" },
	{ label: "(GMT-04:00) Atlantic Time (Canada)", value: "Canada/Atlantic" },
	{ label: "(GMT-04:00) Caracas, La Paz", value: "America/Caracas" },
	{ label: "(GMT-04:00) Manaus", value: "America/Manaus" },
	{ label: "(GMT-04:00) Santiago", value: "America/Santiago" },
	{ label: "(GMT-03:30) Newfoundland", value: "Canada/Newfoundland" },
	{ label: "(GMT-03:00) Brasilia", value: "America/Sao_Paulo" },
	{
		label: "(GMT-03:00) Buenos Aires, Georgetown",
		value: "America/Argentina/Buenos_Aires",
	},
	{ label: "(GMT-03:00) Greenland", value: "America/Godthab" },
	{ label: "(GMT-03:00) Montevideo", value: "America/Montevideo" },
	{ label: "(GMT-02:00) Mid-Atlantic", value: "America/Noronha" },
	{ label: "(GMT-01:00) Cape Verde Is.", value: "Atlantic/Cape_Verde" },
	{ label: "(GMT-01:00) Azores", value: "Atlantic/Azores" },
	{
		label: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
		value: "Africa/Casablanca",
	},
	{
		label: "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
		value: "Etc/Greenwich",
	},
	{
		label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
		value: "Europe/Amsterdam",
	},
	{
		label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
		value: "Europe/Belgrade",
	},
	{
		label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
		value: "Europe/Brussels",
	},
	{
		label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
		value: "Europe/Sarajevo",
	},
	{ label: "(GMT+01:00) West Central Africa", value: "Africa/Lagos" },
	{ label: "(GMT+02:00) Amman", value: "Asia/Amman" },
	{ label: "(GMT+02:00) Athens, Bucharest, Istanbul", value: "Europe/Athens" },
	{ label: "(GMT+02:00) Beirut", value: "Asia/Beirut" },
	{ label: "(GMT+02:00) Cairo", value: "Africa/Cairo" },
	{ label: "(GMT+02:00) Harare, Pretoria", value: "Africa/Harare" },
	{
		label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
		value: "Europe/Helsinki",
	},
	{ label: "(GMT+02:00) Jerusalem", value: "Asia/Jerusalem" },
	{ label: "(GMT+02:00) Minsk", value: "Europe/Minsk" },
	{ label: "(GMT+02:00) Windhoek", value: "Africa/Windhoek" },
	{ label: "(GMT+03:00) Kuwait, Riyadh, Baghdad", value: "Asia/Kuwait" },
	{
		label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
		value: "Europe/Moscow",
	},
	{ label: "(GMT+03:00) Nairobi", value: "Africa/Nairobi" },
	{ label: "(GMT+03:00) Tbilisi", value: "Asia/Tbilisi" },
	{ label: "(GMT+03:30) Tehran", value: "Asia/Tehran" },
	{ label: "(GMT+04:00) Abu Dhabi, Muscat", value: "Asia/Muscat" },
	{ label: "(GMT+04:00) Baku", value: "Asia/Baku" },
	{ label: "(GMT+04:00) Yerevan", value: "Asia/Yerevan" },
	{ label: "(GMT+04:30) Kabul", value: "Asia/Kabul" },
	{ label: "(GMT+05:00) Yekaterinburg", value: "Asia/Yekaterinburg" },
	{ label: "(GMT+05:00) Islamabad, Karachi, Tashkent", value: "Asia/Karachi" },
	{
		label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
		value: "Asia/Calcutta",
	},
	{ label: "(GMT+05:30) Sri Jayawardenapura", value: "Asia/Calcutta" },
	{ label: "(GMT+05:45) Kathmandu", value: "Asia/Katmandu" },
	{ label: "(GMT+06:00) Almaty, Novosibirsk", value: "Asia/Almaty" },
	{ label: "(GMT+06:00) Astana, Dhaka", value: "Asia/Dhaka" },
	{ label: "(GMT+06:30) Yangon (Rangoon)", value: "Asia/Rangoon" },
	{ label: "(GMT+07:00) Bangkok, Hanoi, Jakarta", value: "Asia/Bangkok" },
	{ label: "(GMT+07:00) Krasnoyarsk", value: "Asia/Krasnoyarsk" },
	{
		label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
		value: "Asia/Hong_Kong",
	},
	{ label: "(GMT+08:00) Kuala Lumpur, Singapore", value: "Asia/Kuala_Lumpur" },
	{ label: "(GMT+08:00) Irkutsk, Ulaan Bataar", value: "Asia/Irkutsk" },
	{ label: "(GMT+08:00) Perth", value: "Australia/Perth" },
	{ label: "(GMT+08:00) Taipei", value: "Asia/Taipei" },
	{ label: "(GMT+09:00) Osaka, Sapporo, Tokyo", value: "Asia/Tokyo" },
	{ label: "(GMT+09:00) Seoul", value: "Asia/Seoul" },
	{ label: "(GMT+09:00) Yakutsk", value: "Asia/Yakutsk" },
	{ label: "(GMT+09:30) Adelaide", value: "Australia/Adelaide" },
	{ label: "(GMT+09:30) Darwin", value: "Australia/Darwin" },
	{ label: "(GMT+10:00) Brisbane", value: "Australia/Brisbane" },
	{
		label: "(GMT+10:00) Canberra, Melbourne, Sydney",
		value: "Australia/Canberra",
	},
	{ label: "(GMT+10:00) Hobart", value: "Australia/Hobart" },
	{ label: "(GMT+10:00) Guam, Port Moresby", value: "Pacific/Guam" },
	{ label: "(GMT+10:00) Vladivostok", value: "Asia/Vladivostok" },
	{
		label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
		value: "Asia/Magadan",
	},
	{ label: "(GMT+12:00) Auckland, Wellington", value: "Pacific/Auckland" },
	{ label: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.", value: "Pacific/Fiji" },
	{ label: "(GMT+13:00) Nukualofa", value: "Pacific/Tongatapu" },
];

const MonthsData = [
	{ key: "01", value: "January" },
	{ key: "02", value: "February" },
	{ key: "03", value: "March" },
	{ key: "04", value: "April" },
	{ key: "05", value: "May" },
	{ key: "06", value: "June" },
	{ key: "07", value: "July" },
	{ key: "08", value: "August" },
	{ key: "09", value: "September" },
	{ key: "10", value: "October" },
	{ key: "11", value: "November" },
	{ key: "12", value: "December" },
];

const AllMonthData = [{ key: 0, value: "All" }, ...MonthsData];

const YearData = (() => {
	// max year is current year
	const maxYear = new Date().getFullYear() + 1;
	// start year
	const minYear = 2017;
	let data = [],
		i;
	for (i = maxYear; i >= minYear; i--) {
		data.push({ value: i });
	}
	return data;
})();

const signInStatusOptions = [
	{ value: "Ontime" },
	// { value: "Minor Tardiness" },
	{ value: "Leave" },
	{ value: "Day Off" },
	// { value: "Major Tardiness" },
	{ value: "Late" },
	{ value: "Absent" },
	{ value: "Holiday" },
	{ value: "half day" },
	// { value: "Missing sign out" },
];

const statusOptions = [
	{ label: "attended", value: "attended" },
	{ label: "leave", value: "Leave" },
	{ label: "absent", value: "Absent" },
	{ label: "holiday", value: "Holiday" },
	{ label: "Day Off", value: "Day Off" },
];

const leaveTypes = [
	{ name: "annual leave", id: "Normal" },
	{ name: "unpaid leave", id: "Unpaid" },
	{ name: "sick leave", id: "Sick" },
	{
		name: "Bereavement leave",
		id: "Bereavement",
	},
	{
		name: "Maternity/Paternity leave",
		id: "Maternity_Paternity",
	},

	{
		name: "Hajj leave",
		id: "Hajj",
	},
	{
		name: "Study leave",
		id: "Study",
	},
	{
		name: "Sabbatical leave",
		id: "Sabbatical",
	},
	{
		name: "Marriage leave",
		id: "Marriage",
	},
];

const dayTypeOptions = [
	{ label: "normal_", value: "normal" },
	{ label: "First Half", value: "1st_half" },
	{ label: "Second Half", value: "2nd_half" },
];

const discountOptions = [
	{ label: "normal", value: "Normal" },
	{ label: "emergency", value: "Emergency" },
];

const balanceOptions = [
	{ label: "Add Balance", value: "1" },
	{ label: "Deduct Balance", value: "2" },
];

const balanceTypes = [
  { label: "annual leaves", value: REQUESTS.NORMAL_LEAVES },
];

const workdaysTab = [
	"input.name",
	"input.week_start_day",
	"input.weekends",
	"input.allowed_annual_leaves",
	"input.annual_leave_cuttoff",
	"input.leave_basis",
	"input.annual_leaves_deserved_against",
	"input.allowed_emergency_leaves",
	"input.emergency_penalty",
	"input.emergency_penalty_type",
	"input.allow_permissions",
	"input.min_permission_unit",
	"input.max_units_per_day",
	"input.max_units_per_month",
	"input.number_of_allowed_break_sessions_per_day",
	"input.maximum_break_session_duration",
	"input.maximum_break_duration",
	"input.can_work_holiday",
	"input.default_office_holiday_profile",
	"input.holidays",
];
const AttsignInTab = ["input.attendance_profile_work_timing_input"];

const attProfileWorkdays = [
	"input.name",
	"input.week_start_day",
	"input.weekends",
	"input.holidays",
	"input.office_id",
];
const attProfileLeaves = [
	"input.allowed_annual_leaves",
	"input.annual_leave_cuttoff",
	"input.allowed_emergency_leaves",
	"input.emergency_penalty",
	"input.emergency_penalty_type",
];
const attProfilePermissions = [
	"input.allow_permissions",
	"input.min_permission_unit",
	"input.max_units_per_day",
	"input.max_units_per_month",
	"input.number_of_allowed_break_sessions_per_day",
	"input.maximum_break_session_duration",
	"input.maximum_break_duration",
	"input.can_work_holiday",
];
const attProfileSignIn = ["start"];
const attProfileTardiness = [
	"input.max_lateness_permissibility",
	"input.minor_tardiness_range",
	"input.major_tardiness_range",
	"input.minor_tardiness_penalty",
	"input.minor_tardiness_penalty_type",
	"input.major_tardiness_penalty",
	"input.major_tardiness_penalty_type",
];
const attProfileSignOut = [
	"input.sign_out_reminder_time",
	"input.auto_sign_out_time",
	"input.early_signout_action",
	"input.early_signout_deduction_days",
	"input.early_signout_deduction_type",
	"input.sign_out_cuttoff_time",
];
const attProfileOvertime = [
	"input.min_overtime_cuttoff",
	"input.overtime_limit",
	"input.max_overtime_per_day",
	// "input.max_overtime_per_month",
	// "input.overtime_payment_factor",
];

const BounsTypes = [
	{ label: "Fixed Amount", value: 1 },
	{ label: "Days", value: 2 },
];
const DeductionPolicyTypes = [
	{ label: "Fixed Amount", value: "Fixed" },
	{ label: "Days", value: "Days" },
];

const salaryAmountTypeOptions = [
	{ label: "fixed amount", value: "fixed amount" },
	{ label: "percent of net salary", value: "percent of net salary" },
];

const deductiblesSalaryTypeOptions = [
	{ label: "fixed amount", value: "fixed amount" },
	{ label: "percent of Base salary", value: "percent of Base salary" },
	{ label: "percent of gross salary", value: "percent of gross salary" },
];

const CalculationsSalaryTypeOptions = [
	{ label: "fixed amount", value: "fixed amount" },
	{ label: "percent of Base salary", value: "percent of Base salary" },
	{ label: "percent of gross salary", value: "percent of gross salary" },
	{ label: "percent of net salary", value: "percent of net salary" },
];

const CalculationsDaysOptions = [
	{ label: "30 days", value: "30" },
	{ label: "26 days", value: "26" },
	{ label: "22 days", value: "22" },
	{ label: "custom days", value: "custom days" },
];

const userRoleOptions = [
	{ label: "Admin", value: 10 },
	{ label: "Account Manager", value: 8 },
];

const probationPeriodTypeOptions = [
	{ label: "Days", type: "days" },
	{ label: "Months", type: "months" },
];

const payrollStartOptions = (() => {
	let data = [];
	for (let i = 1; i <= 28; i++) {
		data.push({
			id: `${i}`,
			name: `${i}`,
		});
	}
	return data;
})();

const resetOptions = [
	{ label: "month", value: "month" },
	{ label: "year", value: "year" },
];

const WORK_TIME_PROFILE_TYPES = [
	{ label: "normal_", value: "normal" },
	{ label: "half day", value: "half" },
];

const verticalNavbarStyles = {
	root: {
		flexGrow: 1,
		color: "#2764ac",
		boxShadow: "none",
	},
	indicator: {
		display: "none",
	},
	selected: {
		opacity: 1,
		borderRadius: "2px",
		color: "white !important",
		borderLeft: "4px solid  #59c203",
		backgroundColor: "#2764ac",
	},
	selectedRtl: {
		opacity: 1,
		borderRadius: "2px",
		color: "white !important",
		borderRight: "4px solid  #59c203",
		backgroundColor: "#2764ac",
	},
	flexContainer: {
		overflow: "auto",
	},
	tabRoot: {
		opacity: 0.7,
		color: "#2764ac",
		alignItems: "flex-start",
		"&:focus": {
			outline: 0,
		},
		"&:hover": {
			outline: 0,
			backgroundColor: "#2764ac",
			color: "white !important",
			opacity: 1,
		},
	},
	label: {
		textTransform: "uppercase",
		fontSize: "11px",
		textAlign: "left",
		alignItems: "flex-start",
	},
};

const violdationQuantityUnitsOptions = [
	{
		value: "days",
		label: "days",
	},
	{
		value: "fixed_amount",
		label: "fixed amount",
	},
];

const horizontalNavbarStyles = {
	root: {
		position: "relative",

		"&::after": {
			content: '""',
			position: "absolute",
			backgroundColor: "rgba(39, 100, 172, 0.3)",
			width: "100%",
			height: "3px",
			bottom: 0,
			zIndex: -1,
		},
	},
	scrollButtons: {
		"&.Mui-disabled": { opacity: 0.3 },
	},
	/*flexContainer: {
    paddingBottom: "2px",
    overflow: "hidden",
    scrollbarColor: "rgba(39, 100, 172, 1) transparent",

    "&::-webkit-scrollbar-thumb": {
      color: "rgba(39, 100, 172, 1)",
    },

    "&:hover": {
      overflow: "overlay",

      "@supports (-moz-appearance: none)": {
        overflow: "scroll",
      },
    },
  },*/
	tabRoot: {
		opacity: 0.7,
		color: "#2764ac !important",

		"&:hover": {
			color: "white !important",
			borderRadius: "2px",
			backgroundColor: "#2764ac",

			"& svg": {
				color: "white !important",
			},
		},

		minWidth: "auto",
	},

	indicator: {
		display: "none",
	},
	selected: {
		opacity: 1,
		color: "white !important",
		borderRadius: "2px",
		backgroundColor: "#2764ac",

		"& svg": {
			color: "white !important",
		},
	},
	selectedRtl: {
		opacity: 1,
		color: "white !important",
		borderRadius: "2px",
		backgroundColor: "#2764ac",

		"& svg": {
			color: "white !important",
		},
	},
	separator: {
		margin: "-4px",
	},
};

const possibleDateRangeRequests = [
  REQUESTS.NORMAL_LEAVES,
  REQUESTS.UNPAID_LEAVES,
  REQUESTS.SICK_LEAVES,
  REQUESTS.MARRIAGE_LEAVE,
  REQUESTS.In_Lieu_Of_Work_Day_Leave,
  REQUESTS.MILITARY_CALL_LEAVE,
  REQUESTS.SABBATICAL_LEAVE,
  REQUESTS.BEREAVEMENT,
  REQUESTS.HAJJ_LEAVE,
  REQUESTS.MATERNITY_PATERNITY_LEAVE,
  REQUESTS.STUDY_LEAVE,
  REQUESTS.OPEN_REQUEST,
];

const signInOutTypes = {
	ATTENDANCE_PROFILE_WORK_TIMING: "AttendanceProfileWorkTiming",
	EXCEPTION: "Exception",
	SHIFT: "ScheduleDetailEmployee",
};

const attendanceTypes = {
	OFFICE_BASED: "office based",
	SHIFT_BASED: "shifts",
};

const CompanyModalWeekDays = [
	{ label: "Saturday", value: "Saturday" },
	{ label: "Sunday", value: "Sunday" },
	{ label: "Monday", value: "Monday" },
	{ label: "Tuesday", value: "Tuesday" },
	{ label: "Wednesday", value: "Wednesday" },
	{ label: "Thursday", value: "Thursday" },
	{ label: "Friday", value: "Friday" },
];

const requestApprovalsApplicableTypes = {
	Offices: "Office",
	Departments: "Department",
	Employees: "Employee",
};

const genderOptions = [
	{ label: "male", value: "Male" },
	{ label: "female", value: "Female" },
];

const maritalStatusOptions = [
	{ label: "single", value: "Single" },
	{ label: "married", value: "Married" },
	{ label: "divorced", value: "Divorced" },
	{ label: "widowed", value: "Widowed" },
];

export const assignmentTypesConstants = {
	NORMAL_WORK_DAY: "NORMAL_WORK_DAY",
	EXCEPTIONAL_SHIFT: "EXCEPTIONAL_SHIFT",
	DAY_OFF: "DAY_OFF",
	HOLIDAY: "HOLIDAY",
};

const languageOptions = [
	{ label: "english", value: "en-US", countryCode: "us" },
	{ label: "arabic", value: "ar-EG", countryCode: "eg" },
];

export const tardinessViolations = [
	"minor_violations",
	"major_violations",
	"major_violations1",
	"major_violations2",
	"major_violations3",
	"major_violations4",
	"major_violations5",
	"major_violations6",
	"major_violations7",
	"major_violations8",
	"major_violations9",
	"major_violations10",
];

export const monthOptionDays = {
	2: "30",
	3: "26",
	4: "22",
	6: "7",
	7: "5",
};

export const monthOptionFromNumberOfDays = (numberOfDays) => {
	switch (numberOfDays) {
		case 30:
			return "2";
		case 26:
			return "3";
		case 22:
			return "4";
		case 7:
			return "6";
		case 5:
			return "7";
		default:
			return "5";
	}
};

export const DIVIDE_SALARY_BY_VALUES = {
  CALENDAR_MONTH: "CALENDAR_MONTH",
  WORK_DAYS: "WORK_DAYS",
  FULL_MONTH: "FULL_MONTH",
  CUSTOM_DAYS: "CUSTOM_DAYS",
};

export const divideSalaryByOptions = [
  {
    id: 1,
    name: "Calendar Month",
    value: DIVIDE_SALARY_BY_VALUES.CALENDAR_MONTH,
  },
  {
    id: 2,
    name: "Work Days",
    value: DIVIDE_SALARY_BY_VALUES.WORK_DAYS,
  },
  {
    id: 3,
    name: "30 Days",
    value: DIVIDE_SALARY_BY_VALUES.FULL_MONTH,
  },
  {
    id: 4,
    name: "Custom Days",
    value: DIVIDE_SALARY_BY_VALUES.CUSTOM_DAYS,
  },
];

export default {
	AllMonthData,
	YearData,
	WeekendDays,
	WeekDays,
	penaltiesDeducted,
	PolarOptions,
	timeZones,
	HalfDay,
	signInStatusOptions,
	statusOptions,
	leaveTypes,
	dayTypeOptions,
	discountOptions,
	balanceOptions,
	MonthsData,
	balanceTypes,
	workdaysTab,
	attProfileWorkdays,
	attProfileOvertime,
	attProfileSignOut,
	attProfileTardiness,
	attProfileSignIn,
	attProfilePermissions,
	attProfileLeaves,
	BounsTypes,
	salaryAmountTypeOptions,
	deductiblesSalaryTypeOptions,
	CalculationsSalaryTypeOptions,
	CalculationsDaysOptions,
	payrollStartOptions,
	userRoleOptions,
	resetOptions,
	probationPeriodTypeOptions,
	WORK_TIME_PROFILE_TYPES,
	AttsignInTab,
	verticalNavbarStyles,
	horizontalNavbarStyles,
	violdationQuantityUnitsOptions,
	possibleDateRangeRequests,
	signInOutTypes,
	attendanceTypes,
	Days,
	CompanyModalWeekDays,
	requestApprovalsApplicableTypes,
	genderOptions,
	maritalStatusOptions,
	languageOptions,
	DeductionPolicyTypes,
};

// this page is made to redirect the user to a blank page when switching users
// so it doesn't make requests after switching to another user

import React from "react";

const BlankPage = () => {
  return <div></div>;
};

export default BlankPage;

// handle children input
const ON_RECRUITMENT_CHILD_INPUT_CHANGE = "ON_RECRUITMENT_CHILD_INPUT_CHANGE";

// start handle Recruitement Process Form

const ON_RECRUITMENT_PROCESS_FORM_FORM_FIELDS_CHANGE =
  "ON_RECRUITMENT_PROCESS_FORM_FORM_FIELDS_CHANGE";
const ON_RECRUITMENT_PROCESS_FORM_CHANGE = "ON_RECRUITMENT_PROCESS_FORM_CHANGE";

// end handle Recruitement Process Form

// to fetch recruitment processes
const FETCH_RECRUITMENT_PROCESSES_LIST_ATTEMPT =
  "FETCH_RECRUITMENT_PROCESSES_LIST_ATTEMPT";
const FETCH_RECRUITMENT_PROCESSES_LIST_SUCCESS =
  "FETCH_RECRUITMENT_PROCESSES_LIST_SUCCESS";
const FETCH_RECRUITMENT_PROCESSES_LIST_FAILED =
  "FETCH_RECRUITMENT_PROCESSES_LIST_FAILED";

// to delete recruitment processes
const DELETE_RECRUITMENT_PROCESSES_ATTEMPT =
  "DELETE_RECRUITMENT_PROCESSES_ATTEMPT";
const DELETE_RECRUITMENT_PROCESSES_SUCCESS =
  "DELETE_RECRUITMENT_PROCESSES_SUCCESS";
const DELETE_RECRUITMENT_PROCESSES_FAILED =
  "DELETE_RECRUITMENT_PROCESSES_FAILED";

// to clone recruitment processes
const CLONE_RECRUITMENT_PROCESSES_ATTEMPT =
  "CLONE_RECRUITMENT_PROCESSES_ATTEMPT";
const CLONE_RECRUITMENT_PROCESSES_SUCCESS =
  "CLONE_RECRUITMENT_PROCESSES_SUCCESS";
const CLONE_RECRUITMENT_PROCESSES_FAILED = "CLONE_RECRUITMENT_PROCESSES_FAILED";

// to update recruitment processes status
const UPDTAE_RECRUITMENT_PROCESSES_STATUS_ATTEMPT =
  "UPDTAE_RECRUITMENT_PROCESSES_STATUS_ATTEMPT";
const UPDTAE_RECRUITMENT_PROCESSES_STATUS_SUCCESS =
  "UPDTAE_RECRUITMENT_PROCESSES_STATUS_SUCCESS";
const UPDTAE_RECRUITMENT_PROCESSES_STATUS_FAILED =
  "UPDTAE_RECRUITMENT_PROCESSES_STATUS_FAILED";

// to fetch intake-forms
const FETCH_INTAKE_FORMS_LIST_ATTEMPT = "FETCH_INTAKE_FORMS_LIST_ATTEMPT";
const FETCH_INTAKE_FORMS_LIST_SUCCESS = "FETCH_INTAKE_FORMS_LIST_SUCCESS";
const FETCH_INTAKE_FORMS_LIST_FAILED = "FETCH_INTAKE_FORMS_LIST_FAILED";

// to delete intake-forms
const DELETE_INTAKE_FORMS_ATTEMPT = "DELETE_INTAKE_FORMS_ATTEMPT";
const DELETE_INTAKE_FORMS_SUCCESS = "DELETE_INTAKE_FORMS_SUCCESS";
const DELETE_INTAKE_FORMS_FAILED = "DELETE_INTAKE_FORMS_FAILED";

// to CLONE intake-forms
const CLONE_INTAKE_FORMS_ATTEMPT = "CLONE_INTAKE_FORMS_ATTEMPT";
const CLONE_INTAKE_FORMS_SUCCESS = "CLONE_INTAKE_FORMS_SUCCESS";
const CLONE_INTAKE_FORMS_FAILED = "CLONE_INTAKE_FORMS_FAILED";

// to update intake-forms status
const UPDTAE_INTAKE_FORMS_STATUS_ATTEMPT = "UPDTAE_INTAKE_FORMS_STATUS_ATTEMPT";
const UPDTAE_INTAKE_FORMS_STATUS_SUCCESS = "UPDTAE_INTAKE_FORMS_STATUS_SUCCESS";
const UPDTAE_INTAKE_FORMS_STATUS_FAILED = "UPDTAE_INTAKE_FORMS_STATUS_FAILED";

// to fetch job posts
const FETCH_JOB_POSTS_LIST_ATTEMPT = "FETCH_JOB_POSTS_LIST_ATTEMPT";
const FETCH_JOB_POSTS_LIST_SUCCESS = "FETCH_JOB_POSTS_LIST_SUCCESS";
const FETCH_JOB_POSTS_LIST_FAILED = "FETCH_JOB_POSTS_LIST_FAILED";

// to delete job posts
const DELETE_JOB_POSTS_ATTEMPT = "DELETE_JOB_POSTS_ATTEMPT";
const DELETE_JOB_POSTS_SUCCESS = "DELETE_JOB_POSTS_SUCCESS";
const DELETE_JOB_POSTS_FAILED = "DELETE_JOB_POSTS_FAILED";

// to update job posts status
const UPDTAE_JOB_POSTS_STATUS_ATTEMPT = "UPDTAE_JOB_POSTS_STATUS_ATTEMPT";
const UPDTAE_JOB_POSTS_STATUS_SUCCESS = "UPDTAE_JOB_POSTS_STATUS_SUCCESS";
const UPDTAE_JOB_POSTS_STATUS_FAILED = "UPDTAE_JOB_POSTS_STATUS_FAILED";

// to fetch application list
const FETCH_APPLICATION_LIST_ATTEMPT = "FETCH_APPLICATION_LIST_ATTEMPT";
const FETCH_APPLICATION_LIST_SUCCESS = "FETCH_APPLICATION_LIST_SUCCESS";
const FETCH_APPLICATION_LIST_FAILED = "FETCH_APPLICATION_LIST_FAILED";

// to fetch stages options
const FETCH_PROCESS_STAGES_FILTER_OPTIONS =
  "FETCH_PROCESS_STAGES_FILTER_OPTIONS";

/* Form Builder  */

const ADD_FORM_FIELD = "ADD_FORM_FIELD";
const DELETE_STAGE_PROCESS_FIELDS = "DELETE_STAGE_PROCESS_FIELDS";
const DELETE_FORM_FIELD = "DELETE_FORM_FIELD";

const ADD_PROCESS_STAGE = "ADD_PROCESS_STAGE";
const DELETE_PROCESS_STAGE = "DELETE_PROCESS_STAGE";

const ON_INPUT_FIELD_CHANGE = "ON_INPUT_FIELD_CHANGE";
const ON_STAGE_INPUT_FIELD_CHANGE = "ON_STAGE_INPUT_FIELD_CHANGE";

const UPDATE_PROCESS_STAGE_FIELDS_ORDER = "UPDATE_PROCESS_STAGE_FIELDS_ORDER";

const SET_RECRUITMENT_FORM_SUBMITTING = "SET_RECRUITMENT_FORM_SUBMITTING";
const RESET_RECRUITMENT_PROCESS_FORM = "RESET_RECRUITMENT_PROCESS_FORM";

const UPSERT_RECRUITMENT_PROCESS_ATTEMPT = "UPSERT_RECRUITMENT_PROCESS_ATTEMPT";
const UPSERT_RECRUITMENT_PROCESS_SUCCESS = "UPSERT_RECRUITMENT_PROCESS_SUCCESS";
const UPSERT_RECRUITMENT_PROCESS_FAILED = "UPSERT_RECRUITMENT_PROCESS_FAILED";

const INIT_RECRUITMENT_PROCESS_FORM_ATTEMPT =
  "INIT_RECRUITMENT_PROCESS_FORM_ATTEMPT";
const INIT_RECRUITMENT_PROCESS_FORM_SUCCESS =
  "INIT_RECRUITMENT_PROCESS_FORM_SUCCESS";
const INIT_RECRUITMENT_PROCESS_FORM_FAILED =
  "INIT_RECRUITMENT_PROCESS_FORM_FAILED";

const FETCH_RECRUITMENT_PROCESS_FORM_SUCCESS =
  "FETCH_RECRUITMENT_PROCESS_FORM_SUCCESS";

// to fetch recruitment Intake form
const INIT_RECRUITMENT_INTAKE_FORM_ATTEMPT =
  "INIT_RECRUITMENT_INTAKE_FORM_ATTEMPT";
const INIT_RECRUITMENT_INTAKE_FORM_SUCCESS =
  "INIT_RECRUITMENT_INTAKE_FORM_SUCCESS";
const INIT_RECRUITMENT_INTAKE_FORM_FAILED =
  "INIT_RECRUITMENT_INTAKE_FORM_FAILED";
const FETCH_RECRUITMENT_INTAKE_FORM_SUCCESS =
  "FETCH_RECRUITMENT_INTAKE_FORM_SUCCESS";

const UPSERT_RECRUITMENT_INTAKE_ATTEMPT = "UPSERT_RECRUITMENT_INTAKE_ATTEMPT";
const UPSERT_RECRUITMENT_INTAKE_SUCCESS = "UPSERT_RECRUITMENT_INTAKE_SUCCESS";
const UPSERT_RECRUITMENT_INTAKE_FAILED = "UPSERT_RECRUITMENT_INTAKE_FAILED";

const RESET_RECRUITMENT_INTAKE_FORM = "RESET_RECRUITMENT_INTAKE_FORM";

// reset application list action
const RESET_APPLICATION_LIST = "RESET_APPLICATION_LIST";

// update Application Stage status actions
const UPDATE_APPLICATION_STAGE_STATUS_ATTEMPT =
  "UPDATE_APPLICATION_STAGE_STATUS_ATTEMPT";
const UPDATE_APPLICATION_STAGE_STATUS_SUCCESS =
  "UPDATE_APPLICATION_STAGE_STATUS_SUCCESS";
const UPDATE_APPLICATION_STAGE_STATUS_FAILED =
  "UPDATE_APPLICATION_STAGE_STATUS_FAILED";

// fetch applicants list actions
const FETCH_APPLICANTS_LIST_ATTEMPT = "FETCH_APPLICANTS_LIST_ATTEMPT";
const FETCH_APPLICANTS_LIST_SUCCESS = "FETCH_APPLICANTS_LIST_SUCCESS";
const FETCH_APPLICANTS_LIST_FAILED = "FETCH_APPLICANTS_LIST_FAILED";

// delete applicants actions
const DELETE_APPLICANTS_ATTEMPT = "DELETE_APPLICANTS_ATTEMPT";
const DELETE_APPLICANTS_SUCCESS = "DELETE_APPLICANTS_SUCCESS";
const DELETE_APPLICANTS_FAILED = "DELETE_APPLICANTS_FAILED";

// to fetch job posts options
const FETCH_JOB_POSTS_FILTER_OPTIONS = "FETCH_JOB_POSTS_FILTER_OPTIONS";

// handle job post modal actions
const SHOW_JOB_POST_MODAL = "SHOW_JOB_POST_MODAL";
const HIDE_JOB_POST_MODAL = "HIDE_JOB_POST_MODAL";
const UPSERT_JOB_POST_ATTEMPT = "UPSERT_JOB_POST_ATTEMPT";
const UPSERT_JOB_POST_SUCCESS = "UPSERT_JOB_POST_SUCCESS";
const UPSERT_JOB_POST_FAILED = "UPSERT_JOB_POST_FAILED";

// handle job post form processes and intake forms options actions
const FETCH_JOB_POST_FORM_PROCESSES_AND_INTAKE_FORMS_OPTIONS =
  "FETCH_JOB_POST_FORM_PROCESSES_AND_INTAKE_FORMS_OPTIONS";
const FETCH_JOB_POST_FORM_PROCESSES_AND_INTAKE_FORMS_OPTIONS_SUCCESS =
  "FETCH_JOB_POST_FORM_PROCESSES_AND_INTAKE_FORMS_OPTIONS_SUCCESS";
const FETCH_JOB_POST_FORM_PROCESSES_AND_INTAKE_FORMS_OPTIONS_FAILED =
  "FETCH_JOB_POST_FORM_PROCESSES_AND_INTAKE_FORMS_OPTIONS_FAILED";

// handle add new stage in job post process action
const ADD_JOB_POST_PROCESS_STAGES_INPUT = "ADD_JOB_POST_PROCESS_STAGES_INPUT";

// handle application modal actions
const SHOW_APPLICATION_MODAL = "SHOW_APPLICATION_MODAL";
const HIDE_APPLICATION_MODAL = "HIDE_APPLICATION_MODAL";
const UPSERT_APPLICATION_ATTEMPT = "UPSERT_APPLICATION_ATTEMPT";
const UPSERT_APPLICATION_SUCCESS = "UPSERT_APPLICATION_SUCCESS";
const UPSERT_APPLICATION_FAILED = "UPSERT_APPLICATION_FAILED";

// handle fetch applicant Profile actions
const FETCH_APPLICANT_PROFILE_ATTEMPT = "FETCH_APPLICANT_PROFILE_ATTEMPT";
const FETCH_APPLICANT_PROFILE_SUCCESS = "FETCH_APPLICANT_PROFILE_SUCCESS";
const FETCH_APPLICANT_PROFILE_FAILED = "FETCH_APPLICANT_PROFILE_FAILED";

// handle edit applicant email modal actions
const SHOW_EDIT_APPLICANT_EMAIL_MODAL = "SHOW_EDIT_APPLICANT_EMAIL_MODAL";
const HIDE_EDIT_APPLICANT_EMAIL_MODAL = "HIDE_EDIT_APPLICANT_EMAIL_MODAL";
const UPDATE_APPLICANT_EMAIL_ATTEMPT = "UPDATE_APPLICANT_EMAIL_ATTEMPT";
const UPDATE_APPLICANT_EMAIL_SUCCESS = "UPDATE_APPLICANT_EMAIL_SUCCESS";
const UPDATE_APPLICANT_EMAIL_FAILED = "UPDATE_APPLICANT_EMAIL_FAILED";

// handle add applicant number modal actions
const SHOW_ADD_APPLICANT_PHONE_MODAL = "SHOW_ADD_APPLICANT_PHONE_MODAL";
const HIDE_ADD_APPLICANT_PHONE_MODAL = "HIDE_ADD_APPLICANT_PHONE_MODAL";
const CREATE_APPLICANT_PHONE_ATTEMPT = "CREATE_APPLICANT_PHONE_ATTEMPT";
const CREATE_APPLICANT_PHONE_SUCCESS = "CREATE_APPLICANT_PHONE_SUCCESS";
const CREATE_APPLICANT_PHONE_FAILED = "CREATE_APPLICANT_PHONE_FAILED";

// handle edit applicant number modal actions
const SHOW_EDIT_APPLICANT_PHONE_MODAL = "SHOW_EDIT_APPLICANT_PHONE_MODAL";
const HIDE_EDIT_APPLICANT_PHONE_MODAL = "HIDE_EDIT_APPLICANT_PHONE_MODAL";
const UPDATE_APPLICANT_PHONE_ATTEMPT = "UPDATE_APPLICANT_PHONE_ATTEMPT";
const UPDATE_APPLICANT_PHONE_SUCCESS = "UPDATE_APPLICANT_PHONE_SUCCESS";
const UPDATE_APPLICANT_PHONE_FAILED = "UPDATE_APPLICANT_PHONE_FAILED";

// handle delete applicant phone number actions
const DELETE_APPLICANT_PHONE_NUMBER_ATTEMPT =
  "DELETE_APPLICANT_PHONE_NUMBER_ATTEMPT";
const DELETE_APPLICANT_PHONE_NUMBER_SUCCESS =
  "DELETE_APPLICANT_PHONE_NUMBER_SUCCESS";
const DELETE_APPLICANT_PHONE_NUMBER_FAILED =
  "DELETE_APPLICANT_PHONE_NUMBER_FAILED";

const HANDLE_RECRUITMENT_INTAKE_FORM_CHANGE =
  "HANDLE_RECRUITMENT_INTAKE_FORM_CHANGE";

// handle fetch applicant Profile actions

const FETCH_INTAKE_FORM_ATTEMPT = "FETCH_INTAKE_FORM_ATTEMPT";
const FETCH_INTAKE_FORM_SUCCESS = "FETCH_INTAKE_FORM_SUCCESS";
const FETCH_INTAKE_FORM_FAILED = "FETCH_INTAKE_FORM_FAILED";

// handle submit applican profile actions
const FILL_STAGE_DATA_ATTEMPT = "FILL_STAGE_DATA_ATTEMPT";
const FILL_STAGE_DATA_SUCCESS = "FILL_STAGE_DATA_SUCCESS";
const FILL_STAGE_DATA_FAILED = "FILL_STAGE_DATA_FAILED";

export default {
  // handle children input
  ON_RECRUITMENT_CHILD_INPUT_CHANGE,

  // to fetch recruitment processes
  FETCH_RECRUITMENT_PROCESSES_LIST_ATTEMPT,
  FETCH_RECRUITMENT_PROCESSES_LIST_SUCCESS,
  FETCH_RECRUITMENT_PROCESSES_LIST_FAILED,

  // to delete recruitment processes
  DELETE_RECRUITMENT_PROCESSES_ATTEMPT,
  DELETE_RECRUITMENT_PROCESSES_SUCCESS,
  DELETE_RECRUITMENT_PROCESSES_FAILED,

  // to update recruitment processes status
  UPDTAE_RECRUITMENT_PROCESSES_STATUS_ATTEMPT,
  UPDTAE_RECRUITMENT_PROCESSES_STATUS_SUCCESS,
  UPDTAE_RECRUITMENT_PROCESSES_STATUS_FAILED,

  // to fetch intake-forms
  FETCH_INTAKE_FORMS_LIST_ATTEMPT,
  FETCH_INTAKE_FORMS_LIST_SUCCESS,
  FETCH_INTAKE_FORMS_LIST_FAILED,

  // to delete intake-forms
  DELETE_INTAKE_FORMS_ATTEMPT,
  DELETE_INTAKE_FORMS_SUCCESS,
  DELETE_INTAKE_FORMS_FAILED,

  // to CLONE intake-forms
  CLONE_INTAKE_FORMS_ATTEMPT,
  CLONE_INTAKE_FORMS_SUCCESS,
  CLONE_INTAKE_FORMS_FAILED,

  // to update intake-forms status
  UPDTAE_INTAKE_FORMS_STATUS_ATTEMPT,
  UPDTAE_INTAKE_FORMS_STATUS_SUCCESS,
  UPDTAE_INTAKE_FORMS_STATUS_FAILED,

  // to fetch job posts
  FETCH_JOB_POSTS_LIST_ATTEMPT,
  FETCH_JOB_POSTS_LIST_SUCCESS,
  FETCH_JOB_POSTS_LIST_FAILED,

  // to delete job posts
  DELETE_JOB_POSTS_ATTEMPT,
  DELETE_JOB_POSTS_SUCCESS,
  DELETE_JOB_POSTS_FAILED,

  // to update job posts status
  UPDTAE_JOB_POSTS_STATUS_ATTEMPT,
  UPDTAE_JOB_POSTS_STATUS_SUCCESS,
  UPDTAE_JOB_POSTS_STATUS_FAILED,

  // to fetch application list
  FETCH_APPLICATION_LIST_ATTEMPT,
  FETCH_APPLICATION_LIST_SUCCESS,
  FETCH_APPLICATION_LIST_FAILED,

  // to fetch stages options
  FETCH_PROCESS_STAGES_FILTER_OPTIONS,

  ADD_FORM_FIELD,
  DELETE_FORM_FIELD,
  DELETE_STAGE_PROCESS_FIELDS,

  ADD_PROCESS_STAGE,
  DELETE_PROCESS_STAGE,

  ON_INPUT_FIELD_CHANGE,
  ON_STAGE_INPUT_FIELD_CHANGE,

  UPDATE_PROCESS_STAGE_FIELDS_ORDER,

  SET_RECRUITMENT_FORM_SUBMITTING,
  RESET_RECRUITMENT_PROCESS_FORM,

  UPSERT_RECRUITMENT_PROCESS_ATTEMPT,
  UPSERT_RECRUITMENT_PROCESS_SUCCESS,
  UPSERT_RECRUITMENT_PROCESS_FAILED,

  INIT_RECRUITMENT_PROCESS_FORM_ATTEMPT,
  INIT_RECRUITMENT_PROCESS_FORM_SUCCESS,
  INIT_RECRUITMENT_PROCESS_FORM_FAILED,
  FETCH_RECRUITMENT_PROCESS_FORM_SUCCESS,

  INIT_RECRUITMENT_INTAKE_FORM_FAILED,
  INIT_RECRUITMENT_INTAKE_FORM_ATTEMPT,
  INIT_RECRUITMENT_INTAKE_FORM_SUCCESS,
  FETCH_RECRUITMENT_INTAKE_FORM_SUCCESS,

  UPSERT_RECRUITMENT_INTAKE_ATTEMPT,
  UPSERT_RECRUITMENT_INTAKE_SUCCESS,
  UPSERT_RECRUITMENT_INTAKE_FAILED,
  RESET_RECRUITMENT_INTAKE_FORM,
  // reset application list action
  RESET_APPLICATION_LIST,

  // update Application Stage status actions
  UPDATE_APPLICATION_STAGE_STATUS_ATTEMPT,
  UPDATE_APPLICATION_STAGE_STATUS_SUCCESS,
  UPDATE_APPLICATION_STAGE_STATUS_FAILED,

  // fetch applicants list actions
  FETCH_APPLICANTS_LIST_ATTEMPT,
  FETCH_APPLICANTS_LIST_SUCCESS,
  FETCH_APPLICANTS_LIST_FAILED,

  // delete applicants actions
  DELETE_APPLICANTS_ATTEMPT,
  DELETE_APPLICANTS_SUCCESS,
  DELETE_APPLICANTS_FAILED,

  // to fetch job posts options
  FETCH_JOB_POSTS_FILTER_OPTIONS,

  // handle job post modal actions
  SHOW_JOB_POST_MODAL,
  HIDE_JOB_POST_MODAL,
  UPSERT_JOB_POST_ATTEMPT,
  UPSERT_JOB_POST_SUCCESS,
  UPSERT_JOB_POST_FAILED,

  // handle job post form processes and intake forms options actions
  FETCH_JOB_POST_FORM_PROCESSES_AND_INTAKE_FORMS_OPTIONS,
  FETCH_JOB_POST_FORM_PROCESSES_AND_INTAKE_FORMS_OPTIONS_SUCCESS,
  FETCH_JOB_POST_FORM_PROCESSES_AND_INTAKE_FORMS_OPTIONS_FAILED,

  // handle add new stage in job post process action
  ADD_JOB_POST_PROCESS_STAGES_INPUT,

  ON_RECRUITMENT_PROCESS_FORM_FORM_FIELDS_CHANGE,
  ON_RECRUITMENT_PROCESS_FORM_CHANGE,
  HANDLE_RECRUITMENT_INTAKE_FORM_CHANGE,

  // handle application modal actions
  SHOW_APPLICATION_MODAL,
  HIDE_APPLICATION_MODAL,
  UPSERT_APPLICATION_ATTEMPT,
  UPSERT_APPLICATION_SUCCESS,
  UPSERT_APPLICATION_FAILED,

  // handle fetch applicant Profile actions
  FETCH_APPLICANT_PROFILE_ATTEMPT,
  FETCH_APPLICANT_PROFILE_SUCCESS,
  FETCH_APPLICANT_PROFILE_FAILED,

  // handle edit applicant email modal actions
  SHOW_EDIT_APPLICANT_EMAIL_MODAL,
  HIDE_EDIT_APPLICANT_EMAIL_MODAL,
  UPDATE_APPLICANT_EMAIL_ATTEMPT,
  UPDATE_APPLICANT_EMAIL_SUCCESS,
  UPDATE_APPLICANT_EMAIL_FAILED,

  // handle add applicant number modal actions
  SHOW_ADD_APPLICANT_PHONE_MODAL,
  HIDE_ADD_APPLICANT_PHONE_MODAL,
  CREATE_APPLICANT_PHONE_ATTEMPT,
  CREATE_APPLICANT_PHONE_SUCCESS,
  CREATE_APPLICANT_PHONE_FAILED,

  // handle edit applicant number modal actions
  SHOW_EDIT_APPLICANT_PHONE_MODAL,
  HIDE_EDIT_APPLICANT_PHONE_MODAL,
  UPDATE_APPLICANT_PHONE_ATTEMPT,
  UPDATE_APPLICANT_PHONE_SUCCESS,
  UPDATE_APPLICANT_PHONE_FAILED,

  // handle delete applicant phone number actions
  DELETE_APPLICANT_PHONE_NUMBER_ATTEMPT,
  DELETE_APPLICANT_PHONE_NUMBER_SUCCESS,
  DELETE_APPLICANT_PHONE_NUMBER_FAILED,

  // handle fetch applican Intake form actions

  FETCH_INTAKE_FORM_ATTEMPT,
  FETCH_INTAKE_FORM_SUCCESS,
  FETCH_INTAKE_FORM_FAILED,

  FILL_STAGE_DATA_ATTEMPT,
  FILL_STAGE_DATA_SUCCESS,
  FILL_STAGE_DATA_FAILED,

  CLONE_RECRUITMENT_PROCESSES_ATTEMPT,
  CLONE_RECRUITMENT_PROCESSES_SUCCESS,
  CLONE_RECRUITMENT_PROCESSES_FAILED,
};

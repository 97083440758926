import * as REQUESTS from "../../Constants/Requests";
import loanStatus from "../../Constants/LoanStatusConstants";

const AllMonthData = [
  { key: null, value: "All Months" },
  { key: "01", value: "January" },
  { key: "02", value: "February" },
  { key: "03", value: "March" },
  { key: "04", value: "April" },
  { key: "05", value: "May" },
  { key: "06", value: "June" },
  { key: "07", value: "July" },
  { key: "08", value: "August" },
  { key: "09", value: "September" },
  { key: "10", value: "October" },
  { key: "11", value: "November" },
  { key: "12", value: "December" },
];
const MonthData = [
  { key: "01", value: "January" },
  { key: "02", value: "February" },
  { key: "03", value: "March" },
  { key: "04", value: "April" },
  { key: "05", value: "May" },
  { key: "06", value: "June" },
  { key: "07", value: "July" },
  { key: "08", value: "August" },
  { key: "09", value: "September" },
  { key: "10", value: "October" },
  { key: "11", value: "November" },
  { key: "12", value: "December" },
];
const YearData = (() => {
  // max year is current year
  const maxYear = new Date().getFullYear() + 1;
  // start year
  const minYear = maxYear - 4;
  let data = [],
    i;
  for (i = maxYear; i >= minYear; i--) {
    data.push({ value: i });
  }
  return data;
})();

const FutureYearData = (() => {
  // max year is current year
  const maxYear = new Date().getFullYear() + 5;
  // start year
  const minYear = new Date().getFullYear();
  let data = [],
    i;
  for (i = minYear; i <= maxYear; i++) {
    data.push({ value: i });
  }
  return data;
})();

const REQUESTS_STATUS = {
  pending: 2,
  accepted: 1,
  rejected: 0,
};

const balanceFilterOptions = [
  { label: "annual leaves", value: REQUESTS.NORMAL_LEAVES },
  { label: "sick leaves", value: REQUESTS.SICK_LEAVES },
  { label: "salary", value: REQUESTS.UNPAID_LEAVES },
];

const balanceLogsActionsOptions = [
  { label: "All", value: null },
  { label: "additions", value: "1" },
  { label: "dedcutions", value: "2" },
];

const balanceLogsTypeOptions = [
  { value: REQUESTS.PERMISSIONS, label: "Paid Permissions" },
  { value: REQUESTS.NORMAL_LEAVES, label: "annual leaves" },
  { value: REQUESTS.SICK_LEAVES, label: "sick leaves" },
  // { value: REQUESTS.HOME, label: "Home" },
];

const requestsStatusFilter = [
  { label: "Pending", value: 2 },
  { label: "Accepted", value: 1 },
  { label: "Rejected", value: 0 },
];

const PayrollStatusFilter = [
  { label: "unpaid", value: "unpaid" },
  { label: "paid", value: "paid" },
  { label: "All", value: "all" },
];

const ClaimsStatusFilter = [
  { label: "unpaid claims", value: 4 },
  { label: "paid claims", value: 3 },
  { label: "pending", value: 2 },
  { label: "accepted", value: 1 },
  { label: "rejected", value: 5 },
];

const BonusStatusFilter = [
  { label: "All", value: "all" },
  { label: "unpaid bonuses", value: "un_paid" },
  { label: "paid bonuses", value: "paid" },
];

const DeductionsStatusFilter = [
  { label: "All", value: "all" },
  { label: "unpaid deductibles", value: "un_paid" },
  { label: "paid deductibles", value: "paid" },
];

const companiesStatusFilter = [
  { label: "active", value: "active" },
  { label: "inactive", value: "suspended" },
  { label: "All", value: "all" },
];

const pettyCashTypes = [
  { label: "Add Balance", value: "add" },
  { label: "Deduct Balance", value: "deduct" },
];

const recruitmentProcessStatusFilter = [
  { label: "active", value: "active" },
  { label: "inactive", value: "inactive" },
  { label: "All", value: "all" },
];

const penaltiesStatusFilter = [
  { label: "All", value: "all" },
  { label: "Applied", value: "applied" },
  { label: "Not Applied", value: "not_applied" },
];

const deductedFromStatusFilter = [
  { label: "All", value: "all" },
  { label: "Salary", value: "salary" },
  { label: "annual leaves", value: "Annual" },
];

const causeFilterOptions = [
  { label: "absent-no show", value: "no_show" },
  { label: "absent-late sign-in", value: "late_sign_in" },
  { label: "absent-early sign-out", value: "early_sign_out" },
  { label: "Tier1 Penalty", value: "minor" },
  { label: "Tier2 Penalty", value: "major" },
  { label: "Tier3 Penalty", value: "major1" },
  { label: "Tier4 Penalty", value: "major2" },
  { label: "Tier5 Penalty", value: "major3" },
  { label: "Tier6 Penalty", value: "major4" },
  { label: "Tier7 Penalty", value: "major5" },
  { label: "Tier8 Penalty", value: "major6" },
  { label: "Tier9 Penalty", value: "major7" },
  { label: "Tier10 Penalty", value: "major8" },
  { label: "Tier11 Penalty", value: "major9" },
  { label: "Tier12 Penalty", value: "major10" },
  { label: "early sign-out", value: "early" },
  { label: "missing sign-out", value: "missing" },
];

const loanStatusFilter = [
  { label: "Ongoing", value: loanStatus.ONGOING_LOAN },
  { label: "Settled", value: loanStatus.SETTLED_LOAN },
  { label: "All", value: null },
];

export default {
  AllMonthData,
  YearData,
  FutureYearData,
  REQUESTS_STATUS,
  MonthData,
  balanceFilterOptions,
  balanceLogsActionsOptions,
  balanceLogsTypeOptions,
  requestsStatusFilter,
  PayrollStatusFilter,
  ClaimsStatusFilter,
  BonusStatusFilter,
  DeductionsStatusFilter,
  companiesStatusFilter,
  pettyCashTypes,
  recruitmentProcessStatusFilter,
  penaltiesStatusFilter,
  deductedFromStatusFilter,
  causeFilterOptions,
  loanStatusFilter,
};

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BSelect, InputForm } from "form-builder";
import { useTranslation } from "react-i18next";
import { handleActionMutation } from "../../Helpers/HelperFns";

// Apollo
import { useMutation, useQuery } from "@apollo/client";
import { upsertWorkGroupMutation } from "../../Graphql/mutation";
import { fetchWorkGroupModalQuery } from "../../Graphql/query";

// Actions
import {
  EditSetupWizardEssentialData,
  onFormResetAction,
  onInputChangeAction,
} from "../../Store/Actions";
import { editWorkGroupAction } from "../../Store/Actions/shiftsActions/actions";

// Ui
import CircularProgress from "@mui/material/CircularProgress";
import MainModal from "../MainModal";
import BlockUi from "react-block-ui";
import Swal from "sweetalert2";
import Loader from "../Loader";
import _ from "lodash";

//   form props
const reducerName = "shifts";
const formName = "upsertWorkGroupForm";
const formNameValidation = "upsertWorkGroupFormValidation";
const formServerValidation = "upsertWorkGroupFormServerValidation";

const WorkGroupModal = ({
  isModalOpen,
  setIsModalOpen,
  fetchWorkGroups,
  workGroupId,
  setWorkGroupId,
  isSetupWizard,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const formProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
    reducer: reducerName,
  };

  // //   Redux hooks
  const dispatch = useDispatch();
  const upsertWorkGroupForm = useSelector((state) => state?.shifts?.[formName]);
  const formClientValidation = useSelector(
    (state) => state?.shifts?.[formNameValidation]
  );

  useEffect(() => {
    return () => {
      setWorkGroupId("");
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
      dispatch(onFormResetAction(formServerValidation));
    };
  }, []);

  // // Apollo hooks
  const { data: workGroupOptions, loading: fetchWorkGroupModalLoading } =
    useQuery(fetchWorkGroupModalQuery, {
      variables: {
        id: workGroupId,
        isEditing: workGroupId ? true : false,
      },

      onCompleted: (data) => {
        if (data?.workGroup) {
          dispatch(
            editWorkGroupAction({
              id: data?.workGroup?.id,
              name: data?.workGroup?.name,
              managersIds: data?.workGroup?.managers?.map(
                (manager) => manager?.id
              ),
              employeesIds: data?.workGroup?.employees?.map(
                (employee) => employee?.user?.id
              ),
              workTimingsIds: data?.workGroup?.workTimings?.map(
                (workTiming) => workTiming?.id
              ),
              spacesIds: data?.workGroup?.locations?.map(
                (location) => location?.id
              ),
            })
          );
        }
      },

      onError: (error) => {
        Swal.fire({
          title: t("error"),
          text: error.message ? error.message : t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
          showDenyButton: false,
        });
      },
    });

  const [upsertWorkGroup, { loading: upsertWorkGroupLoading }] = useMutation(
    upsertWorkGroupMutation,
    {
      variables: {
        input: upsertWorkGroupForm,
      },
    }
  );

  const handleSubmit = async () => {
    setIsSubmitting(true);

    if (formClientValidation?.length === 0) {
      setIsSubmitting(false);
      const { response } = await handleActionMutation(upsertWorkGroup, {
        isReturnUnion: true,
        refetchQuery: fetchWorkGroups,
      });

      if (!response?.errors) {
        if (!isSetupWizard) setIsModalOpen(false);
        else dispatch(EditSetupWizardEssentialData("workGroups"));
      }
    }
  };

  // start of handle work time options consist of worktime menu data and old selected work time data
  const serilazedWorkTimingArray = [
    workGroupOptions?.work_timings_menu ?? [],
    workGroupOptions?.workGroup?.workTimings ?? [],
  ].flat();

  const workTimeOptions = _.uniqBy(serilazedWorkTimingArray, "id");
  // End of handle work time options consist of worktime menu data and old selected work time data

  const handleSelectAll = (e) => {
    const name = e.target?.getAttribute("name");
    let value = [];

    if (name === "workTimingsIds") {
      value = workTimeOptions?.map((timing) => timing?.id);
    } else {
      value = workGroupOptions?.offices?.data?.map((location) => location?.id);
    }

    const formEvent = {
      action: "select-option",
      name,
    };

    dispatch(onInputChangeAction(formName, formEvent, "select", value));
  };

  // uncomment this if you don't want to allow selected employees to be managers
  // const filterManagersOptions = workGroupOptions?.managers?.data?.filter(
  //   (emp) => !upsertWorkGroupForm?.employeesIds?.includes(emp?.id)
  // );
  const filterManagersOptions = workGroupOptions?.managers?.data
    ?.concat(
      workGroupOptions?.profile?.company?.businessPartners?.map((bp) => ({
        id: bp?.user?.id,
        name: bp?.user?.name,
      }))
    )
    .filter((el) => el != undefined);

  const filterEmployeeOptions = workGroupOptions?.employees?.map((emp) => {
    return { id: emp?.user?.id, name: emp?.user?.name };
  });
  // uncomment this if you don't want to allow selected managers to be employees
  // ?.filter((emp) => !upsertWorkGroupForm?.managersIds?.includes(emp?.id));

  const renderEmployeeOptions = filterEmployeeOptions?.concat(
    workGroupOptions?.workGroup?.employees?.map((emp) => {
      return {
        id: emp?.user?.id,
        name: emp?.user?.name,
        isFixed: true,
      };
    })
  );

  const handleEmployeesChange = (value, event) => {
    switch (event.action) {
      case "remove-value":
      case "pop-value":
        if (event.removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        value = renderEmployeeOptions.filter((v) => v.isFixed);
        break;
    }

    dispatch(
      onInputChangeAction(
        formName,
        event,
        "select",
        value ? value?.map((val) => val["id"]) : []
      )
    );
  };

  const form = (
    <>
      <InputForm
        {...formProps}
        validateBy="textRequired"
        label={t("name")}
        name="name"
        validationName="input.name"
        type="text"
        placeholder={t("group name")}
        containerStyle="name_field_container"
        inputContainerStyle="name_field_input"
        labelStyle="name_field_label"
        icon="people"
      />

      <BSelect
        {...formProps}
        name="managersIds"
        validationName="input.managersIds"
        label={"managers"}
        keepDefaultStyle
        options={filterManagersOptions}
        optionLabel="name"
        optionValue="id"
        labelStyle="my-2"
        containerStyle="containerStyle flex-column align-items-start "
        inputContainerStyle="flex-grow-1 mr-0 w-100"
        skipLocalization
        isMulti
        icon="people"
      />

      {/* <BSelect
            {...formProps}
            name="employeesIds"
            validationName="input.employeesIds"
            label={"employees"}
            keepDefaultStyle
            options={renderEmployeeOptions
              ?.filter((opt) => opt?.isFixed)
              .concat(renderEmployeeOptions?.filter((opt) => !opt?.isFixed))
              ?.filter((opt) => opt != undefined)}
            isMulti={true}
            optionLabel="name"
            optionValue="id"
            labelStyle="my-2"
            containerStyle={`containerStyle flex-column align-items-start ${
              workGroupId && "workGroup_employees_select"
            }`}
            inputContainerStyle="flex-grow-1 mr-0 w-100"
            skipLocalization
            icon="people"
            onChange={handleEmployeesChange}
          /> */}

      <div className="selectall_dropdown">
        <span onClick={handleSelectAll} name="workTimingsIds">
          {t("select all")}
        </span>
        <BSelect
          {...formProps}
          name="workTimingsIds"
          validateBy="textRequired"
          validationName="input.workTimingsIds"
          label={"work timings"}
          keepDefaultStyle
          options={workTimeOptions}
          isMulti={true}
          optionLabel="name"
          optionValue="id"
          labelStyle="my-2"
          containerStyle="containerStyle flex-column align-items-start"
          inputContainerStyle="flex-grow-1 mr-0 w-100"
          skipLocalization
          icon="calendar"
        />
      </div>

      <div className="selectall_dropdown">
        <span onClick={handleSelectAll} name="spacesIds">
          {t("select all")}
        </span>
        <BSelect
          {...formProps}
          name="spacesIds"
          validateBy="textRequired"
          validationName="input.spacesIds"
          label={"locations"}
          keepDefaultStyle
          options={workGroupOptions?.offices?.data}
          isMulti={true}
          optionLabel="name"
          optionValue="id"
          labelStyle="my-2"
          containerStyle="containerStyle flex-column align-items-start"
          inputContainerStyle="flex-grow-1 mr-0 w-100"
          skipLocalization
          icon="location"
        />
      </div>
    </>
  );

  if (isSetupWizard) {
    return (
      <div className="workgroup-form">
        {fetchWorkGroupModalLoading ? <Loader fixed /> : null}
        {form}
        <button
          onClick={handleSubmit}
          disabled={upsertWorkGroupLoading}
          className="btn btn-primary submit_btn_style ml-auto d-block mt-2"
        >
          {upsertWorkGroupLoading ? (
            <CircularProgress size={15} sx={{ color: "white", mt: 0.25 }} />
          ) : (
            t("save")
          )}
        </button>
      </div>
    );
  }

  return (
    <>
      <MainModal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
        modalTitle={workGroupId ? t("edit work group") : t("new work group")}
        btnLabel={t("save")}
        btnOnClick={handleSubmit}
        btnSubmitLoading={upsertWorkGroupLoading}
        className="work_group_modal"
      >
        <BlockUi tag="div" blocking={fetchWorkGroupModalLoading}>
          {form}
        </BlockUi>
      </MainModal>
    </>
  );
};

export default WorkGroupModal;

const Types = {
  EDIT_WORK_GROUP: "EDIT_WORK_GROUP",
  RESET_WORK_GROUP_MODAL: "RESET_WORK_GROUP_MODAL",
  RESET_WORK_TEAM_ACTION_MODAL: "RESET_WORK_TEAM_ACTION_MODAL",
  EDIT_WORK_TEAM: "EDIT_WORK_TEAM",
  SET_SCHEDULE_SERVER_VALIDATION: "SET_SCHEDULE_SERVER_VALIDATION",
  EDIT_EMPLOYEE_WORK_SCHEDULE: "EDIT_EMPLOYEE_WORK_SCHEDULE",
  SET_ATT_TYPE_SERVER_VALIDATION: "SET_ATT_TYPE_SERVER_VALIDATION",
  SET_WORK_SCHEDULE_SERVER_VALIDATION: "SET_WORK_SCHEDULE_SERVER_VALIDATION",
};

export default Types;

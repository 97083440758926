import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { getOrdinalTranslation } from "../../Helpers/HelperFns";

import { AddButton } from "../Buttons";
import { RemoveIconButton } from "../IconButtonWithTooltip";
import DateTimePickerForm from "../../Builder/Form/DateTimePickerForm";

const BreakSection = ({ breaks = [] }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [localBreaks, setLocalBreaks] = React.useState(breaks);

  // Reducer State
  const editAttendance = useSelector((state) => state?.super?.editAttRecord);

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    setLocalBreaks(breaks);
  }, [breaks?.length]);

  /* ↓ Helpers ↓ */

  const addToStore = (breakInOuts) => {
    dispatch({
      type: "EDIT_ATTENDANCE_RECORD",
      payload: {
        ...editAttendance,
        breakInOuts,
      },
    });
  };

  const handleChange = (val, name, idx) => {
    setLocalBreaks((prev) => {
      const newBreaks = prev.map((p, i) =>
        i === idx ? { ...p, [name]: val.format("YYYY-MM-DD HH:mm:ss") } : p
      );

      newBreaks[idx]?.break_in_time &&
        newBreaks[idx]?.break_out_time &&
        addToStore(newBreaks);

      return newBreaks;
    });
  };

  const handleAddBreak = () => {
    const newBreaks = [
      ...localBreaks,
      {
        id: null,
        logs: [],
        break_in_time: moment(editAttendance?.date),
        break_out_time: moment(editAttendance?.date),
      },
    ];

    setLocalBreaks(newBreaks);
  };

  const handleRemoveBreak = (i) => {
    const newBreaks = localBreaks.reduce((acc, curr, idx) => {
      if (i === idx) return acc;
      return [...acc, curr];
    }, []);

    setLocalBreaks(newBreaks);
    addToStore(newBreaks);
  };

  return (
    <>
      <div className="d-flex align-items-center gap-10 mt-3">
        <b className="text-16">{t("breaks")}</b>
        <AddButton isIconOnly onClick={handleAddBreak} />
      </div>

      {localBreaks?.map((b, idx) => (
        <div key={b?.id} className="d-flex align-items-center gap-20">
          <b>{getOrdinalTranslation(idx + 1, "Break", "f")}</b>

          <DateTimePickerForm
            dateTime
            label="from"
            rootStyle="flex-1"
            datePickerContainer="flex-1 pl-2"
            value={b?.break_in_time ? moment(b?.break_in_time) : null}
            onSelect={(val) => handleChange(val, "break_in_time", idx)}
          />
          <DateTimePickerForm
            dateTime
            label="to"
            rootStyle="flex-1"
            datePickerContainer="flex-1 pl-2"
            value={b?.break_out_time ? moment(b?.break_out_time) : null}
            onSelect={(val) => handleChange(val, "break_out_time", idx)}
          />

          <RemoveIconButton
            sx={{ mt: 2 }}
            onClick={() => handleRemoveBreak(idx)}
          />
        </div>
      ))}
    </>
  );
};

export default BreakSection;

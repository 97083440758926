import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MultipleCheckIn from "../../../Components/SharedFormSections/MultipleCheckIn";
import CheckInSettings from "../../../Components/CheckInSettings";

const CheckInsSection = ({ formProps }) => {
  const { t } = useTranslation();

  // Reducer State
  const {
    check_in_form_id,
    check_out_form_id,
    allow_customize_check_ins,
    allow_multiple_check_ins,
  } = useSelector((state) => state.super.attendaceProfile);

  const workPlacesOptions =
    useSelector((state) => state.super?.attendanceProfileCheckInsWorkPlaces) ??
    [];

  return (
    <>
      <strong className="att_profile_header">{t("Check-ins")}</strong>
      <div className="boxContainer">
        <MultipleCheckIn
          formProps={formProps}
          name="allow_multiple_check_ins"
          allow_customize_check_ins={allow_customize_check_ins}
          customCheckFormIDs={{
            in: check_in_form_id,
            out: check_out_form_id,
          }}
        />

        {allow_multiple_check_ins ? (
          <CheckInSettings
            reducer="super"
            formProps={formProps}
            workPlacesOptions={workPlacesOptions}
          />
        ) : null}
      </div>
    </>
  );
};

export default CheckInsSection;

import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fillStageDataAction,
  fillStageDataFailed,
  fillStageDataSuccess,
  showErrorToast,
  updateApplicationsStatusAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import Select from "react-select";
import PreviewProcessStage from "../../Components/RecruitmentProcessStage/PreviewProcessStage";
import ViewFromBuilder from "../../Containers/ViewFormBuilder";
import { Spinner } from "reactstrap";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getApplicantJobPostQuery } from "../../Graphql/query/RecruitmentQueries";
import {
  fillStageFormMutation,
  updateApplicationStageStatusMutation,
} from "../../Graphql/mutation";
// import { updateCurrentStageMutation } from "../../Graphql/query/RecruitmentQueries";

const getFieldAnswer = (
  field,
  answers,
  answerKey = "applicant_job_post_stage_form_answer"
) => {
  if (!field?.options?.length) {
    if (field?.type?.toLowerCase() == "attachment") {
      return (
        answers?.find((answer) => answer?.field?.id === field?.id)?.[
          answerKey
        ]?.[0]?.attachment?.path ?? null
      );
    }
    return (
      answers?.find((answer) => answer?.field?.id === field?.id)?.[
        answerKey
      ]?.[0]?.answer ?? ""
    );
  }
  if (field?.options?.length) {
    let fieldAnswer =
      answers
        ?.find((answer) => answer?.field?.id === field?.id)
        ?.[answerKey]?.map((answer) => answer?.option_id)
        ?.flat(Infinity) ?? [];
    if (
      field.type == "radio" ||
      field?.selection_type?.toLowerCase() == "single"
    ) {
      return fieldAnswer?.toString() ?? "";
    } else {
      return fieldAnswer ?? [];
    }
  }
};

const ApplicantJobPost = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);

  const [
    getApplicantJobPost,
    { loading, error, data, refetch: refetchApplicantJobPost },
  ] = useLazyQuery(getApplicantJobPostQuery, {
    onCompleted: (data) => {
      handleTabsChange(null, data?.applicant_job_post?.last_stage?.id);
    },
  });

  const isActive = props?.activeJobPost === props?.id;

  useEffect(() => {
    if (isActive) {
      getApplicantJobPost({
        variables: {
          id: props.id,
        },
      });
    }
    return () => {};
  }, [isActive]);

  const [activeStageTab, setActiveStageTab] = useState(0);

  const applicationProcess = data?.applicant_job_post.job_post?.process?.stages;
  const filledApplicationProcess = data?.applicant_job_post?.process_stages;

  let preFilledStage =
    filledApplicationProcess?.find(
      (stage) => stage?.stage?.id == activeStageTab
    ) ?? {};
  let activeStage =
    applicationProcess?.find((stage) => stage?.id == activeStageTab) ?? {};

  const handleTabsChange = (event, currentStage) => {
    setIsEditing(false);
    setActiveStageTab(currentStage);
  };

  useEffect(() => {
    if (activeStageTab && data) {
      if (activeStageTab == "intakeForm") {
        let intakeFormFields =
          data?.applicant_job_post.intake_form?.fields?.reduce((acc, field) => {
            let fieldValue = getFieldAnswer(
              field,
              data?.applicant_job_post.intake_form_field,
              "applicants_job_posts_intake_form_answers"
            );
            return { ...acc, [field.id]: fieldValue };
          }, {});
        dispatch({ type: "SET_EDITED_STAGE", payload: intakeFormFields });

        return;
      }
      let editedStageForm = activeStage?.form?.fields?.reduce((acc, field) => {
        let fieldValue = getFieldAnswer(
          field,
          preFilledStage?.applicant_job_post_stage_form_fields
        );
        return { ...acc, [field.id]: fieldValue };
      }, {});
      dispatch({ type: "SET_EDITED_STAGE", payload: editedStageForm });
    }
  }, [activeStageTab, data]);

  // const isFillStageDataLoading = useSelector(state => state.recruitment.isFillStageDataLoading);

  // const [updateCurrentStage, { data:updateCurrentStageResponse, loading:isUpdateStageLoading, error:errorUpdate }] = useMutation(updateCurrentStageMutation);

  const [
    fillStageData,
    {
      data: fillStageDataResponse,
      loading: isFillStageDataLoading,
      error: fillStageDataError,
    },
  ] = useMutation(fillStageFormMutation, {
    onCompleted: (data) => {
      if (data?.fill_stage_data) {
        dispatch(fillStageDataSuccess());
        getApplicantJobPost({
          variables: {
            id: props.id,
          },
        });
      }
    },
  });

  const [
    updateProcessStatus,
    {
      data: updateProcessStatusResponse,
      loading: isUpdateProcessStatusLoading,
      error: updateProcessStatusError,
    },
  ] = useMutation(updateApplicationStageStatusMutation, {
    onCompleted: (data) => {
      if (data?.change_application_stage) {
        getApplicantJobPost({
          variables: {
            id: props.id,
          },
        });
      }
    },
  });

  // handle accordion toggle change
  const handleAccordionToggleChange = (e) => {
    if (e?.target?.className?.toString()?.includes("select")) {
      return;
    }
    props.onToggleJobPostChange(props?.id);
  };
  // handle tabs toggle change

  // handle update application process status
  const handleOnChangeApllicationStatus = (val) => {
    updateProcessStatus({
      variables: {
        stage_id: val.id,
        applicant_job_post_id: props?.id,
      },
    }).then((response) => {
      console.log(response);
      if (
        response?.errors ||
        response.data?.change_application_stage?.status == "error"
      ) {
        dispatch(
          showErrorToast(
            response.data?.change_application_stage?.message ||
              response.errors[0]?.extensions?.reason
          )
        );
      }
    });

    // props.updateApplicationsStatusAction(val?.id, props?.applicantId);
  };

  const submittedStageForm = useSelector(
    (state) => state?.recruitment?.submittedStageForm
  );

  const handleSaveStageForm = () => {
    if (!isEditing) {
      setIsEditing(true);
      return;
    }
    let answers = activeStage?.form?.fields
      ?.sort((a, b) => a.order - b.order)
      ?.map((field) => ({
        field_id: field.id,
        type: field.type,
        suggested_field_type: null,
        applicant_jobPost_stage_form_field_id:
          preFilledStage?.applicant_job_post_stage_form_fields?.find(
            (data) => data?.field?.id == field?.id
          )?.applicant_jobPost_stage_form_field_id ?? null,
        value:
          !field?.options?.length && field?.type?.toLowerCase() !== "attachment"
            ? submittedStageForm?.[field.id] ?? null
            : null,
        options_id:
          field?.options?.length &&
          field?.type?.toLowerCase() !== "attachment" &&
          submittedStageForm?.[field.id]
            ? [submittedStageForm?.[field.id]]?.flat(Infinity)
            : [],
        attached_file:
          field?.type?.toLowerCase() === "attachment" &&
          typeof submittedStageForm?.[field.id] !== "string" &&
          submittedStageForm?.[field.id]
            ? submittedStageForm?.[field.id]
            : null,
        attachement_id:
          (field?.type?.toLowerCase() === "attachment" &&
            typeof submittedStageForm?.[field.id] !== "string") ||
          (field?.type?.toLowerCase() === "attachment" &&
            submittedStageForm?.[field.id] == null)
            ? null
            : preFilledStage?.applicant_job_post_stage_form_fields?.find(
                (data) => data?.field?.id == field?.id
              )?.applicant_job_post_stage_form_answer?.[0]?.attachment?.id ??
              null,
      }));

    let input = {
      applicant_job_post_id: props?.id,
      answers,
      stage_id: activeStage?.id,
      form_id: activeStage?.form?.id,
    };

    fillStageData({
      variables: {
        input,
      },
    })
      .then((response) => {
        console.log("then", { response });
        if (response?.errors) {
          dispatch(
            fillStageDataFailed(
              response?.errors[0]?.extensions?.validation ?? {}
            )
          );
        }
      })
      .catch((error) => {
        console.log("catch", { error });
      });

    // dispatch(fillStageDataAction(input, props?.applicantId));
    // dispatch(fillStageDataAction(input, props?.applicantId));

    console.log({
      answers,
      stage_id: activeStage?.id,
      form_id: activeStage?.form?.id,
    });
  };

  return (
    <div className="recruitment-process-stage_wrapper_style applicant_profile_job_posts_wrapper_style my-3">
      <Accordion
        expanded={isActive}
        key={props?.id}
        onChange={handleAccordionToggleChange}
        disableGutters
        elevation={0}
        square
        {...props}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="row w-100">
            <div className="col-12 col-md-3 d-flex align-items-baseline">
              <p className="mb-0 font-weight-bold main_job_post_title_style">
                {t("Post")}
              </p>

              <p className="mb-0 mx-3 sub_job_post_title_style">
                {props?.job_post?.title}
              </p>
            </div>

            <div className="col-12 col-md-3 d-flex align-items-baseline">
              <p className="mb-0 font-weight-bold main_job_post_title_style">
                {t("Applied on")}
              </p>

              <p className="mb-0 mx-3 sub_job_post_title_style">
                {moment(props?.date_applied).format("DD - MM - yyyy")}
              </p>
            </div>

            {/* <div className="col-12 col-md-3 d-flex align-items-center"> */}
            {/* <div className="px-3">
                <FontAwesomeIcon icon={faPaperclip} />
              </div> */}

            {/* {props?.job_post?.applicant?.attachment != null ? (
                <div>
                  <a
                    className="btn p-0 font-weight-bold main_job_post_title_style"
                    title={props?.job_post?.applicant?.attachment?.path}
                    key={props?.job_post?.applicant?.attachment?.id}
                    href={props?.job_post?.applicant?.attachment?.path}
                    // download={`Attachment - ${i}`}
                    target="_blank"
                  ></a>
                </div>
              ) : (
                <div>-----------</div>
              )} */}
            {/* </div> */}

            <div className="col-12 offset-md-3 col-md-3 d-flex align-items-baseline">
              <p className="mb-0 font-weight-bold main_job_post_title_style">
                {t("status")}
              </p>

              <div className="w-100 px-2">
                <HasPrivileges
                  reqireMain={[Privilages.EDIT_STATUSES_OF_JOB_APPLICATIONS]}
                  allowBP
                  altChildren={
                    <Select
                      className={
                        "select-def-input-containe b-select-style w-100"
                      }
                      classNamePrefix={"b-select-style"}
                      getOptionLabel={(option) => t(`${option?.name}`)}
                      getOptionValue={(option) => option?.id}
                      value={props?.last_stage}
                      key={`${props?.jobPost?.applicant?.id}`}
                      isRtl={document.dir?.toLowerCase() == "rtl"}
                      isSearchable
                      placeholder={t("select option")}
                      options={props?.jobPost?.process_stages?.stage}
                      isDisabled={true}
                    />
                  }
                  avalibleOnExpire={false}
                  altExpireChildren={
                    <Select
                      className={
                        "select-def-input-containe b-select-style w-100"
                      }
                      classNamePrefix={"b-select-style"}
                      getOptionLabel={(option) => t(`${option?.name}`)}
                      getOptionValue={(option) => option?.id}
                      value={props?.last_stage}
                      key={`${props?.applicant?.id}`}
                      isRtl={document.dir?.toLowerCase() == "rtl"}
                      isSearchable
                      placeholder={t("select option")}
                      options={props?.process_stages?.stage}
                      isDisabled={true}
                    />
                  }
                >
                  <Select
                    className={"select-def-input-containe b-select-style w-100"}
                    classNamePrefix={"b-select-style"}
                    getOptionLabel={(option) => t(`${option?.name}`)}
                    getOptionValue={(option) => option?.id}
                    // value={props?.last_stage}
                    value={
                      data?.applicant_job_post?.last_stage ??
                      props?.last_stage ??
                      null
                    }
                    key={`${props?.applicant?.id}`}
                    onChange={(val) => handleOnChangeApllicationStatus(val)}
                    isRtl={document.dir?.toLowerCase() == "rtl"}
                    isSearchable
                    placeholder={t("select option")}
                    options={props?.job_post?.process?.stages}
                    isLoading={isUpdateProcessStatusLoading || loading}
                    isDisabled={isUpdateProcessStatusLoading || loading}
                  />
                </HasPrivileges>
              </div>
            </div>
          </div>
        </AccordionSummary>

        {loading ? (
          <AccordionDetails>
            <div className="d-flex justify-content-center align-items-center p-4">
              <Spinner
                style={{ width: "2rem", height: "2rem", color: "#2764ac" }}
              />
            </div>
          </AccordionDetails>
        ) : null}
        {data ? (
          <AccordionDetails>
            <div className="d-flex">
              <div className="stages_tabs_warpper_style">
                <Tabs
                  scrollButtons="auto"
                  orientation="vertical"
                  value={activeStageTab}
                  onChange={handleTabsChange}
                >
                  <Tab value={"intakeForm"} label="intake Form" />
                  {applicationProcess
                    ?.sort((a, b) => +b?.initial_flag - +a?.initial_flag)
                    ?.map((stageObj) => (
                      <Tab value={stageObj.id} label={stageObj?.name} />
                    ))}
                </Tabs>
              </div>

              <div className="col stage_form_field_wrapper_style">
                {activeStageTab == "intakeForm" ? (
                  <ViewFromBuilder
                    viewOnly={true}
                    formServerValidation="submitStageServerValidation"
                    formName={"submittedStageForm"}
                    formFields={data?.applicant_job_post.intake_form?.fields}
                  />
                ) : null}
                {applicationProcess?.find(
                  (stage) => stage?.id == activeStageTab
                ) != undefined ? (
                  <>
                    <ViewFromBuilder
                      viewOnly={!isEditing}
                      formServerValidation="submitStageServerValidation"
                      formName={"submittedStageForm"}
                      formFields={activeStage.form.fields}
                    />
                    {activeStage.form.fields?.length ? (
                      <div className="d-flex justify-content-end align-items-center m-3">
                        <button
                          className="btn btn-primary submit_btn_style"
                          disabled={isFillStageDataLoading}
                          onClick={handleSaveStageForm}
                        >
                          {isFillStageDataLoading ? (
                            <Spinner
                              style={{
                                width: "1rem",
                                height: "1rem",
                                color: "#fff",
                              }}
                            />
                          ) : isEditing ? (
                            t("Save")
                          ) : (
                            t("edit")
                          )}
                        </button>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </AccordionDetails>
        ) : null}
      </Accordion>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.recruitment,
});

export default connect(mapStateToProps, {
  updateApplicationsStatusAction,
})(ApplicantJobPost);

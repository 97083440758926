import React from "react";
import CreateSelect from "../../Builder/Form/CreateSelect";
import { InputForm, BSelect } from "form-builder";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewDeductibleAction,
  removeDeductibleInputAction,
  childInputChange,
} from "../../Store/Actions";
import PaymentFactors from "../../Constants/PaymentFactors";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const DeuductableSection = (props) => {
  const {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
    deductible,
    index,
  } = props;
  const FormProps = {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const deductibleLoadingOpts = useSelector(
    (state) => state.super.deductibleLoadingOpts
  );
  const deductiblesOptions = useSelector(
    (state) => state.super.deductiblesOptions
  );
  const paymentFactors = useSelector((state) => state.super.paymentFactors);
  const calculate_salary_by = useSelector((state) => state.super.salaryConfigForm?.calculate_salary_by);

  const handleCreateDeductible = (newValue, index) => {
    dispatch(createNewDeductibleAction(newValue, index));
  };

  const handleSelectInputChange = (val, event, childName, index) => {
    dispatch(
      childInputChange(
        formName,
        childName,
        event.name,
        index,
        val ? val?.value : ""
      )
    );
  };

  const handleChildInputChange = (event, childName, index) => {
    dispatch(
      childInputChange(
        formName,
        childName,
        event.target.name,
        index,
        event.target.value
      )
    );
  };

  const handleRemoveDeductibleInput = (index) => {
    dispatch(removeDeductibleInputAction(index));
  };

  return (
    <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-20 my-2 w-100 order-last">
      <div className="w-100">
        <CreateSelect
          {...FormProps}
          optionLabel="label"
          optionValue="value"
          keepDefaultStyle
          containerStyle=""
          placeholder={t("Select Deductible Name")}
          inputContainerStyle="w-100"
          validateBy="textRequired"
          isClearable
          onCreateOption={(newValue) => handleCreateDeductible(newValue, index)}
          isLoading={deductibleLoadingOpts?.includes(index)}
          options={deductiblesOptions.filter(
            (opt) => !["1", "2", "3"].includes(opt?.value)
          )}
          name="deductible_id"
          value={
            deductiblesOptions?.filter(
              (opt) => opt.value == deductible.deductible_id
            )[0]
          }
          onChange={(e, val) =>
            handleSelectInputChange(e, val, "salary_deductibles", index)
          }
          validationName={`input.user_salary_config_input.salary_deductibles.${index}.deductible_id`}
          icon="money"
        />
      </div>

      <div className="w-100">
        <BSelect
          {...FormProps}
          name="payment_factor_id"
          optionLabel="label"
          optionValue="value"
          options={paymentFactors}
          keepDefaultStyle
          containerStyle=""
          placeholder={t("Select Payment Factor")}
          inputContainerStyle="w-100"
          validateBy="textRequired"
          optDependType="equal"
          optDependKey="deductible"
          optDependValue={["1", 1]}
          value={
            paymentFactors?.filter(
              (opt) => opt.value == deductible?.payment_factor_id
            )[0]
          }
          onChange={(e, val) =>
            handleSelectInputChange(e, val, "salary_deductibles", index)
          }
          validationName={`input.user_salary_config_input.salary_deductibles.${index}.payment_factor_id`}
          icon="money"
          isDisabled={
            calculate_salary_by !== "payment_interval"
          }
        />
      </div>

      <div className="w-100 d-flex gap-10">
        <InputForm
          name="value"
          type="number"
          onWheelCapture={(e) => {
            e.target.blur();
          }}
          {...FormProps}
          placeholder={
            deductible.payment_factor_id == PaymentFactors.FIXED_AMOUNT
              ? t("amount")
              : "%"
          }
          labelStyle="mb-0"
          rootStyle="flex-fill"
          containerStyle=""
          inputContainerStyle="w-100"
          validateBy="textRequired"
          value={deductible.value}
          onChange={(e) =>
            handleChildInputChange(e, "salary_deductibles", index)
          }
          validationName={`input.user_salary_config_input.salary_deductibles.${index}.value`}
          icon="money"
        />

        <div className=" text-initial">
          <button
            className="btn delete_btn_style"
            type="button"
            onClick={() => handleRemoveDeductibleInput(index)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeuductableSection;

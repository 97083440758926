import React, { useEffect } from "react";
import BalanceLogsList from "../../Containers/BalanceLogsList";
import Bonus from "../../Containers/Bonus";
import Deductions from "../../Containers/Deductions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";
import { useHistory, useLocation } from "react-router-dom";
import FinancialsTabs from "./FinancialsTabs";
import ViewPayroll from "../ViewPayroll";
import ViewLoans from "../ViewLoans";
import ViewExpenses from "../ViewExpenses";
import DeductionsPolicyList from "../../Containers/Deductions/DeductionsPolicyList";
import CostCenters from "../../Containers/CostCenters/CostCenters";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ViewFinancials = (props) => {
  let query = useQuery();
  const activeValue = query.get("tab");
  const history = useHistory();
  const renderfirstTab = () => {
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_PAYROLL_LIST],
        allowBP: true,
      })
    ) {
      return history.push(`/financials?tab=payroll`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_EMPLOYEE_BONUSES_DEDUCTIONS],
        allowBP: true,
      })
    ) {
      return history.push(`/financials?tab=bonus`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_LOANS_LIST],
        allowBP: true,
      })
    ) {
      return history.push(`/financials?tab=loans`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [
          Privilages.VIEW_PETTY_CASH_BALANCE_ADJUSTMENT_LIST,
          Privilages.VIEW_THE_EXPENSES_OF_MY_OFFICE,
        ],
        allowBP: true,
      })
    ) {
      return history.push(`/financials?tab=expenses`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [
          Privilages.VIEW_COST_CENTERS,
          Privilages.VIEW_COST_CENTERS_REPORT,
        ],
        allowBP: true,
      })
    ) {
      return history.push(`/financials?tab=cost-centers`);
    }
    return null;
  };

  useEffect(() => {
    if (!activeValue) {
      renderfirstTab();
    }
  }, [activeValue]);

  const renderSelectedTab = () => {
    switch (activeValue) {
      case "payroll":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_PAYROLL_LIST]} allowBP>
            <ViewPayroll />
          </HasPrivileges>
        );
      case "bonus":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_EMPLOYEE_BONUSES_DEDUCTIONS]}
            allowBP
          >
            <Bonus />
          </HasPrivileges>
        );
      case "deductions":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_EMPLOYEE_BONUSES_DEDUCTIONS]}
            allowBP
          >
            <Deductions />
          </HasPrivileges>
        );
      case "deductions_policy":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_EMPLOYEE_BONUSES_DEDUCTIONS]}
            allowBP
          >
            <DeductionsPolicyList />
          </HasPrivileges>
        );
      case "loans":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_LOANS_LIST]} allowBP>
            <ViewLoans />
          </HasPrivileges>
        );
      case "expenses":
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.VIEW_PETTY_CASH_BALANCE_ADJUSTMENT_LIST,
              Privilages.VIEW_THE_EXPENSES_OF_MY_OFFICE,
            ]}
            allowBP
          >
            <ViewExpenses />
          </HasPrivileges>
        );

      case "cost-centers":
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.VIEW_COST_CENTERS,
              Privilages.VIEW_COST_CENTERS_REPORT,
            ]}
            allowBP
          >
            <CostCenters />
          </HasPrivileges>
        );
      default:
        break;
    }
  };
  return (
    <div className="content pt-3">
      <FinancialsTabs />
      <div className="pt-4">{renderSelectedTab()}</div>
    </div>
  );
};

export default ViewFinancials;

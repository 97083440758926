import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import BlockUi from "react-block-ui";
import { Link } from "react-router-dom";
import OfficeForm from "../../Containers/OfficeForm";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const ViewOfficeForm = () => {
  const { t } = useTranslation();
  const { officeId } = useParams();

  // Reducer State
  const isFetchingOffice = useSelector(
    (state) => state.super?.fetchingOfficeForm
  );
  const isWorkplace = useSelector(
    (state) => state.super?.officeForm?.is_workplace
  );

  return (
    <BlockUi
      tag="div"
      className="content office_form_wrapper"
      blocking={isFetchingOffice}
    >
      <div className="px-18">
        <Link
          to="/settings?tab=offices"
          className="back-btn d-flex align-items-center position-sticky mb-4"
        >
          <KeyboardBackspaceIcon className="back-icon" />
          <span>{t("back")}</span>
        </Link>

        <h2 className="sub_title_style">
          {t(
            `${officeId ? "edit" : "new"} ${
              isWorkplace ? "work place" : "office"
            }`
          )}
          <hr />
        </h2>
      </div>

      <OfficeForm officeId={officeId} />
    </BlockUi>
  );
};

export default ViewOfficeForm;

import React, { useEffect } from "react";
import { connect } from "react-redux";
import Constants from "../../Helpers/Constants";
import moment from "moment";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import {
  BSelect,
  CheckboxForm,
  DateTimePickerForm,
  InputForm,
} from "form-builder";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import ShowMoreText from "react-show-more-text";
import {
  fetchUsersClaimsAction,
  onInputResetAction,
  showAcceptClaimRequestModalAction,
  rejectClaimRequestAction,
} from "../../Store/Actions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import Privilages from "../../Constants/Privilages";
import EditClaimRequestModal from "../MyClaims/EditClaimRequestModal";
import AcceptClaimRequestModal from "../MyClaims/AcceptClaimRequestModal";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import {
  AcceptIconButton,
  TimesIconButton,
  DownloadFileIconButton,
} from "../../Components/IconButtonWithTooltip";
import FloatingMenu from "../../Components/FloatingMenu";

const dataTableRef = "claimsList";

const Claims = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchUsersClaimsAction({
      ref: dataTableRef,
      [dataTableRef]: props.data,
    });
    return () => {
      props.onInputResetAction(dataTableRef, "status");
      props.onInputResetAction(dataTableRef, "from");
      props.onInputResetAction(dataTableRef, "to");
      props.onInputResetAction(dataTableRef, "user_id");
      props.onInputResetAction(dataTableRef, "employee_search");
    };
  }, []);

  const handleFilter = () => {
    props.fetchUsersClaimsAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data,
        pagination: {
          ...props.data.pagination,
          currentPage: 1,
        },
      },
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data.status,
    props.data.from,
    props.data.to,
    props.data.user_id,
    props.data.employee_search,
  ]);

  const handlePaginate = (page = props.data.pagination.currentPage) => {
    props.fetchUsersClaimsAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data,
        pagination: {
          ...props.data.pagination,
          currentPage: page,
        },
      },
    });
  };

  // for Accept claim request modal
  const handleAcceptClaimRequest = (data) => {
    let acceptClaimData = {
      amount: data?.amount,
      reason: data?.reason,
      payroll_at: data?.payroll_at,
      id: data?.id,
      name: data?.user?.name,
      currency: data?.currency?.name,
    };
    props.showAcceptClaimRequestModalAction({
      ref: "acceptClaimRequestModalActions",
      formName: "acceptClaimRequestModal",
      acceptClaimData,
    });
  };

  const handleRejectClaimRequest = (row) => {
    swal({
      title: t("default_warning_reject_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.rejectClaimRequestAction(row?.id, row?.payroll_at);
      }
    });
  };

  const columns = [
    {
      name: t("employee name"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 2.2,
      cell: (row) => (
        <CanViewEmployeeProfile
          allowBP
          directManger={row?.user?.manager?.id}
          copiedManagers={row?.user?.copied_managers?.map((cp) => cp?.id)}
          altChildren={<div className="col pl-0">{row?.user?.name}</div>}
        >
          <div className="col pl-0">
            <Link
              className="employee-name"
              to={`/employees/employee-profile/${row?.user?.id}`}
            >
              {row?.user?.name}
            </Link>
          </div>
        </CanViewEmployeeProfile>
      ),
    },
    {
      name: t("amount"),
      wrap: true,
      selector: "amount",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <div className="d-flex">
          <div className="pr-1">{row?.currency?.name}</div>
          <div>{row?.amount}</div>
        </div>
      ),
    },
    {
      name: t("reason"),
      wrap: true,
      selector: "reason",
      sortable: false,
      grow: 2.6,
      cell: (row) => (
        <ShowMoreText
          lines={1}
          more="Show more"
          less="Show less"
          expanded={false}
          width={280}
        >
          {row?.reason}
        </ShowMoreText>
      ),
    },
    {
      name: t("Approved By"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1.4,
      cell: (row) => <>{row?.actionBy?.name}</>,
    },
    {
      name: t("incurred at"),
      wrap: true,
      selector: "incurred_at",
      sortable: false,
      grow: 1.5,
      cell: (row) => (
        <>
          {row?.incurred_at != null
            ? moment(row?.incurred_at).format("DD - MM - yyyy")
            : ""}
        </>
      ),
    },
    {
      name: t("payable at"),
      wrap: true,
      selector: "payroll_at",
      sortable: false,
      grow: 1.3,
      cell: (row) => (
        <>
          {row?.payroll_at != null
            ? moment(row?.payroll_at).format("MMMM YYYY")
            : ""}
        </>
      ),
    },
    {
      name: t("files"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => (
        <div className="cards_table_actions">
          {row?.files?.map((file) => (
            <DownloadFileIconButton key={file?.id} href={file?.path} />
          ))}
        </div>
      ),
    },
    {
      name: t("status"),
      wrap: true,
      selector: "status",
      sortable: true,
      grow: 0.75,
      cell: (row) => {
        switch (row?.status?.toLowerCase()) {
          case "pending":
            return <span className="badge pending_status">{t("pending")}</span>;
          case "unpaid":
            return <span className="badge unpaid_status">{t("unpaid")}</span>;
          case "rejected":
            return (
              <span className="badge rejected_status">{t("rejected")}</span>
            );
          case "accepted":
            return (
              <span className="badge approved_status">{t("accepted")}</span>
            );
          case "paid":
            return <span className="badge paid_status">{t("paid")}</span>;
          default:
            break;
        }
      },
    },
    {
      name: "",
      wrap: true,
      selector: "action",
      grow: 0.5,
      cell: (row) =>
        row?.status?.toLowerCase() === "pending" ? (
          <HasPrivileges
            reqireMain={[Privilages.MANAGE_CLAIM_REQUESTS]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <div className="cards_table_actions">
                <AcceptIconButton />
                <TimesIconButton />
              </div>
            }
          >
            <div className="cards_table_actions">
              <AcceptIconButton onClick={() => handleAcceptClaimRequest(row)} />
              <TimesIconButton
                label="reject"
                onClick={() => handleRejectClaimRequest(row)}
              />
            </div>
          </HasPrivileges>
        ) : null,
      ignoreRowClick: true,
    },
  ];

  return (
    <div className="extra_claims_wrapper_style pt-4">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <FloatingMenu
          list={
            <CheckboxForm
              formName={dataTableRef}
              options={Constants.ClaimsStatusFilter?.map(
                ({ label, value }) => ({
                  label: t(label),
                  value,
                })
              )}
              name="status"
              type="checkbox"
              containerStyle="my-2 my-lg-0"
              labelStyle="label-style"
              optionsContainerStyle="py-2 d-flex flex-column"
              optionItemStyle="optionItemStyle py-1"
              optionInputStyle=" "
              optionLabelStyle="optionLabelStyle"
              optionLabelActiveStyle="optionLabelActiveStyle"
            />
          }
        />

        <div className="d-flex gap-20">
          <InputForm
            reducer="super"
            name="employee_search"
            formName={dataTableRef}
            icon="search"
            type="search"
            placeholder={t("employee search")}
            value={props?.data?.employee_search?.trimStart()}
            inputContainerStyle="w-100"
          />

          <DateTimePickerForm
            name="from"
            placeholder="from"
            formName={dataTableRef}
            requestFormat="YYYY-MM-DD"
          />
          <DateTimePickerForm
            name="to"
            placeholder="to"
            formName={dataTableRef}
            requestFormat="YYYY-MM-DD"
          />
          <BSelect
            reducer="super"
            name="user_id"
            formName={dataTableRef}
            options={props?.extraEmployeesFilter}
            keepDefaultStyle
            optionLabel="name"
            optionValue="id"
            rootStyle="min-width-200"
            isClearable
            placeholder={t("employees")}
            icon="person"
          />
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        data={props.data.data}
        noHeader
        persistTableHead
        sortServer
        defaultSortAsc={props.data.sorting.dir === "asc"}
        defaultSortField={props.data.sorting.key}
        paginationComponent={() => (
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
            reducer="super"
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={props.data.isLoading}
        progressComponent={<Loader />}
      />

      {/* Start of Claim request modal */}
      <EditClaimRequestModal />
      {/* End of Claim request modal */}

      {/* Start of Accept Claim request modal */}
      <AcceptClaimRequestModal />
      {/* End of Accept Claim request modal */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super[dataTableRef],
    extraEmployeesFilter: state.super.extraEmployeesFilter,
  };
};

export default connect(mapStateToProps, {
  fetchUsersClaimsAction,
  onInputResetAction,
  showAcceptClaimRequestModalAction,
  rejectClaimRequestAction,
})(Claims);

import React from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";

import { openSwalConfirm } from "../../../Helpers/HelperFns";
import { removeContactMutation } from "../../../Graphql/mutation";
import { showErrorToast, showSuccessToast } from "../../../Store/Actions";

import {
  EditIconButton,
  RemoveIconButton,
} from "../../../Components/IconButtonWithTooltip";
import { AddButton } from "../../../Components/Buttons";
import { ContactModal } from "../../../Components/CRMFormModals";

const modalInitState = { isOpen: false, isEdit: false, data: null };

const ContactsPanel = ({ data, isDataLoading, refetchData }) => {
  const dispatch = useDispatch();

  // Local State
  const [modalState, setModalState] = React.useState(modalInitState);

  // Server State
  const [removeContact] = useMutation(removeContactMutation);

  /* ↓ Helpers ↓ */

  const handleNew = () => {
    setModalState({
      isOpen: true,
      isEdit: false,
      data: { lead_id: data?.id },
    });
  };

  const handleEdit = (contact) => {
    setModalState({
      isOpen: true,
      isEdit: true,
      data: {
        ...contact,
        lead_id: data.id,
      },
    });
  };

  const handleRemoveLead = (id) => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        removeContact({
          variables: { id },
          onError: () => {
            dispatch(showErrorToast());
          },
          onCompleted: () => {
            dispatch(showSuccessToast());
            refetchData();
          },
        });
      }
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-end">
        <AddButton label="contact" onClick={handleNew} />
      </div>

      {isDataLoading
        ? null
        : data?.contacts.map((contact) => (
            <article key={contact?.id} className="boxContainer">
              <div className="d-flex justify-content-between">
                <strong>{contact?.name}</strong>
                <div className="d-flex gap-10">
                  <EditIconButton
                    onClick={() =>
                      handleEdit({ lead_id: data?.id, ...contact })
                    }
                  />
                  {data?.contacts.length > 1 ? (
                    <RemoveIconButton
                      onClick={() => handleRemoveLead(contact?.id)}
                    />
                  ) : null}
                </div>
              </div>

              {contact?.email}

              {contact?.phones?.map((phone, i) => (
                <div key={i} className="d-flex gap-10">
                  {phone[1]}
                  <span>{phone[3]}</span>
                </div>
              ))}
            </article>
          ))}

      {modalState.isOpen ? (
        <ContactModal
          state={modalState}
          refetchList={refetchData}
          onClose={() => setModalState(modalInitState)}
        />
      ) : null}
    </div>
  );
};

export default ContactsPanel;

import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchCompanySettingsListAction,
  showCompanySettingsModalAction,
  deleteCompanySettingAction,
} from "../../Store/Actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import CompanySettingsModal from "./CompanySettingsModal";
import {
  RemoveIconButton,
  EditIconButton,
} from "../../Components/IconButtonWithTooltip";

const dataTableRef = "companySettingsList";

const CompanySettings = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchCompanySettingsListAction({
      ref: dataTableRef,
      [dataTableRef]: props.data[dataTableRef],
    });
  }, []);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    props.fetchCompanySettingsListAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };

  // handleing add expense company settings modal
  const handleAddCompanySettingsExpense = () => {
    let data = {
      id: null,
      name: "",
      subCategory: [],
    };
    props.showCompanySettingsModalAction({
      data,
      ref: "companySettingsModalActions",
      formName: "companySettingsModal",
    });
  };

  // handleing edit expense company settings modal
  const handleEditCompanySettingsExpense = (row) => {
    let data = {
      id: row?.id,
      name: row?.name,
      subCategory: row?.sub_categories?.map(({ value, label }) => ({
        value,
        label,
      })),
    };
    props.showCompanySettingsModalAction({
      data,
      ref: "companySettingsModalActions",
      formName: "companySettingsModal",
    });
  };

  // handle delete company settings
  const handleDelete = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteCompanySettingAction(row?.id);
      }
    });
  };

  const columns = [
    {
      name: t("category name"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1.2,
    },
    {
      name: t("sub-categories"),
      wrap: true,
      sortable: false,
      grow: 1.5,
      cell: (row) => (
        <>
          {row?.sub_categories.length == 0 ? (
            <>{"-------------------------------"}</>
          ) : (
            <>
              {row?.sub_categories?.map(
                (subCategories, i) =>
                  `${i !== 0 && subCategories?.label ? " - " : ""}${
                    subCategories?.label
                  }`
              )}
            </>
          )}
        </>
      ),
    },
    {
      name: t("actions"),
      wrap: true,
      sortable: false,
      grow: 0.5,
      cell: (row) => (
        <div className="cards_table_actions">
          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_EXPENSE_CATEGORIES]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={<EditIconButton />}
          >
            <EditIconButton
              onClick={() => handleEditCompanySettingsExpense(row)}
            />
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[Privilages.DELETE_EXPENSE_CATEGORIES]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={<RemoveIconButton />}
          >
            <RemoveIconButton onClick={() => handleDelete(row)} />
          </HasPrivileges>
        </div>
      ),
    },
  ];

  return (
    <div className="company_settings_wrapper_style mt-3">
      {props?.isDeleteCompanySettingsLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="row mb-4">
        <div className="d-flex justify-content-end col-12">
          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_EXPENSE_CATEGORIES]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <button
                className="btn add_new_btn_style mt-4"
                type="button"
                disabled
              >
                <FontAwesomeIcon className="mr-2" icon={faPlus} />
                {t("Add Category")}
              </button>
            }
          >
            <button
              className="btn add_new_btn_style "
              type="button"
              onClick={handleAddCompanySettingsExpense}
            >
              <FontAwesomeIcon className="mr-2" icon={faPlus} />
              {t("Add Category")}
            </button>
          </HasPrivileges>
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        data={props.data[dataTableRef].data}
        noHeader
        persistTableHead
        paginationComponent={() => (
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
            reducer="finances"
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={props.data[dataTableRef].isLoading}
        progressComponent={<Loader />}
      />

      {/* Start of company settings modal */}
      <CompanySettingsModal />
      {/* End of company settings modal */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.finances,
    authUser: state.auth.userProfile,
    isDeleteCompanySettingsLoading:
      state?.finances?.deleteCompanySettingsLoading,
  };
};

export default connect(mapStateToProps, {
  fetchCompanySettingsListAction,
  showCompanySettingsModalAction,
  deleteCompanySettingAction,
})(CompanySettings);

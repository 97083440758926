import { put, call, takeEvery } from "redux-saga/effects";
import Types from "../Actions/types";
import HelperFns from "../../Helpers/HelperFns";

import {
  upsertDocumentSuccess,
  upsertDocumentFailed,
  storeDocumentableSuccess,
  storeDocumentableFailed,
  fetchDocumentsListSuccess,
  fetchDocumentsListFailed,
  onInputChangeAction,
  hideUpsertDocModal,
  childInputChangeDocumentsMultiple,
  resetDocumentsRow,
  updateCompanyRowsDocs,
  handleRowDocumentAction,
  fetchRequiredDocumentsFailed,
  fetchRequiredDocumentsSuccess,
  fetchHistoryDocModalFailed,
  fetchHistoryDocModalSuccess,
  showErrorToast,
  showSuccessToast,
} from "../Actions";

import {
  upsertDocument,
  storeDocumentable,
  fetchCompanyDocuments,
  fetchRequiredDocuments,
  fetchHistoryDocModal,
} from "../../Services/DocumentsService";

export function* handleUpsertDocumentSaga({ payload }) {
  try {
    const response = yield call(upsertDocument, payload);
    if (!response?.errors) {
      yield put(showSuccessToast());
      yield put(hideUpsertDocModal());
      yield put(
        upsertDocumentSuccess(
          response?.data?.updateOrCreateDocument?.company?.documentPresets
        )
      );
    } else {
      yield put(
        upsertDocumentFailed(
          response?.errors[0]?.extensions?.reason ?? "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(upsertDocumentFailed(error.message ?? "Something went wrong!"));
  }
}

function* onUpsertDocument() {
  yield takeEvery(Types.UPSERT_DOCUMENT_ATTEMPT, handleUpsertDocumentSaga);
}

export function* handleStoreDocumentableSaga({ payload }) {
  try {
    const response = yield call(storeDocumentable, payload);
    if (!response?.errors) {
      yield put(showSuccessToast());
      yield put(
        storeDocumentableSuccess({
          data: response?.data?.store_documentable?.[
            payload?.formName === "companyRequiredDocuments"
              ? "required_documentables"
              : "optional_documentables"
          ].map((item) => item.document),
          formName: payload?.formName,
          ref: payload?.ref,
        })
      );
    } else {
      yield put(showErrorToast(response?.errors[0]?.extensions?.reason));
      yield put(
        storeDocumentableFailed({
          message:
            response?.errors[0]?.extensions?.reason ?? "Something went wrong!",
          ref: payload?.ref,
        })
      );
      yield put(onInputChangeAction(...payload?.resetInputData));
    }
  } catch (error) {
    yield put(showErrorToast(error?.message));
    yield put(
      storeDocumentableFailed({
        message: error.message ?? "Something went wrong!",
        ref: payload?.ref,
      })
    );
    yield put(onInputChangeAction(payload?.resetInputData));
  }
}

function* onStoreDocumentable() {
  yield takeEvery(
    Types.STORE_DOCUMENTABLE_ATTEMPT,
    handleStoreDocumentableSaga
  );
}

export function* handleStoreDocumentableRowSaga({ payload }) {
  try {
    const response = yield call(storeDocumentable, payload);
    if (!response?.errors) {
      yield put(showSuccessToast());
      const { required_documentables = [], optional_documentables = [] } =
        response?.data?.store_documentable;
      yield put(
        updateCompanyRowsDocs({
          fetchedCompanyRef: payload?.fetchedCompanyRef,
          office: payload?.resetData?.office,
          requiredDocuments: required_documentables.map((doc) => doc?.document),
          optionalDocuments: optional_documentables.map((doc) => doc?.document),
        })
      );
      if (payload?.actionType === "delete") {
        yield put(
          handleRowDocumentAction({
            formName: payload?.formName,
            childName: payload?.childName,
            index: payload?.index,
            action: "delete",
          })
        );
      } else {
        yield put(
          childInputChangeDocumentsMultiple({
            formName: payload?.formName,
            childName: payload?.childName,
            index: payload?.index,
            name: ["showEdit", "isLoading"],
            value: {
              showEdit: false,
              isLoading: false,
            },
          })
        );
      }
      // To Do Add Success Ids To Intial Fetched Row , To Fix Reset Action
    } else {
      yield put(showErrorToast(response?.errors[0]?.extensions?.reason));
      yield put(
        resetDocumentsRow(
          payload?.formName,
          payload?.childName,
          payload?.index,
          payload?.resetData
        )
      );
    }
  } catch (error) {
    yield put(showErrorToast(error?.message));
    yield put(
      resetDocumentsRow(
        payload?.formName,
        payload?.childName,
        payload?.index,
        payload?.resetData
      )
    );
  }
}

function* onStoreDocumentableRow() {
  yield takeEvery(
    Types.STORE_DOCUMENTABLE_ROW_ATTEMPT,
    handleStoreDocumentableRowSaga
  );
}

export function* handlefetchCompanyDocsListSaga({ payload }) {
  try {
    const response = yield call(fetchCompanyDocuments, payload);
    if (!response?.errors) {
      yield put(fetchDocumentsListSuccess(response?.data));
    } else {
      yield put(
        fetchDocumentsListFailed(
          response?.errors[0]?.extensions?.reason ?? "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      fetchDocumentsListFailed(error.message ?? "Something went wrong!")
    );
  }
}

function* onfetchCompanyDocsList() {
  yield takeEvery(
    Types.FETCH_DOCUMENTS_LIST_ATTEMPT,
    handlefetchCompanyDocsListSaga
  );
}

export function* fetchRequiredDocumentsSaga({ payload }) {
  try {
    const response = yield call(fetchRequiredDocuments, payload);
    if (!response?.errors) {
      yield put(
        fetchRequiredDocumentsSuccess({
          missingDocuments: response?.data?.company_profile_missing_documents,
          expiredDocuments:
            response?.data?.company_profile_expired_user_documents,
          data: {
            employees_for_document: response?.data?.employees_for_document,
            profile: response?.data?.profile,
          },
        })
      );
    } else {
      yield put(fetchRequiredDocumentsFailed());
    }
  } catch (error) {
    yield put(fetchRequiredDocumentsFailed());
  }
}

function* onFetchRequiredDocumentsSaga() {
  yield takeEvery(
    Types.FETCH_REQUIRED_DOCUMENTS_ATTEMPT,
    fetchRequiredDocumentsSaga
  );
}

export function* handlefetchHistoryDocModalSaga({ payload }) {
  try {
    const response = yield call(fetchHistoryDocModal, payload);
    if (!response.errors) {
      yield put(
        fetchHistoryDocModalSuccess({
          ref: payload?.ref,
          data: response.data?.user_document_history,
        })
      );
    } else {
      yield put(
        fetchHistoryDocModalFailed({
          ref: payload?.ref,
          message:
            response.errors[0]?.extensions?.reason ||
            HelperFns.localizeFns("Something went wrong"),
        })
      );
    }
  } catch (error) {
    yield put(
      fetchHistoryDocModalFailed({
        ref: payload?.ref,
        message: error.message || HelperFns.localizeFns("Something went wrong"),
      })
    );
  }
}

function* onfetchHistoryDocModal() {
  yield takeEvery(
    Types.FETCH_HISTORY_DOC_MODAL_ATTEMPT,
    handlefetchHistoryDocModalSaga
  );
}

const sagaWatchers = {
  onUpsertDocument,
  onfetchCompanyDocsList,
  onStoreDocumentable,
  onStoreDocumentableRow,
  onFetchRequiredDocumentsSaga,
  onfetchHistoryDocModal,
};

export default sagaWatchers;

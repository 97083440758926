const CREATE_WIZARD_OFFICE_ATTEMPT = "CREATE_WIZARD_OFFICE_ATTEMPT";
const CREATE_WIZARD_OFFICE_SUCCESS = "CREATE_WIZARD_OFFICE_SUCCESS";
const CREATE_WIZARD_OFFICE_FAILED = "CREATE_WIZARD_OFFICE_FAILED";

const CREATE_DEPARTMENTS_ATTEMPT = "CREATE_DEPARTMENTS_ATTEMPT";
const CREATE_DEPARTMENTS_SUCCESS = "CREATE_DEPARTMENTS_SUCCESS";
const CREATE_DEPARTMENTS_FAILED = "CREATE_DEPARTMENTS_FAILED";

const CREATE_POSITIONS_ATTEMPT = "CREATE_POSITIONS_ATTEMPT";
const CREATE_POSITIONS_SUCCESS = "CREATE_POSITIONS_SUCCESS";
const CREATE_POSITIONS_FAILED = "CREATE_POSITIONS_FAILED";

const FETCH_SETUP_WIZARD_ESSENTIAL_DATA_ATTEMPT =
  "FETCH_SETUP_WIZARD_ESSENTIAL_DATA_ATTEMPT";
const FETCH_SETUP_WIZARD_ESSENTIAL_DATA_SUCCESS =
  "FETCH_SETUP_WIZARD_ESSENTIAL_DATA_SUCCESS";
const FETCH_SETUP_WIZARD_ESSENTIAL_DATA_FAIL =
  "FETCH_SETUP_WIZARD_ESSENTIAL_DATA_FAIL";

const SET_SETUP_WIZARD_ACTIVE_TAB = "SET_SETUP_WIZARD_ACTIVE_TAB";

const SET_WIZARD_ATT_TYPE = "SET_WIZARD_ATT_TYPE";

const EDIT_SETUP_WIZARD_ESSENTIAL_DATA = "EDIT_SETUP_WIZARD_ESSENTIAL_DATA";
const UPSERT_SETUP_WIZARD_WORK_TIMING_ATTEMPT =
  "UPSERT_SETUP_WIZARD_WORK_TIMING_ATTEMPT";
const UPSERT_SETUP_WIZARD_WORK_TIMING_SUCCESS =
  "UPSERT_SETUP_WIZARD_WORK_TIMING_SUCCESS";
const UPSERT_SETUP_WIZARD_WORK_TIMING_FAILED =
  "UPSERT_SETUP_WIZARD_WORK_TIMING_FAILED";

const UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_ATTEMPT =
  "UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_ATTEMPT";
const UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_SUCCESS =
  "UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_SUCCESS";
const UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_FAILED =
  "UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_FAILED";
const SET_SETUP_WIZARD_REMAINING_STEPS = "SET_SETUP_WIZARD_REMAINING_STEPS";

const actionTypes = {
  CREATE_DEPARTMENTS_ATTEMPT,
  CREATE_DEPARTMENTS_SUCCESS,
  CREATE_DEPARTMENTS_FAILED,

  CREATE_POSITIONS_ATTEMPT,
  CREATE_POSITIONS_SUCCESS,
  CREATE_POSITIONS_FAILED,

  CREATE_WIZARD_OFFICE_ATTEMPT,
  CREATE_WIZARD_OFFICE_SUCCESS,
  CREATE_WIZARD_OFFICE_FAILED,

  FETCH_SETUP_WIZARD_ESSENTIAL_DATA_ATTEMPT,
  FETCH_SETUP_WIZARD_ESSENTIAL_DATA_SUCCESS,
  FETCH_SETUP_WIZARD_ESSENTIAL_DATA_FAIL,

  SET_WIZARD_ATT_TYPE,

  SET_SETUP_WIZARD_ACTIVE_TAB,

  EDIT_SETUP_WIZARD_ESSENTIAL_DATA,
  UPSERT_SETUP_WIZARD_WORK_TIMING_ATTEMPT,
  UPSERT_SETUP_WIZARD_WORK_TIMING_SUCCESS,
  UPSERT_SETUP_WIZARD_WORK_TIMING_FAILED,
  UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_ATTEMPT,
  UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_SUCCESS,
  UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_FAILED,
  SET_SETUP_WIZARD_REMAINING_STEPS,
};

export default actionTypes;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import { useFormBuilderFieldValidation } from "../../Helpers/Validations/formbuilderInputValidation";

import Select from "react-select";
import FieldError from "./FieldError";
import Accordion from "@mui/material/Accordion";
import CreatableSelect from "react-select/creatable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

const fieldTypeOptions = [
  { value: "text", label: "text" },
  {
    value: "checkbox",
    label: "checkbox",
  },
  {
    value: "radio",
    label: "Radio Button",
  },
  { value: "attachment", label: "Attachment" },
  { value: "dropList", label: "Drop List" },
];

const FormBuilderField = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [serverValidations, setServerValidation] = useState({});

  const submitted = props.submittedFields?.includes(props?.field?.id);

  let validationOpts = useSelector(
    (state) => state.recruitment?.recruitmentProcessValidationOpts
  );

  const handleToggleField = (ev) => {
    ev.stopPropagation();

    if (props?.openFields?.includes(props?.field?.id)) {
      // setSubmitted(true);
      if (validation.isValid) {
        props.onCloseField(props.field.id);
      } else {
        dispatch({
          type: "SUBMITED_FIELD",
          payload: props.field.id,
        });
      }
    } else {
      props.onOpenField(props.field.id);
    }
  };

  const validation = useFormBuilderFieldValidation(props.field, props.field.id);

  function onFormBilderChange(inputType, name, value) {
    let field = { ...props.field };
    if (name == "type") {
      field["validation_type_ids"] = [];
      field["apply_validation"] = false;
    }
    switch (inputType) {
      case "text":
      case "radio":
        field = { ...field, [name]: value };
        break;
      case "select":
        if (name === "type" && value.toLowerCase() === "droplist") {
          field = { ...field, [name]: value, selection_type: "Single" };
          break;
        } else if (
          name === "type" &&
          value.toLowerCase() !== "droplist" &&
          !(
            value.toLowerCase() === "text" ||
            value.toLowerCase() === "attachment"
          )
        ) {
          field = { ...field, [name]: value, selection_type: null };
          break;
        } else if (
          (name === "type" && value.toLowerCase() === "text") ||
          value.toLowerCase() === "attachment"
        ) {
          field = { ...field, [name]: value, options: [], mainOptions: [] };
          break;
        } else {
          field = { ...field, [name]: value };
          break;
        }
      case "checkbox":
        if (name === "apply_validation") {
          field = { ...field, [name]: value, validation_type_ids: [] };
          break;
        }
        if (name === "apply_description") {
          field = { ...field, [name]: value, description: "" };
          break;
        }
      case "multiSelect":
        field = { ...field, [name]: value };
        break;

      case "requiredInput":
        field = {
          ...field,
          apply_validation: value,
          validation_type_ids: value ? [{ value: "1", label: "required" }] : [],
        };
        break;

      case "createOption":
        let newOption = {
          value: value,
          label: value,
        };
        field = {
          ...field,
          mainOptions: [...(field?.mainOptions ?? []), newOption],
          options: [...(field?.options ?? []), newOption],
        };
      default:
        break;
    }
    props.onUpdateFormBuilder(field, props.id);
  }

  const formServerValidation = useSelector(
    (state) => state.recruitment?.[props.serverValidation]
  );

  const vaildContainerClassName = (name) => {
    let inputValidationName = props?.field?.id + "." + name;
    let isInValid =
      (!!validation.validationErrors[inputValidationName] && submitted) ||
      !!formServerValidation[inputValidationName];
    if (isInValid) {
      return "invalid-container-style";
    }
    return "";
  };

  const commonFieldsErrorProps = {
    prefixValidationName: props.field?.id,
    submitted: submitted,
    clientValidations: validation.validationErrors,
    serverValidations,
  };

  // useEffect(() => {

  // }, [formServerValidation]);

  const handleUpdateServerValidation = () => {
    Object.keys(formServerValidation).forEach((key) => {
      let fieldName = key.split(".").pop();
      if (key.substring(0, key.lastIndexOf(".")) === props.validationName) {
        setServerValidation((prevState) => ({
          ...prevState,
          [`${props.field.id}.${fieldName}`]: formServerValidation[key],
        }));
        props.onOpenField(props.field.id, true);
        return;
      }
    });
  };

  const useDidServerValidationUpdate = useDidUpdateEffect(
    handleUpdateServerValidation,
    [formServerValidation]
  );

  let parentOptions = props.data.filter(
    (field) => field.id == props.field?.parent_field_id
  )[0]?.options;

  useEffect(() => {
    let currnetSelectedIds =
      props.field?.dependent_option_names?.map((opt) => opt?.value) ?? [];

    let selected = parentOptions?.filter((opt) =>
      currnetSelectedIds.includes(opt?.value)
    );
    onFormBilderChange("multiSelect", "dependent_option_names", selected ?? []);
  }, [parentOptions]);

  return (
    <>
      <Accordion
        expanded={props?.openFields?.includes(props?.field?.id)}
        onChange={handleToggleField}
        // expanded={props.field.id == fieldId}
        // classes={{
        //   root: `MuiAccordion-root ${Object.keys(errors).length > 0 && submitField === props.field.id
        //     ? "border border-danger"
        //     : ""
        //     }`,
        // }}
      >
        {/* Start Form Field Header */}

        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <h1>{!!props.field.name ? props.field.name : t("Field")}</h1>

          <button
            className="btn d-flex ml-auto"
            onClick={(e) => {
              e.stopPropagation();
              props.onDeleteField(props.id);
            }}
          >
            <FontAwesomeIcon icon={faTrash} color="#ff7878" />
          </button>
        </AccordionSummary>

        {/* End Form Field Header */}

        {/* Start Form Field Contents  */}

        <AccordionDetails classes={{ root: "field-details-root" }}>
          <div className={`form-field px-3`}>
            {/* Start Form Field Name & Parent Field depend on Option Section  */}

            <div className="d-flex flex-row align-items-baseline pt-3">
              {props.dependField ? (
                <>
                  <div className="col-md d-flex flex-wrap flex-column px-0">
                    <div
                      className={`col-md flex-column px-0 ${vaildContainerClassName(
                        "dependent_option_names"
                      )}`}
                    >
                      <label className="mr-4 validity-label-style">
                        {t("options")}
                      </label>
                      <Select
                        className="select-def-input-container b-select-style flex-grow-1"
                        classNamePrefix="b-select-style"
                        value={props.field?.dependent_option_names}
                        hideSelectedOptions
                        isMulti
                        isClearable
                        isSearchable
                        onChange={(selected, { name }) =>
                          onFormBilderChange(
                            "multiSelect",
                            name,
                            selected ?? []
                          )
                        }
                        name="dependent_option_names"
                        options={
                          props.data.filter(
                            (field) => field.id == props.field?.parent_field_id
                          )[0].options
                        }
                      />
                    </div>
                    <FieldError
                      {...commonFieldsErrorProps}
                      name="dependent_option_names"
                    />
                  </div>
                  <div className="col-md d-flex flex-wrap flex-column">
                    <div
                      className={`d-flex flex-column ${vaildContainerClassName(
                        "name"
                      )}`}
                    >
                      <label className="mr-4 validity-label-style">
                        {t("name")}
                      </label>

                      <input
                        type="text"
                        className="text-input flex-grow-1 validity-input-style mx-0"
                        onChange={(e) =>
                          onFormBilderChange(
                            "text",
                            e.target.name,
                            e?.target?.value
                          )
                        }
                        value={props.field.name}
                        name="name"
                      />
                    </div>
                    <FieldError {...commonFieldsErrorProps} name="name" />
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex flex-wrap flex-grow-1 flex-column">
                    <div
                      className={` d-flex flex-column ${vaildContainerClassName(
                        "name"
                      )}`}
                    >
                      <label className="mr-4 validity-label-style">
                        {t("name")}
                      </label>

                      <input
                        type="text"
                        className="text-input flex-grow-1 validity-input-style mx-0"
                        onChange={(e) =>
                          onFormBilderChange(
                            "text",
                            e.target.name,
                            e?.target?.value
                          )
                        }
                        value={props.field.name}
                        name="name"
                      />
                    </div>
                    <FieldError {...commonFieldsErrorProps} name="name" />
                  </div>
                </>
              )}
            </div>

            {/* End Form Field Name & Parent Field depend on Option Section  */}

            {/* Start Form Field Type Section  */}
            <div className="align-items-center d-flex flex-row justify-content-between">
              <div className={`flex-grow-1 ${vaildContainerClassName("type")}`}>
                <label className="mr-4 validity-label-style">
                  {t("field type")}
                </label>
                <Select
                  className="select-def-input-container b-select-style"
                  classNamePrefix="b-select-style"
                  isSearchable
                  name="type"
                  onChange={(selected, { name }) => {
                    onFormBilderChange("select", name, selected?.value ?? null);
                  }}
                  value={
                    fieldTypeOptions?.filter(
                      (opt) => opt.value == props.field.type
                    )[0] ?? null
                  }
                  options={fieldTypeOptions}
                />
                <FieldError {...commonFieldsErrorProps} name="type" />
              </div>
              {/* Start Form Field Selection Type for Droplist  */}

              {props.field.type?.toLowerCase() == "droplist" ? (
                <div className={`${vaildContainerClassName("selection_type")}`}>
                  <label className="mr-4 validity-label-style">
                    {t("selection")}
                  </label>
                  <div>
                    <input
                      type="radio"
                      name="selection_type"
                      value="Single"
                      onChange={(e) =>
                        onFormBilderChange(
                          "radio",
                          e.target.name,
                          e.target.value
                        )
                      }
                      checked={props.field.selection_type === "Single"}
                    />
                    <label className="mx-2">{t("Single")}</label>
                    <input
                      type="radio"
                      name="selection_type"
                      value="Multiple"
                      onChange={(e) =>
                        onFormBilderChange(
                          "radio",
                          e.target.name,
                          e.target.value
                        )
                      }
                      checked={props.field.selection_type === "Multiple"}
                    />
                    <label className="mx-2 ">{t("multiple")}</label>
                  </div>
                  <FieldError
                    {...commonFieldsErrorProps}
                    name="selection_type"
                  />
                </div>
              ) : null}
              {/* End Form Field Selection Type for Droplist  */}
            </div>
            {/* End Form Field Type Section  */}

            {/* Start Form Field options for Field has Options   */}
            {props.field.type?.toLowerCase() !== "text" &&
            props.field.type?.toLowerCase() !== "attachment" &&
            !!props.field.type ? (
              <>
                <div className={`${vaildContainerClassName("options")}`}>
                  <label className="mr-4 validity-label-style">
                    {t("field options")}
                  </label>
                  <CreatableSelect
                    className="select-def-input-container b-select-style flex-grow-1"
                    classNamePrefix="b-select-style"
                    isClearable
                    name="options"
                    // onChange={handleCreateSelectChange}
                    onChange={(selected, { name }) =>
                      onFormBilderChange("multiSelect", name, selected ?? [])
                    }
                    hideSelectedOptions={true}
                    // onCreateOption={handleCreateOption}
                    onCreateOption={(selected) =>
                      onFormBilderChange(
                        "createOption",
                        undefined,
                        selected ?? []
                      )
                    }
                    isMulti
                    value={props.field.options}
                    options={props.field.mainOptions}
                  />
                  <FieldError {...commonFieldsErrorProps} name="options" />
                </div>
              </>
            ) : null}

            {/* End Form Field options for Field has Options   */}

            {/* Satrt Form Field Validation Section  */}

            <div
              className={`my-2 ${vaildContainerClassName(
                "validation_type_ids"
              )}`}
            >
              {props.field.type?.toLowerCase() === "text" ? (
                <label className="align-items-center d-flex">
                  <input
                    type="checkbox"
                    name="apply_validation"
                    checked={
                      props.field?.apply_validation ||
                      props.field?.validation_type_ids?.length
                    }
                    onChange={(e) => {
                      onFormBilderChange(
                        "checkbox",
                        e.target.name,
                        e.target.checked
                      );
                    }}
                  />
                  <span className="mx-2">{t("apply validations")}</span>
                </label>
              ) : (
                <label className="align-items-center d-flex">
                  <input
                    type="checkbox"
                    name="required_validation"
                    checked={props.field?.validation_type_ids
                      ?.map((validation) => validation?.value)
                      ?.includes("1")}
                    onChange={(e) =>
                      onFormBilderChange("requiredInput", "", e.target.checked)
                    }
                  />
                  <span className="mx-2">{t("Required")}</span>
                </label>
              )}

              {(props.field?.apply_validation ||
                props.field?.validation_type_ids?.length) &&
              props.field.type?.toLowerCase() === "text" ? (
                <Select
                  className="select-def-input-container b-select-style flex-grow-1"
                  classNamePrefix="b-select-style"
                  onChange={(selected, { name }) =>
                    onFormBilderChange("multiSelect", name, selected ?? [])
                  }
                  isSearchable
                  value={props.field?.validation_type_ids}
                  isMulti
                  name="validation_type_ids"
                  options={validationOpts}
                />
              ) : null}

              <FieldError
                {...commonFieldsErrorProps}
                name="validation_type_ids"
              />
            </div>

            {/* End Form Field Validation Section  */}

            {/* Satrt Form Field Description Section  */}

            <div className={`my-2 ${vaildContainerClassName("description")}`}>
              <label className="align-items-center d-flex">
                <input
                  type="checkbox"
                  name="apply_description"
                  checked={props.field?.apply_description}
                  onChange={(e) =>
                    onFormBilderChange(
                      "checkbox",
                      e.target.name,
                      e.target.checked
                    )
                  }
                />
                <span className="mx-2">{t("description")}</span>
              </label>

              {props.field?.apply_description ||
              props.field.description?.length ? (
                <textarea
                  className="textarea-input"
                  name="description"
                  onChange={(e) =>
                    onFormBilderChange("text", e.target.name, e.target.value)
                  }
                  value={props.field.description}
                  rows={3}
                />
              ) : null}
              <FieldError {...commonFieldsErrorProps} name="description" />
            </div>

            {/* End Form Field Description Section  */}

            {/* Start Add Depend Form Field   */}
          </div>
          {props.field.type?.toLowerCase() !== "text" &&
          props.field.type?.toLowerCase() !== "attachment" &&
          !!props.field.type ? (
            <>
              <div className="px-3 my-2">
                <div className="d-flex flex-row align-items-baseline py-2">
                  <h5 className="mr-2 d-flex field-title">
                    {t("Dependent Field")}
                  </h5>
                  <button
                    className="d-flex add-button"
                    role="button"
                    onClick={() =>
                      props.handleAddChildField(
                        props.id,
                        props.field?.ancestors
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faPlus} color="#fff" />
                  </button>
                </div>
              </div>
            </>
          ) : null}

          {/* End Add Depend Form Field  */}
        </AccordionDetails>

        {/* End Form Field Contents  */}
      </Accordion>

      {/* Start Call Recursive Form Field For Options */}
      <div className="ml-2">
        {props?.data
          ?.filter(({ parent_field_id }) => parent_field_id == props.id)
          ?.map((depField, index) => (
            <FormBuilderField
              key={depField.id}
              id={depField.id}
              field={depField}
              onUpdateFormBuilder={props.onUpdateFormBuilder}
              handleAddChildField={props.handleAddChildField}
              onDeleteField={props.onDeleteField}
              dependField
              data={props?.data}
              serverValidation={props.serverValidation}
              validationName={`${props.validationName}.options.${index}`}
              openFields={props.openFields}
              onOpenField={props.onOpenField}
              onCloseField={props.onCloseField}
              submittedFields={props.submittedFields}
            />
          ))}
      </div>
      {/* End Call Recursive Form Field For Options */}
    </>
  );
};

export default FormBuilderField;

import * as Types from "../Actions/Feedback/types";

const initState = {
  // Toast
  toast: {
    msg: "",
    type: "",
    duration: 0,
    isOpen: false,
    translate: false,
  },

  // Full Screen Dialog
  modalContainer: {
    name: "",
  },
};

const feedbackReducer = (state = initState, action) => {
  switch (action.type) {
    /*  Toast */
    case Types.SHOW_TOAST:
      if (action?.payload?.msg?.indexOf("go.apollo.dev") > -1) {
        return state;
      }
      return {
        ...state,
        toast: {
          isOpen: true,
          ...action.payload,
        },
      };
    case Types.HIDE_TOAST:
      return {
        ...state,
        toast: initState.toast,
      };

    /* Container Modal */
    case Types.OPEN_MODAL_CONTAINER:
      return {
        ...state,
        modalContainer: {
          ...action.payload,
        },
      };
    case Types.CLOSE_MODAL_CONTAINER:
      return {
        ...state,
        modalContainer: initState.modalContainer,
      };

    default:
      return state;
  }
};

export default feedbackReducer;

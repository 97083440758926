import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Pagination from "../../Pagination";
import { AddButton } from "../../Buttons";
import { faPlus, faPen, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  showAddCompanySubscriptionModalAction,
  deleteCompanyPlanSubscriptionAction,
} from "../../../Store/Actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../Loader";
import swal from "sweetalert";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import Privilages from "../../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import AddSubscriptionModal from "./AddSubscriptionModal";

const SubscriptionList = (props) => {
  const { t } = useTranslation();

  // handleing add subscription modal
  const handleAddCompanySubscription = () => {
    let data = {
      company_id: props?.companyId,
      id: null,
      canEditSubcriptionClientStatus:props?.canEditSubcriptionStatus,
      company_status:props?.getLastSubcriptionStatus,
    };
    props.showAddCompanySubscriptionModalAction({
      data,
      ref: "addSubscriptionModalActions",
      formName: "addSubscriptionModal",
    });
  };

  // for edit plan subscription
  const handleEditPlanSubscriptionModal = (row) => {
    let data = {
      company_id: props?.companyId,
      contract_amount: props?.contract_amount,
      contract_duration: props?.contract_duration,
      id: row?.id,
      plan_id: row?.plan?.id,
      employees_limit: row?.employees_limit ?? "",
      currency_id: row?.currency?.id,
      payment_amount: row?.payment_amount,
      start_date: row?.start_date,
      end_date: row?.end_date,
      company_status:row?.company_status,
      canEditSubcriptionClientStatus:row?.canEditSubcriptionClientStatus,
      created_by_agent_id:row?.createdByAgent?.id,
      companyCanEditSubcriptionStatus: row?.company?.canEditSubcriptionStatus,
      future_subscription_check:row?.future_subscription_check,
      client_at: row?.client_at,
      isActive: row?.status?.toLowerCase() === "active",
    };
    props.showAddCompanySubscriptionModalAction({
      data,
      ref: "addSubscriptionModalActions",
      formName: "addSubscriptionModal",
    });
  };

  // to delete plan subscription record
  const handleDeletePlanSubscription = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteCompanyPlanSubscriptionAction(row?.id, props?.companyId);
      }
    });
  };

  const columns = [
    {
      name: t("from"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => (
        <>
          {row?.start_date != null
            ? moment(row?.start_date).format("DD - MM - YYYY")
            : ""}
        </>
      ),
    },
    {
      name: t("to"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => (
        <>
          {row?.end_date != null
            ? moment(row?.end_date).format("DD - MM - YYYY")
            : ""}
        </>
      ),
    },
    {
      name: t("Plan"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => <>{row?.plan?.name}</>,
    },
    {
      name: t("Amount Paid"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => (
        <>
          <b className="currency_symbol_color pr-1">{row?.currency?.symbol}</b>
          {row?.payment_amount}
        </>
      ),
    },
    {
      name: t("Status"),
      selector: (row) => row?.company_status,
    },
    {
      name: t("employees limit"),
      selector: "employees_limit",
    },
    {
      name: t("actions"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[
            Privilages.SUPER_PRIVILEGE,
            Privilages.EDIT_DELETE_SUBSCIPTION,
          ]}
        >
          <div className="data-table-action">
            {row?.status?.toLowerCase() == "active" ||
            row?.future_subscription_check ||
            row?.canEditSubcriptionClientStatus ? (
              <span className="btn pl-0">
                <FontAwesomeIcon
                  icon={faPen}
                  onClick={() => handleEditPlanSubscriptionModal(row)}
                />
              </span>
            ) : (
              ""
            )}

            {row?.last_subscription_check ? (
              <span className="btn">
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => handleDeletePlanSubscription(row)}
                />
              </span>
            ) : (
              ""
            )}
          </div>
        </HasPrivileges>
      ),
    },
  ];

  return (
    <div className="col-12 subscription_list_wrapper_style employee-form px-0">
      {props?.isDeleteCompanySubscriptionLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="d-flex align-items-baseline justify-content-end pb-4">
        <HasPrivileges
          reqireMain={[
            Privilages.SUPER_PRIVILEGE,
            Privilages.ADD_NEW_SUBSCIPTION,
          ]}
        >
          <AddButton
            label={t("Subscriptions")}
            onClick={handleAddCompanySubscription}
          />
        </HasPrivileges>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="requests-profile-data-list datatable_shadow_style"
        columns={columns}
        data={props.data.data}
        noHeader
        persistTableHead
        paginationComponent={() => (
          <Pagination
            tableRef={props.dataTableRef}
            styleWraper=""
            onPaginate={props.onPaginate}
            reducer={props.reducer}
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={props.data.isLoading}
        progressComponent={<Loader />}
      />

      {/* Start of Add/Edit Company Subscriptions Modal */}
      <AddSubscriptionModal />
      {/* End of Add/Edit Company Subscriptions Modal */}
    </div>
  );
};

const mapStateToProps = (state, { reducer, dataTableRef }) => {
  return {
    data: state[reducer][dataTableRef],
    isDeleteCompanySubscriptionLoading:
      state?.super?.deleteCompanySubscriptionLoading,
  };
};

export default connect(mapStateToProps, {
  showAddCompanySubscriptionModalAction,
  deleteCompanyPlanSubscriptionAction,
})(SubscriptionList);

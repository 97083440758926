import React from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import Privilages from "../../Constants/Privilages";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";

import Tooltip from "@mui/material/Tooltip";
import { Email, LocalPhone, Sms, WhatsApp } from "@mui/icons-material";
import Constants from "../../Constants";
import { useDispatch } from "react-redux";
import { sendEmailVerificationAction, sendPhoneVerificationAction } from "../../Store/Actions";

const InfoTooltip = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  let relevantAttendanceTypeConfiguration =
    props?.data?.employee?.relevantAttendanceTypeConfiguration;
  let workTeamName = relevantAttendanceTypeConfiguration?.workTeam?.name;
  let workGroupName = relevantAttendanceTypeConfiguration?.workGroup?.name;

  const handleVerifyEmailBtn = () => {
    if (
      props?.data.email_verified ||
      props?.data.user_type == "Owner"
    ) {
      return;
    }
    dispatch(sendEmailVerificationAction(null, props?.data.id, false));
  };

  const handleVerifyPhoneBtn = (send_via) => {
    if (
      props?.data.phone_verified ||
      props?.data.user_type == "Owner"
    ) {
      return;
    }
    dispatch(sendPhoneVerificationAction(
      null,
      props.data.id,
      false,
      send_via
    ));
  };

  return (
    <>
      {props?.data.has_credentials ? (
        <>
          {props?.data.email ? (
            <>
              <Tooltip
                classes={{
                  tooltip: "custom_tooltip_style p-0",
                  arrow: "custom_arrow_tooltip_style",
                }}
                arrow={true}
                interactive
                title={
                  <React.Fragment>
                    <div className="pb-0 text-center pt-2">
                      {/* <div className="col">
                                    <label>{t("email")}</label>
                                  </div> */}
                      <div>
                        <p className="other_employee_info_style mb-1 text-lowercase px-2">
                          {/* <label className="mr-2">
                                        {t("email")}
                                      </label> */}
                          {props?.data.email}
                        </p>
                      </div>
                    </div>
                    <HasPrivileges
                      allowBP
                      reqireMain={[Privilages.VERIFY_PHONE_EMAIL]}
                    >
                      {!props?.data?.email_verified &&
                        props?.data?.active &&
                        props?.data?.user_type != "Owner" &&
                        props?.data?.user_type != "Office" ? (
                        <div className="click-to-verify-message">
                          {t("click to verify email")}
                        </div>
                      ) : (
                        ""
                      )}
                    </HasPrivileges>
                  </React.Fragment>
                }
              >
                <div className="d-flex">
                  <HasPrivileges
                    allowBP
                    reqireMain={[Privilages.VERIFY_PHONE_EMAIL]}
                    altChildren={
                      <div
                        className={
                          !props?.data.phone
                            ? "mx-1 tooltip_icon_btn_wrapper_style"
                            : "tooltip_icon_btn_wrapper_style"
                        }
                      >
                        <Email
                          sx={{
                            fontSize: 12,
                            color:
                              props?.data.email_verified &&
                                props?.data.user_type !== "Owner"
                                ? "#27b40c"
                                : "#313030",
                          }}
                        />
                      </div>
                    }
                  >
                    <div
                      className={
                        !props?.data.phone
                          ? "mx-1 tooltip_icon_btn_wrapper_style"
                          : "tooltip_icon_btn_wrapper_style"
                      }
                      onClick={handleVerifyEmailBtn}
                    >
                      <Email
                        sx={{
                          fontSize: 12,
                          color:
                            props?.data.email_verified &&
                              props?.data.user_type !== "Owner"
                              ? "#27b40c"
                              : "#313030",
                        }}
                      />
                    </div>
                  </HasPrivileges>
                </div>
              </Tooltip>
            </>
          ) : (
            ""
          )}

          {props?.data.phone ? (
            <>
              <Tooltip
                classes={{
                  tooltip: "custom_tooltip_style p-0",
                  arrow: "custom_arrow_tooltip_style",
                }}
                interactive={true}
                arrow={true}
                title={
                  <>
                    <div className="pb-0 text-center pt-2">
                      {/* <div className="col">
                                    <label>{t("email")}</label>
                                  </div> */}
                      <div>
                        <p className="other_employee_info_style mb-1 text-lowercase px-2">
                          {/* <label className="mr-2">
                                        {t("email")}
                                      </label> */}
                          {props?.data.phone}
                        </p>
                      </div>
                    </div>
                    <HasPrivileges
                      allowBP
                      reqireMain={[Privilages.VERIFY_PHONE_EMAIL]}
                    >
                      {!props?.data?.phone_verified &&
                        props?.data?.active &&
                        props?.data?.user_type !== "Owner" &&
                        props?.data?.user_type !== "Office" ? (
                        <>
                          <div className="click-to-verify-message">
                            {t("verify via")}
                          </div>
                          <div className="veriy-phone-buttons-container">
                            <Tooltip title={t("sms")}>
                              <button
                                onClick={() => handleVerifyPhoneBtn("sms")}
                                className="verify-phone-button"
                              >
                                <Sms className="verify-phone-sms-icon" />
                              </button>
                            </Tooltip>
                            <span className="verify-phone-icons-separator"></span>
                            <Tooltip title={t("whatsapp")}>
                              <button
                                onClick={() => handleVerifyPhoneBtn("whatsapp")}
                                className="verify-phone-button"
                              >
                                <WhatsApp className="verify-phone-whatsapp-icon" />
                              </button>
                            </Tooltip>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </HasPrivileges>
                  </>
                }
              >
                <div className="d-flex">
                  <HasPrivileges
                    allowBP
                    altChildren={
                      <div
                        className={
                          !props?.data.email
                            ? "mx-1 tooltip_icon_btn_wrapper_style"
                            : "mx-2 tooltip_icon_btn_wrapper_style"
                        }
                      >
                        <LocalPhone
                          sx={{
                            fontSize: 12,
                            color:
                              props?.data.phone_verified &&
                                props?.data.user_type !== "Owner"
                                ? "#27b40c"
                                : "#313030",
                          }}
                        />
                      </div>
                    }
                    reqireMain={[Privilages.VERIFY_PHONE_EMAIL]}
                  >
                    <div
                      className={
                        !props?.data.email
                          ? "mx-1 tooltip_icon_btn_wrapper_style"
                          : "mx-2 tooltip_icon_btn_wrapper_style"
                      }
                    // onClick={handleVerifyPhoneBtn}
                    >
                      <LocalPhone
                        sx={{
                          fontSize: 12,
                          color:
                            props?.data.phone_verified &&
                              props?.data.user_type !== "Owner"
                              ? "#27b40c"
                              : "#313030",
                        }}
                      />
                    </div>
                  </HasPrivileges>
                </div>
              </Tooltip>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      <Tooltip
        interactive
        classes={{
          tooltip: "custom_tooltip_style info_tooltip_style",
          arrow: "custom_arrow_tooltip_style",
        }}
        arrow={true}
        title={
          <React.Fragment>
            <div>
              {props?.data?.date_of_birth ? (
                <div className="d-flex pb-0 my-1">
                  <div className="mr-2">
                    <label>{t("date of birth")}</label>
                  </div>
                  <div>
                    <p className="other_employee_info_style mb-2 custom-tooltip-info-name">
                      {props?.data?.date_of_birth}
                    </p>
                  </div>
                </div>
              ) : null}

              {props?.data?.gender ? (
                <div className="d-flex pb-0 my-1">
                  <div className="mr-2">
                    <label>{t("gender")}</label>
                  </div>
                  <div>
                    <p className="other_employee_info_style mb-2 custom-tooltip-info-name">
                      {props?.data?.gender}
                    </p>
                  </div>
                </div>
              ) : null}

              {props?.data?.marital_status ? (
                <div className="d-flex pb-0 my-1">
                  <div className="mr-2">
                    <label>{t("marital status")}</label>
                  </div>
                  <div>
                    <p className="other_employee_info_style mb-2 custom-tooltip-info-name">
                      {props?.data?.marital_status}
                    </p>
                  </div>
                </div>
              ) : null}

              {props?.data?.national_id ? (
                <div className="d-flex pb-0 my-1">
                  <div className="mr-2">
                    <label>{t("natoinal id")}</label>
                  </div>
                  <div>
                    <p className="other_employee_info_style mb-2 custom-tooltip-info-name">
                      {props?.data?.national_id}
                    </p>
                  </div>
                </div>
              ) : null}

              {props?.data?.insurance_number ? (
                <div className="d-flex pb-0 my-1">
                  <div className="mr-2">
                    <label>{t("insurance number")}</label>
                  </div>
                  <div>
                    <p className="other_employee_info_style mb-2 custom-tooltip-info-name">
                      {props?.data?.insurance_number}
                    </p>
                  </div>
                </div>
              ) : null}

              {props?.data?.joining_date ? (
                <div className="d-flex pb-0">
                  <div className="mr-2">
                    <label>{t("joined from")}</label>
                  </div>
                  <div>
                    <p className="other_employee_info_style mb-2 custom-tooltip-info-name">
                      {moment(props?.data?.joining_date).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
              ) : null}

              <p className="other_employee_info_style mb-2 custom-tooltip-info-name">
                {props?.data?.office?.name}
              </p>

              {relevantAttendanceTypeConfiguration?.type ===
                Constants.attendanceTypes.SHIFT_BASED ? (
                <>
                  <p className="other_employee_info_style mb-2 custom-tooltip-info-name">
                    {t("work group")} : {workGroupName ?? "------"}
                  </p>
                  <p className="other_employee_info_style mb-2 custom-tooltip-info-name">
                    {t("work team")} : {workTeamName ?? "------"}
                  </p>
                </>
              ) : null}

              <div className="d-flex mb-2 align-items-center gap-1">
                <div>
                  <label>{t("username")}</label>
                </div>
                <div>
                  <p className="other_employee_info_style custom-tooltip-info-name mb-0">
                    {props?.data?.user_alias}
                  </p>
                </div>
              </div>
              {props?.data?.emp_code ? (
                <div className="d-flex mb-2">
                  <div>
                    <label>{t("employee code")}</label>
                  </div>
                  <div>
                    <p className="other_employee_info_style custom-tooltip-info-name mb-0">
                      {props?.data?.emp_code}
                    </p>
                  </div>
                </div>
              ) : null}

              <div className="pb-0">
                <div>
                  <label>{t("managers")}</label>
                </div>
                <div>
                  <p className="other_employee_info_style mb-0 custom-tooltip-info-name">
                    {props?.data?.manager?.name}
                  </p>
                  {props?.data?.copied_managers?.map((manager) => (
                    <p
                      className="other_employee_info_style mb-0 custom-tooltip-info-name"
                      key={manager?.id}
                    >
                      {manager?.name}
                    </p>
                  ))}
                </div>
              </div>

              {relevantAttendanceTypeConfiguration?.type ===
                Constants.attendanceTypes.SHIFT_BASED ? (
                <div className="pb-0 pt-2">
                  <div>
                    <label>{t("work group managers")}</label>
                  </div>
                  <div>
                    {relevantAttendanceTypeConfiguration?.workGroup?.managers?.map(
                      (manager) => (
                        <p
                          key={manager?.id}
                          className="other_employee_info_style mb-0 custom-tooltip-info-name"
                        >
                          {manager?.name}
                        </p>
                      )
                    )}
                  </div>
                </div>
              ) : null}

              {relevantAttendanceTypeConfiguration?.type !==
                Constants.attendanceTypes.SHIFT_BASED ? (
                <>
                  <div className="pb-0 pt-2">
                    <div>
                      <label>{t("attendance profile")}</label>
                    </div>
                    <div>
                      <p className="other_employee_info_style mb-2 custom-tooltip-info-name">
                        {props?.data?.attendance_profile?.name}
                      </p>
                    </div>
                  </div>

                  <div className="pb-0">
                    <div>
                      <label>{t("active work timing")}</label>
                    </div>
                    <div>
                      <p
                        dir="auto"
                        className="other_employee_info_style mb-2 custom-tooltip-info-name text-left"
                      >
                        {
                          props?.data?.attendance_profile?.activeWorkTiming
                            ?.name
                        }
                      </p>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </React.Fragment>
        }
      >
        <div className="tooltip_icon_btn_wrapper_style">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            // xmlns="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            focusable="false"
            width="13"
            height="13"
            viewBox="0 0 24 24"
            fill="#313030"
          >
            <path d="M11 4h2v11h-2V4zm2 14v2h-2v-2h2z" />
          </svg>
        </div>
      </Tooltip>
    </>
  );
};

export default InfoTooltip;

import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  fetchAssignmentFormAction,
  // toggleAssignmentPreviewModal,
} from "../../Store/Actions";
import Privileges from "../../Constants/Privilages";
import { addEditDeleteAssignmentPrivileges } from "../../Constants";

import {
  EditIconButton,
  PreviewIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import { Tooltip } from "@mui/material";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import SyncAltOutlinedIcon from "@mui/icons-material/SyncAltOutlined";

const AssignmentCard = ({
  gridStyle,
  assignment,
  numberOfColumns,
  handleEdit,
  handleDelete,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Constants
  const {
    id,
    canEditFrom,
    canEditTo,
    name,
    normalWorkTiming,
    offices,
    createdBy,
    change_shift,
    workplace_setting,
    is_holiday,
  } = assignment;

  /* ↓ Helpers ↓ */

  const handlePreview = () => {
    // dispatch(toggleAssignmentPreviewModal(true));
    dispatch(
      fetchAssignmentFormAction({
        includeAssignment: true,
        assignmentId: id,
      })
    );
  };

  const checkIfCardIsCentered = () => {
    if (numberOfColumns > 1) {
      return "text-center";
    }
  };

  const renderLocations = () => {
    return offices?.map((office, index) => {
      if (index + 1 === offices?.length) {
        return office.name;
      } else {
        return office.name + " - ";
      }
    });
  };

  return (
    <div style={gridStyle} className="assignment-card">
      <div className="d-flex align-items-center justify-content-between w-100">
        <Tooltip title={t(name)}>
          <span className={`assignment-name ${checkIfCardIsCentered()}`}>
            {name}
            {assignment?.change_shift ? (
              <SyncAltOutlinedIcon
                sx={{ fontSize: 16, marginInlineStart: 0.5 }}
              />
            ) : null}
          </span>
        </Tooltip>
      </div>

      <span className={`assignment-shift mt-1 ${checkIfCardIsCentered()}`}>
        {is_holiday
          ? t("holiday")
          : !!normalWorkTiming?.id
          ? normalWorkTiming?.name
          : t("day off")}
      </span>

      {/* Locations */}
      {normalWorkTiming?.name ? (
        offices?.length ? (
          <Tooltip title={renderLocations()}>
            <span
              className={`assignment-location mb-1 ${checkIfCardIsCentered()}`}
            >
              {renderLocations()}
            </span>
          </Tooltip>
        ) : workplace_setting === "work_timing_location" ? (
          <span
            className={`assignment-location mb-1 ${checkIfCardIsCentered()}`}
          >
            {t("work timing location")}
          </span>
        ) : (
          <span
            className={`assignment-location mb-1 ${checkIfCardIsCentered()}`}
          >
            any location
          </span>
        )
      ) : null}

      <div className="d-flex align-items-center justify-content-between w-100">
        <span className={`assignment-creator ${checkIfCardIsCentered()}`}>
          {t("created by")} {createdBy?.name}
        </span>
      </div>

      {/* Preview */}
      <PreviewIconButton
        onClick={handlePreview}
        className="assignment-preview-button"
      />

      {/* Remove */}
      {canEditFrom || canEditTo ? (
        <HasPrivileges
          allowBP
          reqireMain={
            change_shift
              ? [Privileges.CHANGE_SHIFT]
              : addEditDeleteAssignmentPrivileges
          }
        >
          <RemoveIconButton
            onClick={handleDelete}
            className="assignment-delete-button"
          />
        </HasPrivileges>
      ) : (
        ""
      )}

      {/* Edit */}
      {canEditFrom || canEditTo ? (
        <HasPrivileges
          allowBP
          reqireMain={
            change_shift
              ? [Privileges.CHANGE_SHIFT]
              : addEditDeleteAssignmentPrivileges
          }
        >
          <EditIconButton
            onClick={handleEdit}
            className="assignment-edit-button"
          />
        </HasPrivileges>
      ) : (
        ""
      )}
    </div>
  );
};

export default AssignmentCard;

import Types from "../Actions/types";

const initState = {
  setupWizardLoading: false,
  setupWizardEssentialInfo: {},
  companyWizardActiveTab: 1,
  setupWizardinitialLoading: false,
  setupWizardRemainingSteps: 0,
  wizardAttType: null,
};

export const companyWizardReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.FETCH_SETUP_WIZARD_ESSENTIAL_DATA_ATTEMPT:
      return {
        ...state,
        setupWizardLoading: true,
        setupWizardinitialLoading: true,
      };

    case Types.FETCH_SETUP_WIZARD_ESSENTIAL_DATA_SUCCESS:
      return {
        ...state,
        setupWizardLoading: false,
        setupWizardinitialLoading: false,
        setupWizardEssentialInfo: action?.payload,
      };

    case Types.FETCH_SETUP_WIZARD_ESSENTIAL_DATA_FAIL:
      return {
        ...state,
        setupWizardLoading: false,
        setupWizardinitialLoading: false,
      };

    case Types.CREATE_DEPARTMENTS_ATTEMPT:
    case Types.CREATE_POSITIONS_ATTEMPT:
    case Types.UPSERT_SETUP_WIZARD_WORK_TIMING_ATTEMPT:
    case Types.UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_ATTEMPT:
      return {
        ...state,
        setupWizardLoading: true,
      };

    case Types.CREATE_DEPARTMENTS_SUCCESS:
    case Types.CREATE_DEPARTMENTS_FAILED:
    case Types.CREATE_POSITIONS_SUCCESS:
    case Types.CREATE_POSITIONS_FAILED:
    case Types.UPSERT_SETUP_WIZARD_WORK_TIMING_SUCCESS:
    case Types.UPSERT_SETUP_WIZARD_WORK_TIMING_FAILED:
    case Types.UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_SUCCESS:
    case Types.UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_FAILED:
      return {
        ...state,
        setupWizardLoading: false,
      };

    case Types.SET_SETUP_WIZARD_ACTIVE_TAB:
      return {
        ...state,
        companyWizardActiveTab: action.payload,
      };

    case Types.EDIT_SETUP_WIZARD_ESSENTIAL_DATA:
      if (action.payload === "wizardAttType") return state;
      else
        return {
          ...state,
          setupWizardEssentialInfo: {
            ...state.setupWizardEssentialInfo,
            [action.payload]: {
              paginatorInfo: {
                count: 1,
              },
            },
          },
        };

    case Types.SET_WIZARD_ATT_TYPE:
      return {
        ...state,
        wizardAttType: action.payload,
      };

    case Types.SET_SETUP_WIZARD_REMAINING_STEPS:
      return {
        ...state,
        setupWizardRemainingSteps: action.payload,
      };

    case Types.LOG_OUT:
    case "SWITCH_ACCOUNT":
      return initState;

    default:
      return state;
  }
};

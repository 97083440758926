import React from "react";
import { useTranslation } from "react-i18next";

import { Section, Unit } from "./SectionBase";

const NoShowDeductionSection = (props) => {
  const { t } = useTranslation();

  return (
    <Section
      title="no show deduction"
      violations={props?.absent_violations}
      units={[
        <Unit label="deduct from" val={props?.emergency_penalty_type} />,
        <Unit label="reset every" val={t(props?.absent_reset_period)} />,
      ]}
    />
  );
};

export default NoShowDeductionSection;

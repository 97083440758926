import { put, call, takeEvery, delay } from "redux-saga/effects";
import Types from "../Actions/types";
import swal from "sweetalert";

import {
  loginSuccess,
  loginFailed,
  fetchProfileSuccess,
  refreshSignInOutAction,
  verifyUSerPhoneSuccess,
  verifyUSerPhoneFailed,
  sendPhoneVerificationSuccess,
  sendPhoneVerificationFailed,
  sendPhoneVerificationAction,
  sendEmailVerificationSuccess,
  sendEmailVerificationFailed,
  sendEmailVerificationAction,
  verifyUserEmailSuccess,
  verifyUserEmailFailed,
  fetchSetupWizardDataInAuth,
  showSuccessToast,
  showErrorToast,
} from "../Actions";

import {
  authUser,
  verifyUserPhone,
  SendUserPhoneVerification,
  sendUserEmailVerification,
  verifyEmailAddress,
} from "../../Services/Client";
import HelperFns from "../../Helpers/HelperFns";
import history from "../../Helpers/Navigations/history";
import Store from "..";

export function* handleLogingInSaga({ payload }) {
  try {
    const userAuth = yield call(authUser, payload);
    if (!userAuth.errors) {
      if (
        (userAuth?.data?.login?.user?.active &&
          userAuth?.data?.login?.user?.hasActiveCompany
        ) ||
        userAuth?.data?.login?.user?.user_type?.toLowerCase() === "super"
      ) {
        yield put(fetchProfileSuccess(userAuth?.data?.login?.user));
        yield put(
          loginSuccess(
            userAuth?.data?.login?.access_token,
            userAuth?.data?.login?.user
          )
        );
        yield put({
          type: "ADD_ACCOUNT_TO_LIST",
          payload: {
            account: userAuth?.data?.login,
          },
        });
        if (
          !userAuth?.data?.login?.user?.company?.hasActiveSubscription &&
          userAuth?.data?.login?.user?.user_type?.toLowerCase() !== "super"
        ) {
          HelperFns.showToast("warning", "subscription expired");
        }
        yield delay(500);
        if (userAuth?.data?.login?.user?.user_type?.toLowerCase() !== "super") {
          yield put(refreshSignInOutAction());
        }
        if (userAuth?.data?.login?.user?.user_type?.toLowerCase() === "owner") {
          yield put(fetchSetupWizardDataInAuth());
        }
      } else {
        yield put(
          showErrorToast(
            !userAuth?.data?.login?.user?.active
              ? "You have been Suspended"
              : "Your Company has been Suspended"
          )
        );
        yield put(loginFailed());
      }
    } else {
      if (userAuth.errors[0]?.extensions.reason === "phone_verification") {
        swal({
          title: HelperFns.localizeFns("verify phone number"),
          text: HelperFns.localizeFns(userAuth.errors[0]?.extensions.reason),
          icon: "warning",
          className: "swal-warning-style",
          buttons: true,
        }).then((confirm) => {
          if (confirm) {
            Store.dispatch(
              sendPhoneVerificationAction(
                payload?.user?.username,
                null,
                false,
                null
              ) // username is a phone number in this case
            );
          }
        });
        yield put(loginFailed());
        return;
      }
      if (userAuth.errors[0]?.extensions.reason === "email_verification") {
        swal({
          title: HelperFns.localizeFns("verify email"),
          text: HelperFns.localizeFns(userAuth.errors[0]?.extensions.reason),
          icon: "warning",
          className: "swal-warning-style",
          buttons: true,
        }).then((confirm) => {
          if (confirm) {
            Store.dispatch(
              sendEmailVerificationAction(payload?.user?.username)
            );
          }
        });
        yield put(loginFailed());
        return;
      } else {
        yield put(showErrorToast(userAuth.errors?.[0]?.extensions?.reason));
        yield put(loginFailed());
      }
    }
  } catch (error) {
    yield put(loginFailed());
  }
}

function* onLogingInAttempt() {
  yield takeEvery(Types.LOGING_IN_ATTEMPT, handleLogingInSaga);
}

export function* handleVerifyUserPhoneSaga({ payload }) {
  try {
    const response = yield call(verifyUserPhone, payload);
    if (
      !response.errors &&
      response.data?.verify_phone_number?.status === "success"
    ) {
      yield put(verifyUSerPhoneSuccess());
      setTimeout(() => {
        history.push("/login");
      }, 1500);
      yield put(showSuccessToast(response?.data?.verify_phone_number?.message));
      return;
    }
    if (
      !response.errors &&
      response.data?.verify_phone_number?.status === "reset"
    ) {
      yield put(verifyUSerPhoneSuccess());
      setTimeout(() => {
        history.push(`/${response?.data?.verify_phone_number.message}`);
      }, 1500);
      yield put(showSuccessToast());
      return;
    } else {
      yield put(verifyUSerPhoneFailed());
      yield put(showErrorToast(response.data?.verify_phone_number?.message));
    }
  } catch (error) {
    yield put(verifyUSerPhoneFailed());
    yield put(showErrorToast(error?.message));
  }
}

function* onVerifyUserPhoneSaga() {
  yield takeEvery(Types.VERIFY_USER_PHONE_ATTEMPT, handleVerifyUserPhoneSaga);
}

export function* handleVerifyUserEmailSaga({ payload }) {
  try {
    const response = yield call(verifyEmailAddress, payload);
    if (!response.errors && response.data?.verify_email?.status == "success") {
      yield put(verifyUserEmailSuccess());
      setTimeout(() => {
        history.push("/login");
      }, 1500);
      yield put(showSuccessToast(response?.data?.verify_email?.message));
      return;
    }

    if (!response.errors && response.data?.verify_email?.status === "reset") {
      yield put(verifyUserEmailSuccess());
      setTimeout(() => {
        history.push(`/${response?.data?.verify_email?.message}`);
      }, 1500);
      yield put(showSuccessToast());
      return;
    } else {
      yield put(verifyUserEmailFailed());
      yield put(showErrorToast(response.data?.verify_email?.message));
    }
  } catch (error) {
    yield put(verifyUserEmailFailed());
    yield put(showErrorToast(error?.message));
  }
}

function* onVerifyUserEmailSaga() {
  yield takeEvery(Types.VERIFY_USER_EMAIL_ATTEMPT, handleVerifyUserEmailSaga);
}

export function* handleSendUserPhoneVerificationSaga({ payload }) {
  try {
    const response = yield call(SendUserPhoneVerification, payload);

    if (!response.errors) {
      if (response?.data?.send_verification_message?.status == "success") {
        yield put(sendPhoneVerificationSuccess());
        yield put(
          showSuccessToast(response?.data?.send_verification_message?.message)
        );
        return;
      }

      if (
        response?.data?.send_verification_message?.status === "force_send" ||
        response?.data?.send_verification_message?.status === "fail"
      ) {
        yield put(sendPhoneVerificationFailed());

        return swal({
          title: HelperFns.localizeFns("Verify phone number"),
          text: response?.data?.send_verification_message?.message,
          icon: "warning",
          className: "swal-warning-style",
          buttons: true,
        }).then((confirm) => {
          if (confirm) {
            Store.dispatch(
              sendPhoneVerificationAction(
                null,
                payload?.user_id,
                true,
                payload?.send_via
              )
            );
          }
        });
      }
    }

    if (!response?.data?.send_verification_message) {
      yield put(sendPhoneVerificationFailed());
      yield put(
        showErrorToast(response?.data?.send_verification_message?.message)
      );
    }
  } catch (error) {
    yield put(sendPhoneVerificationFailed());
    yield put(showErrorToast(error?.message));
  }
}

function* onSendUserPhoneVerificationSaga() {
  yield takeEvery(
    Types.SEND_PHONE_VERIFICATION_ATTEMPT,
    handleSendUserPhoneVerificationSaga
  );
}

export function* handleSendUserEmailVerificationSaga({ payload }) {
  try {
    const response = yield call(sendUserEmailVerification, payload);

    if (!response.errors) {
      if (response?.data?.send_verification_email?.status === "success") {
        yield put(sendEmailVerificationSuccess());
        yield put(
          showSuccessToast(response?.data?.send_verification_email?.message)
        );
        return;
      }

      if (
        response?.data?.send_verification_email?.status === "force_send" ||
        response?.data?.send_verification_email?.status === "fail"
      ) {
        yield put(sendEmailVerificationFailed());

        return swal({
          title: HelperFns.localizeFns("verify email"),
          text: response?.data?.send_verification_email?.message,
          icon: "warning",
          className: "swal-warning-style",
          buttons: true,
        }).then((confirm) => {
          if (confirm) {
            Store.dispatch(
              sendEmailVerificationAction(null, payload?.user_id, true)
            );
          }
        });
      }
    } else {
      yield put(sendEmailVerificationFailed());
      yield put(
        showErrorToast(response?.data?.send_verification_email?.message)
      );
    }
  } catch (error) {
    yield put(sendEmailVerificationFailed());
    yield put(showErrorToast(error?.message));
  }
}

function* onSendUserEmailVerificationSaga() {
  yield takeEvery(
    Types.SEND_EMAIL_VERIFICATION_ATTEMPT,
    handleSendUserEmailVerificationSaga
  );
}

export default {
  onLogingInAttempt,
  onVerifyUserPhoneSaga,
  onSendUserPhoneVerificationSaga,
  onSendUserEmailVerificationSaga,
  onVerifyUserEmailSaga,
};

import _ from "lodash";
import Types from "../Actions/types";

export const initState = {
  usersList: [],
};

export const LoginUserList = (state = initState, action) => {
  switch (action.type) {
    case "ADD_ACCOUNT_TO_LIST":
      // case Types.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        usersList: _.uniqBy(
          [...state.usersList, action.payload.account],
          (account) => account?.user?.id
        ),
      };
    case "REMOVE_ACCOUNT":
      return {
        ...state,
        usersList: _.filter(
          state.usersList,
          (account) => account?.user?.id !== action?.payload
        ),
      };

    // case Types.LOG_OUT:
    //   return { ...initState };

    default:
      return state;
  }
};

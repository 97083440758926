import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { connect, useSelector } from "react-redux";
import {
  dismissManagePrivilagesModalAction,
  upsertAdminUserPrivilegesAction,
  fetchRolePresetPrivilegesAction,
} from "../../Store/Actions";
import TreeView from "../../Builder/TreeView";
import Loader from "../../Components/Loader";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";

const formName = "managePrivilagesModal";
const formNameValidation = "managePrivilagesModalValidation";

const EditAdminPrivilagesModal = (props) => {
  const { t } = useTranslation();

  // states from store
  const privilegeTemplateModalValidatoin = useSelector(
    (state) => state.super.privilegeTemplateModalValidatoin
  );

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }

    props?.upsertAdminUserPrivilegesAction({
      user_id: props.managePrivilagesModal.id,
      main: props.managePrivilagesModal.main,
      all: props.managePrivilagesModal.all,
      managed: props.managePrivilagesModal.managed,
      presetAction: !!presetAction.action
        ? {
            ...presetAction,
            role_id: presetAction.action == 1 ? presetAction.role_id : null,
            user_type: "saas",
            limit_access: null,
          }
        : null,
    });
    console.log(props.managePrivilagesModal);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  // use states for preset role and action
  const [presetRole, setpresetRole] = useState(null);
  const [presetAction, setPresetAction] = useState({
    role_id: null,
    action: null,
    name: null,
  });

  const isModalVissible = props?.modalData?.isVissible;
  const modalMessage = props?.modalData?.modalMessage;
  const isLoading = props?.modalData?.isLoading;
  const toggleModal = () => {
    props?.dismissManagePrivilagesModalAction({
      ref: "managePrivilagesModalActions",
      formName,
    });
  };

  // handle on change preset role action to fetch privilages according to this role
  useEffect(() => {
    if (presetRole?.value) {
      props?.fetchRolePresetPrivilegesAction(presetRole?.value);
      setPresetAction((prevState) => ({
        ...prevState,
        action: null,
        name: presetRole?.label,
        role_id: presetRole?.value,
      }));
    }
  }, [presetRole]);

  // reset all values
  useEffect(() => {
    setIsSubmitting(false);
    setpresetRole(null);
    setPresetAction({
      role_id: null,
      action: null,
      name: null,
    });
    return () => {
      setpresetRole(null);
      setPresetAction({
        role_id: null,
        action: null,
        name: null,
      });
    };
  }, [isModalVissible]);

  // handle input for template on change function
  const handleInputChange = (event) => {
    const { name, value, checked } = event?.target;
    if (name === "name") {
      setPresetAction((prevState) => ({ ...prevState, [name]: value }));
      return;
    }
    if (checked) {
      setPresetAction((prevState) => ({ ...prevState, [name]: Number(value) }));
      return;
    } else {
      setPresetAction((prevState) => ({ ...prevState, [name]: null }));
    }
  };

  if (props?.isLoading) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }
  return (
    <MainModal
      isOpen={isModalVissible}
      btnLabelCancel="Cancel"
      btnOnCancelClick={toggleModal}
      modalTitle={`${t("Management Privileges")} - ${
        props.managePrivilagesModal.name
      }`}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="manage_privilages_modal_wrapper_style"
    >
      <div>
        {/* Start of preset role select */}
        <div className="d-flex flex-row align-items-center px-3 py-1">
          <label className="d-flex">{t("role")}</label>
          <Select
            className={
              "select-def-input-containe b-select-style col-md-6 col-sm-12"
            }
            classNamePrefix={"b-select-style"}
            value={presetRole}
            onChange={(val) => setpresetRole(val)}
            isLoading={props.loadingRolePresetPrivileges}
            isClearable={true}
            isRtl={document.dir?.toLowerCase() == "rtl"}
            isSearchable
            id="role_preset"
            placeholder={t("select option")}
            options={props.planRoles}
          />
        </div>
        {/* End of preset role select */}

        {props.managePrivilagesModal.id && (
          <>
            {props?.loadingRolePresetPrivileges ? (
              <div className="loader_wrapper_style">
                <Loader />
              </div>
            ) : null}

            <TreeView
              parents={JSON.parse(
                props?.managePrivilagesModal?.system_privileges
              )}
              adminPriviages
            />
          </>
        )}

        {/* Start of preset template checkbox and input */}
        <HasPrivileges
          reqireMain={[Privilages.EDIT_ADMIN_USERS_PRIVILEGES]}
          allowBP={false}
        >
          <div className="d-flex flex-row align-items-center px-3 py-1 gap-10">
            <label className="checkbox">
              <input
                type={"checkbox"}
                name="action"
                value="2"
                checked={presetAction?.action == 2}
                onChange={handleInputChange}
              />
              <span></span>

              <div
                className={`
                ${"booleanInputLabelStyle"}
                ${presetAction?.action == 2 ? "lightActiveColor" : ""}
                `}
              >
                {t("save as a new role template")}
              </div>
            </label>

            {!!presetAction?.action ? (
              <div className="w-25">
                <input
                  type={"text"}
                  className={`input-style-default w-100 ${
                    privilegeTemplateModalValidatoin?.hasOwnProperty(
                      "input.presetAction.name"
                    )
                      ? "privilege-template-input-error"
                      : ""
                  }`}
                  placeholder={t("name")}
                  name="name"
                  value={presetAction?.name}
                  onChange={handleInputChange}
                />
              </div>
            ) : null}

            {privilegeTemplateModalValidatoin?.hasOwnProperty(
              "input.presetAction.name"
            ) && presetAction?.action ? (
              <div className="privilege-template-error">
                {privilegeTemplateModalValidatoin["input.presetAction.name"][0]}
              </div>
            ) : null}
          </div>
        </HasPrivileges>
        {/* End of preset template checkbox and input */}

        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.managePrivilagesModalActions,
  modalValidation: state.super[formNameValidation],
  managePrivilagesModal: state.super[formName],
  isLoading: state.super.editPrivileges,
  planRoles: state.super.planRoles,
  loadingRolePresetPrivileges: state.super.loadingRolePresetPrivileges,
});

export default connect(mapStateToProps, {
  dismissManagePrivilagesModalAction,
  upsertAdminUserPrivilegesAction,
  fetchRolePresetPrivilegesAction,
})(EditAdminPrivilagesModal);

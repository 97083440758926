import React from "react";
import {
  InputForm,
  CheckboxForm,
  BSelect,
  RadioboxForm,
  DateTimePickerForm,
} from "form-builder";
import Dropzone from "react-dropzone-uploader";
import { useDispatch, useSelector } from "react-redux";
import { inArray } from "jquery";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function ViewFromBuilder(props) {
  const {
    formName,
    formFields,
    formServerValidation,
    viewOnly = false,
  } = props;
  const dispatch = useDispatch();

  const serverError = useSelector((state) => state?.recruitment?.[formServerValidation])

  const renderElement = (
    element,
    validationNamePrefix,
    formName,
    formServerValidation,
    formValues,
    viewOnly
  ) => {

    let shouldRender = (dependOn, dependancyValue) => {
      if (Array.isArray(dependOn)) {
        return Boolean(
          dependancyValue.filter((el) => dependOn.includes(el)).length
        );
      }
      return dependancyValue.includes(dependOn);
    };

    const handleFileChange = (e, status, field) => {
      if (status == "done") {
        console.log(e.file)
        dispatch({
          type: "UPDATE_VALUE",
          payload: {
            formName,
            name: field?.id,
            value: e.file
          }
        })

        // console.log(e, status)
        // console.log(typeof (file))
      }
      if (status == "removed") {
        // console.log(file, status)
        dispatch({
          type: "UPDATE_VALUE",
          payload: {
            formName,
            name: field?.id,
            value: null
          }
        })
      }
      return;
    };
    switch (element.type) {
      case "text":
        return (
          <div style={{ minWidth: "200px" }} className={`mx-3 ${element.grid}`}>
            <InputForm
              {...element}
              skipLocalization
              disabled={viewOnly}
              name={element.id}
              label={element.name}
              reducer="recruitment"
              formName={formName}
              validationName={`${validationNamePrefix}.value`}
              formServerValidation={formServerValidation}
              labelStyle="mb-2"
              containerStyle="mt-0 mb-3"
              inputContainerStyle=" "
              {...(element?.parent_field_id && {
                dependOn: element?.parent_field_id,
                dependancyType: "equal",
                dependancyValue: element?.dependent_option_names?.map(
                  (opt) => opt.value
                ),
              })}
            />
            {(!!element?.description && !element?.parent_field_id) ||
              (element?.parent_field_id &&
                shouldRender(
                  formValues?.[element?.parent_field_id],
                  element?.dependent_option_names?.map((opt) => opt.value)
                )) ? <div className="notes-style"> {element?.description} </div> : null}
          </div>
        );
      case "checkbox":
        return (
          <div style={{ minWidth: "200px" }} className={`mx-3 ${element.grid}`}>
            <CheckboxForm
              {...element}
              disabled={viewOnly}
              name={element.id}
              key={element.id}
              label={element.name}
              reducer="recruitment"
              formName={formName}
              validationName={`${validationNamePrefix}.options_id`}
              formServerValidation={formServerValidation}
              containerStyle="my-2"
              labelStyle="label-style"
              type="checkbox"
              optionsContainerStyle="optionsContainerStyle gap-10"
              optionItemStyle="optionItemStyle my-2 fit-width"
              optionInputStyle="align-items-center d-flex fit-width flex-row optionInputStyle"
              optionLabelStyle="optionLabelStyle lineheight-initial text-wrap fit-width"
              // disabled
              options={element.options}
              {...(element?.parent_field_id && {
                dependOn: element?.parent_field_id,
                dependancyType: "equal",
                dependancyValue: element?.dependent_option_names?.map(
                  (opt) => opt.value
                ),
              })}
            />
            {(!!element?.description && !element?.parent_field_id) ||
              (element?.parent_field_id &&
                shouldRender(
                  formValues?.[element?.parent_field_id],
                  element?.dependent_option_names?.map((opt) => opt.value)
                )) ? <div className="notes-style"> {element?.description} </div> : null}
          </div>
        );
      case "date":
        return (
          <DateTimePickerForm
            value="  "
            {...element}
            disabled={viewOnly}
            key={element.id}
            label={element.name}
            reducer="recruitment"
            formName={formName}
            validationName={`${validationNamePrefix}.options_id`}
            formServerValidation={formServerValidation}
          // disabled
          />
        );
      case "dropList":
        return (
          <div style={{ minWidth: "200px" }} className={`mx-3 ${element.grid}`}>
            <BSelect
              {...element}
              isDisabled={viewOnly}
              name={element.id}
              key={element.id}
              label={element.name}
              reducer="recruitment"
              formName={formName}
              validationName={`${validationNamePrefix}.options_id`}
              formServerValidation={formServerValidation}
              optionLabel="label"
              optionValue="value"
              // disabled
              skipLocalization
              options={element.options}
              isMulti={element.selection_type?.toLowerCase() === "multiple"}
              {...(element?.parent_field_id && {
                dependOn: element?.parent_field_id,
                dependancyType: "equal",
                dependancyValue: element?.dependent_option_names?.map(
                  (opt) => opt.value
                ),
              })}
            />

            {(!!element?.description && !element?.parent_field_id) ||
              (element?.parent_field_id &&
                shouldRender(
                  formValues?.[element?.parent_field_id],
                  element?.dependent_option_names?.map((opt) => opt.value)
                )) ? <div className="notes-style"> {element?.description} </div> : null}
          </div>
        );
      case "radio":
        return (
          <div style={{ minWidth: "200px" }} className={`mx-3 ${element.grid}`}>
            <RadioboxForm
              {...element}
              disabled={viewOnly}
              key={element.id}
              name={element.id}
              label={element.name}
              reducer="recruitment"
              formName={formName}
              validationName={`${validationNamePrefix}.options_id`}
              formServerValidation={formServerValidation}
              containerStyle="my-2"
              labelStyle="label-style"
              type="radio"
              optionsContainerStyle="optionsContainerStyle gap-10"
              optionItemStyle="optionItemStyle my-2 fit-width"
              optionInputStyle="align-items-center d-flex fit-width flex-row optionInputStyle"
              optionLabelStyle="optionLabelStyle lineheight-initial text-wrap fit-width"
              options={element.options?.map((option) => ({
                ...option,
                optProps: {
                  disabled: viewOnly,
                },
              }))}
              {...(element?.parent_field_id && {
                dependOn: element?.parent_field_id,
                dependancyType: "equal",
                dependancyValue: element?.dependent_option_names?.map(
                  (opt) => opt.value
                ),
              })}
            />
            {(!!element?.description && !element?.parent_field_id) ||
              (element?.parent_field_id &&
                shouldRender(
                  formValues?.[element?.parent_field_id],
                  element?.dependent_option_names?.map((opt) => opt.value)
                )) ? <div className="notes-style"> {element?.description} </div> : null}
          </div>
        );
      case "attachment":
        return (
          <div style={{ minWidth: "200px" }} className={`mx-3 ${element.grid}`}>
            <div className="h-auto">
              <label className="label-style">{element.name}</label>

              {formValues?.[element?.id] && typeof (formValues?.[element?.id]) == "string" ? <div className="d-flex flex-row flex-wrap justify-content-between"> <a className="d-flex align-self-center" target={"_blank"} href={`${formValues?.[element?.id]}`}>attachment</a> {viewOnly ? null : <span role="button" onClick={(e) => handleFileChange(e, "removed", element)}><FontAwesomeIcon icon={faTimes} /></span>}</div>
                :
                <>
                  <Dropzone
                    disabled={viewOnly}
                    name={element.id}
                    multiple={false}
                    maxFiles={1}
                    inputContent={"attachments"}
                    onChangeStatus={(e, status) => handleFileChange(e, status, element)}
                  />
                  <div className="error_message_style my-2"><span className="error-color">{serverError[`${validationNamePrefix}.attached_file`]}</span></div>
                </>
              }
            </div>
            {(!!element?.description && !element?.parent_field_id) ||
              (element?.parent_field_id &&
                shouldRender(
                  formValues?.[element?.parent_field_id],
                  element?.dependent_option_names?.map((opt) => opt.value)
                )) ? <div className="notes-style"> {element?.description} </div> : null}
          </div>
        );

      default:
        return <div>{element.type}</div>;
    }
  };

  const formValues = useSelector((state) => state.recruitment?.[formName]);

  return (
    <div className="d-flex flex-row align-items-baseline flex-wrap">
      {formFields
        ?.sort((a, b) => a.order - b.order)
        ?.map((field, i) =>
          renderElement(
            field,
            `input.answers.${i}`,
            formName,
            formServerValidation,
            formValues,
            viewOnly
          )
        )}
    </div>
  );
}

export default ViewFromBuilder;

import React, { useEffect, useState } from "react";
import { InputForm } from "form-builder";
import { useTranslation } from "react-i18next";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import AddIcon from "@mui/icons-material/Add";
import ReactShowMoreText from "react-show-more-text";
import { useDispatch, useSelector } from "react-redux";
import {
  onFormResetAction,
  toggleAssetTypeModal,
  toggleUnscrappItemModal,
} from "../../../Store/Actions";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_SCRAPPED_ASSETS } from "../../../Graphql/query";
import DataTable from "react-data-table-component";
import Loader from "../../../Components/Loader";
import Pagination from "../../../Components/Pagination";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../../Components/IconButtonWithTooltip";
import Swal from "sweetalert2";
import { DELETE_ITEM } from "../../../Graphql/mutation";
import { showToast } from "../../../Helpers/HelperFns";
import { IconButton } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import UnscrappItemModal from "./UnscrappItemModal";
import Privilages from "../../../Constants/Privilages";

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const ScrappedAssets = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState(paginationInitState);

  const isModalOpen = useSelector(
    (state) => state?.assets?.unscrappItemModal?.isOpen
  );

  const [fetchScrappedAssets, { loading, data, refetch }] = useLazyQuery(
    GET_SCRAPPED_ASSETS,
    {
      variables: {
        page: pagination?.currentPage,
        input: {
          ...(!!search
            ? {
                filter: {
                  name: search,
                },
              }
            : {}),
        },
      },
      onCompleted: (data) => {
        setPagination(data?.scrappedItems?.paginatorInfo);
      },
    }
  );

  useEffect(() => {
    fetchScrappedAssets();
  }, []);

  const scrappedItems = data?.scrappedItems?.data ?? [];

  const handleRefetchData = () => {
    fetchScrappedAssets();
  };

  const [attemptDeleteItem, { loading: deleteItemLoading }] = useMutation(
    DELETE_ITEM,
    {
      onCompleted: (data) => {
        if (data?.deleteItem?.__typename == "GeneralException") {
          showToast("error", data?.deleteItem?.message);
          return;
        }
        handleRefetchData();
      },
      onError(err) {
        showToast(
          "error",
          err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
        );
      },
    }
  );

  const handleChange = (e) => {
    setSearch(e?.target?.value ?? "");
  };

  const handlePaginate = (page = pagination?.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleUnscrapp = (item) => {
    dispatch(toggleUnscrappItemModal({ isOpen: true, item }));
  };

  const handleDelete = (item) => {
    Swal.fire({
      icon: "warning",
      title: t("are you sure"),
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      customClass: {
        popup: "swal-warning-style",
        icon: "swal-icon",
        denyButton: "order-2",
        confirmButton: "order-3",
        htmlContainer: "attendance-type-alert-text",
      },
    }).then(async (values) => {
      if (values.isConfirmed) {
        attemptDeleteItem({
          variables: {
            id: item?.id,
          },
        });
      }
    });
  };

  const columns = [
    {
      name: t("name"),
      cell: (scrappedItem) => (
        <p className="m-0">
          {scrappedItem?.asset?.name} - {scrappedItem?.asset?.model}
        </p>
      ),
    },
    {
      name: t("type"),
      cell: (scrappedItem) => (
        <p className="m-0">{scrappedItem?.asset?.assetType?.name}</p>
      ),
    },
    {
      name: t("serial number"),
      selector: "serial_number",
    },
    {
      name: t("scrapping note"),
      selector: "note",
      cell: (row) => (
        <ReactShowMoreText lines={1}>
          {row?.note ?? "------------------"}
        </ReactShowMoreText>
      ),
    },
    {
      width: "100px",
      cell: (item) => (
        <div className="d-flex align-items-center gap-5">
          <HasPrivileges
            allowBP
            reqireMain={[Privilages.SCRAP_RESTORE_ASSET_UNITS]}
          >
            <IconButton onClick={() => handleUnscrapp(item)} size="small">
              <ReplayIcon />
            </IconButton>
          </HasPrivileges>

          <HasPrivileges allowBP reqireMain={[Privilages.DELETE_ASSET_UNITES]}>
            <RemoveIconButton onClick={() => handleDelete(item)} />
          </HasPrivileges>
        </div>
      ),
    },
  ];

  return (
    <div className="py-3">
      <InputForm
        placeholder={t("search")}
        containerStyle="w-100"
        icon="search"
        value={search}
        onChange={handleChange}
        rootStyle="w-100"
      />

      <DataTable
        noHeader
        data={scrappedItems}
        columns={columns}
        className="cards_table my-3"
        progressPending={loading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {isModalOpen ? (
        <UnscrappItemModal handleRefetch={handleRefetchData} />
      ) : null}
    </div>
  );
};

export default ScrappedAssets;

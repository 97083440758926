import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchEmailNotificationsAction,
  updateEmailNotificationsAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import Loader from "../../Components/Loader";
import EmailNotificationsList from "../../Components/EmailNotificationsList";

const EmailNotifications = (props) => {
  const { t } = useTranslation();
  let firstLoad = true;

  useEffect(() => {
    props?.fetchEmailNotificationsAction(firstLoad);
    return () => {};
  }, []);

  const handleEmailSettingsSwitchChange = (e) => {
    props?.updateEmailNotificationsAction(
      e.target.value,
      e.target.name,
      +e.target.checked
    );
  };

  useEffect(() => {
    return () => {};
  }, [props.isLoading]);
  if (props.isLoading) {
    return <Loader />;
  }

  return (
    <HasPrivileges allowBP reqireMain={[Privilages.CUSTOMIZE_NOTIFICATIONS]}>
      <div className="content col-12 email_notifications_wrapper_style ">
        <HasPrivileges
          allowBP
          altChildren={
            <>
              {/* <div className="col-6">
                <h2>{t("Notify Me When")}</h2>
              </div>

              <div className="col-6 d-flex justify-content-start ">
                <h3>{t("Email")}</h3>

                <h3 className="mx-2 px-2 px-2-rtl">{t("Mobile App")}</h3>
              </div> */}
              <div className="col-12 notification-drawer-header-card ">
                <div className="row">
                  <div className="col-6">
                    <h2>{t("Notify Me When")}</h2>
                  </div>

                  <div className="col-6  d-flex justify-content-around ">
                    <h3 className="col-6 px-0">{t("Email")}</h3>
                    <h3 className=" px-2-rtl col-6 px-0">{t("Mobile App")}</h3>
                  </div>
                </div>
              </div>

              {props?.emailNotificationsSettings
                ?.filter(({ manager_flag }) => !manager_flag)
                ?.map((emailNotificationsInfo) => (
                  <EmailNotificationsList
                    emailNotificationsInfo={emailNotificationsInfo}
                    handleEmailSettingsSwitchChange={
                      handleEmailSettingsSwitchChange
                    }
                  />
                ))}
            </>
          }
        ></HasPrivileges>

        <HasPrivileges
          allowBP
          altChildren={
            <>
              {props?.user?.isManager ? (
                <>
                  <div className="col-12">
                    <hr />
                  </div>

                  <div className="col-12 mt-1">
                    <h2>{t("Notify Me When someone I manage")}</h2>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          }
        >
          <>
            {props?.user?.isManager ? (
              <>
                <div className="col-12 notification-drawer-header-card ">
                  <div className="row">
                    <div className="col-6">
                      <h2>{t("Notify Me When someone I manage")}</h2>
                    </div>

                    <div className="col-6  d-flex justify-content-around ">
                      <h3 className="col-6 px-0">{t("Email")}</h3>
                      <h3 className=" px-2-rtl col-6 px-0">
                        {t("Mobile App")}
                      </h3>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        </HasPrivileges>

        {props?.user?.isManager ? (
          <>
            {props?.emailNotificationsSettings
              ?.filter(({ manager_flag }) => manager_flag)
              ?.map((emailNotificationsInfo) => (
                <EmailNotificationsList
                  emailNotificationsInfo={emailNotificationsInfo}
                  handleEmailSettingsSwitchChange={
                    handleEmailSettingsSwitchChange
                  }
                />
              ))}
          </>
        ) : (
          ""
        )}
      </div>
    </HasPrivileges>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.userProfile,
    emailNotificationsSettings: state?.super?.emailNotificationsSettings,
    isLoading: state?.super?.emailNotificationsSettingsLoading,
  };
};

export default connect(mapStateToProps, {
  fetchEmailNotificationsAction,
  updateEmailNotificationsAction,
})(EmailNotifications);

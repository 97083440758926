import React from "react";

import EmployeeSignInRecordInfo from "./EmployeeSignInRecordInfo";

const HistoryEmployeeCard = ({
  signInHasTooltip,
  signInTooltipTitle,
  signInTooltipStyle,
  signOutTooltipTitle,
  signOutTooltipStyle,
  durationHasTooltip,
  durationTooltipTitle,
  durationTooltipStyle,
  employeeRecord,
  isMyProfileHistory,
  ...props
}) => {
  return (
    <div className="parent-card-container">
      {/* start of employee card info */}
      <EmployeeSignInRecordInfo
        id={employeeRecord?.id}
        employeeRecord={employeeRecord}
        {...props}
        isMyProfileHistory={isMyProfileHistory}
        hideName={props?.isEmpProfileHistory || isMyProfileHistory}
        recordOrder={1}
      />
      {employeeRecord?.multiple_sign_ins?.map((childrenSignin, index) => (
        <EmployeeSignInRecordInfo
          id={childrenSignin?.id}
          employeeRecord={childrenSignin}
          {...props}
          isMyProfileHistory={isMyProfileHistory}
          hideName
          recordOrder={index + 2}
          includeOrder
        />
      ))}
      {/* end of employee card info */}
    </div>
  );
};

export default HistoryEmployeeCard;

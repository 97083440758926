import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateTimePickerForm, BSelect } from "form-builder";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// Ui
import MainModal from "../MainModal";
import BlockUi from "react-block-ui";

// Helpers
import { addMembersToWorkTeamMutation } from "../../Graphql/mutation";
import { fetchAddWorkTeamMembersModalQuery } from "../../Graphql/query";
import { onFormResetAction } from "../../Store/Actions";
import { handleActionMutation } from "../../Helpers/HelperFns";

//   form props
const formName = "addWorkTeamMembersForm";
const formNameValidation = "addWorkTeamMembersFormValidation";
const formServerValidation = "addWorkTeamMembersFormServerValidation";
const reducer = "shifts";

const AddWorkTeamMembersModal = ({
  isModalOpen,
  setIsModalOpen,
  workGroupId,
  fetchWokTeamEmployees,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();
  const { workTeamId } = useParams();

  const formProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
    reducer,
  };

  //   Redux hooks
  const dispatch = useDispatch();
  const addWorkTeamMembersForm = useSelector(
    (state) => state?.shifts?.[formName]
  );
  const formClientValidation = useSelector(
    (state) => state?.shifts?.[formNameValidation]
  );

  // Apollo hooks
  const { data: modalOptionsData, loading: fetchModalLoading } = useQuery(
    fetchAddWorkTeamMembersModalQuery,
    {
      variables: {
        workGroupId,
        workTeamId,
      },
    }
  );
  const [addMembersToWorkTeam, { loading: addMembersToWorkTeamLoading }] =
    useMutation(addMembersToWorkTeamMutation);

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
      dispatch(onFormResetAction(formServerValidation));
    };
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    if (formClientValidation?.length === 0) {
      setIsSubmitting(false);

      const { error } = await handleActionMutation(addMembersToWorkTeam, {
        variables: {
          input: {
            workTeamId,
            employeesIds: addWorkTeamMembersForm?.employeesIds ?? [],
            start:
              moment(addWorkTeamMembersForm?.start).format("YYYY-MM-DD") ?? "",
          },
        },
        isReturnUnion: true,
        refetchQuery: fetchWokTeamEmployees,
      });

      if (!error) {
        setIsModalOpen(false);
      }
    }
  };

  const filteredEmployees = modalOptionsData?.workGroup?.employees
    ?.filter((employee) => {
      const isEmployeeAlreadyMember =
        modalOptionsData?.teamEmployees?.data?.find(
          (workTeamEmployee) =>
            workTeamEmployee?.user?.id === employee?.user?.id
        );

      return !isEmployeeAlreadyMember;
    })
    .map((employee) => ({
      id: employee?.user?.id,
      name: employee?.user?.name,
    }));

  return (
    <MainModal
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(false)}
      modalTitle={t("add team members")}
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={addMembersToWorkTeamLoading}
    >
      <BlockUi tag="div" blocking={fetchModalLoading}>
        <DateTimePickerForm
          {...formProps}
          datePickerContainer="w-100"
          label="start date"
          containerStyle="flex-column align-items-start"
          labelStyle="custom-label-style mb-2"
          name="start"
          validateBy="textRequired"
          validationName="input.start"
        />
        <BSelect
          {...formProps}
          name="employeesIds"
          validateBy="textRequired"
          validationName="input.employeesIds"
          label={"employees"}
          keepDefaultStyle
          options={filteredEmployees ?? []}
          isMulti={true}
          optionLabel="name"
          optionValue="id"
          labelStyle="my-2"
          containerStyle="containerStyle flex-column align-items-start"
          inputContainerStyle="flex-grow-1 mr-0 w-100"
          skipLocalization
          icon="people"
        />
      </BlockUi>
    </MainModal>
  );
};

export default AddWorkTeamMembersModal;

import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

const PiechartPlaceholder = () => {
  return (
    <div className="card-body">
      <div className="d-flex flex-column flex-md-row justify-content-between chart-title">
        <Skeleton variant="text" width={100} height={30} />
        <div className="d-flex mr-0">
          <Skeleton variant="rectangular" width={120} height={25} />
          <div style={{ width: "20px" }} />
          <Skeleton variant="rectangular" width={120} height={25} />
        </div>
      </div>
      <Grid item>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Grid justify="center" alignItems="center">
            <Skeleton variant="circular" width={280} height={280} />
          </Grid>
          <Grid justify="center" alignItems="center">
            <Skeleton variant="text" width={120} height={30} />
            <div style={{ height: "20px" }} />
            <Skeleton variant="text" width={120} height={30} />
            <div style={{ height: "20px" }} />
            <Skeleton variant="text" width={120} height={30} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PiechartPlaceholder;

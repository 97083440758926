import { put, call, takeLatest, select } from "redux-saga/effects";
import Types from "../Actions/types";

import * as Action from "../Actions";
import * as Client from "../../Services/Client";

import HelperFns from "../../Helpers/HelperFns";

export const getAdminReducer = (state) => state.admin;

export function* handleFetchSystemPlansListSaga({ payload }) {
  try {
    const response = yield call(Client.fetchSystemPlansList, payload);
    if (!response.errors) {
      yield put(
        Action.fetchSystemPlansListSuccess(payload.ref, response.data.plans)
      );
    } else {
      yield put(Action.fetchSystemPlansListFailed(payload.ref));
      yield put(Action.showErrorToast());
    }
  } catch (error) {
    yield put(Action.fetchSystemPlansListFailed(payload.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchSystemPlansListSaga() {
  yield takeLatest(
    Types.FETCH_SYSTEM_PLANS_LIST_ATTEMPT,
    handleFetchSystemPlansListSaga
  );
}

export function* handleFetchSystemPrivilegesSaga({ payload }) {
  try {
    const response = yield call(Client.fetchSystemPrivileges, payload);
    if (!response.errors) {
      yield put(
        Action.fetchSystemPrivilegesSuccess(response.data.system_privileges)
      );
    } else {
      yield put(Action.fetchSystemPrivilegesFailed());
      yield put(Action.showErrorToast());
    }
  } catch (error) {
    yield put(Action.fetchSystemPrivilegesFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchSystemPrivilegesSaga() {
  yield takeLatest(
    Types.FETCH_SYSTEM_PRIVILEGES_ATTEMPT,
    handleFetchSystemPrivilegesSaga
  );
}

export function* handleSystemEditPlanSaga({ payload }) {
  try {
    const response = yield call(Client.editSystemPlan, payload);
    if (!response.errors) {
      yield put(
        Action.editSystemPlanSuccess(
          response.data.plan,
          response.data.system_privileges
        )
      );
    } else {
      yield put(Action.editSystemPlanFailed());
      yield put(Action.showErrorToast());
    }
  } catch (error) {
    yield put(Action.editSystemPlanFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onEditSystemPlanSaga() {
  yield takeLatest(Types.EDIT_SYSTEM_PLAN_ATTEMPT, handleSystemEditPlanSaga);
}

export function* handleFetchPlanRolesSaga({ payload }) {
  try {
    const response = yield call(Client.fetchPlanRoles, payload);
    if (!response.errors) {
      yield put(Action.fetchPlanRolesSuccess(response.data.plan));
    } else {
      yield put(Action.fetchPlanRolesFailed());
      yield put(Action.showErrorToast());
    }
  } catch (error) {
    yield put(Action.fetchPlanRolesFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchPlanRolesSaga() {
  yield takeLatest(Types.FETCH_PLAN_ROLES_ATTEMPT, handleFetchPlanRolesSaga);
}

export function* handleUpsertSystemPlanSaga({ payload, cb }) {
  try {
    const response = yield call(Client.upsertSystemPlan, payload);
    if (!response.errors) {
      yield put(
        Action.upsertSystemPlanSuccess(payload.ref, response?.data?.upsert_plan)
      );
      if (!!payload?.plan?.id) {
        yield put(Action.showSuccessToast());
      }

      yield call(cb, response?.data?.upsert_plan?.id);
    } else {
      if (response?.errors[0]?.extensions?.validation) {
        yield put(
          Action.upsertSystemPlanFailed(
            response?.errors[0]?.extensions?.validation
          )
        );
        return;
      }
      yield put(Action.upsertSystemPlanFailed());
      yield put(Action.showErrorToast());
    }
  } catch (error) {
    yield put(Action.upsertSystemPlanFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpsertSystemPlanSaga() {
  yield takeLatest(
    Types.UPSERT_SYSTEM_PLAN_ATTEMPT,
    handleUpsertSystemPlanSaga
  );
}

export function* handleUpsertRoleSaga({ payload }) {
  try {
    let response;
    if (payload.role?.isNewPlan) {
      response = yield call(Client.upsertRole, payload);
    } else {
      response = yield call(Client.upsertRoleTemplateToPlan, payload);
    }
    if (!response.errors) {
      yield put(Action.upsertRoleSuccess(response?.data?.role));
      if (payload.role?.isNewPlan) {
        yield put(Action.fetchPlanRolesAction(payload.role?.plan_id));
      } else {
        yield put(Action.fetchSystemPlanPage(payload.role?.plan_id));
      }
      yield put(Action.showSuccessToast());
    } else {
      if (response?.errors[0]?.extensions?.validation) {
        yield put(
          Action.upsertRoleFailed(response?.errors[0]?.extensions?.validation)
        );
        return;
      }
      yield put(Action.upsertRoleFailed());
      yield put(Action.showErrorToast());
    }
  } catch (error) {
    yield put(Action.upsertRoleFailed({}));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpsertRoleSaga() {
  yield takeLatest(Types.UPSERT_ROLE_ATTEMPT, handleUpsertRoleSaga);
}

export function* handleFetchPlanPageSaga({ payload }) {
  try {
    const response = yield call(Client.fetchPlanPage, payload);
    if (!response.errors) {
      yield put(Action.fetchSystemPlanPageSuccess(response.data.plan));
    } else {
      yield put(Action.fetchSystemPlanPageFailed());
      yield put(Action.showErrorToast());
    }
  } catch (error) {
    yield put(Action.fetchSystemPlanPageFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchPlanPageSaga() {
  yield takeLatest(
    Types.FETCH_SYSTEM_PLANE_PAGE_ATTEMPT,
    handleFetchPlanPageSaga
  );
}

export function* handleUpdatePlanStatusSaga({ payload }) {
  try {
    let adminReducer = yield select(getAdminReducer); // <-- get the inbound requests

    const response = yield call(Client.updatePlanStatus, payload);
    if (!response.errors) {
      yield put(Action.updatePlanStatusSuccess());
      if (payload.listView) {
        yield put(
          Action.fetchSystemPlansListAction({
            ref: "systemPlansList",
            systemPlansList: adminReducer?.systemPlansList,
          })
        );
      } else {
        yield put(Action.fetchSystemPlanPage(payload.planId));
      }
      yield put(Action.showSuccessToast());
    } else {
      yield put(Action.updatePlanStatusFailed());
      if (response?.errors[0]?.extensions?.validation?.["input.plan_id"]) {
        yield put(
          Action.showErrorToast(
            response?.errors[0]?.extensions?.validation?.[
              "input.plan_id"
            ]?.toString()
          )
        );
        return;
      }
      yield put(Action.showErrorToast());
    }
  } catch (error) {
    yield put(Action.updatePlanStatusFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onUpdatePlanStatusSaga() {
  yield takeLatest(
    Types.UPDTAE_PLAN_STATUS_ATTEMPT,
    handleUpdatePlanStatusSaga
  );
}

export function* handleDeletePlanSaga({ payload }) {
  try {
    let adminReducer = yield select(getAdminReducer); // <-- get the inbound requests

    const response = yield call(Client.deletePlan, payload);
    if (!response.errors) {
      yield put(Action.deletePlanSuccess());
      if (payload.listView) {
        yield put(
          Action.fetchSystemPlansListAction({
            ref: "systemPlansList",
            systemPlansList: adminReducer?.systemPlansList,
          })
        );
      } else {
        window.history.back();
      }
      yield put(Action.showSuccessToast());
    } else {
      yield put(Action.deletePlanFailed());
      if (response?.errors[0]?.extensions?.validation?.["input.id"]) {
        yield put(
          Action.showErrorToast(
            response?.errors[0]?.extensions?.validation?.[
              "input.id"
            ]?.toString()
          )
        );
        return;
      }
      yield put(Action.showErrorToast());
    }
  } catch (error) {
    yield put(Action.deletePlanFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeletePlanSaga() {
  yield takeLatest(Types.DELETE_SYSTEM_PLAN_ATTEMPT, handleDeletePlanSaga);
}

export function* handleDeleteRoleSaga({ payload }) {
  try {
    let response;
    if (payload.isNewPlan) {
      response = yield call(Client.deleteRole, payload);
    } else {
      response = yield call(Client.deleteRoleTemplate, payload);
    }
    if (!response.errors) {
      yield put(Action.deleteRoleSuccess());
      if (payload.isNewPlan) {
        yield put(Action.fetchPlanRolesAction(payload?.planId));
      } else {
        yield put(Action.fetchSystemPlanPage(payload.planId));
      }
      yield put(Action.showSuccessToast());
    } else {
      yield put(Action.deleteRoleFailed());
      if (response?.errors[0]?.extensions?.validation?.["input.id"]) {
        yield put(
          Action.showErrorToast(
            response?.errors[0]?.extensions?.validation?.[
              "input.id"
            ]?.toString()
          )
        );
        return;
      }
      yield put(Action.showErrorToast());
    }
  } catch (error) {
    yield put(Action.deleteRoleFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteRoleSaga() {
  yield takeLatest(Types.DELETE_ROLE_ATTEMPT, handleDeleteRoleSaga);
}

// handle Edit Role Modal

export function* handleFetchEditRoleSaga({ payload }) {
  try {
    const response = yield call(Client.fetchEditRole, payload);
    if (!response.errors) {
      yield put(
        Action.fetchEditRoleSuccess(
          response.data.plan_privileges,
          response.data?.role
        )
      );
    } else {
      yield put(Action.fetchEditRoleFailed());
      yield put(Action.showErrorToast());
    }
  } catch (error) {
    yield put(Action.fetchEditRoleFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchEditRoleSaga() {
  yield takeLatest(Types.FETCH_EDIT_ROLE_ATTEMPT, handleFetchEditRoleSaga);
}
// handle generta demo

export function* handleGenertaDemoSaga({ payload }) {
  try {
    const response = yield call(Client.generateDemo, payload);
    if (
      !response.errors &&
      response?.data?.generate_demo?.status === "success"
    ) {
      yield put(Action.generateDemoSuccess());
      yield put(Action.showSuccessToast());
    } else {
      yield put(Action.generateDemoFailed());
      yield put(
        Action.showErrorToast(response?.data?.generate_demo?.message)
      );
    }
  } catch (error) {
    yield put(Action.generateDemoFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onGenertaDemoSaga() {
  yield takeLatest(Types.GENERATE_DEMO_ATTEMPT, handleGenertaDemoSaga);
}

export function* handleFetchAdminHolidaysListSaga({ payload }) {
  try {
    const response = yield call(Client.fetchAdminHolidaysList, payload);
    if (!response?.errors) {
      yield put(
        Action.fetchAdminHolidaysSuccess(
          payload?.ref,
          response?.data?.admin_holidays
        )
      );
      yield put(
        Action.fetchAdminHoildaysCountriesOptionsAction(
          response?.data?.countries
        )
      );
    } else {
      yield put(Action.fetcAdminHolidaysFailed(payload?.ref));
      yield put(
        Action.showErrorToast(
          response.errors[0]?.extensions?.reason || response.errors[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(Action.fetcAdminHolidaysFailed(payload?.ref));
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onFetchAdminHolidays() {
  yield takeLatest(
    Types.FETCH_ADMIN_HOLIDAYS_LIST_ATTEMPT,
    handleFetchAdminHolidaysListSaga
  );
}

export function* handleUpsertAdminHolidaySaga({ payload }) {
  try {
    const response = yield call(Client.upsertAdminHoliday, payload?.data);
    if (!response?.errors) {
      yield put(Action.upsertAdminHolidaysSuccess(payload.ref));
      yield put(
        Action.dismissAdminHolidaysModalAction({
          ref: payload.ref,
          formName: "adminHolidaysModal",
        })
      );
      yield put(Action.showSuccessToast());
      let adminReducer = yield select(getAdminReducer);
      yield put(
        Action.fetchAdminHolidaysAction({
          ref: "adminHolidaysList",
          adminHolidaysList: adminReducer["adminHolidaysList"],
        })
      );
    } else {
      if (response?.errors[0]?.extensions?.validation) {
        yield put(
          Action.upsertAdminHolidaysFailed({
            ref: payload.ref,
            serverRef: "adminHolidaysModalServerValidation",
            errors: HelperFns.parseValidatorErrors(
              response?.errors[0]?.extensions?.validation
            ),
          })
        );
      } else {
        yield put(
          Action.upsertAdminHolidaysFailed({
            ref: payload.ref,
            serverRef: "adminHolidaysModalServerValidation",
            errors: {},
            modalMessage:
              response?.errors[0]?.extensions?.reason ??
              response?.errors[0]?.message,
          })
        );
      }
    }
  } catch (error) {
    yield put(
      Action.upsertAdminHolidaysFailed({
        ref: payload.ref,
        serverRef: "adminHolidaysModalServerValidation",
        errors: {},
        modalMessage:
          error.message ?? HelperFns.localizeFns("something went wrong"),
      })
    );
    console.log("err", error.response);
  }
}

function* onUpsertAdminHoliday() {
  yield takeLatest(
    Types.UPSERT_ADMIN_HOLIDAYS_ATTEMPT,
    handleUpsertAdminHolidaySaga
  );
}

export function* handleDeleteAdminHolidaysSaga({ payload }) {
  try {
    const response = yield call(Client.deleteAdminHolidays, payload);
    if (
      !response.errors &&
      response.data?.delete_admin_holiday?.status == "success"
    ) {
      yield put(Action.deleteAdminHolidaySuccess());
      yield put(
        Action.showSuccessToast(
          response.data?.delete_admin_holiday?.message
        )
      );
      let adminReducer = yield select(getAdminReducer);
      yield put(
        Action.fetchAdminHolidaysAction({
          ref: "adminHolidaysList",
          adminHolidaysList: adminReducer["adminHolidaysList"],
        })
      );
    } else {
      yield put(Action.deleteAdminHolidayFailed());
      yield put(
        Action.showErrorToast(
          response.data?.delete_admin_holiday?.message ||
            response.errors[0]?.extensions?.reason
        )
      );
    }
  } catch (error) {
    yield put(Action.deleteAdminHolidayFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}

function* onDeleteAdminHolidays() {
  yield takeLatest(
    Types.DELETE_ADMIN_HOLIDAYS_ATTEMPT,
    handleDeleteAdminHolidaysSaga
  );
}

export default {
  onFetchSystemPlansListSaga,
  onUpsertSystemPlanSaga,
  onFetchSystemPrivilegesSaga,
  onUpsertRoleSaga,
  onFetchPlanRolesSaga,
  onEditSystemPlanSaga,
  onFetchPlanPageSaga,
  onUpdatePlanStatusSaga,
  onDeletePlanSaga,
  onDeleteRoleSaga,
  onFetchEditRoleSaga,
  onFetchAdminHolidays,
  onUpsertAdminHoliday,
  onDeleteAdminHolidays,
  onGenertaDemoSaga,
};

import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import {
  BSelect,
  InputForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
  RadioboxForm,
} from "form-builder";
import { connect } from "react-redux";
import {
  dismissCompanyModalAction,
  upsertCompaniesAction,
  addBusinessPartnersInputAction,
  removeBusinessPartnersInputAction,
  childInputChange,
  updateCompaniesAction,
  deleteCompanyBussPartnersAction,
  onInputResetWithValueAction,
  updateEmployeeLimitValueAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import {
  faTimes,
  faPlus,
  faUserTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert";
import Loader from "../../Components/Loader";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import IntlTelInput from "react-intl-tel-input";
// import "../../Assets/sass/phone-input.scss";
import Tooltip from "@mui/material/Tooltip";
import validateAddingBusinessPartner from "./ValidateAddingBusinessPartnersFunction";
import Constants from "../../Constants";
import HelperFns, { openSwalConfirm } from "../../Helpers/HelperFns";
import { EditIconButton } from "../../Components/IconButtonWithTooltip";
import DataTable from "react-data-table-component";
import moment from "moment";

const formName = "companiesModal";
const formNameValidation = "companiesModalValidation";
const formServerValidation = "companiesModalServerValidation";

const CompanyModal = (props) => {
  const modalType = props.data.companiesModal.type;
  const numberOfPartners = props.data.companiesModal.partners_input.length;
  const numberOfEmployees = props.data.companiesModal.numberOfEmployees;
  const employeeLimits = props?.companiesModal?.employees_limit;
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPayrollCalcSecEdit, setIsPayrollCalcSecEdit] = useState(false);

  const [isForceSave, setIsForceSave] = useState(false);
  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  // Start of add/remove bussiness partners multiple inputs
  const handleAddBussinessPartnersInputs = (company_id) => {
    props.addBusinessPartnersInputAction(company_id);
  };

  const handleRemoveBussinessPartnersInputs = (index) => {
    props.removeBusinessPartnersInputAction(index);
  };

  const handleChildInputChange = (event, childName, index) => {
    props.childInputChange(
      formName,
      childName,
      event.target.name,
      index,
      event.target.value
    );
  };

  // End of add/remove bussiness partners multiple inputs

  useEffect(() => {
    Object.keys(props.serverVaildation).forEach((key, index) => {
      if (props.serverVaildation[key] == "phone already exists") {
        setIsForceSave(true);
      }
    });
  }, [JSON.stringify(props.serverVaildation)]);
  // Start of country code extention function action
  const handleUpdateCountryCode = (seletedCountryData, childName, index) => {
    let country_code = seletedCountryData.iso2;
    props.childInputChange(
      formName,
      childName,
      "phone_country_code",
      index,
      country_code
    );
  };

  const handlePhoneChange = (value, seletedCountryData, childName, index) => {
    let country_code = seletedCountryData.iso2;
    props.childInputChange(
      formName,
      childName,
      "phone_country_code",
      index,
      country_code
    );
    props.childInputChange(formName, childName, "phone", index, value);
  };
  // End of country code extention function action

  // delete bussiness partner
  const handleDeleteBussinessPartners = (id) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props?.deleteCompanyBussPartnersAction(id);
      }
    });
  };

  // handle employees limit according to plan selected

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }

    const {
      viewLogs,
      Leaves,
      Permissions,
      Salary,
      Grace,
      Overtime,
      new_start_year,
      new_start_of_month,
      new_start_of_week,
      change_action,
      year_start_month,
      month_start_day,
      week_day,
      first_day_of_month_selected_month,
      client_at,
      contract_duration,
      contract_amount,
      ...company
    } = props.companiesModal;
    const data = {
      ...company,
      force_save: isForceSave,
      ...(props.companiesModal?.type === "Add" &&
        company?.company_status === "client" && {
        client_at,
        contract_amount: +contract_amount,
        contract_duration: +contract_duration,
      }),
      allow_am_access: HelperFns.checkPrivileges({
        privileges: [Privilages.EDIT_COMPANY_SETTINGS],
      })
        ? props.companiesModal?.allow_am_access
        : undefined,

      ...(props.companiesModal?.type == "Add" && {
        year_start_month,
        month_start_day,
        week_day,
      }),

      ...(props.companiesModal?.type == "Edit" && {
        new_start_year: new_start_year || undefined,
        new_start_of_month: new_start_of_month || undefined,
        new_start_of_week: new_start_of_week || undefined,
        change_action,
        ...(change_action && {
          prorate: [
            ...(Leaves ? ["Leaves"] : []),
            ...(Salary ? ["Salary"] : []),
            ...(Permissions ? ["Permissions"] : []),
            ...(Grace ? ["Grace"] : []),
            ...(Overtime ? ["Overtime"] : []),
            
          ],
        }),
      }),
    };

    if (props.companiesModal?.type == "Edit") {
      if (
        new_start_year ||
        new_start_of_month ||
        new_start_of_week ||
        change_action
      ) {
        openSwalConfirm(
          (confirmed) =>
            confirmed &&
            props.updateCompaniesAction({
              data,
              ref: "companiesModalActions",
            })
        );
      } else {
        props.updateCompaniesAction({
          data,
          ref: "companiesModalActions",
        });
      }
    }
    if (props.companiesModal?.type == "Add") {
      props.upsertCompaniesAction({
        data,
        ref: "companiesModalActions",
      });
    }
  };

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const [isLogsOpen, setIsLogsOpen] = useState(false);

  const toggleModal = () => {
    props.dismissCompanyModalAction({ ref: "companiesModalActions", formName });
  };

  const handleInterceptChangeOfPayrollMonth = (value) => {
    props.onInputResetWithValueAction(
      formName,
      "new_start_of_month",
      `${moment(value).subtract(1, "month").format("YYYY/MM")}/20`
    );
  };

  useEffect(() => {
    setIsSubmitting(false);
    setIsPayrollCalcSecEdit(false);
    setIsForceSave(false);
  }, [isModalVissible]);

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={t(`${props.companiesModal?.type} Company`)}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="company_form_wrapper_style"
      btnLabel={isForceSave ? "Force Save" : "save"}
      btnColor={isForceSave ? "danger" : "primary"}
      size="lg"
    >
      {props?.fetchedBuissPartnersDataIsLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}
      <>
        <div className="row employee-form px-3">
          <div className="col-12 my-0">
            <h4 className="sub-title-style mb-1">{t("Company Info")}</h4>
            <hr />

            <div className="row mt-3 mb-2">
              <div className="col-12 mb-2">
                <InputForm
                  {...FormProps}
                  validateBy="textRequired"
                  name="company_name"
                  validationName={`input.company_input.name`}
                  placeholder={t("name")}
                  label="Company Name"
                  labelStyle="mb-2"
                  containerStyle="mt-0"
                  inputContainerStyle=" "
                  icon="person"
                />
              </div>

              <div className="col-12 mb-2">
                <BSelect
                  {...FormProps}
                  name="primarySAAS"
                  validationName={`input.primarySAAS`}
                  label="Assign Primary Account Manager"
                  labelStyle="mb-2"
                  keepDefaultStyle
                  optionLabel="name"
                  optionValue="id"
                  options={props?.data?.companyAccountManagers?.filter(
                    (manager) =>
                      !props?.companiesModal?.secondarySAAS?.includes(
                        manager?.id
                      )
                  )}
                  inputContainerStyle=""
                  placeholder={t("Select Primary Account Manager")}
                  icon="person"
                  rootStyle="flex-1"
                  validateBy="textRequired"
                />
              </div>

              <div className="col-12 mb-2">
                <BSelect
                  {...FormProps}
                  name="secondarySAAS"
                  validationName={`input.secondarySAAS`}
                  label="Assign Secondary Account Managers"
                  labelStyle="mb-2"
                  keepDefaultStyle
                  optionLabel="name"
                  optionValue="id"
                  options={props?.data?.companyAccountManagers?.filter(
                    (manager) =>
                      manager?.id !== props?.companiesModal?.primarySAAS
                  )}
                  isMulti={true}
                  inputContainerStyle=""
                  placeholder={t("Select Secondary Account Managers")}
                  icon="person"
                  rootStyle="flex-1"
                />
              </div>

              <div className="col-12 mb-2 d-flex gap-20 align-items-end">
                <BSelect
                  {...FormProps}
                  name="week_start_day"
                  validationName={`input.week_start_day`}
                  label="calendar week start day"
                  labelStyle="mb-2"
                  keepDefaultStyle
                  optionLabel="label"
                  optionValue="value"
                  options={Constants.CompanyModalWeekDays}
                  inputContainerStyle=""
                  rootStyle="flex-1"
                  placeholder={t("select day")}
                  icon="person"
                />
                <HasPrivileges reqireMain={[Privilages.EDIT_COMPANY_SETTINGS]}>
                  <CheckboxBooleanForm
                    {...FormProps}
                    setWithValue
                    name="allow_am_access"
                    options={["Allow account managers access to company"]}
                    validationName={`input.allow_am_access`}
                  />
                </HasPrivileges>
              </div>

              {props.companiesModal?.type == "Add" ? (
                <>
                  <BSelect
                    {...FormProps}
                    name="year_start_month"
                    validateBy="textRequired"
                    validationName={`input.year_start_month`}
                    label="first month of the year"
                    labelStyle="mb-2"
                    keepDefaultStyle
                    optionLabel="value"
                    optionValue="key"
                    options={Constants.MonthsData}
                    inputContainerStyle=""
                    rootStyle="col-6"
                    placeholder={t("select month")}
                    icon="calendar"
                  />
                  <BSelect
                    {...FormProps}
                    name="month_start_day"
                    validateBy="textRequired"
                    validationName={`input.month_start_day`}
                    label="Month Start Day"
                    labelStyle="mb-2"
                    keepDefaultStyle
                    options={Constants.payrollStartOptions}
                    inputContainerStyle=""
                    rootStyle="col-6"
                    placeholder={t("select day")}
                    icon="calendar"
                  />

                  <BSelect
                    {...FormProps}
                    name="week_day"
                    validateBy="textRequired"
                    validationName={`input.week_day`}
                    label="payroll week start day"
                    labelStyle="mb-2"
                    keepDefaultStyle
                    optionLabel="label"
                    optionValue="value"
                    options={Constants.CompanyModalWeekDays}
                    inputContainerStyle=""
                    rootStyle="col-6"
                    placeholder={t("select day")}
                    icon="calendar"
                  />
                  {/* <CheckboxBooleanForm
                  {...FormProps}
                  setWithValue
                  name="allow_multiple_offices_start"
                  rootStyle="col-6 d-flex align-items-end"
                  options={["Allow offices to override month start day"]}
                  validationName={`input.allow_multiple_offices_start`}
                /> */}
                </>
              ) : null}

              <HasPrivileges
                reqireMain={[
                  Privilages.SUPER_PRIVILEGE,
                  Privilages.EDIT_COMPANT_ACTIVITY_REPORT_DATA,
                ]}
              >
                <div className="col-12 mb-2 d-flex gap-20 align-items-end">
                  <div className="col-6">
                    <RadioboxForm
                      {...FormProps}
                      options={[
                        { label: "Default", value: "default" },
                        { label: "Exclude", value: "exclude" },
                        { label: "Custom", value: "custom" },
                      ]}
                      labelStyle="d-block font-weight-bold gray-color"
                      optionsContainerStyle="penalty-days-style fit-width flex-md-row flex-column"
                      optionItemStyle=" "
                      optionLabelStyle="mb-0 mr-3"
                      optionInputStyle=" "
                      containerStyle="d-flex flex-wrap align-items-center gap-20 my-2"
                      label="Activity Report status"
                      name="report_status"
                      validationName="input.report_status"
                    />
                  </div>
                  <div className="col-6">
                    <InputForm
                      {...FormProps}
                      type="number"
                      name="ratio"
                      validateBy="textRequired"
                      validationName={`input.subscription_input.ratio`}
                      label="ratio"
                      labelStyle="mb-2"
                      containerStyle="mt-0"
                      // rootStyle="flex-1"
                      inputContainerStyle=" "
                      placeholder={t("enter ratio")}
                      icon="percent"
                      dependOn="report_status"
                      dependancyType="equal"
                      dependancyValue={["custom"]}
                    />
                  </div>
                </div>
              </HasPrivileges>

              <hr className="hr_custom_style mx-3 my-2" />
            </div>
          </div>

          <div className="col-12  my-0">
            <div className="d-flex align-items-baseline">
              <div className="mb-2">
                <h4 className="sub-title-style mb-1">
                  {t("business partners")}
                </h4>
                <hr />
              </div>
              <div className="ml-3">
                <Tooltip
                  title={
                    !validateAddingBusinessPartner(
                      modalType,
                      numberOfPartners,
                      numberOfEmployees,
                      employeeLimits
                    )
                      ? t("you have reached the maximum number of employees")
                      : ""
                  }
                >
                  <div className="d-inline-block">
                    <button
                      className={`btn add_new_btn_style ${!validateAddingBusinessPartner(
                        modalType,
                        numberOfPartners,
                        numberOfEmployees,
                        employeeLimits
                      )
                        ? "pe-none"
                        : ""
                        }`}
                      type="button"
                      disabled={
                        !validateAddingBusinessPartner(
                          modalType,
                          numberOfPartners,
                          numberOfEmployees,
                          employeeLimits
                        )
                      }
                      onClick={() =>
                        handleAddBussinessPartnersInputs(
                          props?.companiesModal?.id
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </Tooltip>
              </div>
            </div>

            {props?.companiesModal?.partners_input?.map(
              (bussinessPartner, i) => (
                <>
                  <div className="row mt-2 mb-2">
                    <div className="col mb-2">
                      <InputForm
                        {...FormProps}
                        name="name"
                        validateBy="textRequired"
                        placeholder={t("name")}
                        value={bussinessPartner.name}
                        onChange={(e) =>
                          handleChildInputChange(e, "partners_input", i)
                        }
                        validationName={`input.partners_input.${i}.name`}
                        label="name"
                        labelStyle="mb-2"
                        containerStyle="mt-0"
                        inputContainerStyle=" "
                        icon="person"
                      />
                    </div>

                    <div className="col mb-2">
                      <div
                        className={
                          isSubmitting &&
                            props?.serverVaildation &&
                            `input.partners_input.${i}.phone` in
                            props?.serverVaildation
                            ? "invalid-container-style"
                            : " "
                        }
                      >
                        <label className="mb-2 validity-label-style">
                          {t("Phone Number")}
                        </label>
                        <div dir="ltr">
                          <IntlTelInput
                            inputClassName="input-style-default"
                            style={{ display: "flex" }}
                            fieldName={"phone"}
                            telInputProps={{
                              dir: "ltr",
                            }}
                            preferredCountries={["eg", "ae", "sa"]}
                            onPhoneNumberChange={(
                              isValid,
                              value,
                              seletedCountryData,
                              fullNumber,
                              extension,
                              event
                            ) => {
                              handlePhoneChange(
                                value,
                                seletedCountryData,
                                "partners_input",
                                i
                              );
                            }}
                            onSelectFlag={(
                              currentNumber,
                              seletedCountryData,
                              fullNumber,
                              isValid
                            ) => {
                              handleUpdateCountryCode(
                                seletedCountryData,
                                "partners_input",
                                i
                              );
                            }}
                            defaultCountry={bussinessPartner?.phone_country_code?.toLowerCase()}
                            separateDialCode
                            format={false}
                            formatOnInit={false}
                            value={bussinessPartner?.phone}
                          />
                        </div>
                        <div className="validity-msg-style">
                          {props?.serverVaildation &&
                            `input.partners_input.${i}.phone` in
                            props?.serverVaildation
                            ? props?.serverVaildation[
                            `input.partners_input.${i}.phone`
                            ]
                            : null}
                        </div>
                      </div>
                    </div>

                    <div className="col mb-2">
                      <InputForm
                        {...FormProps}
                        name="email"
                        placeholder={t("email")}
                        value={bussinessPartner.email}
                        onChange={(e) =>
                          handleChildInputChange(e, "partners_input", i)
                        }
                        validationName={`input.partners_input.${i}.email`}
                        label="email"
                        labelStyle="mb-2"
                        containerStyle="mt-0"
                        inputContainerStyle=" "
                        icon="mail"
                      />
                    </div>

                    {bussinessPartner.id != null ? (
                      <HasPrivileges
                        reqireMain={[
                          Privilages.SUPER_PRIVILEGE,
                          Privilages.DELETE_BUSINESS_PARTNERS,
                        ]}
                      >
                        <div className="mt-3 pt-3 pr-3">
                          <button
                            className="btn add_new_btn_style cancel_object_btn_style"
                            type="button"
                            onClick={() =>
                              handleDeleteBussinessPartners(
                                bussinessPartner?.id
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faUserTimes} />
                          </button>
                        </div>
                      </HasPrivileges>
                    ) : (
                      <div className="mt-3 pt-3 pr-3">
                        <button
                          className="btn add_new_btn_style cancel_object_btn_style"
                          type="button"
                          onClick={() => handleRemoveBussinessPartnersInputs(i)}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )
            )}

            {/* Start of bussiness partners section server validation */}
            <span className="warnig-msg-style">
              {props.serverVaildation[`input.partners_input`] ?? " "}
            </span>
            {/* End of bussiness partners section server validation */}

            {props.companiesModal?.type == "Add" ? (
              <hr className="hr_custom_style my-3" />
            ) : (
              ""
            )}
          </div>

          {props.companiesModal?.type == "Add" ? (
            <div className="col-12 my-0">
              <h4 className="sub-title-style mb-1">{t("Subscription")}</h4>
              <hr />

              <div className="row mt-3 mb-2">
                <div className="col-12 col-md-6 mb-2">
                  <BSelect
                    {...FormProps}
                    name="plan_id"
                    validateBy="textRequired"
                    validationName={`input.subscription_input.plan_id`}
                    label="Plan"
                    labelStyle="mb-2"
                    keepDefaultStyle
                    optionLabel="name"
                    optionValue="id"
                    options={props?.data?.companySubscriptionPlans}
                    inputContainerStyle=""
                    placeholder={t("Select Plan")}
                    getOptionLabel={(option) =>
                      `${option?.name} - ${option?.trial_value == null ? t("free") : t("paid")
                      }`
                    }
                    icon="type"
                  />
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <InputForm
                    {...FormProps}
                    type="number"
                    validateBy="textRequired"
                    name="employees_limit"
                    validationName={`input.subscription_input.employees_limit`}
                    placeholder={t("Enter limit")}
                    label="Employees limit"
                    labelStyle="mb-2"
                    containerStyle="mt-0"
                    inputContainerStyle=" "
                  />
                </div>

                {
                  // props?.companiesModal?.plan_id &&
                  // props?.data?.companySubscriptionPlans?.filter(
                  //   (subscribtion) =>
                  //     subscribtion?.id == props?.companiesModal?.plan_id
                  // )[0]?.trial_value == null
                  false ? null : (
                    <>
                      <div className="col-12 col-md-6 mb-2">
                        <BSelect
                          {...FormProps}
                          name="currency_id"
                          validateBy="textRequired"
                          validationName={`input.subscription_input.currency_id`}
                          label="Currency"
                          labelStyle="mb-2"
                          keepDefaultStyle
                          optionLabel="name"
                          optionValue="id"
                          options={props?.data?.companyCurrency}
                          inputContainerStyle=""
                          placeholder={t("select currency")}
                          icon="currency"
                        />
                      </div>

                      <div className="col-12 col-md-6 mb-2">
                        <InputForm
                          {...FormProps}
                          type="number"
                          validateBy="textRequired"
                          name="payment_amount"
                          validationName={`input.subscription_input.payment_amount`}
                          placeholder={t("Enter Amount")}
                          label="Payable Amount"
                          labelStyle="mb-2"
                          containerStyle="mt-0"
                          inputContainerStyle=" "
                          icon="money"
                        />
                      </div>
                    </>
                  )
                }

                <div className="col-12 col-md-6 mb-2">
                  <DateTimePickerForm
                    {...FormProps}
                    containerStyle="custom_datepicker_wrapper_style"
                    name="start_date"
                    validateBy="textRequired"
                    validationName={`input.subscription_input.start_date`}
                    label="start date"
                    labelStyle="mb-2"
                    hasIcon
                    minDate={new Date()}
                    requestFormat="yyyy-MM-DD"
                  />
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <DateTimePickerForm
                    {...FormProps}
                    containerStyle="custom_datepicker_wrapper_style"
                    name="end_date"
                    label="end date"
                    labelStyle="mb-2"
                    validationName={`input.subscription_input.end_date`}
                    validateBy="end_date"
                    dependTime="start_date"
                    hasIcon
                    minDate={new Date()}
                    requestFormat="yyyy-MM-DD"
                  />
                </div>
                <div className="col-12 col-md-6 mb-2">
                  <RadioboxForm
                    {...FormProps}
                    options={[
                      { label: "Trial", value: "trial" },
                      { label: "Client", value: "client" },
                    ]}
                    labelStyle="d-block font-weight-bold gray-color"
                    optionsContainerStyle="penalty-days-style fit-width flex-md-row flex-column"
                    optionItemStyle=" "
                    optionLabelStyle="mb-0 mr-3"
                    optionInputStyle=" "
                    containerStyle="d-flex flex-wrap align-items-center gap-20 my-2"
                    label="subscription status"
                    name="company_status"
                    validationName="input.subscription_input.company_status"
                  />
                </div>
                <div className="col-12 d-flex gap-10">
                  <DateTimePickerForm
                    {...FormProps}
                    rootStyle="flex-1"
                    containerStyle=" "
                    name="client_at"
                    label="client at"
                    labelStyle="mb-2"
                    validationName={`input.subscription_input.client_at`}
                    hasIcon
                    requestFormat="yyyy-MM-DD"
                    dependOn="company_status"
                    dependancyType="equal"
                    dependancyValue={["client"]}
                  />
                  <InputForm
                    {...FormProps}
                    name="contract_duration"
                    validateBy="textRequired"
                    validationName={`input.subscription_input.contract_duration`}
                    label="contract months duration"
                    labelStyle="mb-2"
                    rootStyle="flex-1"
                    containerStyle="mt-0"
                    inputContainerStyle=" "
                    placeholder={t("enter contract months duration")}
                    icon="time"
                    dependOn="company_status"
                    dependancyType="equal"
                    dependancyValue={["client"]}
                  />
                  <InputForm
                    {...FormProps}
                    name="contract_amount"
                    validateBy="textRequired"
                    validationName={`input.subscription_input.contract_amount`}
                    label="contract amount"
                    labelStyle="mb-2"
                    containerStyle="mt-0"
                    rootStyle="flex-1"
                    inputContainerStyle=" "
                    placeholder={t("enter contract amount")}
                    icon="money"
                    dependOn="company_status"
                    dependancyType="equal"
                    dependancyValue={["client"]}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {props.companiesModal?.canEditMonthStartDay &&
            props.companiesModal?.type == "Edit" ? (
            <div className="col-12">
              <div className="d-flex gap-20 align-items-center mb-3">
                <div className="">
                  <h4 className="sub-title-style mb-1">
                    {t("Payroll Calculation")}
                  </h4>
                  <hr />
                </div>

                {isPayrollCalcSecEdit ? null : (
                  <EditIconButton
                    onClick={() => setIsPayrollCalcSecEdit(true)}
                  />
                )}
              </div>

              {isPayrollCalcSecEdit ? (
                <EditableInputs
                  FormProps={FormProps}
                  first_day_of_month_selected_month={
                    props.companiesModal?.first_day_of_month_selected_month
                  }
                  handleInterceptChangeOfPayrollMonth={
                    handleInterceptChangeOfPayrollMonth
                  }
                />
              ) : (
                <div className="d-flex gap-20 justify-content-between">
                  <div>
                    <b className="d-block mb-2">
                      {t("first month of the year")}
                    </b>
                    <span>{props?.companiesModal?.year_start_month}</span>
                  </div>

                  <div>
                    <b className="d-block mb-2">{t("Month Start Day")}</b>
                    <span>{props?.companiesModal?.month_start_day}</span>
                  </div>

                  <div>
                    <b className="d-block mb-2">
                      {t("payroll week start day")}
                    </b>
                    <span>{props?.companiesModal?.week_day}</span>
                  </div>
                </div>
              )}

              <button
                className="view-change-logs-button-style"
                onClick={() => setIsLogsOpen((prev) => !prev)}
              >
                {t("view change logs")}
              </button>
              {isLogsOpen ? <Logs logs={props?.companiesModal?.logs} /> : null}
            </div>
          ) : null}

          {/* (Start) Error Message */}
          {modalMessage && isSubmitting && (
            <div className="warnig-msg-style">{modalMessage}</div>
          )}
          {/* (End) Error Message */}
        </div>
      </>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.companiesModalActions,
  modalValidation: state.super[formNameValidation],
  companiesModal: state.super[formName],
  serverVaildation: state.super[formServerValidation],
  data: state.super,
  fetchedBuissPartnersDataIsLoading:
    state?.super?.fetchedBuissPartnersDataIsLoading,
});

export default connect(mapStateToProps, {
  dismissCompanyModalAction,
  upsertCompaniesAction,
  addBusinessPartnersInputAction,
  removeBusinessPartnersInputAction,
  childInputChange,
  updateCompaniesAction,
  deleteCompanyBussPartnersAction,
  updateEmployeeLimitValueAction,
  onInputResetWithValueAction,
})(CompanyModal);

const EditableInputs = ({
  FormProps,
  first_day_of_month_selected_month,
  handleInterceptChangeOfPayrollMonth,
}) => {
  const { t } = useTranslation();

  const monthBefore = moment(first_day_of_month_selected_month, "MM-YYYY")
    .subtract(1, "month")
    .format("MM-YYYY");

  const currentMonth = moment(
    first_day_of_month_selected_month,
    "MM-YYYY"
  ).format("MM-YYYY");

  return (
    <div className="mt-2">
      <div className="d-flex gap-20 justify-content-between mb-2">
        <DateTimePickerForm
          {...FormProps}
          placeholder="Choose month"
          containerStyle="custom_datepicker_wrapper_style"
          name="new_start_year"
          validationName={`input.subscription_input.new_start_year`}
          label="first month of the year"
          labelStyle="mb-2"
          hasIcon
          picker={"month"}
          format="yyyy-MM"
          requestFormat="yyyy-MM"
          rootStyle="flex-1"
        />
        <DateTimePickerForm
          {...FormProps}
          containerStyle="custom_datepicker_wrapper_style"
          name="new_start_of_week"
          validationName={`input.subscription_input.new_start_of_week`}
          label="payroll week start day"
          labelStyle="mb-2"
          hasIcon
          requestFormat="yyyy-MM-DD"
          rootStyle="flex-1"
        />
      </div>
      <div className="d-flex gap-10 flex-1">
        <DateTimePickerForm
          {...FormProps}
          label="payroll month"
          containerStyle="custom_datepicker_wrapper_style mr-3"
          name="first_day_of_month_selected_month"
          hasIcon
          mode={"month"}
          picker={"month"}
          format={"MM-YYYY"}
          requestFormat={"MM-YYYY"}
          rootStyle="flex-1"
          onIntercept={handleInterceptChangeOfPayrollMonth}
        />
        <DateTimePickerForm
          {...FormProps}
          containerStyle="custom_datepicker_wrapper_style"
          name="new_start_of_month"
          validationName={`input.subscription_input.new_start_of_month`}
          label="Month Start Day"
          labelStyle="mb-2"
          hasIcon
          requestFormat="yyyy-MM-DD"
          rootStyle="flex-1"
          disabled={!first_day_of_month_selected_month}
          disabledDate={(date) => {
            const shouldDisable =
              moment(date).isBefore(
                moment(`20-${monthBefore}`, "DD-MM-YYYY"),
                "days"
              ) ||
              moment(date).isAfter(
                moment(`10-${currentMonth + 1}`, "DD-MM-YYYY"),
                "days"
              ) ||
              moment(date).format("DD") === "29" ||
              moment(date).format("DD") === "30" ||
              moment(date).format("DD") === "31" ||
              !!!first_day_of_month_selected_month;
            return shouldDisable;
          }}
        />
      </div>

      <b className="d-block mb-2">{t("Proration")}</b>
      <CheckboxBooleanForm
        {...FormProps}
        name="change_action"
        options={[
          "Prorate Current week/month/year according to the changes made",
        ]}
        validationName={`input.change_action`}
      />

      <div className="d-flex gap-20">
        <CheckboxBooleanForm
          {...FormProps}
          setWithValue
          name="Leaves"
          options={["Leaves"]}
          dependOn="change_action"
          dependancyType="equal"
          dependancyValue={[1]}
        />
        <CheckboxBooleanForm
          {...FormProps}
          setWithValue
          name="Permissions"
          options={["Permissions"]}
          dependOn="change_action"
          dependancyType="equal"
          dependancyValue={[1]}
        />
        <CheckboxBooleanForm
          {...FormProps}
          setWithValue
          name="Salary"
          options={["Salary"]}
          dependOn="change_action"
          dependancyType="equal"
          dependancyValue={[1]}
        />
        <CheckboxBooleanForm
          {...FormProps}
          setWithValue
          name="Grace"
          options={["Grace"]}
          dependOn="change_action"
          dependancyType="equal"
          dependancyValue={[1]}
        />
        <CheckboxBooleanForm
          {...FormProps}
          setWithValue
          name="Overtime"
          options={["Overtime"]}
          dependOn="change_action"
          dependancyType="equal"
          dependancyValue={[1]}
        />
      </div>
    </div>
  );
};

const Logs = ({ logs = [] }) => {
  const { t } = useTranslation();

  const columns = [
    { name: t("On"), selector: "created_at" },
    { name: t("Field"), selector: "type", grow: 1.5 },
    { name: t("old Value"), selector: "old_value" },
    { name: t("new Value"), selector: "new_value" },
    { name: t("start from"), selector: "from" },
    { name: t("Changed By"), selector: "user.name", grow: 1.5 },
  ];

  return <DataTable noHeader data={logs} columns={columns} />;
};

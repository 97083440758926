import React from "react";
// import { connect } from "react-redux";
import { makeStyles } from "tss-react/mui";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import i18n from "i18next";
import { useSelector } from "react-redux";
import Constants from "../../Constants";

const verticalNavbarStyles = makeStyles()(() => Constants.verticalNavbarStyles);
const horizontalNavbarStyles = makeStyles()(
  () => Constants.horizontalNavbarStyles
);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const RequestsTabs = (props) => {
  const history = useHistory();
  let query = useQuery();
  const activeValue = query.get("tab");
  const { classes } = props?.isRouteSidebar
    ? verticalNavbarStyles()
    : horizontalNavbarStyles();
  const handleChange = (event, newValue) => {
    history.push(`/requests?tab=${newValue}`);
  };

  const { t } = useTranslation();
  const isRtl =
    i18n.dir(useSelector((state) => state.user.userProfile.lng)) === "rtl"
      ? true
      : false;

  const possibleValues = [
    "inbound-requests",
    "claims",
    "request-rules",
    "approval-layers",
    "loan-requests",
  ];

  return (
    <>
      <Tabs
        variant="scrollable"
        value={possibleValues.includes(activeValue) ? activeValue : false}
        onChange={handleChange}
        orientation={props?.isRouteSidebar ? "vertical" : "horizontal"}
        scrollButtons="auto"
        classes={{
          root: classes.root,
          indicator: classes.indicator,
          flexContainer: classes.flexContainer,
          scrollButtons: classes.scrollButtons,
        }}
      >
        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_EMPLOYEE_REQUESTS],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value="inbound-requests"
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faHands"]}
                  className="mx-2 routeBar-icon"
                />
                {t("requests")}
              </div>
            }
          />
        )}
        
        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_LOAN_REQUEST],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value="loan-requests"
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faMoneyCheckAlt"]}
                  className="mx-2 routeBar-icon"
                />
                {t("loan requests")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_EMPLOYEE_CLAIMS],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value="claims"
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faHandHoldingUsd"]}
                  className="mx-2 routeBar-icon"
                />
                {t("claims")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [
            Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_ALL_DEPARTMENTS,
            Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_MY_DEPARTMENT,
          ],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value="request-rules"
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faHands"]}
                  className="mx-2 routeBar-icon"
                />
                {t("Request Rules")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_APPROVAL_LAYERS],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value="approval-layers"
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faCheckDouble"]}
                  className="mx-2 routeBar-icon"
                />
                {t("approval rules")}
              </div>
            }
          />
        )}

      </Tabs>
    </>
  );
};

export default RequestsTabs;

import * as Yup from "yup";

const hasNoOptsFields = ["text", "attachment"];

export const processFormFieldSchema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().when("apply_description", {
    is: true,
    then: Yup.string().required(),
  }),
  validation_type_ids: Yup.array().when("apply_validation", {
    is: true,
    then: Yup.array().required(),
  }),
  type: Yup.string().required(),

  selection_type: Yup.string().when("type", {
    is: "Drop List",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired().nullable(),
  }),

  options: Yup.array().when("type", {
    is: (type) => !hasNoOptsFields.includes(type),
    then: Yup.array().required(),
  }),

  dependent_option_names: Yup.array().when("parent_field_id", {
    is: (parent_field_id) => parent_field_id !== null,
    then: Yup.array().required(),
  }),
});

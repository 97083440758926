import gql from "graphql-tag";
import { formFieldFragment } from "../fragments/RecruitmentFragments";




export const getApplicantJobPostQuery = gql`
    query applicantJobPost($id: ID!) {
        applicant_job_post(id: $id) {
          intake_form{
            fields{
              ...formField
            }
          }
          intake_form_field{
             
            field{
              ...formField
            }
            applicants_job_posts_intake_form_answers{
              id 
              answer
              option_id
              attachment{
                id 
                path
              }
              
             
            }
          }
                id
                date_applied
                last_stage {
                  id
                  name
                }
                applicant {
                  id
                  attachment {
                    id
                    path
                  }
                }
                job_post {
                  id
                  title
                  process {
                    stages {
                      id
                      name
                      initial_flag
                      form {
                        id
                        fields {
                            ...formField
                        }
                      }
                    }
                  }
                }
                process_stages {
                  stage {
                    id
                  }
                  applicant_job_post_stage_form_fields {
                    applicant_jobPost_stage_form_field_id: id
                    field {
                      id
                    }
                    applicant_job_post_stage_form_answer {
                      id
                      answer
                      option_id
                      attachment{
                        id 
                        path
                      }
                    }
                  }
                }
              }    
             
        
}
${formFieldFragment}
`

import React from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
  setInputValidateAction,
} from "../../Store/Actions";
import { useFormValidation } from "../../Helpers/Hooks/useFormValidation";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { AccessAlarms } from "@mui/icons-material";
import { withDependency } from "../../Helpers/HOC/withDependency";
import "./DateTimePickerFormStyle.scss";
import Picker from "rc-picker";
import "rc-picker/assets/index.css";
import en from "rc-picker/lib/locale/en_US";
import ar from "rc-picker/lib/locale/ar_EG";
import generateConfig from "rc-picker/lib/generate/moment";
import { useTranslation } from "react-i18next";

const DateTimePickerForm = ({
  formName,
  name,
  formSubmitting,
  formNameValidation,
  validateBy,
  format,
  requestFormat,
  label,
  rootStyle = "",
  containerStyle,
  datePickerContainer,
  inputStyle,
  labelStyle,
  hasIcon = true,
  append,
  appendKey,
  dateTime,
  timeOnly,
  validateContainerStyle,
  validateMessage,
  reducer,
  weekSelect,
  startDate,
  endDate,
  formServerValidation,
  placeholder,
  openToDate,
  validationName = null,
  iconBackground = true,
  isClearable = false,
  onIntercept = () => undefined,
  ...props
}) => {
  let pickerFormat = format
    ? format
    : dateTime
    ? "DD/MM/yyyy hh:mm a"
    : timeOnly
    ? "hh:mm a"
    : "DD/MM/yyyy";
  requestFormat = requestFormat
    ? requestFormat
    : dateTime
    ? "yyyy/MM/DD HH:mm"
    : timeOnly
    ? "HH:mm"
    : "yyyy/MM/DD";

  const inputval = props.value ?? props.super?.[formName]?.[name];
  const isDisabled = props.disabled;
  const isServerValid = !Boolean(props.super?.[formServerValidation])
    ? false
    : props.super?.[formServerValidation]?.[validationName ?? name]
    ? true
    : false;

  const { t } = useTranslation();

  const validate = useFormValidation(
    inputval,
    validationName ?? name,
    formNameValidation,
    formSubmitting,
    validateBy,
    isDisabled,
    props.super?.[formName]?.[props.dependTime]
  );

  const weekRange = `${moment(startDate).format("DD/MM/yyyy")} - ${moment(
    endDate
  ).format("DD/MM/yyyy")}`;

  const handleDatePickerChange = (value) => {
    if (onIntercept(value,name)) return;
    let e;
    if (weekSelect && moment(value[0]).isValid()) {
      e = {
        target: {
          name,
          value: moment(value).format(requestFormat),
        },
      };
    } else if (moment(value).isValid() && openToDate) {
      const selectedTime = moment(value).format("HH:mm");
      const selectedDate = moment(openToDate).format("yyyy-MM-DD");
      e = {
        target: {
          name,
          value: moment(selectedDate + " " + selectedTime).format(
            requestFormat
          ),
        },
      };
    } else if (moment(value).isValid()) {
      e = {
        target: {
          name,
          value: moment(value).format(requestFormat),
        },
      };
    } else {
      e = {
        target: {
          name,
          value: null,
        },
      };
    }
    if (append) {
      props.onInputChangeAction(formName, e, "append", appendKey);
    } else {
      props.onInputChangeAction(formName, e);
    }
  };

  // const CustomIconInput = ({ value, onClick }) => (
  //   <div
  //     className={`${
  //       inputStyle ? inputStyle : "date-picker-input-default"
  //     } validity-input-style`}
  //   >
  //     <span style={{ padding: "1.5px 6px", backgroundColor: "#f5f5f5" }}>
  //       {timeOnly ? (
  //         <AccessAlarms
  //           style={{ fontSize: 14, color: "rgb(121 121 121 / 69%)" }}
  //         ></AccessAlarms>
  //       ) : (
  //         <DateRangeIcon
  //           style={{ fontSize: 14, color: "rgb(121 121 121 / 69%)" }}
  //         ></DateRangeIcon>
  //       )}
  //     </span>
  //     <button
  //       className={`form-control ${
  //         inputStyle
  //           ? inputStyle
  //           : `form-control p-0 ${!true ? "is-invalid" : ""}`
  //       }`}
  //       onClick={onClick}
  //       {...props}
  //     >
  //       {value
  //         ? weekSelect
  //           ? weekRange
  //           : value
  //         : placeholder
  //         ? placeholder
  //         : weekSelect
  //         ? "Choose Week"
  //         : timeOnly
  //         ? "Choose Time"
  //         : dateTime
  //         ? "Choose Date"
  //         : "Choose Day"}
  //     </button>
  //   </div>
  // );
  const vaildContainer = () => {
    if (!validate.validity || isServerValid) {
      return validateContainerStyle ?? "invalid-container-style";
    }
    return " ";
  };

  return (
    <div className={`${rootStyle} ${vaildContainer()}`}>
      <div
        className={`${
          containerStyle ? containerStyle : "container-style-default"
        }`}
      >
        {label && (
          <label
            className={`label-style-default ${
              labelStyle ? labelStyle : ""
            } validity-label-style`}
          >
            {t(label)}
          </label>
        )}
        <div
          className={`${
            datePickerContainer ? datePickerContainer : "ml-auto"
          } ${dateTime && "fix-datetimepicker-width"}`}
        >
          <Picker
            name={name}
            allowClear={isClearable}
            className={`${
              inputStyle ? inputStyle : "date-picker-input-default"
            } validity-input-style`}
            dropdownClassName="def-date-picker-dropdown"
            onChange={(e) => handleDatePickerChange(e)}
            onSelect={(e) => handleDatePickerChange(e)}
            {...(dateTime && {
              showTime: true,
            })}
            {...(timeOnly && {
              showTime: true,
              picker: "time",
            })}
            use12Hours
            showSecond={false}
            generateConfig={{ ...generateConfig }}
            locale={
              document.documentElement.lang?.includes("ar")
                ? { ...ar, locale: "ar-custome" }
                : en
            }
            format={pickerFormat}
            value={
              Boolean(props.super?.[formName]?.[name])
                ? moment(props.super?.[formName]?.[name], requestFormat)
                : null
            }
            suffixIcon={timeOnly ? <AccessAlarms /> : <DateRangeIcon />}
            placeholder={
              placeholder
                ? placeholder
                : weekSelect
                ? "Choose Week"
                : timeOnly
                ? t("select time")
                : dateTime
                ? t("select date")
                : t("choose day")
            }
            {...props}
          />
        </div>
      </div>
      <div className="validity-msg-style">
        {validateMessage ? validateMessage : validate.validityMessage}

        {validate.validityMessage && <br />}
        {Boolean(props.super[formServerValidation])
          ? props.super[formServerValidation][validationName ?? name] ?? ""
          : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state, { reducer = "super" }) => {
  return {
    super: state[reducer],
  };
};

export default connect(mapStateToProps, {
  onInputChangeAction,
  onInputResetAction,
  setInputInValidateAction,
  setInputValidateAction,
})(withDependency(DateTimePickerForm));

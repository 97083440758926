import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InputForm,
  CheckboxBooleanForm,
  RadioboxForm,
  BSelect,
} from "form-builder";
import Const from "../../../Helpers/Constants";
import Constants from "../../../Constants";

import { useTranslation } from "react-i18next";

import { components } from "react-select/dist/react-select.cjs.prod";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { onFormResetAction } from "../../../Store/Actions";
import useDidUpdateEffect from "../../../Helpers/Hooks/useDidUpdate";

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <DateRangeIcon className="b-select-style_select_icon" />
      {children}
    </components.Control>
  );
};

const weightedDeservedAgainstOptions = [
  { label: "Weighted Normal Shifts", name: "weightedNormalShifts" },
  { label: "paid leaves", name: "paidLeaves" },
  { label: "Days Off", name: "daysOff" },
  { label: "holidays", name: "holidays" },
  { label: "Absence", name: "absence" },
  { label: "unpaid leaves", name: "unpaidLeaves" },
];
const hoursDeservedAgainstOptions = [
  { label: "Normal Paid Hours", name: "normalPaidHours" },
  { label: "Overtime Hours", name: "overtimeHours" },
  { label: "Paid Leave Hours", name: "paidLeaveHours" },
];

const prepaidAddLeavesAt = [
  {
    label: "beginning of the month",
    value: "startOfMonth",
  },
  {
    label: "end of the month",
    value: "endOfMonth",
  },
];
const accrualAddLeavesAt = [
  {
    label: "On reaching the weight to earn one leave day",
    value: "onReachingTheTarget",
  },
  {
    label: "monthly",
    value: "startOfMonth",
  },
  {
    label: "weekly",
    value: "startOfWeek",
  },
];

const AnnualLeavesSection = ({ formProps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // redux hooks
  const leavesBreaksForm = useSelector((state) => state.super.leavesBreaksForm);

  const isAnnualLeavesAllowedInLeaveBreakProfile = useSelector(
    (state) => state?.super?.isAnnualLeavesAllowedInLeaveBreakProfile
  );

  // Constants
  const isPrepaid = leavesBreaksForm?.leave_basis === "annual";

  const isEditing =
    !!leavesBreaksForm?.id &&
    isAnnualLeavesAllowedInLeaveBreakProfile &&
    !isPrepaid;

  /* ↓ State Effects ↓ */

  useDidUpdateEffect(() => {
    if (!leavesBreaksForm?.allow_annual_leaves) {
      dispatch(
        onFormResetAction(formProps?.formName, {
          ...leavesBreaksForm,
          allowed_annual_leaves: "",
          annual_leave_cuttoff: "",
          deserved_against: [],
          allow_emergency: 0,
          add_leaves_every: "year",
          allowed_emergency_leaves: "",
          include_weekends_and_holidays_in_annual: 1,
          leave_basis: "annual",
          total_weighted_days: "365",
          deduct_leaves_against_unpaid_leaves: 0,
          deduct_leaves_against_paid_leaves: 0,
          deduct_leaves_against_absent_days: 0,
          request_leaves: 0,
          remaining_balance_option_id: "3",
          allow_max_number_of_leaves_to_be_rolled: 0,
          leave_basis_type: "weightedDays",
          holiday_hours_count: "",
          day_off_hours_count: "",
          start_week_day_number: "",
        })
      );
    }
  }, [leavesBreaksForm?.allow_annual_leaves]);

  /* ↓ Helpers ↓ */

  const onSelectAccrueBy = () => {
    dispatch(
      onFormResetAction(formProps?.formName, {
        ...leavesBreaksForm,
        deserved_against: [],
      })
    );
  };

  const onSelectDeservedAgainst = (event) => {
    let deserved_against = leavesBreaksForm.deserved_against;
    const checked = Boolean(
      leavesBreaksForm.deserved_against.find(
        (d) => d?.name === event.target.name
      )
    );

    if (checked) {
      deserved_against = deserved_against.filter(
        (d) => d?.name !== event.target.name
      );
    } else {
      deserved_against = [...deserved_against, { name: event.target.name }];
    }

    dispatch(
      onFormResetAction(formProps?.formName, {
        ...leavesBreaksForm,
        deserved_against,
      })
    );
  };

  return (
    <>
      <CheckboxBooleanForm
        {...formProps}
        options={["Allow employees to request annual leaves"]}
        name="allow_annual_leaves"
        validationName="input.annual_leave_setting.allow_annual_leaves"
        containerStyle="leaves_checkbox_container mb-2"
        setWithValue
        checkedValue={true}
        unCheckedValue={false}
        disabled={isEditing}
      />
      {leavesBreaksForm?.allow_annual_leaves ? (
        <div>
          <label className="label-style-default mt-2 mb-1">
            {t("Leave Basis")}
          </label>
          <RadioboxForm
            {...formProps}
            name="leave_basis"
            validationName="input.annual_leave_setting.leave_basis"
            validateBy="textRequired"
            options={[
              {
                label: t("Prepaid Basis"),
                value: "annual",
              },
              {
                label: t("Accrual Basis"),
                value: "accrual",
              },
            ]}
            containerStyle="leaves_radio_container"
            optionLabelStyle="leaves_radio_label"
            optionLabelActiveStyle=""
            optionInputStyle=" "
          />
          <RadioboxForm
            {...formProps}
            name="leave_basis_type"
            label="Accrue By"
            validationName="input.annual_leave_setting.leave_basis_type"
            validateBy="textRequired"
            options={[
              {
                label: t("Weighted Days"),
                value: "weightedDays",
              },
              {
                label: t("Hours Worked"),
                value: "workingHours",
              },
            ]}
            containerStyle="leaves_radio_container my-2"
            optionLabelStyle="leaves_radio_label"
            optionLabelActiveStyle=""
            optionInputStyle=" "
            dependOn="leave_basis"
            dependancyType="equal"
            dependancyValue={["accrual"]}
            interceptChange={onSelectAccrueBy}
          />
          <RadioboxForm
            {...formProps}
            name="add_leaves_every"
            label="Add Leave Balance"
            validationName="input.annual_leave_setting.add_leaves_every"
            validateBy="textRequired"
            options={[
              {
                label: t("monthly"),
                value: "month",
              },
              {
                label: t("annually"),
                value: "year",
              },
            ]}
            containerStyle="leaves_radio_container my-2"
            optionLabelStyle="leaves_radio_label"
            optionLabelActiveStyle=""
            optionInputStyle=" "
            dependOn="leave_basis"
            dependancyType="equal"
            dependancyValue={["annual"]}
          />

          <InputForm
            {...formProps}
            validateBy="textRequired"
            label={isPrepaid ? "Leave Balance" : undefined}
            name="allowed_annual_leaves"
            validationName="input.annual_leave_setting.allowed_annual_leaves"
            type="text"
            hasSuffix
            suffixStyle="w-100"
            suffixTitle={
              isPrepaid
                ? "Days"
                : leavesBreaksForm?.leave_basis_type === "weightedDays"
                ? "In weight"
                : "Hours"
            }
            containerStyle="leaves_field_container mt-2"
            inputContainerStyle="d-flex align-items-center"
          />

          <div className="d-flex gap-10">
            <RadioboxForm
              {...formProps}
              name="add_leaves_at"
              label={isPrepaid ? "Add At" : "Accrue On"}
              validationName="input.annual_leave_setting.add_leaves_at"
              validateBy="textRequired"
              options={
                isPrepaid
                  ? prepaidAddLeavesAt?.map((opt) => ({
                      ...opt,
                    }))
                  : accrualAddLeavesAt?.map((opt) => ({
                      ...opt,
                    }))
              }
              optionItemStyle="mr-3"
              containerStyle="leaves_radio_container"
              optionLabelStyle="leaves_radio_label"
              optionLabelActiveStyle=""
              optionInputStyle=" "
              {...(isPrepaid && {
                dependOn: "add_leaves_every",
                dependancyType: "equal",
                dependancyValue: ["month"],
              })}
            />
            <BSelect
              {...formProps}
              dependOn="add_leaves_at"
              dependancyType="equal"
              dependancyValue={["startOfWeek"]}
              placeholder={t("select week day")}
              name="start_week_day_number"
              components={{ Control }}
              validationName="input.settle_leaves.start_week_day_number"
              keepDefaultStyle
              options={Constants.WeekDays}
              optionLabel="label"
              optionValue="value"
              validateBy="textRequired"
              rootStyle="leaves_select_root"
              containerStyle="leaves_select_container mt-4"
            />
          </div>

          {/* <RadioboxForm
            {...formProps}
            name="annual_leaves_deserved_against"
            label="Annual Leaves Are Deserved Against"
            labelStyle="label-style-default mt-3 mb-1"
            validationName="input.annual_leave_setting.annual_leaves_deserved_against"
            validateBy="textRequired"
            options={[
              {
                label: t("Work Days / Holidays/ Paid Leaves"),
                value: "work_day",
              },
              {
                label: t("Calendar Week"),
                value: "calendar_week",
              },
            ]}
            containerStyle="leaves_radio_container"
            optionLabelStyle="leaves_radio_label"
            optionLabelActiveStyle=""
            optionInputStyle=" "
            dependOn="leave_basis"
            dependancyType="equal"
            dependancyValue={["accrual"]}
          /> */}

          <InputForm
            {...formProps}
            validateBy="textRequired"
            label="Employee must submit leave request before the start of work day by"
            name="annual_leave_cuttoff"
            validationName="input.annual_leave_setting.annual_leave_cuttoff"
            type="text"
            hasSuffix
            suffixTitle="Hours"
            containerStyle="leaves_field_container mt-2"
            inputContainerStyle="d-flex align-items-center"
          />

          <div className="row justify-content-between px-3 mt-3">
            <CheckboxBooleanForm
              {...formProps}
              options={["Include Credit For Emergency"]}
              name="allow_emergency"
              // validationName="input.annual_leave_setting.allowed_emergency_leaves"
              type="checkbox"
              containerStyle="leaves_checkbox_container"
              disabled={isEditing}
            />

            <InputForm
              {...formProps}
              validateBy="textRequired"
              dependOn="allow_emergency"
              dependancyType="equal"
              dependancyValue={[1]}
              name="allowed_emergency_leaves"
              validationName="input.annual_leave_setting.allowed_emergency_leaves"
              type="text"
              hasSuffix
              suffixTitle="Days"
              containerStyle="leaves_field_container justify-content-end"
              inputContainerStyle="d-flex align-items-center"
            />
          </div>

          <div className="notes-style h2 my-3 fit-width">
            {t(
              "Emergency credit is a portion of the employee annual leaves that they can request regardless of the set time limit that applies on annual leaves"
            )}
          </div>

          <CheckboxBooleanForm
            {...formProps}
            options={[
              "Include Holidays and Weekends When Calculating Normal Leave Durations",
            ]}
            name="include_weekends_and_holidays_in_annual"
            validationName="input.annual_leave_setting.include_weekends_and_holidays_in_annual"
            type="checkbox"
            containerStyle="leaves_checkbox_container mb-2"
            disabled={isEditing}
          />

          <h4 className="sub-title-style mt-3">
            {t(
              isPrepaid
                ? "Deduct from leaves against"
                : "Leaves Are Deserved Against"
            )}
          </h4>

          <div className="d-flex gap-20">
            <CheckboxBooleanForm
              {...formProps}
              options={["unpaid leaves"]}
              name="deduct_leaves_against_unpaid_leaves"
              validationName="input.annual_leave_setting.deduct_leaves_against_unpaid_leaves"
              type="checkbox"
              containerStyle="leaves_checkbox_container mb-2"
              dependOn="leave_basis"
              dependancyType="equal"
              dependancyValue={["annual"]}
              disabled={isEditing}
            />
            <CheckboxBooleanForm
              {...formProps}
              options={["paid leaves"]}
              name="deduct_leaves_against_paid_leaves"
              validationName="input.annual_leave_setting.deduct_leaves_against_paid_leaves"
              type="checkbox"
              containerStyle="leaves_checkbox_container mb-2"
              dependOn="leave_basis"
              dependancyType="equal"
              dependancyValue={["annual"]}
              disabled={isEditing}
            />
            <CheckboxBooleanForm
              {...formProps}
              options={["absent days"]}
              name="deduct_leaves_against_absent_days"
              validationName="input.annual_leave_setting.deduct_leaves_against_absent_days"
              type="checkbox"
              containerStyle="leaves_checkbox_container mb-2"
              dependOn="leave_basis"
              dependancyType="equal"
              dependancyValue={["annual"]}
              disabled={isEditing}
            />
          </div>

          {isPrepaid ? null : (
            <div className="d-flex flex-wrap gap-10">
              {(leavesBreaksForm?.leave_basis_type === "weightedDays"
                ? weightedDeservedAgainstOptions
                : hoursDeservedAgainstOptions
              ).map((opt) => {
                const checked = Boolean(
                  leavesBreaksForm.deserved_against.find(
                    ({ name }) => name === opt.name
                  )
                );
                return (
                  <label
                    key={opt.name}
                    className="d-flex align-items-center gap-5 checkbox"
                  >
                    <input
                      type="checkbox"
                      name={opt.name}
                      checked={checked}
                      onChange={onSelectDeservedAgainst}
                      // disabled={isEditing}
                    />
                    <span />
                    <b
                      className={
                        checked ? "lightActiveColor" : "booleanInputLabelStyle"
                      }
                    >
                      {t(opt.label)}
                    </b>
                  </label>
                );
              })}
            </div>
          )}

          <InputForm
            {...formProps}
            validateBy="textRequired"
            label="Number of hours per 1 day of holiday"
            name="holiday_hours_count"
            validationName="input.annual_leave_setting.holiday_hours_count"
            hasSuffix
            suffixTitle="Hours"
            containerStyle="leaves_field_container mt-2"
            inputContainerStyle="d-flex align-items-center"
            dependOn="leave_basis_type"
            dependancyType="equal"
            dependancyValue={["workingHours"]}
          />
          <InputForm
            {...formProps}
            validateBy="textRequired"
            label="Number of hours per 1 day off"
            name="day_off_hours_count"
            validationName="input.annual_leave_setting.day_off_hours_count"
            hasSuffix
            suffixTitle="Hours"
            containerStyle="leaves_field_container mt-2"
            inputContainerStyle="d-flex align-items-center"
            dependOn="leave_basis_type"
            dependancyType="equal"
            dependancyValue={["workingHours"]}
          />

          <InputForm
            {...formProps}
            validateBy="textRequired"
            label="Total Weighted Days"
            name="total_weighted_days"
            validationName="input.annual_leave_setting.total_weighted_days"
            hasSuffix
            suffixTitle="Days"
            containerStyle="leaves_field_container mt-2"
            inputContainerStyle="d-flex align-items-center"
            dependOn="leave_basis"
            dependancyType="equal"
            dependancyValue={["annual"]}
            // disabled={isEditing}
          />

          <h4 className="sub-title-style mt-3">
            {t("Leaves During Probation Period")}
          </h4>

          <CheckboxBooleanForm
            {...formProps}
            options={[
              "Employee can't request leaves during their probation period",
            ]}
            name="request_leaves"
            validationName="input.probation_period.request_leaves"
            type="checkbox"
            containerStyle="leaves_checkbox_container mb-2"
            disabled={isEditing}
          />

          <h4 className="sub-title-style mt-3">
            {t("Settle End Of Year Annual leave Balance")}
          </h4>

          <div>
            <label className="label-style-default mb-1">
              {t("Remaining Balance at the end of the year will")}
            </label>

            <RadioboxForm
              {...formProps}
              name="remaining_balance_option_id"
              validationName="input.settle_leaves.remaining_balance_option_id"
              options={[
                {
                  label: t("Rollover Remaing Balance To Next Year"),
                  value: "1",
                  optProps: {
                    disabled: isEditing,
                  },
                },
              ]}
              containerStyle="leaves_radio_container"
              optionLabelStyle="leaves_radio_label"
              optionLabelActiveStyle=""
              optionInputStyle=" "
            />
            <CheckboxBooleanForm
              {...formProps}
              options={["Rollover Balance Automatically"]}
              name="rollover_balance_automatic"
              validationName="input.settle_leaves.rollover_balance_automatic"
              type="checkbox"
              dependOn="remaining_balance_option_id"
              dependancyType="equal"
              dependancyValue={["1"]}
              containerStyle="leaves_checkbox_container ml-3"
              disabled={isEditing}
            />

            <InputForm
              {...formProps}
              validateBy="textRequired"
              label="rolled over leave balance should expire after "
              name="rollover_expires_after"
              validationName="input.settle_leaves.rollover_expires_after"
              type="text"
              hasSuffix
              suffixTitle={t("months")}
              dependOn="rollover_balance_automatic"
              dependancyType="equal"
              dependancyValue={[1]}
              containerStyle="leaves_field_container"
              inputContainerStyle="d-flex align-items-center"
              labelStyle="font-weight-bold"
              rootStyle="py-2 mx-3"
            />

            <div className="row justify-content-between align-items-center ml-3">
              <CheckboxBooleanForm
                {...formProps}
                options={[
                  "Set a maximum number of leaves to be rolled over to next year",
                ]}
                name="allow_max_number_of_leaves_to_be_rolled"
                validationName="input.settle_leaves.max_number_of_leaves_to_be_rolled"
                type="checkbox"
                dependOn="rollover_balance_automatic"
                dependancyType="equal"
                dependancyValue={[1]}
                containerStyle="leaves_checkbox_container"
                disabled={isEditing}
              />
              <InputForm
                {...formProps}
                validateBy="textRequired"
                dependOn="allow_max_number_of_leaves_to_be_rolled"
                dependancyType="equal"
                dependancyValue={[1]}
                name="max_number_of_leaves_to_be_rolled"
                validationName="input.settle_leaves.max_number_of_leaves_to_be_rolled"
                type="text"
                hasSuffix
                suffixTitle={t("days")}
                containerStyle="leaves_field_container mb-0"
                inputContainerStyle="d-flex align-items-center"
                disabled={isEditing}
              />
            </div>

            <RadioboxForm
              {...formProps}
              name="remaining_balance_option_id"
              options={[
                {
                  label: t("Zero Balance"),
                  value: "3",
                  optProps: {
                    disabled: isEditing,
                  },
                },
                {
                  label: t("Zero Remiaining Balance and Convert to Bonus Days"),
                  value: "2",
                  optProps: {
                    disabled: isEditing,
                  },
                },
              ]}
              containerStyle="leaves_radio_container"
              optionsContainerStyle="d-flex flex-column"
              optionLabelStyle="leaves_radio_label"
              optionLabelActiveStyle=""
              optionInputStyle="mt-2"
            />

            <div className="d-flex align-items-center justify-content-between mb-2">
              <RadioboxForm
                {...formProps}
                options={[
                  {
                    label: "December Of the same year",
                    value: false,
                    optProps: {
                      disabled: isEditing,
                    },
                  },
                  {
                    label: "In the following year",
                    value: true,
                    optProps: {
                      disabled: isEditing,
                    },
                  },
                ]}
                dependOn="remaining_balance_option_id"
                dependancyType="equal"
                dependancyValue={["2"]}
                validateBy="textRequired"
                name="next_year"
                label="payable at"
                validationName="input.settle_leaves.next_year"
                containerStyle="leaves_radio_container"
                optionsContainerStyle="d-flex"
                optionLabelStyle="leaves_radio_label"
                optionLabelActiveStyle=""
                optionInputStyle=" "
              />

              <BSelect
                {...formProps}
                dependOn="next_year"
                dependancyType="equal"
                dependancyValue={[true]}
                placeholder={t("select month")}
                name="payable_at"
                components={{ Control }}
                validationName="input.settle_leaves.payable_at"
                keepDefaultStyle
                options={Const.MonthData}
                optionLabel="value"
                optionValue="key"
                validateBy="textRequired"
                rootStyle="leaves_select_root"
                containerStyle="leaves_select_container mt-4"
                isDisabled={isEditing}
              />
            </div>

            {leavesBreaksForm?.remaining_balance_option_id == "2" &&
            leavesBreaksForm?.request_leaves ? (
              <div className="notes-style h2 mt-3 fit-width">
                {t("probation_balance_note")}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AnnualLeavesSection;

import React from "react";
import { CheckboxBooleanForm } from "form-builder";
import { InputForm } from "../../../Builder/Form";

const UnpaidLeavesSection = ({ formProps }) => {
  return (
    <>
      <CheckboxBooleanForm
        {...formProps}
        options={["Allow Employees To Request Unpaid Leaves"]}
        name="allow_unpaid_leaves"
        validationName="input.unpaid_leave_setting.unpaid_leaves"
        type="checkbox"
        containerStyle="leaves_checkbox_container mb-2"
      />

      <CheckboxBooleanForm
        {...formProps}
        options={["Employee must submit leave request before the start of work day"]}
        name="allow_unpaid_leave_cutoff"
        validationName="input.unpaid_leave_setting.allow_unpaid_leave_cutoff"
        type="checkbox"
        containerStyle="leaves_checkbox_container mb-2"
        dependOn="allow_unpaid_leaves"
        dependancyType="equal"
        dependancyValue={[1]}
        setWithValue
      />

      <InputForm
        {...formProps}
        validateBy="textRequired"
        label="Employee must submit leave request before the start of work day by"
        name="unpaid_leave_cutoff"
        validationName="input.unpaid_leave_setting.unpaid_leave_cutoff"
        type="text"
        hasSuffix
        suffixTitle="Hours"
        containerStyle="leaves_field_container my-2"
        inputContainerStyle="d-flex align-items-center"
        dependOn="allow_unpaid_leave_cutoff"
        dependancyType="equal"
        dependancyValue={[true]}
      />

      <CheckboxBooleanForm
        {...formProps}
        options={[
          "Include Holidays and Weekends When Calculating Unpaid Leave Durations",
        ]}
        name="include_weekends_and_holidays_in_unpaid"
        validationName="input.unpaid_leave_setting.include_weekends_and_holidays_in_unpaid"
        type="checkbox"
        dependOn="allow_unpaid_leaves"
        dependancyType="equal"
        dependancyValue={[1]}
        containerStyle="leaves_checkbox_container"
      />
    </>
  );
};

export default UnpaidLeavesSection;

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { onInputResetWithValueAction } from "../../Store/Actions";
import { RegulationTypes } from "../../Constants/RegulationTypes";
import { InputForm } from "form-builder";

const SocialSection = (props) => {
  const {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
    index,
  } = props;

  const FormProps = {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    calculatedInsurance();

    return () => {};
  }, [props.totalGross, props.type, props?.relevantTaxInfo]);

  const taxInfo = props?.relevantTaxInfo;

  const calculatedInsurance = () => {
    if (
      props.type == RegulationTypes.REGULATION_EGP &&
      props?.relevantTaxInfo
    ) {
      let insuredSalary = Math.min(
        Math.max(
          (props.totalGross * taxInfo?.insurance_salary_percentage) / 100,
          taxInfo?.min_insurance_salary
        ),
        taxInfo?.max_insurance_salary
      );

      // set insurance value
      let amount = (
        (insuredSalary * taxInfo?.employee_insurance_percentage) /
        100
      ).toFixed(2);

      dispatch(
        onInputResetWithValueAction(
          formName,
          "socialDeduction",
          isNaN(amount) ? 0 : amount
        )
      );
    }
  };

  return (
    <div className="flex-fill order-one">
      <InputForm
        name="socialDeduction"
        type="number"
        labelStyle="mb-2"
        label={t("social insurance")}
        onWheelCapture={(e) => {
          e.target.blur();
        }}
        placeholder={t("amount")}
        {...FormProps}
        // labelStyle="mb-0"
        containerStyle=""
        inputContainerStyle="w-100 text-center"
        validateBy="textRequired"
        disabled={props.type == RegulationTypes.REGULATION_EGP}
        validationName={`input.user_salary_config_input.salary_deductibles.${index}.value`} // should change this
        hasTooltip={props.type == RegulationTypes.REGULATION_EGP}
        tooltipTitle={
          <ul>
            <li>min insurance = {taxInfo?.min_insurance_salary}</li>
            <li>max insurance = {taxInfo?.max_insurance_salary}</li>
            <li>
              insurance Salary Percentage ={" "}
              {taxInfo?.insurance_salary_percentage} %
            </li>
            <li>
              employee Insurance Percentage ={" "}
              {taxInfo?.employee_insurance_percentage} %
            </li>
            <li>
              min InsuredSalary = max(gross salary x insurance Salary
              Percentage, min insurance Salary)
            </li>
            <li>
              insuredSalary = min (min InsuredSalary , max insurance salary){" "}
            </li>
            <li>insurance = insuredSalary x employee Insurance Percentage </li>
          </ul>
        }
        icon="money"
      />
    </div>
  );
};

export default SocialSection;

import React, { useEffect, useState } from "react";
import TaxForm from "./TaxForm";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_LATEST_TAX_INFO,
  GET_TAX_INFO,
  GET_TAX_INFOS,
} from "../../Graphql/query";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import { EditIconButton } from "../../Components/IconButtonWithTooltip";
import moment from "moment";
import { Spinner } from "reactstrap";

const TaxLawBrackets = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="tax-brackets-container">
      {data?.brackets?.map((bracket, index) => {
        return (
          <div
            className="d-flex align-items-center mr-3"
            style={{
              width: "48.5%",
              ...(index % 2 === 0
                ? { borderRight: "1px solid #999" }
                : { paddingLeft: "50px" }),
              paddingBottom: index !== data?.brackets?.length - 1 ? 10 : 0,
            }}
          >
            <p className="bracket-order-style">
              {t("bracket-order", {
                index: index + 1,
                suffix:
                  index + 1 == 1
                    ? "st"
                    : index + 1 == 2
                    ? "nd"
                    : index + 1 == 3
                    ? "rd"
                    : "th",
              })}
            </p>
            <p className="mb-0 w-100 font-weight-bold">{t("from")}</p>
            <p className="mb-0 w-100">
              {bracket?.start} {t("EGP")}
            </p>
            <p className="mb-0 w-100 font-weight-bold">{t("to")}</p>
            <p className="mb-0 w-100">
              {bracket?.end} {t("EGP")}
            </p>
            <p className="mb-0 w-100 font-weight-bold">{t("percentage")}</p>
            <p className="mb-0 w-100">
              {bracket?.percentage ? `${bracket?.percentage} %` : "0 %"}
            </p>
          </div>
        );
      })}
    </div>
  );
};

const formInitialState = {
  id: null,
  name: "",
  fiscalYear: "",
  insuranceSalaryPercentage: "",
  temporaryEmploymentPercentage: "",
  minInsuredSalary: "",
  maxInsuredSalary: "",
  employeeInsurancePercentage: "",
  businessOwnerInsurancePercentage: "",
  personalTaxExemption: "",
  brackets: [{ from: "0", to: "", percentage: "" }],
};

const TaxesPage = () => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [formData, setFormData] = useState({ ...formInitialState });

  const [search, setSearch] = useState("");

  const { data: taxInfosData, loading: taxInfosLoading } = useQuery(
    GET_TAX_INFOS,
    {
      fetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
    }
  );

  const handleOpenModal = () => {
    attemptGetLatestTaxInfo();
  };

  const handleResetForm = () => {
    setFormData({ ...formInitialState });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    handleResetForm();
  };

  const handleGetTaxInfoCompleted = (data, isEditting) => {
    const {
      id,
      name,
      from,
      employee_insurance_percentage,
      employer_insurance_percentage,
      insurance_salary_percentage,
      max_insurance_salary,
      min_insurance_salary,
      personal_exemption,
      temporary_employment_percentage,
      brackets = [],
    } = data ?? {};

    setFormData({
      id: isEditting ? id : null,
      name,
      fiscalYear: moment(from, "YYYY-MM"),
      employeeInsurancePercentage: employee_insurance_percentage,
      businessOwnerInsurancePercentage: employer_insurance_percentage,
      insuranceSalaryPercentage: insurance_salary_percentage,
      minInsuredSalary: min_insurance_salary,
      maxInsuredSalary: max_insurance_salary,
      personalTaxExemption: personal_exemption,
      temporaryEmploymentPercentage: temporary_employment_percentage,
      brackets: brackets?.map((bracket) => ({
        id: isEditting ? bracket?.id : null,
        from: bracket?.start,
        to: bracket?.end,
        percentage: bracket?.percentage,
      })),
    });

    setIsModalOpen(true);
  };

  const [attemptGetTaxInfo, { loading: getTaxInfoLoading }] = useLazyQuery(
    GET_TAX_INFO,
    {
      onCompleted: (data) => {
        handleGetTaxInfoCompleted(data?.taxInfo, true);
      },
    }
  );

  const [attemptGetLatestTaxInfo, { loading: getLatestTaxInfoLoading }] =
    useLazyQuery(GET_LATEST_TAX_INFO, {
      onCompleted: (data) => {
        handleGetTaxInfoCompleted(data?.latestTaxInfo, false);
      },
    });

  const handleEditTaxInfo = (id) => {
    attemptGetTaxInfo({
      variables: {
        id,
      },
    });
  };

  const columns = [
    {
      name: t("name"),
      wrap: true,
      sortable: false,
      selector: "name",
      // grow: 1.2,
    },
    {
      name: t("period"),
      wrap: true,
      sortable: false,
      selector: "from",
    },
    {
      name: t("min insured salary"),
      wrap: true,
      sortable: false,
      selector: "min_insurance_salary",
      cell: (row) => `${row?.min_insurance_salary} ${t("EGP")}`,
    },
    {
      name: t("max insured sal."),
      wrap: true,
      sortable: false,
      selector: "max_insurance_salary",
      cell: (row) => `${row?.max_insurance_salary} ${t("EGP")}`,
    },
    {
      name: t("employee share"),
      wrap: true,
      sortable: false,
      selector: "employee_insurance_percentage",
      cell: (row) => `${row?.employee_insurance_percentage} %`,
    },
    {
      name: t("owner's share"),
      wrap: true,
      sortable: false,
      selector: "employer_insurance_percentage",
      cell: (row) => `${row?.employer_insurance_percentage} %`,
    },
    {
      name: t("exemption"),
      wrap: true,
      sortable: false,
      selector: "personal_exemption",
      cell: (row) => `${row?.personal_exemption} ${t("EGP")}`,
    },
    {
      name: t("brackets"),
      wrap: true,
      sortable: false,
      selector: "personal_exemption",
      cell: (row) =>
        `${row?.brackets?.length} ${
          row?.brackets?.length > 1 ? "brackets" : "bracket"
        }`,
    },
    {
      name: "",
      wrap: true,
      sortable: false,
      cell: (row) =>
        row?.canEdit ? (
          <EditIconButton onClick={() => handleEditTaxInfo(row?.id)} />
        ) : (
          <></>
        ),
    },
  ];

  useEffect(() => {
    return () => {
      setSearch("");
    };
  }, []);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between w-100 my-3">
        {getTaxInfoLoading ? (
          <div className="loader_wrapper_style">
            <Loader />
          </div>
        ) : null}

        {/* <InputForm
          name="search"
          type="text"
          placeholder={t("search")}
          inputContainerStyle=" "
          rootStyle="w-100 mr-3"
          containerStyle="w-100"
          onChange={(e) => setSearch(e?.target?.value)}
          value={search}
        /> */}
        <h2 className="mb-0">{t("Egyptian Tax Regulations")}</h2>

        <button
          onClick={handleOpenModal}
          className="btn add_new_btn_style text-nowrap taxes-add-new-btn"
          disabled={getLatestTaxInfoLoading}
        >
          {getLatestTaxInfoLoading ? (
            <Spinner style={{ width: "1rem", height: "1rem", color: "#fff" }} />
          ) : (
            <>
              <FontAwesomeIcon className="mr-1" icon={faPlus} />

              {t("add new")}
            </>
          )}
        </button>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        data={taxInfosData?.taxInfos ?? []}
        noHeader
        persistTableHead
        progressPending={taxInfosLoading}
        progressComponent={<Loader />}
        expandableRows
        expandableRowsComponent={<TaxLawBrackets />}
        expandableIcon={{
          expanded: (
            <FontAwesomeIcon
              className="custom-style-for-testint"
              icon={faAngleUp}
            />
          ),
          collapsed: (
            <FontAwesomeIcon
              className="custom-style-for-testint"
              icon={faAngleDown}
            />
          ),
        }}
      />

      {isModalOpen ? (
        <TaxForm
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          formData={formData}
          setFormData={setFormData}
          handleResetForm={handleResetForm}
        />
      ) : null}
    </div>
  );
};

export default TaxesPage;

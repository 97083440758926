import React from "react";

import { Skeleton } from "@mui/material";

const FacialRecognitionCardPlaceholder = () => {
  return (
    <>
      <div className="d-flex align-items-center gap-10">
        <Skeleton animation={false} variant="circular" width={30} height={30} />
        <div className="flex-fill">
          <Skeleton animation={false} height={14} />
          <Skeleton animation={false} height={14} />
        </div>
      </div>

      <div className="mt-3 pt-1">
        <Skeleton animation={false} height={16} />
        <Skeleton animation={false} height={16} className="mt-2" />
      </div>
    </>
  );
};

export default FacialRecognitionCardPlaceholder;

import React from "react";
import { useQuery } from "@apollo/client";

import { gql } from "@apollo/client";
import { showToast } from "../../Helpers/HelperFns";

import { BSelect } from "form-builder";
import { components } from "react-select";
import IconButtonWithTooltip from "../IconButtonWithTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserSlash } from "@fortawesome/free-solid-svg-icons";

const GET_CRM_AGENTS = gql`
  query getCRMAgents($page: CrmPages, $activeOnly: Boolean = true) {
    agents: getCrmAgentsMenuAccordingToPage(
      page: $page
      active: $activeOnly
    ) {
      id
      name
    }
  }
`;

const LeadAgentSelect = ({ page, ...props }) => {
  const [agents, setAgents] = React.useState([]);
  const [activeOnly, setActiveOnly] = React.useState(true);
  const { loading } = useQuery(GET_CRM_AGENTS, {
    variables: { page, activeOnly },
    onCompleted: ({ agents }) => {
      setAgents(agents || []);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  return (
    <BSelect
      {...props}
      isLoading={loading}
      isActive={activeOnly}
      options={loading ? [] : agents}
      components={{ Control: DataControl }}
      onToggle={() => setActiveOnly((prev) => !prev)}
    />
  );
};

export default LeadAgentSelect;

const DataControl = ({ children, ...props }) => {
  const { isActive, onToggle } = props.selectProps;

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onToggle();
  };

  return (
    <components.Control {...props}>
      <span onMouseDown={handleClick}>
        <IconButtonWithTooltip
          onClick={() => undefined}
          label={`show ${isActive ? "all" : "active"} employees`}
          icon={
            isActive ? (
              <FontAwesomeIcon icon={faUser} size="sm" color="#90979e" />
            ) : (
              <FontAwesomeIcon icon={faUserSlash} size="sm" color="#90979e" />
            )
          }
        />
      </span>

      {children}
    </components.Control>
  );
};

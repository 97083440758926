import React from "react";
import Constants from "../../Constants";
import { CheckboxBooleanForm, BSelect } from "form-builder";
import { connect } from "react-redux";
import AddHolidayModal from "../AddHolidayModal";
import {
  showOfficeHolidayModalAction,
  updateObjectMultiSelectAction,
} from "../../Store/Actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuid, validate } from "uuid";
import { components } from "react-select";

import { useTranslation } from "react-i18next";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";

const AttOfficeHolidaySection = (props) => {
  const { formProps } = props;
  const { t } = useTranslation();
  const handleAddOfficeHoliday = () => {
    let data = {
      id: uuid(),
      name: "",
      start_date: "",
      end_date: "",
    };
    props.showOfficeHolidayModalAction({
      data,
      ref: "officeHolidayModalActions",
      formName: "holidayForm",
    });
  };

  const handleEditOfficeHoliday = (data) => {
    props.showOfficeHolidayModalAction({
      data,
      ref: "officeHolidayModalActions",
      formName: "holidayForm",
    });
  };
  const handlechange = (value, e) => {
    props.updateObjectMultiSelectAction({
      data: value ?? [],
      formName: formProps.formName,
      name: e.name,
    });
  };

  // This fucntion for the multi select open modal for each chosen option and to edit it
  const MultiValueContainer = (props) => {
    return (
      <components.MultiValueLabel
        {...props}
        innerProps={{
          ...props.innerProps,
          onClick: (e) => {
            if (!(e.target instanceof SVGElement)) {
              handleEditOfficeHoliday(props.data);
            }
          },
        }}
      />
    );
  };

  const Control = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        <PeopleOutlineIcon className="b-select-style_select_icon" />
        {children}
      </components.Control>
    );
  };

  return (
    <>
      <h4 className="att_profile_header my-3">{t("holidays")}</h4>
      <div className="boxContainer">
        <CheckboxBooleanForm
          {...formProps}
          options={["Apply office holidays"]}
          name="default_office_holiday_profile"
          type="checkbox"
        />
        <div
          className={`d-flex signin_work_timing_container holidays_container mt-2 ${
            Boolean(
              props.data[formProps.formName].default_office_holiday_profile
            ) && "d-none"
          }`}
        >
          <div className="mb-3 w-100">
            <BSelect
              {...formProps}
              name="holidays"
              label="holidays"
              keepDefaultStyle
              placeholder={t("select option")}
              options={props?.data?.holidayOptions}
              // validateBy="arrayRequired"
              components={{ MultiValueContainer, Control }}
              isMulti
              openMenuOnClick={false}
              value={props.data[formProps.formName].holidays}
              onChange={handlechange}
              getOptionLabel={(option) => option["name"]}
              getOptionValue={(option) => option}
              dependOn="default_office_holiday_profile"
              dependancyType="equal"
              dependancyValue={[0]}
              rootStyle="w-100"
            />
          </div>

          <button
            className="btn add_new_btn_style"
            type="button"
            onClick={handleAddOfficeHoliday}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
      <AddHolidayModal type="att_profile" />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
  };
};

export default connect(mapStateToProps, {
  showOfficeHolidayModalAction,
  updateObjectMultiSelectAction,
})(AttOfficeHolidaySection);

import React from "react";
import { useSelector } from "react-redux";

import { Modal } from "reactstrap";
import Loader from "../../Components/Loader";
import EmployeeForm from "../../Containers/EmployeeForm";

const formName = `employeeForm`;

const EditEmployeeModal = () => {
  // Reducer State
  const isOpen = useSelector((state) => state.super.editEmployeeForm);
  const isLoading = useSelector((state) => state.super.editEmployeeIsLoading);

  return isLoading ? (
    <Loader fixed />
  ) : (
    <Modal
      isOpen={isOpen}
      wrapClassName="edit-employee-form-modal"
      backdrop={true}
    >
      <EmployeeForm formName={formName} />
    </Modal>
  );
};

export default EditEmployeeModal;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Privileges from "../../../Constants/Privilages";
import {
  formatOptions,
  openSwalConfirm,
  showToast,
} from "../../../Helpers/HelperFns";
import { fetchCompanyRemindersQuery } from "../../../Graphql/query";
import { EditIconButton, RemoveIconButton } from "../../IconButtonWithTooltip";
import Loader from "../../Loader";
import moment from "moment";
import { AddButton } from "../../Buttons";
import Pagination from "../../Pagination";
import DataTable from "react-data-table-component";
import { CommentCell, ContactTooltip } from "../../CRM";
import ReactShowMoreText from "react-show-more-text";
import CompanyCommentModal from "../CompanyCommentModal";
import CompanyActivityAndReminderModal from "../CompanyActivityAndReminderModal";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import { BSelect, DateTimePickerForm } from "form-builder";
import { DELETE_COMPANY_REMINDERS } from "../../../Graphql/mutation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";

const activityTypeOptions = ["Call", "Message", "Email", "Meeting"];
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const CompanyRemindersList = () => {
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [pagination, setPagination] = useState(paginationInitState);
  const [filters, setFilters] = useState({
    to: "",
    from: "",
    type: "",
  });
  const [isAddReminderModalOpen, setIsAddReminderModalOpen] = useState(false);
  const [commentModal, setCommentModal] = useState({
    isOpen: false,
    data: {},
  });

  const [loadingRowsList, setLoadingRowsList] = useState([]);

  const handleOpenCommentModal = (data) => {
    setCommentModal({
      isOpen: true,
      data,
    });
  };

  const handleCloseCommentModal = () => {
    setCommentModal({
      isOpen: false,
      data: {},
    });
  };

  const handleOpenReminderModal = () => {
    setIsAddReminderModalOpen(true);
  };

  const handleCloseReminderModal = () => {
    setIsAddReminderModalOpen(false);
  };

  const handleEditReminderModal = (data) => {
    const {
      company,
      account_manager,
      contact,
      comments,
      status,
      ...filterdData
    } = data;
    dispatch({
      type: "UPDATE_ALL_FORM_DATA",
      payload: {
        formName: "comapnyReminderForm",
        formData: {
          ...filterdData,
          contact_id: data?.contact?.id,
          savedAttachments: data?.attachments,
          attachments: [],
        },
      },
    });
    setIsAddReminderModalOpen(true);
  };

  const [removeReminderMutation] = useMutation(DELETE_COMPANY_REMINDERS);

  const [
    fetchCompanyReminders,
    { data: companyRemindersData, loading: fetchCompanyRemindersIsLoading },
  ] = useLazyQuery(fetchCompanyRemindersQuery, {
    onError(err) {
      showToast(
        "error",
        err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
      );
    },
    onCompleted: (data) => {
      setPagination(data?.company_reminders?.paginatorInfo);
    },
  });

  const handelFetchCompanyReminders = () => {
    fetchCompanyReminders({
      variables: {
        input: {
          to: filters?.to ? filters?.to + " 00:00:00" : "",
          from: filters?.from ? filters?.from + " 00:00:00" : "",
          type: filters?.type?.name || "",
          company_id: companyId,
        },
        first: 20,
        page: pagination.currentPage || 1,
      },
    });
  };

  useEffect(() => {
    handelFetchCompanyReminders();
  }, [filters?.to, filters?.from, filters?.type, pagination.currentPage]);

  const columns = [
    { name: t("type"), cell: ({ type }) => <>{type}</> },
    { name: t("contact"), cell: ({ contact = {} }) => ContactTooltip(contact) },
    {
      name: t("date"),
      cell: ({ date }) => <>{moment(date).format("DD - MM - YYYY")}</>,
    },
    {
      name: t("notes"),
      cell: ({ notes }) => (
        <ReactShowMoreText lines={1}>{notes}</ReactShowMoreText>
      ),
    },
    {
      name: t("comments"),
      cell: ({ id, comments = [] }) => (
        <CommentCell
          comments={comments}
          onClick={() =>
            handleOpenCommentModal({
              id,
              comments,
              type: "CompanyReminder",
            })
          }
        />
      ),
    },
    {
      cell: (data) => (
        <>
          {loadingRowsList.includes(data?.id) ? (
            <b className="d-flex gap-5">
              <FontAwesomeIcon icon="fa-solid fa-spinner" />{" "}
              {t("in progress...")}
            </b>
          ) : (
            <>
              <HasPrivileges
                reqireMain={[
                  Privileges.SUPER_PRIVILEGE,
                  Privileges.ADD_EDIT_COMPANY_REMINDERS,
                ]}
              >
                <EditIconButton onClick={() => handleEditReminderModal(data)} />
              </HasPrivileges>

              <HasPrivileges
                reqireMain={[
                  Privileges.SUPER_PRIVILEGE,
                  Privileges.DELETE_COMPANY_REMINDERS,
                ]}
              >
                <RemoveIconButton
                  onClick={() => handleRemoveReminder(data?.id)}
                />
              </HasPrivileges>
            </>
          )}
        </>
      ),
    },
  ];

  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleSelectFilters = (val, { name }) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleDateFiltersChange = (name, val) => {
    setFilters((prev) => ({
      ...prev,
      [name]: val ? val.format("YYYY-MM-DD") : null,
    }));
  };

  const handleRemoveReminder = (reminderId) => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        setLoadingRowsList((prev) => [...prev, reminderId]);
        removeReminderMutation({
          variables: {
            id: reminderId,
          },
          onCompleted: (res) => {
            if (res?.delete_company_reminder?.status === "success") {
              setLoadingRowsList((prev) =>
                prev?.filter((rowID) => rowID !== reminderId)
              );
              showToast("success", t("done"));
              handelFetchCompanyReminders();
            } else {
              showToast(
                res?.delete_company_reminder?.status ?? "error",
                res?.delete_company_reminder?.message ??
                  t("something went wrong")
              );
            }
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
            );
          },
        });
      }
    });
  };

  return (
    <>
      {/* Filters */}
      <div className="d-flex gap-20">
        <BSelect
          isClearable
          name="type"
          label="type"
          icon="employee"
          keepDefaultStyle
          rootStyle="flex-fill"
          value={filters.type}
          onChange={handleSelectFilters}
          options={formatOptions(activityTypeOptions)}
        />
      </div>

      <div className="d-flex gap-20 mt-4">
        <DateTimePickerForm
          hasIcon
          isClearable
          label="from"
          labelStyle="w-100"
          value={filters.from ? moment(filters.from, "YYYY-MM-DD") : null}
          format={"YYYY-MM-DD"}
          rootStyle="flex-fill"
          datePickerContainer="w-100"
          onChange={(val) => handleDateFiltersChange("from", val)}
        />
        <DateTimePickerForm
          hasIcon
          label="to"
          isClearable
          labelStyle="w-100"
          value={filters.to ? moment(filters.to, "YYYY-MM-DD") : null}
          format={"YYYY-MM-DD"}
          rootStyle="flex-fill"
          datePickerContainer="w-100"
          onChange={(val) => handleDateFiltersChange("to", val)}
        />
        <div className={`d-flex align-items-end mt-4 justify-content-end`}>
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.ADD_EDIT_COMPANY_REMINDERS,
            ]}
          >
            <AddButton label="reminder" onClick={handleOpenReminderModal} />
          </HasPrivileges>
        </div>
      </div>

      {/* List */}
      <DataTable
        noHeader
        data={companyRemindersData?.company_reminders?.data}
        columns={columns}
        className="cards_table my-3"
        progressPending={fetchCompanyRemindersIsLoading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {isAddReminderModalOpen ? (
        <CompanyActivityAndReminderModal
          isAddReminder
          refetchList={handelFetchCompanyReminders}
          onClose={handleCloseReminderModal}
          isOpen={isAddReminderModalOpen}
        />
      ) : null}

      {commentModal.isOpen ? (
        <CompanyCommentModal
          data={commentModal.data}
          onClose={handleCloseCommentModal}
          refetchList={handelFetchCompanyReminders}
        />
      ) : null}
    </>
  );
};

export default CompanyRemindersList;

import React from "react";

import Tooltip from "@mui/material/Tooltip";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const ContactTooltip = (c) => {
  const isNoInfo = !c?.position && !c?.email && !c?.phones?.length;

  return (
    <Tooltip
      sx={{ bgcolor: "rgba(97, 97, 97, 0.7)" }}
      title={
        !isNoInfo ? (
          <ul className="p-0 mb-0 d-flex flex-column" style={{ gap: 4 }}>
            {/* Position */}
            {c?.position ? <li>{c?.position}</li> : null}

            {/* Email */}
            {c?.email ? (
              <li>
                <a
                  className="d-flex align-items-center gap-10"
                  href={`mailto::${c?.email}`}
                  style={{ color: "inherit" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <MailIcon fontSize="small" />
                  {c?.email}
                </a>
              </li>
            ) : null}

            {/* Phones */}
            {c?.phones?.length ? (
              <li>
                <ul className="p-0 mb-0 d-flex flex-column" style={{ gap: 2 }}>
                  {c?.phones.map((p, i) => (
                    <li key={i} className="d-flex align-items-center gap-20">
                      <a
                        className="d-flex align-items-center gap-10"
                        href={`https://wa.me/+${p[0]}${p[1]}`}
                        style={{ color: "inherit" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <WhatsAppIcon
                          fontSize="small"
                          sx={{ color: "#25d366" }}
                        />
                        +{p[0] + p[1]}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            ) : null}
          </ul>
        ) : undefined
      }
    >
      <div className="mb-2">{c?.name}</div>
    </Tooltip>
  );
};

export default ContactTooltip;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useMutation } from "@apollo/client";

import {
  showToast,
  formatOptions,
  openSwalConfirm,
  formatSendingInputs,
} from "../../Helpers/HelperFns";
import {
  leadStatusOptions,
  activityTypeOptions,
  dateTimeListFormat,
} from "../../Constants/CRM";
import {
  activitiesListQuery,
  // activitiesOptionsQuery,
} from "../../Graphql/query";
import moment from "moment";
import Privileges from "../../Constants/Privilages";
import { removeActivityMutation } from "../../Graphql/mutation";

import {
  CommentCell,
  LeadNameCell,
  FiltersChips,
  ContactTooltip,
  LeadsServerRestSelect,
} from "../../Components/CRM";
import {
  ActivityModal,
  CommentModal,
  ReminderModal,
} from "../../Components/CRMFormModals";
import IconButtonWithTooltip, {
  EditIconButton,
  RemoveIconButton,
  DownloadFileIconButton,
} from "../../Components/IconButtonWithTooltip";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import ReactShowMoreText from "react-show-more-text";
import { NotificationAdd } from "@mui/icons-material";
import { LeadAgentSelect } from "../../Components/CRM";
import FiltersDrawer from "../../Components/FiltersDrawer";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { AddButton, FilterButton } from "../../Components/Buttons";
import { BSelect, DateTimePickerForm, RadioboxForm } from "form-builder";

const modalInitState = { isOpen: false, data: null };
const modals = [
  { name: "activity", Modal: ActivityModal },
  { name: "comment", Modal: CommentModal },
  { name: "reminder", Modal: ReminderModal },
];
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};
const sendFilters = ({ lead, ...filters }) => {
  return {
    page: 1,
    ...formatSendingInputs({ ...filters, lead_id: lead }),
  };
};
const formatFiltersChips = (filters, others) => {
  return {
    ...filters,
    page: undefined,
    first: undefined,
    f_type: undefined,
    ...(others?.leadProfileData?.id && { lead: undefined }),
    ...(others?.isAdminProfile && { assigned_to: undefined }),
  };
};

const ActivitiesList = ({ isLeadProfile, leadProfileData, adminID }) => {
  const { t } = useTranslation();
  const isAdminProfile = Boolean(adminID);
  const filtersInitState = {
    to: "",
    from: "",
    type: null,
    status: null,
    f_type: "All",
    lead: leadProfileData?.id || null,
    assigned_to: isAdminProfile ? adminID : null,
  };

  // Local State
  const [activities, setActivities] = useState([]);
  // const [options, setOptions] = useState({ });
  const [filtersChips, setFiltersChips] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState(filtersInitState);
  const [pagination, setPagination] = useState(paginationInitState);
  const [disableSubmitFilters, setDisableSubmitFilters] = useState(true);
  const [modalsState, setModalsState] = useState(
    modals.map(({ name }) => ({ name, ...modalInitState }))
  );

  // Server State
  const [removeActivity, { loading: removeLoading }] = useMutation(
    removeActivityMutation
  );
  // const { loading: isOptionsLoading } = useQuery(activitiesOptionsQuery, {
  //   onCompleted: ({ users }) => {
  //     setOptions({ users: users?.data || [] });
  //   },
  //   onError: (err) => {
  //     showToast(
  //       "error",
  //       err?.graphQLErrors?.[0]?.extensions?.reason ||
  //         err?.graphQLErrors?.[0]?.message ||
  //         err?.message
  //     );
  //   },
  // });
  const [getListData, { loading: isListLoading }] = useLazyQuery(
    activitiesListQuery,
    {
      onCompleted: ({ activities }) => {
        setActivities(activities?.data);
        setPagination(activities?.paginatorInfo);
        setFiltersChips(
          formatFiltersChips(filters, { isAdminProfile, leadProfileData })
        );
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    }
  );

  // Constants
  const columns = [
    { name: t("type"), cell: ({ type }) => JSON.parse(type)?.name },
    {
      name: t("name"),
      cell: ({ lead }) => (
        <LeadNameCell lead={lead} isLeadProfile={isLeadProfile} />
      ),
    },
    { name: t("contact"), cell: ({ contact = {} }) => ContactTooltip(contact) },
    {
      name: t("date"),
      cell: ({ date }) => moment(date).format(dateTimeListFormat),
    },
    {
      name: t("comments"),
      cell: ({ id, comments = [] }) => (
        <HasPrivileges
          reqireMain={[
            Privileges.SUPER_PRIVILEGE,
            Privileges.VIEW_COMMENTS_ON_ACTIVITIES,
          ]}
        >
          <CommentCell
            comments={comments}
            onClick={() =>
              handleOpenModal("comment", { id, comments, type: "Activity" })
            }
          />
        </HasPrivileges>
      ),
    },
    {
      name: t("status"),
      cell: ({ status = [] }) => (
        <div>
          <strong className="d-block">{JSON.parse(status)?.[0]}</strong>
          {JSON.parse(status)?.[1]}
        </div>
      ),
    },
    { name: t("created by"), selector: (row) => row?.user?.name },
    {
      name: t("notes"),
      cell: ({ notes }) => (
        <ReactShowMoreText lines={1}>{notes}</ReactShowMoreText>
      ),
    },
    {
      name: t("attachments"),
      cell: ({ attachments = [] }) => (
        <div className="cards_table_actions">
          {attachments?.map((attachment) => (
            <DownloadFileIconButton
              key={attachment?.file?.id}
              href={attachment?.file?.path}
            />
          ))}
        </div>
      ),
    },
    {
      cell: (data) => (
        <>
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.ADD_EDIT_REMINDERS,
            ]}
          >
            <IconButtonWithTooltip
              label="add reminder"
              icon={<NotificationAdd fontSize="small" className="blue-color" />}
              onClick={() =>
                handleOpenModal("reminder", {
                  id: data?.lead?.id,
                  name: data?.lead?.name,
                  contacts: data?.lead?.contacts,
                })
              }
            />
          </HasPrivileges>
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.ADD_EDIT_ACTIVITIES,
            ]}
          >
            <EditIconButton
              onClick={() => handleOpenModal("activity", { id: data.id })}
            />
          </HasPrivileges>
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.DELETE_ACTIVITIES,
            ]}
          >
            <RemoveIconButton onClick={() => handleRemoveActivity(data)} />
          </HasPrivileges>
        </>
      ),
    },
  ];

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    refetchList();
  }, []);

  /* ↓ Helpers ↓ */

  const refetchList = (vars, variables) => {
    getListData({
      variables: {
        ...sendFilters(variables || filters),
        first: pagination.perPage,
        ...(isAdminProfile && { assigned_to: +adminID }),
        ...(leadProfileData?.id && { lead: leadProfileData.id }),
        ...vars,
      },
    });
  };

  const closeDrawer = () => () => {
    setShowFilters(() => false);
    setDisableSubmitFilters(() => true);
  };

  const handleSubmitFilter = () => {
    refetchList();
    closeDrawer()();
  };

  const handleResetFilter = () => {
    handleFilter(filtersInitState);
  };

  const handleDeleteFilter = (key) => {
    const newFilters = { ...filters, [key]: undefined };
    setFilters(newFilters);
    refetchList({}, newFilters);
    setFiltersChips((prev) => ({ ...prev, [key]: undefined }));
  };

  const handleFilter = (newFiltersState) => {
    setFilters(newFiltersState);
    setDisableSubmitFilters(false);
  };

  const handleOpenModal = (name, data = null) => {
    const modalState = { name, data, isOpen: true };

    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? modalState : p))
    );
  };

  const handleCloseModal = (name) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, ...modalInitState } : p))
    );
  };

  const handlePaginate = (page = pagination.currentPage) => {
    refetchList({ page });
  };

  const handleSelectFilters = (val, { name }) => {
    handleFilter((prev) => ({ ...prev, [name]: val }));
  };

  const handleDateFiltersChange = (name, val) => {
    handleFilter((prev) => ({ ...prev, [name]: val }));
  };

  const handleRadioFilters = (e) => {
    handleFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleRemoveActivity = (data) => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        removeActivity({
          variables: { id: data.id },
          onCompleted: () => {
            refetchList();
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
          },
        });
      }
    });
  };

  return (
    <>
      {removeLoading ? <Loader fixed /> : null}

      <div className="d-flex justify-content-between align-items-center pt-4 mb-4">
        {/* Actions */}
        <div className="d-flex justify-content-end gap-10 min-width-200">
          <FilterButton onClick={() => setShowFilters(true)} />
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.ADD_EDIT_ACTIVITIES,
            ]}
          >
            <AddButton
              label="activity"
              onClick={() => handleOpenModal("activity")}
            />
          </HasPrivileges>
        </div>

        {/* Filters Chips */}
        <div
          style={{ order: -1 }}
          className="d-flex flex-fill gap-10 flex-wrap"
        >
          <FiltersChips
            filters={filtersChips}
            handleDeleteFilter={handleDeleteFilter}
          />
        </div>
      </div>

      {/* Filters */}
      <FiltersDrawer
        skipCloseSubmit
        toggleDrawer={closeDrawer}
        isFilterDrawerOpen={showFilters}
        onFilterReset={handleResetFilter}
        onFilterSubmit={handleSubmitFilter}
        disableSubmitFilters={disableSubmitFilters}
        render={() => (
          <>
            {isLeadProfile ? null : (
              <LeadsServerRestSelect
                type="Activities"
                isClearable
                name="lead"
                label="lead"
                icon="employee"
                keepDefaultStyle
                value={filters.lead}
                onChange={handleSelectFilters}
              />
            )}
            <BSelect
              isClearable
              name="status"
              label="lead status"
              icon="employee"
              keepDefaultStyle
              value={filters.status}
              onChange={handleSelectFilters}
              options={formatOptions(leadStatusOptions)}
            />
            <BSelect
              isClearable
              name="type"
              label="type"
              icon="employee"
              keepDefaultStyle
              value={filters.type}
              onChange={handleSelectFilters}
              options={formatOptions(activityTypeOptions)}
            />
            {isAdminProfile ? null : (
              <LeadAgentSelect
                isClearable
                page="ACTIVITIES"
                name="assigned_to"
                label="assigned to"
                keepDefaultStyle
                value={filters.assigned_to}
                onChange={handleSelectFilters}
              />
            )}
            <DateTimePickerForm
              hasIcon
              isClearable
              label="from"
              labelStyle="w-100"
              value={filters.from}
              datePickerContainer="w-100"
              onChange={(val) => handleDateFiltersChange("from", val)}
            />
            <DateTimePickerForm
              hasIcon
              label="to"
              isClearable
              labelStyle="w-100"
              value={filters.to}
              datePickerContainer="w-100"
              onChange={(val) => handleDateFiltersChange("to", val)}
            />
            {isLeadProfile ? null : (
              <HasPrivileges
                reqireMain={[
                  Privileges.SUPER_PRIVILEGE,
                  Privileges.VIEW_ACTIVITIES_OF_ASSIGNED_LEAD,
                  Privileges.VIEW_ACTIVITIES_OF_OTHER_AGENTS,
                ]}
              >
                <RadioboxForm
                  name="f_type"
                  value={filters.f_type}
                  optionInputStyle=" "
                  containerStyle="mb-0"
                  optionItemStyle="d-inline-block mx-2"
                  optionsContainerStyle="d-flex gap-10"
                  options={[
                    {
                      label: "mine",
                      value: "Mine",
                      optProps: { onChange: handleRadioFilters },
                    },
                    {
                      label: "managed",
                      value: "Managed",
                      optProps: { onChange: handleRadioFilters },
                    },
                    {
                      label: "all",
                      value: "All",
                      optProps: { onChange: handleRadioFilters },
                    },
                  ]}
                />
              </HasPrivileges>
            )}
          </>
        )}
      />

      {/* List */}
      <DataTable
        noHeader
        data={activities}
        columns={columns}
        className="cards_table"
        progressPending={isListLoading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {modals.map(({ name, Modal }, i) =>
        modalsState[i].isOpen ? (
          <Modal
            key={name}
            refetchList={refetchList}
            data={modalsState[i]?.data}
            onClose={() => handleCloseModal(name)}
            {...(name === "reminder" && { isLeadList: true })}
            {...(name === "activity" && { isLeadProfile, leadProfileData })}
            {...(name === "comment" && {
              canCommentPrivileges: [
                Privileges.SUPER_PRIVILEGE,
                Privileges.COMMENT_ON_ACTIVITIES,
              ],
            })}
          />
        ) : null
      )}
    </>
  );
};

export default ActivitiesList;

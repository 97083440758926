import React from "react";
import { connect } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Privilages from "../../Constants/Privilages";
import { ADMIN_PRIVILEGES } from "../../Constants/Nav";
import { useTranslation } from "react-i18next";

const HasPrivileges = ({
  main,
  allScope,
  managedScope,
  reqireMain = [],
  requireScope = [],
  scope,
  children,
  altChildren,
  allowBP = false,
  planPrivileges = [],
  avalibleOnExpire = true,
  altExpireChildren,
  isExpired,
  authUser,
}) => {
  let availablePrivileges = planPrivileges.map(({ id }) => id);
  const { t } = useTranslation();
  const renderChildren = () => {
    if (isExpired && !avalibleOnExpire) {
      return altExpireChildren ? (
        <Tooltip title={t("Your company subscription has expired")}>
          <ExpireChildrenRef>{altExpireChildren}</ExpireChildrenRef>
        </Tooltip>
      ) : null;
    }
    return <>{children}</>;
  };

  if (
    (allowBP &&
      main?.includes(Privilages.BUSINESS_PARTNER_PRIVILEGES) &&
      reqireMain?.some((prev) => availablePrivileges.indexOf(prev) !== -1)) ||
    (allowBP &&
      main?.includes(Privilages.BUSINESS_PARTNER_PRIVILEGES) &&
      !reqireMain?.length)
  ) {
    return renderChildren();
  } else {
    switch (scope) {
      case "all":
        if (
          allScope?.some(
            (prev) =>
              requireScope.indexOf(prev) !== -1 &&
              availablePrivileges.indexOf(prev) !== -1
          )
        ) {
          return renderChildren();
        }
        return <>{altChildren ?? null}</>;
      case "managed":
        if (
          managedScope?.some(
            (prev) =>
              requireScope.indexOf(prev) !== -1 &&
              availablePrivileges.indexOf(prev) !== -1
          )
        ) {
          return renderChildren();
        }
        return <>{altChildren ?? null}</>;
      default:
        // FOR ADMIN PRIVILEGES
        if (
          reqireMain?.some((prev) => ADMIN_PRIVILEGES?.indexOf(prev) !== -1) &&
          reqireMain?.some((prev) => main?.indexOf(prev) !== -1)
        ) {
          return <>{children}</>;
        }
        // FOR OTHERS PRIVILEGES *NON ADMIN*
        if (
          reqireMain?.some(
            (prev) =>
              main?.indexOf(prev) !== -1 &&
              availablePrivileges?.indexOf(prev) !== -1
          )
        ) {
          return renderChildren();
        }
        return <>{altChildren ?? null}</>;
    }
  }
};

const ExpireChildrenRef = React.forwardRef((props, ref) => (
  <span {...props} ref={ref} style={{ opacity: "0.65" }}>
    {props.children}
  </span>
));

const mapStateToProps = (state) => {
  return {
    authUser: state.auth?.userProfile,
    main: state.auth.userProfile?.prviliege_ids,
    allScope: state.auth.userProfile?.prviliege_ids_with_all_employee_scope,
    managedScope:
      state.auth.userProfile?.prviliege_ids_with_managed_employee_scope,
    isExpired: !state.auth?.userProfile?.company?.hasActiveSubscription,
    planPrivileges:
      state.auth?.userProfile?.company?.activeSubscription?.plan?.privileges,
  };
};

export default connect(mapStateToProps)(HasPrivileges);

import React from "react";
import { useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { showToast } from "../../Helpers/HelperFns";
import { wizardOfficeQuery } from "../../Graphql/query";

import OfficeForm from "../OfficeForm";
import Loader from "../../Components/Loader";

const SetupWizardOffice = () => {
  const { t } = useTranslation();

  // Local State
  const [officeId, setOfficeId] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  // Reducer State
  const isFetchingOffice = useSelector(
    (state) => state.super?.fetchingOfficeForm
  );

  // Server State
  const [getOffice] = useLazyQuery(wizardOfficeQuery);

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    getOffice({
      onCompleted: ({ offices }) => {
        offices?.data?.length && setOfficeId(offices?.data[0]?.id);
        setIsLoading(false);
      },
      onError: (err) => {
        setIsLoading(false);
        showToast("error", err?.message);
      },
    });
  }, []);

  return (
    <section className="setup-wizard-step office_form_wrapper">
      <h2 className="setup-wizard-title">
        {t("create your headquarter office")}
      </h2>

      {isLoading ? (
        <Loader fixed />
      ) : (
        <>
          {isFetchingOffice ? <Loader fixed /> : null}
          <OfficeForm setupWizard={true} officeId={officeId} />
        </>
      )}
    </section>
  );
};

export default SetupWizardOffice;

import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import { setSetupWizardActiveTab } from "../../Store/Actions";

import { MobileStepper, Button } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const SetupWizardTabs = ({ steps }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Reducer State
  const { companyWizardActiveTab } = useSelector((state) => state.wizard);

  /* ↓ Helpers ↓ */

  const handleNext = () => {
    dispatch(setSetupWizardActiveTab(companyWizardActiveTab + 1));
  };

  const handleBack = () => {
    dispatch(setSetupWizardActiveTab(companyWizardActiveTab - 1));
  };

  return (
    <MobileStepper
      variant="progress"
      position="static"
      steps={steps}
      activeStep={companyWizardActiveTab - 1}
      sx={{ ".MuiLinearProgress-root": { flex: 1, marginInline: 1 } }}
      nextButton={
        <Button
          size="small"
          disableRipple
          disableElevation
          onClick={handleNext}
          disabled={companyWizardActiveTab === steps}
          endIcon={
            theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )
          }
        >
          {t("next step")}
        </Button>
      }
      backButton={
        <Button
          size="small"
          disableRipple
          disableElevation
          onClick={handleBack}
          disabled={companyWizardActiveTab === 1}
          startIcon={
            theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )
          }
        >
          {t("previous step")}
        </Button>
      }
    />
  );
};

export default SetupWizardTabs;

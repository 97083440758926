import React from "react";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  onFormResetAction,
  toggleEditingCostCenterAllowanceConfigs,
} from "../../Store/Actions";
import { DIVIDE_SALARY_BY_VALUES } from "../../Constants";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { useMutation } from "@apollo/client";
import { DELETE_COST_CENTER_ALLOWANCE_CONFIG } from "../../Graphql/mutation";
import { showToast } from "../../Helpers/HelperFns";

const ExpandedCostCenter = ({ data, handleOpenModal, refetch = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const costCenter = data ?? {};

  const handleAddNewAllowanceConfig = () => {
    dispatch(
      onFormResetAction("costCenterForm", {
        id: costCenter?.id,
        allowancesConfigurations: [
          {
            from: null,
            allowances: [
              {
                id: null,
                payment_factor_id: "Fixed",
                amount: 0,
                month_option_id: null,
                customDays: null,
                notPaidOnUnpaidLeaves: 0,
                notPaidOnPaidLeaves: 0,
                notPaidOnAbsenteDays: 0,
              },
            ],
          },
        ],
      })
    );
    dispatch(toggleEditingCostCenterAllowanceConfigs(true));
    handleOpenModal();
  };

  const handleEditAllowanceConfig = (allowanceConfig) => {
    dispatch(
      onFormResetAction("costCenterForm", {
        id: costCenter?.id,
        allowancesConfigurations: [
          {
            from: allowanceConfig?.from,
            originalFrom: allowanceConfig?.from,
            allowances: allowanceConfig?.allowances?.map((allowance) => {
              return {
                id: allowance?.id,
                payment_factor_id: allowance?.paymentFactor,
                amount: allowance?.value,
                month_option_id:
                  allowance?.monthDays == DIVIDE_SALARY_BY_VALUES.CALENDAR_MONTH
                    ? DIVIDE_SALARY_BY_VALUES.CALENDAR_MONTH
                    : allowance?.monthDays == DIVIDE_SALARY_BY_VALUES.WORK_DAYS
                    ? DIVIDE_SALARY_BY_VALUES.WORK_DAYS
                    : allowance?.monthDays == "30"
                    ? DIVIDE_SALARY_BY_VALUES.FULL_MONTH
                    : DIVIDE_SALARY_BY_VALUES.CUSTOM_DAYS,
                customDays: allowance?.monthDays,
                notPaidOnUnpaidLeaves:
                  !allowance?.payableOn?.includes("Unpaid"),
                notPaidOnPaidLeaves: !allowance?.payableOn?.includes("Paid"),
                notPaidOnAbsenteDays: !allowance?.payableOn?.includes("Absent"),
              };
            }),
          },
        ],
      })
    );
    dispatch(toggleEditingCostCenterAllowanceConfigs(true));
    handleOpenModal();
  };

  const [attemptDeleteCostCenterConfig] = useMutation(
    DELETE_COST_CENTER_ALLOWANCE_CONFIG,
    {
      onCompleted: () => {
        refetch();
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
        );
      },
    }
  );

  const handleDeleteCostCenterConfig = (allowanceConfing) => {
    attemptDeleteCostCenterConfig({
      variables: {
        id: costCenter?.id,
        from: allowanceConfing?.from,
      },
    });
  };

  return (
    <div>
      <div className="d-flex align-items-center gap-10 my-3">
        <h4 className="blue-color font-weight-bold fa-1x m-0">
          {t("allowances")}
        </h4>
        <IconButton
          sx={{
            height: 24,
            width: 24,
            fontSize: 16,
            color: "#fff",
            backgroundColor: "#2764ac",
            borderRadius: 1,
            ":hover": {
              backgroundColor: "#12498a",
            },
          }}
          onClick={handleAddNewAllowanceConfig}
        >
          <FontAwesomeIcon icon={faPlus} />
        </IconButton>
      </div>

      {costCenter?.allowanceConfiguration?.map((allowanceConfig, index) => {
        return (
          <div className="d-flex align-items-center justify-content-between gap-10 border p-2 rounded my-2 relative">
            <div>
              <p className="m-0">{t("period")}</p>
              <p className="m-0">{allowanceConfig?.from}</p>
            </div>
            {allowanceConfig?.allowances?.map((allowance) => {
              return (
                <div>
                  <p className="m-0">{allowance?.name}</p>
                  {/* <p className="m-0">{allowance?.paymentFactor}</p> */}
                  <p className="m-0">
                    {`${
                      allowance?.paymentFactor == "Fixed"
                        ? allowance?.value
                        : `(${allowance?.paymentFactor} / ${allowance?.monthDays})`
                    } / ${t("Day")}`}
                  </p>
                </div>
              );
            })}
            <div>
              <HasPrivileges
                allowBP
                reqireMain={[Privilages.ADD_EDIT_DELETE_COST_CENTER]}
              >
                <EditIconButton
                  onClick={() => handleEditAllowanceConfig(allowanceConfig)}
                />
                <RemoveIconButton
                  onClick={() => handleDeleteCostCenterConfig(allowanceConfig)}
                />
              </HasPrivileges>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ExpandedCostCenter;

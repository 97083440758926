import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onInputResetAction,
  onInputResetWithValueAction,
} from "../../Store/Actions";
export const withDependency = (Component) => (props) => {
  const dispatch = useDispatch();
  const data = props.reducer ?? "super";
  const store = useSelector((state) => state);
  useEffect(() => {
    if (props.dependancyType) {
      switch (props.dependancyType) {
        case "greater":
          if (
            !props.dependancyValue.filter(
              (el) => store[data][props.formName][props.dependOn] > el
            ).length
          ) {
            handleResetInput();
          }
          break;
        case "less":
          if (
            !props.dependancyValue.filter(
              (el) => store[data][props.formName][props.dependOn] < el
            ).length
          ) {
            handleResetInput();
          }
          break;
        case "equal":
          if (Array.isArray(store[data][props.formName][props.dependOn])) {

            !Boolean(
              props.dependancyValue.filter(
                (el) => store[data][props.formName][props.dependOn].includes(el)
              ).length
            ) && handleResetInput();
            return;
          }
          if (
            !props.dependancyValue.includes(
              store[data][props.formName][props.dependOn]
            )
          ) {
            handleResetInput();
          }
          break;
        default:
          return;
      }
    }
  }, [
    store[data][props.formName]
      ? store[data][props.formName][props.dependOn]
      : null,
  ]);
  const handleResetInput = () => {
    if (!props.readOnly && typeof props?.resetValue == "undefined") {
      dispatch(onInputResetAction(props.formName, props.name));
      // props.onInputResetAction(props.formName, props.name);
    }
    if (!props.readOnly && typeof props?.resetValue != "undefined") {
      dispatch(
        onInputResetWithValueAction(
          props.formName,
          props.name,
          props?.resetValue
        )
      );
    }
  };
  switch (props.dependancyType) {
    case "greater":
      return (
        Boolean(
          props.dependancyValue.filter(
            (el) => store[data][props.formName][props.dependOn] > el
          ).length
        ) && <Component {...props} />
      );
    case "less":
      return (
        Boolean(
          props.dependancyValue.filter(
            (el) => store[data][props.formName][props.dependOn] < el
          ).length
        ) && <Component {...props} />
      );
    case "equal":
      if (Array.isArray(store[data][props.formName][props.dependOn])) {
        return (
          Boolean(
            props.dependancyValue.filter(
              (el) => store[data][props.formName][props.dependOn].includes(el)
            ).length
          ) && <Component {...props} />
        );
      }
      return (
        props.dependancyValue.includes(
          store[data][props.formName][props.dependOn]
        ) && <Component {...props} />
      );
    default:
      return <Component {...props} />;
  }
};
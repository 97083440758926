import gql from "graphql-tag";


export const formFieldFragment = gql`
  fragment formField on FormField {
    id
    suggested_field_type
    grid
    parent_field_id
    name
    description
    order
    type
    selection_type
    dependent_option_names: dependable_options {
        value: id
        label: name
    }
    options {
        value: id
        label: name
    } 
  }
`;
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EmployeeForm from "../../Containers/EmployeeForm";
import BlockUi from "react-block-ui";

import { initEmployeeAction } from "../../Store/Actions";

const formName = `employeeForm`;
const salaryConfigFormName = `salaryConfigForm`;

const CreateEmployeeProfilePage = () => {
  // Redux hooks
  const dispatch = useDispatch();
  const initEmployeeStatus = useSelector(
    (state) => state.super.initEmployeeStatus
  );

  useEffect(() => {
    // Fetch All Employees Data On First Render
    dispatch(initEmployeeAction());
  }, []);

  return (
    <BlockUi tag="div" className="content" blocking={initEmployeeStatus}>
      <EmployeeForm formName={formName} isFirst={true} />
    </BlockUi>
  );
};

export default CreateEmployeeProfilePage;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MainModal from "../../../Components/MainModal";
import { InputForm } from "form-builder";
import { useMutation } from "@apollo/client";
import { UPSERT_WAREHOUSE } from "../../../Graphql/mutation";
import {
  onFormResetAction,
  toggleWarehouseModal,
} from "../../../Store/Actions";
import { showToast } from "../../../Helpers/HelperFns";

const formName = "upsertWarehouseForm";
const formNameValidation = "upsertWarehouseClientValidations";

const UpsertWarehouseModal = ({ handleRefetch }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [formSubmitting, setFormSubmitting] = useState(false);

  const isOpen = useSelector((state) => state?.assets?.warehouseModal?.isOpen);
  const formData = useSelector((state) => state?.assets?.[formName]);
  const clientValidations = useSelector(
    (state) => state?.assets?.[formNameValidation]
  );

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
    };
  }, []);

  const [attemptUpsertWarehous, { loading }] = useMutation(UPSERT_WAREHOUSE, {
    onCompleted: (data) => {
      if (data?.updateOrCreateWarehouse?.__typename == "GeneralException") {
        showToast("error", data?.updateOrCreateWarehouse?.message);

        return;
      }
      dispatch(toggleWarehouseModal({ isOpen: false }));
      handleRefetch();
    },
    onError(err) {
      showToast(
        "error",
        err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
      );
    },
  });

  const handleSubmit = () => {
    setFormSubmitting(true);

    if (!clientValidations?.length) {
      attemptUpsertWarehous({
        variables: {
          input: {
            id: formData?.id,
            name: formData?.name,
            address: formData?.address,
          },
        },
      });
    }
  };

  const dismiss = () => {
    dispatch(toggleWarehouseModal({ isOpen: false }));
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={dismiss}
      modalTitle={
        formData?.id ? t("edit storage location") : t("new storage location")
      }
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={loading}
    >
      <InputForm
        formName={formName}
        formNameValidation={formNameValidation}
        formSubmitting={formSubmitting}
        reducer="assets"
        validateBy="textRequired"
        name="name"
        placeholder={t("name")}
        label="name"
        labelStyle="mb-2"
        containerStyle="mt-0 mb-3"
      />

      <InputForm
        formName={formName}
        formNameValidation={formNameValidation}
        formSubmitting={formSubmitting}
        reducer="assets"
        validateBy="textRequired"
        name="address"
        placeholder={t("address")}
        label="address"
        labelStyle="mb-2"
        containerStyle="mt-0 mb-3"
      />
    </MainModal>
  );
};

export default UpsertWarehouseModal;

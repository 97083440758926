import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { hideToast } from "../../Store/Actions";

import { Snackbar, Alert } from "@mui/material";

const defaultMessages = {
	info: "info",
	success: "success",
	warning: "warning",
	error: "something went wrong",
};

const Toast = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	// Reducer State
	const {
		msg,
		type,
		isOpen = false,
		duration = 3000,
		translate = false,
	} = useSelector((state) => state?.feedback?.toast);

	/* Helpers */

	const handleClose = () => {
		dispatch(hideToast());
	};

	return (
		<Snackbar
			open={isOpen}
			onClose={handleClose}
			autoHideDuration={duration}
			anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
		>
			<Alert severity={type} onClose={handleClose}>
				{translate ? t(msg || defaultMessages?.[type]) : msg || defaultMessages?.[type]}
			</Alert>
		</Snackbar>
	);
};

export default Toast;

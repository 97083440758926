import React from "react";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import Privileges from "../../../Constants/Privilages";
import { onFormResetAction } from "../../../Store/Actions";
import { conditionalFooterRowStyles } from "../../../Constants";
import HelperFns, { showToast } from "../../../Helpers/HelperFns";
import { GET_AGENT_LEADS_STATUS_REPORT } from "../../../Graphql/query";

import Loader from "../../../Components/Loader";
import { DateTimePickerForm } from "form-builder";
import DataTable from "react-data-table-component";
import { LeadAgentSelect } from "../../../Components/CRM";

const filtersReducerKey = "agentLeadsStatusReport";
const formatData = (data) => {
  if (!data?.length) return data || [];

  const getCount = (val) => +val?.split(" (")?.[0];
  const sum = data?.reduce(
    (acc, curr) => ({
      lead_count: acc?.lead_count
        ? acc?.lead_count + +curr?.lead_count
        : +curr?.lead_count,
      fresh_lead_count_with_percentage: acc?.fresh_lead_count_with_percentage
        ? acc?.fresh_lead_count_with_percentage +
          getCount(curr?.fresh_lead_count_with_percentage)
        : getCount(curr?.fresh_lead_count_with_percentage),
      interested_count_with_percentage: acc?.interested_count_with_percentage
        ? acc?.interested_count_with_percentage +
          getCount(curr?.interested_count_with_percentage)
        : getCount(curr?.interested_count_with_percentage),
      unreachable_count_with_percentage: acc?.unreachable_count_with_percentage
        ? acc?.unreachable_count_with_percentage +
          getCount(curr?.unreachable_count_with_percentage)
        : getCount(curr?.unreachable_count_with_percentage),
      not_interested_count_with_percentage:
        acc?.not_interested_count_with_percentage
          ? acc?.not_interested_count_with_percentage +
            getCount(curr?.not_interested_count_with_percentage)
          : getCount(curr?.not_interested_count_with_percentage),
      follow_up_count_with_percentage: acc?.follow_up_count_with_percentage
        ? acc?.follow_up_count_with_percentage +
          getCount(curr?.follow_up_count_with_percentage)
        : getCount(curr?.follow_up_count_with_percentage),
      reviewing_proposal_count_with_percentage:
        acc?.reviewing_proposal_count_with_percentage
          ? acc?.reviewing_proposal_count_with_percentage +
            getCount(curr?.reviewing_proposal_count_with_percentage)
          : getCount(curr?.reviewing_proposal_count_with_percentage),
      demo_count_with_percentage: acc?.demo_count_with_percentage
        ? acc?.demo_count_with_percentage +
          getCount(curr?.demo_count_with_percentage)
        : getCount(curr?.demo_count_with_percentage),
      trial_count_with_percentage: acc?.trial_count_with_percentage
        ? acc?.trial_count_with_percentage +
          getCount(curr?.trial_count_with_percentage)
        : getCount(curr?.trial_count_with_percentage),
      client_count_with_percentage: acc?.client_count_with_percentage
        ? acc?.client_count_with_percentage +
          getCount(curr?.client_count_with_percentage)
        : getCount(curr?.client_count_with_percentage),
    }),
    {}
  );

  return [...data, { name: "Total", ...sum }];
};

const AgentLeadsStatusReport = () => {
  const dispatch = useDispatch();

  // Local State
  const [data, setData] = React.useState([]);

  // Reducer State
  const filters = useSelector((state) => state.crm[filtersReducerKey]);

  // Server State
  const { loading } = useQuery(GET_AGENT_LEADS_STATUS_REPORT, {
    variables: {
      to: filters?.to?.format("YYYY-MM-DD"),
      from: filters?.from?.format("YYYY-MM-DD"),
      agents: filters?.agents ? filters?.agents?.map((agent) => agent?.id) : [],
    },
    onCompleted: (res) => {
      setData(formatData(res?.agent_leads_status_report));
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
  });

  // Constants
  const canViewAll = HelperFns.checkPrivileges({
    privileges: [Privileges.VIEW_OTHER_CRM_REPORTS],
  });
  const columns = React.useMemo(
    () => [
      {
        name: "Agent name",
        selector: "name",
        grow: 2,
      },
      {
        name: "Total leads",
        selector: "lead_count",
      },
      {
        name: "Fresh leads",
        selector: "fresh_lead_count_with_percentage",
      },
      {
        name: "Interested",
        selector: "interested_count_with_percentage",
      },
      {
        name: "Unreachable",
        selector: "unreachable_count_with_percentage",
      },
      {
        name: "Not interested",
        selector: "not_interested_count_with_percentage",
      },
      { name: "Follow up", selector: "follow_up_count_with_percentage" },
      {
        name: "Reviewing Proposal",
        selector: "reviewing_proposal_count_with_percentage",
      },
      { name: "Demo", selector: "demo_count_with_percentage" },
      { name: "Trial", selector: "trial_count_with_percentage" },
      { name: "Client", selector: "client_count_with_percentage" },
      { name: "Clients/Total Demos (%)", selector: "clients_demo_ratio" },
    ],
    []
  );

  /* ↓ Helpers ↓ */

  const handleSelectFilters = (val, { name }) => {
    dispatch(onFormResetAction(filtersReducerKey, { ...filters, [name]: val }));
  };
  const handleDateFiltersChange = (name, val) => {
    dispatch(onFormResetAction(filtersReducerKey, { ...filters, [name]: val }));
  };

  return (
    <>
      {/* Filters */}
      <div className="d-flex gap-20 justify-content-between align-items-end mb-3 mt-2">
        {canViewAll ? (
          <LeadAgentSelect
            isMulti
            isClearable
            name="agents"
            label="agent"
            page="REPORTS"
            value={filters.agents}
            onChange={handleSelectFilters}
            inputContainerStyle="w-100"
            containerStyle=" "
            rootStyle="flex-1"
          />
        ) : null}
        <DateTimePickerForm
          hasIcon
          isClearable
          label="from"
          value={filters.from}
          rootStyle="flex-fill"
          containerStyle="py-0"
          labelStyle="w-100 mb-2"
          datePickerContainer="w-100"
          onChange={(val) => handleDateFiltersChange("from", val)}
        />
        <DateTimePickerForm
          hasIcon
          isClearable
          label="to"
          value={filters.to}
          rootStyle="flex-fill"
          containerStyle="py-0"
          labelStyle="w-100 mb-2"
          datePickerContainer="w-100"
          onChange={(val) => handleDateFiltersChange("to", val)}
        />
      </div>

      {/* Table */}
      <DataTable
        noHeader
        data={data}
        columns={columns}
        className="cards_table"
        progressPending={loading}
        progressComponent={<Loader />}
        conditionalRowStyles={conditionalFooterRowStyles}
      />
    </>
  );
};

export default AgentLeadsStatusReport;

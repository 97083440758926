import React from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { showToast } from "../../Helpers/HelperFns";
import { onFormResetAction } from "../../Store/Actions";
import { upsertDeductibleMutation } from "../../Graphql/mutation";

import MainModal from "../MainModal";
import { InputForm } from "form-builder";

const reducer = "settings";
const formNameValidation = "settingsClientValidation";
const formServerValidation = "settingsServerValidation";

const UpsertDeductibleModal = ({ data, onClose, refetchList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: data?.isEdit ? data?.name : "",
  });

  // Reducer State
  const clientValidations = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  // Server State
  const [upsert, { loading: upsertLoading }] = useMutation(
    upsertDeductibleMutation
  );

  // Constants
  const FormProps = {
    reducer,
    formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  /* State Effects */

  React.useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formServerValidation));
    };
  }, []);

  /* Helpers */

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpsert = () => {
    setFormSubmitting(true);
    if (clientValidations.length) return;

    upsert({
      variables: {
        input: { ...(data?.isEdit && { id: data.id }), name: formData.name },
      },
      onCompleted: () => {
        handleCloseModal();
        showToast("success");
        refetchList();
      },
      onError: (err) => {
        const validations = err?.graphQLErrors?.[0]?.extensions?.validation;
        if (validations) {
          dispatch(onFormResetAction(formServerValidation, validations));
        } else {
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason ||
              err?.graphQLErrors?.[0]?.message ||
              err?.message
          );
        }
      },
    });
  };

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <MainModal
      isOpen
      size="lg"
      toggle={handleCloseModal}
      btnOnClick={handleUpsert}
      btnSubmitLoading={upsertLoading}
      modalTitle={`${data?.isEdit ? "Edit" : "New"} Deductible`}
    >
      <InputForm
        name="name"
        label="name"
        rootStyle="w-100"
        validateBy="textRequired"
        placeholder={t("enter deductible name")}
        onChange={handleTextChange}
        inputContainerStyle="w-100"
        value={formData.name}
        validationName="input.name"
        {...FormProps}
      />
    </MainModal>
  );
};

export default UpsertDeductibleModal;

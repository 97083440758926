import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MainModal from "../../../Components/MainModal";
import { InputForm } from "form-builder";
import { useMutation } from "@apollo/client";
import { scrapItemMutation } from "../../../Graphql/mutation";
import {
  onFormResetAction,
  toggleScrappItemModal,
} from "../../../Store/Actions";
import { showToast } from "../../../Helpers/HelperFns";

const formName = "scrappItemForm";
const formNameValidation = "scrappItemClientValidations";

const ScrappItemModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [formSubmitting, setFormSubmitting] = useState(false);

  const isOpen = useSelector((state) => state?.assets?.scrappItemModal?.isOpen);

  const itemData = useSelector((state) => state?.assets?.scrappItemModal?.item);

  const formData = useSelector((state) => state?.assets?.[formName]);

  const clientValidations = useSelector(
    (state) => state?.assets?.[formNameValidation]
  );

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
    };
  }, []);

  // Start of handle scrap item mutation
  const [scrappedItem, { loading: scrapItemLoading }] = useMutation(
    scrapItemMutation,
    {
      onCompleted: (data) => {
        if (data?.scrappedItem?.__typename == "GeneralException") {
          // error toaster
          showToast("error", data?.scrappedItem?.message);
          return;
        }
        // success toaster
        showToast("success");

        // dismiss modal
        handleDismissModal();

        // handle refetch data list
        props?.handleRefetch();
      },
      onError(err) {
        // error toaster
        showToast(
          "error",
          err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
        );
      },
    }
  );
  // End of handle scrap item mutation

  const handleSubmit = () => {
    setFormSubmitting(true);

    if (!clientValidations?.length) {
      scrappedItem({
        variables: {
          input: {
            item_id: itemData?.id,
            note: formData?.note,
          },
        },
      });
    }
  };

  const handleDismissModal = () => {
    dispatch(toggleScrappItemModal({ isOpen: false, item: null }));
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={handleDismissModal}
      modalTitle={t("scrapp asset")}
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={scrapItemLoading}
    >
      <div className="mb-3">
        <h5 className="font-weight-bold">{t("asset")}</h5>
        <p className="mb-2">{`${itemData?.assetName} - ${itemData?.assetType} ${itemData?.assetBrand} ${itemData?.assetModel}`}</p>
        <p className="m-0">{itemData?.serial_number}</p>
      </div>

      <div className="row">
        <div className="col-12">
          <InputForm
            name="note"
            label={"notes"}
            labelStyle="mb-2"
            placeholder={t("notes")}
            formName={formName}
            formNameValidation={formNameValidation}
            formSubmitting={formSubmitting}
            reducer="assets"
            validationName={`input.note`}
            containerStyle=""
            inputContainerStyle="w-100"
            type="text"
            isMultiLines
          />
        </div>
      </div>
    </MainModal>
  );
};

export default ScrappItemModal;

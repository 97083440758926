import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MainModal from "../../../Components/MainModal";
import { BSelect } from "form-builder";
import { useMutation, useQuery } from "@apollo/client";
import { UNSCRAPP_ITEM, UPSERT_ASSET_TYPE } from "../../../Graphql/mutation";
import {
  onFormResetAction,
  toggleUnscrappItemModal,
} from "../../../Store/Actions";
import { showToast } from "../../../Helpers/HelperFns";
import { GET_WAREHOUSES_MENU } from "../../../Graphql/query";

const formName = "unscrappItemForm";
const formNameValidation = "unscrappItemClientValidations";

const UnscrappItemModal = ({ handleRefetch }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [formSubmitting, setFormSubmitting] = useState(false);

  const isOpen = useSelector(
    (state) => state?.assets?.unscrappItemModal?.isOpen
  );

  const itemData = useSelector(
    (state) => state?.assets?.unscrappItemModal?.item
  );

  const formData = useSelector((state) => state?.assets?.[formName]);

  const clientValidations = useSelector(
    (state) => state?.assets?.[formNameValidation]
  );

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
    };
  }, []);

  const { data: warehousesMenuQueryData, loading: warehousesLoading } =
    useQuery(GET_WAREHOUSES_MENU);

  const [attemptUnscrappItem, { loading }] = useMutation(UNSCRAPP_ITEM, {
    onCompleted: (data) => {
      if (data?.unscrappedItem?.__typename == "GeneralException") {
        showToast("error", data?.unscrappedItem?.message);

        return;
      }
      dispatch(toggleUnscrappItemModal({ isOpen: false, item: null }));
      handleRefetch();
    },
    onError(err) {
      showToast(
        "error",
        err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
      );
    },
  });

  const handleSubmit = () => {
    setFormSubmitting(true);

    if (!clientValidations?.length) {
      attemptUnscrappItem({
        variables: {
          input: {
            item_id: itemData?.id,
            warehouse_id: formData?.warehouseId,
          },
        },
      });
    }
  };

  const dismiss = () => {
    dispatch(toggleUnscrappItemModal({ isOpen: false, item: null }));
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={dismiss}
      modalTitle={t("unscrapp asset")}
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={loading}
    >
      <div className="mb-3">
        <h5 className="font-weight-bold">{t("asset")}</h5>
        <p className="mb-2">{`${itemData?.asset?.name} - ${itemData?.asset?.assetType?.name}`}</p>
        <p className="m-0">{itemData?.serial_number}</p>
      </div>

      <BSelect
        formName={formName}
        formNameValidation={formNameValidation}
        formSubmitting={formSubmitting}
        reducer="assets"
        validateBy="textRequired"
        name="warehouseId"
        label="storage location"
        labelStyle="mb-2"
        keepDefaultStyle
        isLoading={warehousesLoading}
        options={warehousesMenuQueryData?.companyWarehouses?.data ?? []}
        inputContainerStyle=""
        placeholder={t("Select storage location")}
        icon="location"
      />
    </MainModal>
  );
};

export default UnscrappItemModal;

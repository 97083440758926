import React, { useEffect, useState } from "react";
import { InputForm } from "form-builder";
import { useTranslation } from "react-i18next";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  onFormResetAction,
  toggleWarehouseDetailsModal,
  toggleWarehouseModal,
} from "../../../Store/Actions";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_ASSET_TYPES, GET_WAREHOUSES } from "../../../Graphql/query";
import DataTable from "react-data-table-component";
import Loader from "../../../Components/Loader";
import Pagination from "../../../Components/Pagination";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../../Components/IconButtonWithTooltip";
import Swal from "sweetalert2";
import { DELETE_ASSET_TYPE, DELETE_WAREHOUSE } from "../../../Graphql/mutation";
import HelperFns, { showToast } from "../../../Helpers/HelperFns";
import UpsertWarehouseModal from "./UpsertWarehouseModal";
import { IconButton } from "@mui/material";
import WarehouseDetailsModal from "./WarehouseDetailsModal";
import TransferAssetsModal from "./TransferAssetsModal";
import Privilages from "../../../Constants/Privilages";

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const StorageLocations = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isUpsertWarehouseModalOpen = useSelector(
    (state) => state?.assets?.warehouseModal?.isOpen
  );

  const isWarehouseDetailsModalOpen = useSelector(
    (state) => state?.assets?.warehouseDetailsModal?.isOpen
  );

  const isTransferAssetsModalOpen = useSelector(
    (state) => state?.assets?.transferAssetsModal?.isOpen
  );

  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState(paginationInitState);

  const [fetchWarehouses, { loading, data, refetch }] = useLazyQuery(
    GET_WAREHOUSES,
    {
      variables: {
        page: pagination?.currentPage,
        input: {
          ...(!!search
            ? {
                filter: {
                  name: search,
                },
              }
            : {}),
        },
      },
      onCompleted: (data) => {
        setPagination(data?.companyWarehouses?.paginatorInfo);
      },
    }
  );

  useEffect(() => {
    fetchWarehouses();
  }, []);

  const warehouses = data?.companyWarehouses?.data ?? [];

  const handleRefetchData = () => {
    fetchWarehouses();
  };

  const [attemptDeleteWarehouse, { loading: deleteWarehouseLoading }] =
    useMutation(DELETE_WAREHOUSE, {
      onCompleted: (data) => {
        if (data?.deleteWarehouse?.__typename == "GeneralException") {
          showToast("error", data?.deleteWarehouse?.message);
          return;
        }
        handleRefetchData();
      },
      onError(err) {
        showToast(
          "error",
          err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
        );
      },
    });

  const handleChange = (e) => {
    setSearch(e?.target?.value ?? "");
  };

  const handleAddNew = () => {
    dispatch(toggleWarehouseModal({ isOpen: true }));
  };

  const handlePaginate = (page = pagination?.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleEdit = (warehouse) => {
    dispatch(toggleWarehouseModal({ isOpen: true }));
    dispatch(onFormResetAction("upsertWarehouseForm", { ...warehouse }));
  };

  const handleDelete = (warehouse) => {
    Swal.fire({
      icon: "warning",
      title: t("are you sure"),
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      customClass: {
        popup: "swal-warning-style",
        icon: "swal-icon",
        denyButton: "order-2",
        confirmButton: "order-3",
        htmlContainer: "attendance-type-alert-text",
      },
    }).then(async (values) => {
      if (values.isConfirmed) {
        attemptDeleteWarehouse({
          variables: {
            id: warehouse?.id,
          },
        });
      }
    });
  };

  const handleViewWarehouseDetails = (warehouseId) => {
    dispatch(toggleWarehouseDetailsModal({ isOpen: true, warehouseId }));
  };

  const columns = [
    {
      name: t("name"),
      cell: (warehouse) => {
        return (
          <IconButton
            size="small"
            style={{
              color: "#5d6979",
              borderRadius: 5,
            }}
            onClick={() => handleViewWarehouseDetails(warehouse?.id)}
            {...(!HelperFns.checkPrivileges({
              allowBP: true,
              privileges: [
                Privilages.VIEW_STORED_ASSETS_UNITS_IN_EACH_LOCATION,
              ],
            })
              ? { disabled: true }
              : {})}
          >
            {warehouse?.name}
          </IconButton>
        );
      },
    },
    { name: t("address"), selector: "address" },
    {
      name: t("stored"),
      selector: "items_count",
    },
    {
      width: "100px",
      cell: (warehouse) => (
        <div className="d-flex align-items-center gap-5">
          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_DELETE_STORAGE_LOCATIONS]}
            allowBP
          >
            <EditIconButton onClick={() => handleEdit(warehouse)} />
            <RemoveIconButton onClick={() => handleDelete(warehouse)} />
          </HasPrivileges>
        </div>
      ),
    },
  ];

  return (
    <div className="py-3">
      <div className="d-flex align-items-center gap-10">
        <InputForm
          placeholder={t("search")}
          containerStyle="w-100"
          icon="search"
          value={search}
          onChange={handleChange}
          rootStyle="w-100"
        />
        <HasPrivileges
          reqireMain={[Privilages.ADD_EDIT_DELETE_STORAGE_LOCATIONS]}
          allowBP
        >
          <button
            className="btn add_new_btn_style text-nowrap"
            onClick={handleAddNew}
          >
            <AddIcon className="add_btn_icon" />
            {t("add new")}
          </button>
        </HasPrivileges>
      </div>

      <DataTable
        noHeader
        data={warehouses}
        columns={columns}
        className="cards_table my-3"
        progressPending={loading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {isUpsertWarehouseModalOpen ? (
        <UpsertWarehouseModal handleRefetch={handleRefetchData} />
      ) : null}

      {isWarehouseDetailsModalOpen ? (
        <WarehouseDetailsModal handleRefetch={handleRefetchData} />
      ) : null}

      {isTransferAssetsModalOpen ? (
        <TransferAssetsModal handleRefetch={handleRefetchData} />
      ) : null}
    </div>
  );
};

export default StorageLocations;

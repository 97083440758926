import React from "react";

import { useTranslation } from "react-i18next";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CustomTableHeader = (props) => {
  const { t } = useTranslation();

  // props variables
  const currentColumnId = props?.column?.colId;
  const columnHeaderName = props?.displayName;

  return (
    <div
      className={`custom_table_header ${currentColumnId}_wrapper ${
        props?.enableSorting && "cursor-pointer"
      }`}
      onClick={() => props?.handleHeaderClick(currentColumnId)}
    >
      <span>{t(columnHeaderName)}</span>
      <span
        className={`${
          props?.sortColumn === currentColumnId
            ? "custom_table_header_icons"
            : "d-none"
        }`}
      >
        <KeyboardArrowUpIcon
          className={`${props?.sortDirection === "DESC" && "d-none"}`}
        />
        <KeyboardArrowDownIcon
          className={`${props?.sortDirection === "ASC" && "d-none"}`}
        />
      </span>
    </div>
  );
};

export default CustomTableHeader;

import React from "react";
import MyPettyCash from "../../Containers/MyPettyCash";

const MyPettyCashTab = (props) => {
  return (
    <>
      <MyPettyCash />
    </>
  );
};

export default MyPettyCashTab;

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  setWizardAttType,
  setSetupWizardActiveTab,
  EditSetupWizardEssentialData,
} from "../../Store/Actions";
import Constants from "../../Constants";
import Privilages from "../../Constants/Privilages";
import { checkCompanyPrivileges } from "../../Helpers/HelperFns";

import { Radio, RadioGroup, FormControlLabel } from "@mui/material";

const labelID = "setup-wizard-attType-step-title";
const isCompanyHasShift = checkCompanyPrivileges({
  privileges: [Privilages.VIEW_EMPLOYEE_WORK_GROUPS],
});

const SetupWizardAttType = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [msg, setMsg] = React.useState("");

  // Reducer State
  const { companyWizardActiveTab, wizardAttType } = useSelector(
    (state) => state.wizard
  );

  /* ↓ Helpers ↓ */

  const handleChange = (event) => {
    dispatch(setWizardAttType(event.target.value));
  };

  const handleNext = () => {
    if (wizardAttType) {
      dispatch(setSetupWizardActiveTab(companyWizardActiveTab + 1));
      dispatch(EditSetupWizardEssentialData("wizardAttType"));
      setMsg("");
    } else {
      setMsg(t("validation.filled"));
    }
  };

  return (
    <section className="setup-wizard-step">
      <h2 className="setup-wizard-title mb-4">
        {t("Choose your attendance type")}
      </h2>

      <div className="card-box pt-0">
        <RadioGroup
          value={wizardAttType}
          onChange={handleChange}
          aria-labelledby={labelID}
          name="setup-wizard-attType-step"
        >
          <FormControlLabel
            value={Constants.attendanceTypes.OFFICE_BASED}
            label={t("office based")}
            control={<Radio />}
          />
          <FormControlLabel
            value={Constants.attendanceTypes.SHIFT_BASED}
            label={t("shift based")}
            control={<Radio />}
            disabled={!isCompanyHasShift}
          />
        </RadioGroup>
        {msg ? (
          <p role="alert" className="error-color mt-2">
            {msg}
          </p>
        ) : null}

        <button
          onClick={handleNext}
          className="btn btn-primary submit_btn_style ml-auto d-block mt-4"
        >
          {t("next")}
        </button>
      </div>
    </section>
  );
};

export default SetupWizardAttType;

import React from "react";
import { connect } from "react-redux";
import MainModal from "../../Components/MainModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import { handleDashbaordUiToggleCheckboxAction } from "../../Store/Actions";

const formName = "dashboardChartsColumns";

const ToggleModal = (props) => {
  const { t } = useTranslation();

  // handle checkbox toggle
  const handleDashboardCheckBoxChange = (event) => {
    event.persist();
    props.handleDashbaordUiToggleCheckboxAction(formName, event);
  };

  return (
    <MainModal
      isOpen={props?.isModalVissible}
      toggle={props?.toggleModal}
      modalTitle={t("toggle columns")}
      btnLabel={t("close")}
      btnOnClick={props?.toggleModal}
    >
      <div className="row">
        <div className="col-12">
          {/* (Start) View Employee Daily Statuses */}
          <HasPrivileges
            reqireMain={[Privilages.VIEW_EMPLOYEE_DAILY_STATUSES]}
            allowBP
          >
            <div className="mb-1">
              <label className={"checkbox"}>
                <input
                  type="checkbox"
                  name={"view_employee_daily_statuses"}
                  value={
                    props.dashboardChartsColumns.view_employee_daily_statuses
                  }
                  onChange={(e) => handleDashboardCheckBoxChange(e)}
                  checked={
                    +props.dashboardChartsColumns.view_employee_daily_statuses
                  }
                />
                <span></span>

                <div
                  className={
                    props.dashboardChartsColumns.view_employee_daily_statuses
                      ? "booleanInputLabelStyle lightActiveColor"
                      : "booleanInputLabelStyle"
                  }
                >
                  {t("View Employee Daily Statuses")}
                </div>
              </label>
            </div>
          </HasPrivileges>
          {/* (End) View Employee Daily Statuses */}

          {/* (Start) View Employee Attendance History Chart */}
          <HasPrivileges
            reqireMain={[Privilages.VIEW_EMPLOYEE_ATTENDANCE_HISTORY_CHART]}
            allowBP
            avalibleOnExpire={false}
          >
            <div className="mb-1">
              <label className={"checkbox"}>
                <input
                  type="checkbox"
                  name={"view_employee_attendance_history_chart"}
                  value={
                    props?.dashboardChartsColumns
                      ?.view_employee_attendance_history_chart
                  }
                  onChange={(e) => handleDashboardCheckBoxChange(e)}
                  checked={
                    +props?.dashboardChartsColumns
                      ?.view_employee_attendance_history_chart
                  }
                />
                <span></span>

                <div
                  className={
                    props?.dashboardChartsColumns
                      ?.view_employee_attendance_history_chart
                      ? "booleanInputLabelStyle lightActiveColor"
                      : "booleanInputLabelStyle"
                  }
                >
                  {t("View Employee Attendance History Chart")}
                </div>
              </label>
            </div>
          </HasPrivileges>
          {/* (End) View Employee Attendance History Chart */}

          {/* (Start) View Employee Overtime Chart */}
          <HasPrivileges
            reqireMain={[Privilages.VIEW_EMPLOYEE_OVERTIME_CHART]}
            allowBP
            avalibleOnExpire={false}
          >
            <div className="mb-1">
              <label className={"checkbox"}>
                <input
                  type="checkbox"
                  name={"view_employee_overtime_chart"}
                  value={
                    props?.dashboardChartsColumns?.view_employee_overtime_chart
                  }
                  onChange={(e) => handleDashboardCheckBoxChange(e)}
                  checked={
                    +props?.dashboardChartsColumns?.view_employee_overtime_chart
                  }
                />
                <span></span>

                <div
                  className={
                    props?.dashboardChartsColumns?.view_employee_overtime_chart
                      ? "booleanInputLabelStyle lightActiveColor"
                      : "booleanInputLabelStyle"
                  }
                >
                  {t("View Employee Overtime Chart")}
                </div>
              </label>
            </div>
          </HasPrivileges>
          {/* (End) View Employee Overtime Chart */}

          {/* (Start) View Employees Per Department Chart */}
          <HasPrivileges
            reqireMain={[Privilages.VIEW_EMPLOYEES_PER_DEPARTMENT_CHART]}
            allowBP
            avalibleOnExpire={false}
          >
            <div className="mb-1">
              <label className={"checkbox"}>
                <input
                  type="checkbox"
                  name={"view_employees_per_department_chart"}
                  value={
                    props?.dashboardChartsColumns
                      ?.view_employees_per_department_chart
                  }
                  onChange={(e) => handleDashboardCheckBoxChange(e)}
                  checked={
                    +props?.dashboardChartsColumns
                      ?.view_employees_per_department_chart
                  }
                />
                <span></span>

                <div
                  className={
                    props?.dashboardChartsColumns
                      ?.view_employees_per_department_chart
                      ? "booleanInputLabelStyle lightActiveColor"
                      : "booleanInputLabelStyle"
                  }
                >
                  {t("View Employees Per Department Chart")}
                </div>
              </label>
            </div>
          </HasPrivileges>
          {/* (End) View Employees Per Department Chart */}

          {/* (Start) View The Expenses Chart Of My Office */}
          <HasPrivileges
            reqireMain={[Privilages.VIEW_THE_EXPENSES_CHART_OF_MY_OFFICE]}
            allowBP
            avalibleOnExpire={false}
          >
            <div className="mb-1">
              <label className={"checkbox"}>
                <input
                  type="checkbox"
                  name={"view_the_expenses_chart_of_my_office"}
                  value={
                    props?.dashboardChartsColumns
                      ?.view_the_expenses_chart_of_my_office
                  }
                  onChange={(e) => handleDashboardCheckBoxChange(e)}
                  checked={
                    +props?.dashboardChartsColumns
                      ?.view_the_expenses_chart_of_my_office
                  }
                />
                <span></span>

                <div
                  className={
                    props?.dashboardChartsColumns
                      ?.view_the_expenses_chart_of_my_office
                      ? "booleanInputLabelStyle lightActiveColor"
                      : "booleanInputLabelStyle"
                  }
                >
                  {t("View The Expenses Chart Of My Office")}
                </div>
              </label>
            </div>
          </HasPrivileges>
          {/* (End) View The Expenses Chart Of My Office */}

          {/* (Start) View Turnover Chart */}
          <HasPrivileges
            reqireMain={[Privilages.VIEW_TURNOVER_CHART]}
            allowBP
            avalibleOnExpire={false}
          >
            <div className="mb-1">
              <label className={"checkbox"}>
                <input
                  type="checkbox"
                  name={"view_turnover_chart"}
                  value={props?.dashboardChartsColumns?.view_turnover_chart}
                  onChange={(e) => handleDashboardCheckBoxChange(e)}
                  checked={+props?.dashboardChartsColumns?.view_turnover_chart}
                />
                <span></span>

                <div
                  className={
                    props?.dashboardChartsColumns?.view_turnover_chart
                      ? "booleanInputLabelStyle lightActiveColor"
                      : "booleanInputLabelStyle"
                  }
                >
                  {t("View Turnover Chart ")}
                </div>
              </label>
            </div>
          </HasPrivileges>
          {/* (End) View Turnover Chart */}

          {/* (Start) Open Payroll Months */}
          <HasPrivileges
            reqireMain={[Privilages.VIEW_OPEN_PAYROLL_MONTHS]}
            allowBP
            avalibleOnExpire={false}
          >
            <div className="mb-1">
              <label className={"checkbox"}>
                <input
                  type="checkbox"
                  name={"view_open_payroll_months"}
                  value={
                    props?.dashboardChartsColumns?.view_open_payroll_months
                  }
                  onChange={(e) => handleDashboardCheckBoxChange(e)}
                  checked={
                    +props?.dashboardChartsColumns?.view_open_payroll_months
                  }
                />
                <span></span>

                <div
                  className={
                    props?.dashboardChartsColumns?.view_open_payroll_months
                      ? "booleanInputLabelStyle lightActiveColor"
                      : "booleanInputLabelStyle"
                  }
                >
                  {t("View Open Payroll Months")}
                </div>
              </label>
            </div>
          </HasPrivileges>
          {/* (End) Open Payroll Months */}

          {/* (Start) View Missing / Expired Documents */}
          <HasPrivileges
            reqireMain={[Privilages.VIEW_MISSING_EXPIRED_DOCUMENT]}
            allowBP
            avalibleOnExpire={false}
          >
            <div className="mb-1">
              <label className={"checkbox"}>
                <input
                  type="checkbox"
                  name={"VIEW_MISSING_EXPIRED_DOCUMENT"}
                  value={
                    props?.dashboardChartsColumns?.VIEW_MISSING_EXPIRED_DOCUMENT
                  }
                  onChange={(e) => handleDashboardCheckBoxChange(e)}
                  checked={
                    +props?.dashboardChartsColumns
                      ?.VIEW_MISSING_EXPIRED_DOCUMENT
                  }
                />
                <span></span>

                <div
                  className={
                    props?.dashboardChartsColumns?.VIEW_MISSING_EXPIRED_DOCUMENT
                      ? "booleanInputLabelStyle lightActiveColor"
                      : "booleanInputLabelStyle"
                  }
                >
                  {t("View Missing / Expired Documents")}
                </div>
              </label>
            </div>
          </HasPrivileges>
          {/* (End) View Missing / Expired Documents */}

          {/* (Start) View Inbound Requests */}
          {/* <HasPrivileges
            reqireMain={[Privilages.VIEW_EMPLOYEE_REQUESTS]}
            allowBP
          >
            <div>
              <label className={"checkbox"}>
                <input
                  type="checkbox"
                  name={"VIEW_EMPLOYEE_REQUESTS"}
                  value={props?.dashboardChartsColumns?.VIEW_EMPLOYEE_REQUESTS}
                  onChange={(e) => handleDashboardCheckBoxChange(e)}
                  checked={
                    +props?.dashboardChartsColumns?.VIEW_EMPLOYEE_REQUESTS
                  }
                />
                <span></span>

                <div
                  className={
                    props?.dashboardChartsColumns?.VIEW_EMPLOYEE_REQUESTS
                      ? "booleanInputLabelStyle lightActiveColor"
                      : "booleanInputLabelStyle"
                  }
                >
                  {t("View Inbound Requests")}
                </div>
              </label>
            </div>
          </HasPrivileges> */}
          {/* (End) View Inbound Requests */}
        </div>
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboardChartsColumns: state.dashboardUi.dashboardChartsColumns,
  };
};

export default connect(mapStateToProps, {
  handleDashbaordUiToggleCheckboxAction,
})(ToggleModal);

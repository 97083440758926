import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import { makeStyles } from "tss-react/mui";
import HelperFns from "../../../Helpers/HelperFns";
import Constants, { crmTabs } from "../../../Constants";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const verticalNavbarStyles = makeStyles()(() => Constants.verticalNavbarStyles);
const horizontalNavbarStyles = makeStyles()(
  () => Constants.horizontalNavbarStyles
);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CRMTabs = ({ isRouteSidebar }) => {
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();

  // Constants
  const activeValue = query.get("tab");
  const { classes } = isRouteSidebar
    ? verticalNavbarStyles()
    : horizontalNavbarStyles();

  /* ↓ Helpers ↓ */

  const handleChange = (_, newValue) => {
    history.push(`/crm?tab=${newValue}`);
  };

  return (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      onChange={handleChange}
      orientation={isRouteSidebar ? "vertical" : "horizontal"}
      value={
        crmTabs.map(({ value }) => value).includes(activeValue)
          ? activeValue
          : false
      }
      classes={{
        root: classes.root,
        indicator: classes.indicator,
        flexContainer: classes.flexContainer,
        scrollButtons: classes.scrollButtons,
      }}
    >
      {crmTabs.map((tab) => (
        <Tab
          key={tab?.value}
          value={tab?.value}
          classes={{
            root: HelperFns.checkPrivileges({
              allowBP: false,
              privileges: tab?.privileges,
            })
              ? classes.tabRoot
              : "d-none",
            wrapper: "top-bar-label",
            selected: classes.selected,
          }}
          label={
            <div className="mx-2 routeBar-text">
              {tab?.icon || (
                <FontAwesomeIcon
                  icon={faMailBulk}
                  className="mx-2 routeBar-icon"
                />
              )}
              {t(tab?.value || tab?.value)}
            </div>
          }
        />
      ))}
    </Tabs>
  );
};

export default CRMTabs;

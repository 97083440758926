import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { InputForm } from "form-builder";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import {
  dismissCompanySettingsModalAction,
  upsertCompanySettingAction,
  onInputChangeAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";

const formName = "companySettingsModal";
const formNameValidation = "companySettingsModalValidation";
const formServerValidation = "companySettingsModalServerValidation";

const CompanySettingsModal = (props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }

    let updatedSubCategory = props?.companySettingsModal?.subCategory.map(
      (sub) =>
        sub.__isNew__
          ? { id: null, name: sub.label }
          : { id: sub.value, name: sub.label }
    );

    props.upsertCompanySettingAction({
      data: { ...props?.companySettingsModal, subCategory: updatedSubCategory },
      ref: "companySettingsModalActions",
    });
  };

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const toggleModal = () => {
    props.dismissCompanySettingsModalAction({
      ref: "companySettingsModalActions",
      formName,
    });
  };

  // Start of handling on create select component option
  const handleChange = (newValue) => {
    let e = {
      target: {
        value: newValue == null ? [] : newValue,
        name: "subCategory",
      },
    };
    props.onInputChangeAction(formName, e);
  };
  // End of handling on create select component option

  useEffect(() => {
    setIsSubmitting(false);
  }, [isModalVissible]);

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={t(
        `${props.companySettingsModal?.id != null ? "Edit" : "Add"} Category`
      )}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="company_settings_form_wrapper_style"
    >
      <div>
        <div className="row mb-2">
          <div className="col-12">
            <InputForm
              label="category name"
              placeholder="category name"
              labelStyle="mb-2"
              reducer="finances"
              {...FormProps}
              name="name"
              validationName={`input.name`}
              containerStyle=""
              inputContainerStyle="w-100"
              validateBy="textRequired"
              icon="person"
            />
          </div>
        </div>

        <div className="row mb-3">
          <div
            className={`col-12 ${
              props?.serverVaildation &&
              "input.subCategory" in props?.serverVaildation
                ? "invalid-container-style"
                : ""
            }`}
          >
            <label className="mb-2 validity-label-style">
              {t("sub-categories")}
            </label>
            <CreatableSelect
              className="select-def-input-container"
              classNamePrefix="b-select-style"
              isMulti
              onChange={handleChange}
              options={props?.companySettingsModal?.sub_categories}
              value={props?.companySettingsModal?.subCategory}
              placeholder={t("select option")}
            />

            <span className="validity-msg-style">
              {props?.serverVaildation &&
              "input.subCategory" in props?.serverVaildation
                ? props?.serverVaildation["input.subCategory"]
                : null}
            </span>
          </div>
        </div>

        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.finances.companySettingsModalActions,
  modalValidation: state.finances[formNameValidation],
  companySettingsModal: state.finances[formName],
  serverVaildation: state.finances[formServerValidation],
  data: state.finances,
});

export default connect(mapStateToProps, {
  dismissCompanySettingsModalAction,
  upsertCompanySettingAction,
  onInputChangeAction,
})(CompanySettingsModal);

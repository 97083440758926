import React from "react";
import { InputForm } from "form-builder";
import { useTranslation } from "react-i18next";

const MedicalSection = (props) => {
  const {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
    index,
  } = props;

  const FormProps = {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
  };

  const { t } = useTranslation();

  return (
    <div className="flex-fill order-two">
      <InputForm
        name="medicalDeduction"
        type="number"
        label="medical insurance"
        onWheelCapture={(e) => {
          e.target.blur();
        }}
        placeholder={t("amount")}
        {...FormProps}
        labelStyle="mb-2"
        containerStyle=""
        inputContainerStyle="w-100"
        validateBy="textRequired"
        validationName={`input.user_salary_config_input.salary_deductibles.${index}.value`} // should change this
        icon="money"
      />
    </div>
  );
};

export default MedicalSection;

import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Pagination from "../../Components/Pagination";
import { WorkGroupModal } from "../../Components/WorkGroupModal";

// Apollo
import { useLazyQuery, useMutation } from "@apollo/client";
import { fetchWorkGroupsQuery } from "../../Graphql/query";
import { deleteWorkGroupMutation } from "../../Graphql/mutation";

// Ui
import { PreviewIconButton } from "../../Components/IconButtonWithTooltip";
import EmployeesModal from "../../Components/SharedModals/EmployeesModal";
import Loader from "../../Components/Loader";
import Swal from "sweetalert2";

// Icons
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Helper Functions
import { fetchSetupWizardEssentialDataAttempt } from "../../Store/Actions";
import HelperFns, { handleActionMutation } from "../../Helpers/HelperFns";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";

const employeeModalInitState = { data: null, isOpen: false };

const ViewWorkGroups = () => {
  const dispatch = useDispatch();
  const [workGroupId, setWorkGroupId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employeeModalState, setEmployeeModalState] = useState(
    employeeModalInitState
  );
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    lastItem: 20,
    total: 20,
    perPage: 20,
    currentPage: 1,
    hasMorePages: true,
    firstItem: 1,
    lastPage: 4,
  });

  // Apollo hooks
  const [fetchWorkGroups, { data = [], loading: fetchWorkGroupsLoading }] =
    useLazyQuery(fetchWorkGroupsQuery, {
      variables: {
        name: search,
        first: pagination?.perPage,
        page: pagination?.currentPage,
      },
      onCompleted: (data) => {
        setPagination(data?.workGroups?.paginatorInfo);
      },
      onError: (error) => {
        Swal.fire({
          title: t("error"),
          text: error.message ? error.message : t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
          showDenyButton: false,
        });
      },
    });

  const [deleteWorkGroup] = useMutation(deleteWorkGroupMutation);

  useEffect(() => {
    fetchWorkGroups();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);

    fetchWorkGroups({
      variables: {
        page: 1,
      },
    });
  };

  const handlePaginate = (page) => {
    fetchWorkGroups({
      variables: {
        page,
      },
    });
  };

  const handleEdit = (id) => {
    setWorkGroupId(id);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    Swal.fire({
      icon: "warning",
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      customClass: {
        popup: "swal-warning-style",
        icon: "swal-icon",
        denyButton: "order-2",
        confirmButton: "order-3",
      },
    }).then((values) => {
      if (values.isConfirmed) {
        handleActionMutation(deleteWorkGroup, {
          variables: {
            id,
          },
          refetchQuery: fetchWorkGroups,
          onCompleted: () => {
            dispatch(fetchSetupWizardEssentialDataAttempt());
          },
        });
      }
    });
  };

  const columns = [
    {
      name: t("name"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <div className="name_entry">
          <PersonOutlineIcon />
          <span dir={HelperFns.isStringRTL(row?.name) ? "rtl" : "ltr"}>
            {row?.name}
          </span>
        </div>
      ),
    },
    {
      name: t("managers"),
      selector: "managers",
      wrap: true,
      sortable: false,
      grow: 1,
      cell: (row) =>
        row?.managers?.map((manager) => manager?.name).join(", ") ??
        "----------",
    },
    {
      name: t("No. Of Employees"),
      wrap: true,
      selector: "employees",
      sortable: false,
      grow: 1,
      cell: (row) => row?.employees?.length ?? "----------",
    },
    {
      grow: 1,
      cell: (row) => (
        <>
          <PreviewIconButton
            label="Preview employees"
            onClick={() =>
              setEmployeeModalState({
                isOpen: true,
                data: { id: row?.id, isWorkgroup: true },
              })
            }
          />
          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_DELETE_WORK_GROUPS]}
            allowBP
          >
            <div className="action_btns">
              {row?.canEdit ? (
                <FontAwesomeIcon
                  icon={faPencilAlt}
                  className="edit_icon"
                  onClick={() => handleEdit(row?.id)}
                />
              ) : null}
              {row?.canDelete ? (
                <FontAwesomeIcon
                  icon={faTimes}
                  className="delete_icon"
                  onClick={() => handleDelete(row?.id)}
                />
              ) : null}
            </div>
          </HasPrivileges>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center gap-20 mt-3">
        <div className="input-container-style-default search_container">
          <div className="input_icon">
            <SearchIcon />
          </div>
          <input
            type="text"
            placeholder={t("search")}
            className="search_input"
            onChange={handleSearch}
          />
        </div>

        <HasPrivileges
          reqireMain={[Privilages.ADD_EDIT_DELETE_WORK_GROUPS]}
          allowBP
        >
          <button
            className="btn add_new_btn_style"
            onClick={() => setIsModalOpen(true)}
          >
            <AddIcon className="add_btn_icon" />
            {t("add new")}
          </button>
        </HasPrivileges>
      </div>

      <div className="w-100 mt-4" style={{ overflow: "auto" }}>
        <div style={{ minWidth: "1200px" }}>
          <DataTable
            className="cards_table"
            columns={columns}
            data={data?.workGroups?.data}
            keyField="id"
            noHeader
            persistTableHead
            sortServer
            progressPending={fetchWorkGroupsLoading}
            progressComponent={<Loader />}
          />
        </div>
      </div>

      <Pagination
        customPaginator={pagination}
        styleWraper=""
        onPaginate={handlePaginate}
      />

      {/* Modal */}
      {isModalOpen ? (
        <WorkGroupModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          fetchWorkGroups={fetchWorkGroups}
          workGroupId={workGroupId}
          setWorkGroupId={setWorkGroupId}
        />
      ) : null}
      {employeeModalState.isOpen ? (
        <EmployeesModal
          data={employeeModalState.data}
          onClose={() => setEmployeeModalState(employeeModalInitState)}
        />
      ) : null}
    </>
  );
};

export default ViewWorkGroups;

import React, { useEffect, useState } from "react";
import {
  InputForm,
  BSelect,
  CheckboxBooleanForm,
  DateTimePickerForm,
} from "form-builder";
import MainModal from "../../Components/MainModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { onInputResetWithValueAction } from "../../Store/Actions";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_COST_CENTER_FORM_OPTIONS } from "../../Graphql/query";
import CreateSelect from "../../Builder/Form/CreateSelect";
import {
  createNewAllowanceMutation,
  CREATE_COST_CENTER,
  UPDATE_COST_CENTER,
  ADD_COST_CENTER_CONFIGURATION,
} from "../../Graphql/mutation";
import { showToast } from "../../Helpers/HelperFns";
import moment from "moment";
import {
  DIVIDE_SALARY_BY_VALUES,
  divideSalaryByOptions,
} from "../../Constants";

const PAYMENT_FACTORS = {
  FIXED_AMOUNT: "Fixed",
  PERCENT_OF_BASE: "Percent of base salary",
  PERCENT_OF_GROSS: "Percent of gross salary",
  PERCENT_OF_NET: "Percent of net salary",
};

const paymentFactorsOptions = [
  { id: PAYMENT_FACTORS.FIXED_AMOUNT, name: PAYMENT_FACTORS.FIXED_AMOUNT },
  {
    id: PAYMENT_FACTORS.PERCENT_OF_BASE,
    name: PAYMENT_FACTORS.PERCENT_OF_BASE,
  },
  {
    id: PAYMENT_FACTORS.PERCENT_OF_GROSS,
    name: PAYMENT_FACTORS.PERCENT_OF_GROSS,
  },
  { id: PAYMENT_FACTORS.PERCENT_OF_NET, name: PAYMENT_FACTORS.PERCENT_OF_NET },
];

const CostCenterForm = ({
  isOpen = false,
  handleCloseModal = () => {},
  formProps = {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [allowancesOptions, setAllowancesOptions] = useState([]);

  const clientValidations = useSelector(
    (state) => state?.super?.[formProps.formNameValidation]
  );

  const isEditingCostCenterAllowanceConfigs = useSelector(
    (state) => state?.super?.isEditingCostCenterAllowanceConfigs
  );

  const [
    attemptGetFormOptions,
    { data: formOptionsData, loading: formOptionsLoading },
  ] = useLazyQuery(GET_COST_CENTER_FORM_OPTIONS, {
    onCompleted: (data) => {
      setAllowancesOptions(data?.profile?.company?.allowances ?? []);
    },
  });

  const [attemptCreateNewAllowance, { loading: createNewAllowanceLoading }] =
    useMutation(createNewAllowanceMutation);

  const [attemptCreateCostCenter, { loading: createCostCenterLoading }] =
    useMutation(CREATE_COST_CENTER, {
      onCompleted: () => {
        handleCloseModal();
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
        );
      },
    });

  const [attemptUpdateCostCenter, { loading: updateCostCenterLoading }] =
    useMutation(UPDATE_COST_CENTER, {
      onCompleted: () => {
        handleCloseModal();
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
        );
      },
    });

  const [addCostCenterConfig, { loading: addCostCenterConfigLoading }] =
    useMutation(ADD_COST_CENTER_CONFIGURATION, {
      onCompleted: () => {
        handleCloseModal();
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
        );
      },
    });

  useEffect(() => {
    attemptGetFormOptions();
  }, []);

  // filter optiosn to execude other options that are not needed here

  const costCenterFormData = useSelector(
    (state) => state?.super?.[formProps?.formName]
  );

  const allowancesConfigurations = costCenterFormData?.allowancesConfigurations;

  const handleAddNewAllowance = (index) => {
    dispatch(
      onInputResetWithValueAction(
        formProps?.formName,
        "allowancesConfigurations",
        [
          ...allowancesConfigurations?.map((allowanceConfig, i) => {
            if (index == i) {
              return {
                ...allowanceConfig,
                allowances: [
                  ...allowanceConfig?.allowances,
                  {
                    id: null,
                    payment_factor_id: "Fixed Amount",
                    amount: 0,
                    month_option_id: null,
                    customDays: null,
                    notPaidOnUnpaidLeaves: 0,
                    notPaidOnPaidLeaves: 0,
                    notPaidOnAbsenteDays: 0,
                  },
                ],
              };
            } else {
              return allowanceConfig;
            }
          }),
        ]
      )
    );
  };

  const handleDeleteAllowance = (allowanceConfigIndex, index) => {
    dispatch(
      onInputResetWithValueAction(
        formProps?.formName,
        "allowancesConfigurations",
        [
          ...allowancesConfigurations?.map((allowanceConfig, i) => {
            if (allowanceConfigIndex == i) {
              return {
                ...allowanceConfig,
                allowances: [
                  ...allowanceConfig?.allowances?.filter((_, i) => i !== index),
                ],
              };
            } else {
              return allowanceConfig;
            }
          }),
        ]
      )
    );
  };

  const handleChangeChildInput = (name, value, allowanceConfigIndex, index) => {
    dispatch(
      onInputResetWithValueAction(
        formProps?.formName,
        "allowancesConfigurations",
        [
          ...allowancesConfigurations?.map((allowanceConfig, configIndex) => {
            if (allowanceConfigIndex == configIndex) {
              return {
                ...allowanceConfig,
                allowances: [
                  ...allowanceConfig?.allowances?.map((allowance, i) => {
                    if (index == i) {
                      return { ...allowance, [name]: value };
                    } else {
                      return allowance;
                    }
                  }),
                ],
              };
            } else {
              return allowanceConfig;
            }
          }),
        ]
      )
    );
  };

  const handleChangeFromDate = (allowanceConfigIndex, value) => {
    dispatch(
      onInputResetWithValueAction(
        formProps?.formName,
        "allowancesConfigurations",
        [
          ...allowancesConfigurations?.map((allowanceConfig, configIndex) => {
            if (allowanceConfigIndex == configIndex) {
              return {
                ...allowanceConfig,
                from: moment(value).format("YYYY-MM-DD"),
              };
            } else {
              return allowanceConfig;
            }
          }),
        ]
      )
    );
  };

  const handleCreateAllowance = (value, index, allowanceConfigIndex) => {
    attemptCreateNewAllowance({
      variables: {
        value,
      },
      onCompleted: (data) => {
        const newAllowance = data?.newAllowance;
        handleChangeChildInput(
          "id",
          newAllowance?.value,
          allowanceConfigIndex,
          index
        );
        setAllowancesOptions((prev) => [...prev, newAllowance]);
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ?? err.message
        );
      },
    });
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (clientValidations?.length) {
      // should be if any form client valdiations
      return;
    }

    if (!!costCenterFormData?.id) {
      if (isEditingCostCenterAllowanceConfigs) {
        addCostCenterConfig({
          variables: {
            costCenterId: +costCenterFormData?.id,
            from: allowancesConfigurations?.[0]?.from,
            originalFrom: allowancesConfigurations?.[0]?.originalFrom,
            allowances: allowancesConfigurations?.[0]?.allowances
              ?.filter((allowance) => !!allowance?.id)
              ?.map((allowance) => {
                return {
                  id: +allowance?.id,
                  paymentFactor: allowance?.payment_factor_id,
                  value:
                    allowance?.payment_factor_id == PAYMENT_FACTORS.FIXED_AMOUNT
                      ? allowance?.amount?.toString()
                      : "",
                  monthDays:
                    allowance?.month_option_id ==
                    DIVIDE_SALARY_BY_VALUES.CUSTOM_DAYS
                      ? allowance?.customDays
                      : allowance?.month_option_id ==
                        DIVIDE_SALARY_BY_VALUES.FULL_MONTH
                      ? "30"
                      : allowance?.month_option_id, // -> (worked days - calendar month)
                  payableOn: [
                    ...(!!allowance?.notPaidOnUnpaidLeaves ? [] : ["Unpaid"]),
                    ...(!!allowance?.notPaidOnPaidLeaves ? [] : ["Paid"]),
                    ...(!!allowance?.notPaidOnAbsenteDays ? [] : ["Absent"]),
                  ],
                };
              }),
          },
        });
      } else {
        attemptUpdateCostCenter({
          variables: {
            id: +costCenterFormData?.id,
            name: !!costCenterFormData?.name
              ? costCenterFormData?.name
              : undefined,
          },
        });
      }
    } else {
      attemptCreateCostCenter({
        variables: {
          name: costCenterFormData?.name,
          allowanceConfiguration: allowancesConfigurations
            ?.filter(
              (allowanceConfiguration) =>
                !!allowanceConfiguration?.allowances?.find(
                  (allowance) => !!allowance?.id
                )
            )
            ?.map((allowanceConfig) => {
              return {
                from: allowanceConfig?.from,
                allowances: allowanceConfig?.allowances?.map((allowance) => {
                  return {
                    id: +allowance?.id,
                    paymentFactor: allowance?.payment_factor_id,
                    value:
                      allowance?.payment_factor_id ==
                      PAYMENT_FACTORS.FIXED_AMOUNT
                        ? allowance?.amount?.toString()
                        : "",
                    monthDays:
                      allowance?.month_option_id ==
                      DIVIDE_SALARY_BY_VALUES.CUSTOM_DAYS
                        ? allowance?.customDays
                        : allowance?.month_option_id ==
                          DIVIDE_SALARY_BY_VALUES.FULL_MONTH
                        ? "30"
                        : allowance?.month_option_id, // -> (worked days - calendar month)
                    payableOn: [
                      ...(!!allowance?.notPaidOnUnpaidLeaves ? [] : ["Unpaid"]),
                      ...(!!allowance?.notPaidOnPaidLeaves ? [] : ["Paid"]),
                      ...(!!allowance?.notPaidOnAbsenteDays ? [] : ["Absent"]),
                    ],
                  };
                }),
              };
            }),
        },
      });
    }
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={handleCloseModal}
      modalTitle={
        costCenterFormData?.id ? t("edit cost center") : t("new cost center")
      }
      className="work_schedule_modal"
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={
        createCostCenterLoading ||
        updateCostCenterLoading ||
        addCostCenterConfigLoading
      }
      disableSubmitButton={
        createCostCenterLoading ||
        updateCostCenterLoading ||
        addCostCenterConfigLoading
      }
      size="lg"
    >
      {!isEditingCostCenterAllowanceConfigs ? (
        <InputForm
          {...formProps}
          name="name"
          placeholder={t("name")}
          containerStyle="w-100"
          rootStyle="w-100"
          inputStyle="p-2"
        />
      ) : null}

      {(!costCenterFormData?.id ||
        (costCenterFormData?.id && isEditingCostCenterAllowanceConfigs)) &&
        allowancesConfigurations?.map(
          (allowanceConfig, allowanceConfigIndex) => {
            return (
              <div>
                <div className="d-flex align-items-center gap-10 my-3">
                  <h4 className="blue-color font-weight-bold fa-1x m-0">
                    {t("allowances")}
                  </h4>
                  <IconButton
                    sx={{
                      height: 24,
                      width: 24,
                      fontSize: 16,
                      color: "#fff",
                      backgroundColor: "#2764ac",
                      borderRadius: 1,
                      ":hover": {
                        backgroundColor: "#12498a",
                      },
                    }}
                    onClick={() => handleAddNewAllowance(allowanceConfigIndex)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </IconButton>
                </div>

                {!!costCenterFormData?.id ? (
                  <DateTimePickerForm
                    inputStyle="date-picker-input-default validity-input-style sign_in_out_input attendance_sign_picker"
                    placeholder={t("choose date")}
                    containerStyle=" "
                    labelStyle="mb-2"
                    // validateBy="textRequired"
                    hasIcon
                    requestFormat="YYYY-MM-DD"
                    onChange={(value) =>
                      handleChangeFromDate(allowanceConfigIndex, value)
                    }
                    value={
                      !!allowanceConfig?.from
                        ? moment(allowanceConfig?.from, "YYYY-MM-DD")
                        : null
                    }
                  />
                ) : null}

                {allowanceConfig?.allowances?.map((allowance, index) => {
                  return (
                    <div>
                      <div className="d-flex align-items-center gap-10 my-3">
                        <CreateSelect
                          {...formProps}
                          icon="employee"
                          keepDefaultStyle
                          containerStyle=""
                          placeholder={t("Select Allowance Name")}
                          inputContainerStyle="w-100"
                          isClearable
                          onCreateOption={(newValue) =>
                            handleCreateAllowance(
                              newValue,
                              index,
                              allowanceConfigIndex
                            )
                          }
                          optionLabel="label"
                          optionValue="value"
                          isLoading={formOptionsLoading}
                          options={allowancesOptions}
                          value={allowancesOptions?.find(
                            (opt) => opt?.value == allowance?.id
                          )}
                          onChange={(e) =>
                            handleChangeChildInput(
                              "id",
                              e?.value,
                              allowanceConfigIndex,
                              index
                            )
                          }
                          rootStyle="w-100"
                        />

                        <BSelect
                          {...formProps}
                          name="payment_factor_id"
                          keepDefaultStyle
                          placeholder={t("select option")}
                          options={paymentFactorsOptions}
                          validateBy="textRequired"
                          skipLocalization
                          rootStyle="w-100"
                          onChange={(e) =>
                            handleChangeChildInput(
                              "payment_factor_id",
                              e?.id,
                              allowanceConfigIndex,
                              index
                            )
                          }
                          value={paymentFactorsOptions?.find(
                            (paymentFactor) =>
                              paymentFactor?.id == allowance?.payment_factor_id
                          )}
                        />

                        {allowance?.payment_factor_id ==
                        PAYMENT_FACTORS.FIXED_AMOUNT ? (
                          <InputForm
                            {...formProps}
                            type="number"
                            // validateBy="textRequired"
                            placeholder={t("amount")}
                            inputContainerStyle=" "
                            autoComplete="off"
                            rootStyle="w-100"
                            onChange={(e) =>
                              handleChangeChildInput(
                                "amount",
                                e?.target?.value,
                                allowanceConfigIndex,
                                index
                              )
                            }
                            value={allowance?.amount}
                          />
                        ) : (
                          <div className="d-flex align-items-center w-100 gap-5">
                            <p className="m-0 text-nowrap">
                              {t(
                                allowance?.payment_factor_id ==
                                  PAYMENT_FACTORS.PERCENT_OF_BASE
                                  ? "Base"
                                  : allowance?.payment_factor_id ==
                                    PAYMENT_FACTORS.PERCENT_OF_GROSS
                                  ? "Gross"
                                  : "Net"
                              )}{" "}
                              /
                            </p>

                            <BSelect
                              {...formProps}
                              name="month_option_id"
                              keepDefaultStyle
                              placeholder={t("select option")}
                              options={divideSalaryByOptions}
                              // validateBy="textRequired"
                              skipLocalization
                              rootStyle="w-100"
                              onChange={(e) =>
                                handleChangeChildInput(
                                  "month_option_id",
                                  e?.value,
                                  allowanceConfigIndex,
                                  index
                                )
                              }
                              value={divideSalaryByOptions?.find(
                                (opt) =>
                                  opt?.value == allowance?.month_option_id
                              )}
                            />

                            {allowance?.month_option_id ==
                            DIVIDE_SALARY_BY_VALUES.CUSTOM_DAYS ? (
                              <InputForm
                                name="custom_days"
                                placeholder={t("days")}
                                containerStyle="w-100"
                                rootStyle="w-100"
                                inputStyle="p-2"
                                onChange={(e) =>
                                  handleChangeChildInput(
                                    "customDays",
                                    e?.target?.value,
                                    allowanceConfigIndex,
                                    index
                                  )
                                }
                                value={allowance?.customDays}
                              />
                            ) : null}
                          </div>
                        )}

                        <div style={{ width: 80 }}>
                          {!isEditingCostCenterAllowanceConfigs ||
                          (isEditingCostCenterAllowanceConfigs &&
                            index != 0) ? (
                            <IconButton
                              onClick={() =>
                                handleDeleteAllowance(
                                  allowanceConfigIndex,
                                  index
                                )
                              }
                              color="secondary"
                              aria-label="delete"
                            >
                              <DeleteIcon color="warning" />
                            </IconButton>
                          ) : null}
                        </div>
                      </div>

                      <div className="d-flex align-items-center gap-10 my-2">
                        <p className="m-0 mr-5">
                          {t("This allowance should not be payable on")}
                        </p>
                        <CheckboxBooleanForm
                          {...formProps}
                          options={[t("unpaid leaves")]}
                          type="checkbox"
                          onChange={(e) =>
                            handleChangeChildInput(
                              "notPaidOnUnpaidLeaves",
                              allowance?.notPaidOnUnpaidLeaves == 0 ? 1 : 0,
                              allowanceConfigIndex,
                              index
                            )
                          }
                          checked={!!allowance?.notPaidOnUnpaidLeaves}
                        />

                        <CheckboxBooleanForm
                          {...formProps}
                          options={[t("paid leaves")]}
                          type="checkbox"
                          onChange={(e) =>
                            handleChangeChildInput(
                              "notPaidOnPaidLeaves",
                              allowance?.notPaidOnPaidLeaves == 0 ? 1 : 0,
                              allowanceConfigIndex,
                              index
                            )
                          }
                          checked={!!allowance?.notPaidOnPaidLeaves}
                        />

                        <CheckboxBooleanForm
                          {...formProps}
                          options={[t("absent days")]}
                          type="checkbox"
                          onChange={(e) =>
                            handleChangeChildInput(
                              "notPaidOnAbsenteDays",
                              allowance?.notPaidOnAbsenteDays == 0 ? 1 : 0,
                              allowanceConfigIndex,
                              index
                            )
                          }
                          checked={!!allowance?.notPaidOnAbsenteDays}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          }
        )}
    </MainModal>
  );
};

export default CostCenterForm;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import { fetchDocumentsListAttempt } from "../../Store/Actions";

import "./styls.scss";
import DocsRow from "./DocsRow";
import CompanyDocs from "./CompanyDocs";
import Loader from "../../Components/Loader";
import UpsertDocModal from "./UpsertDocModal";

const DocumentsList = () => {
  const dispatch = useDispatch();

  // Reducer State
  const isLoading = useSelector(
    (state) => state.documents.documentsListActions.isLoading
  );
  const company_id = useSelector(
    (state) => state.auth?.userProfile?.company?.id
  );

  /* ↓ State Effects ↓ */

  useEffect(() => {
    dispatch(
      fetchDocumentsListAttempt({
        company_id,
        hasEmployeesPrivileges: HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.MANAGE_COMPANY_DOCUMENTS],
        }),
      })
    );
  }, []);

  return (
    <div className="mt-3 ">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CompanyDocs />
          <DocsRow
            fetchedCompanyRef="company_offices"
            childName="officeDocsForm"
            documentable_type="Office"
          />
          <DocsRow
            fetchedCompanyRef="company_departments"
            childName="departmentDocsForm"
            documentable_type="Department"
          />
          <DocsRow
            fetchedCompanyRef="company_positions"
            childName="positionsDocsForm"
            documentable_type="Position"
          />
          <DocsRow
            fetchedCompanyRef="employees_for_document"
            childName="employeesDocsForm"
            documentable_type="Employee"
          />
          <UpsertDocModal />
        </>
      )}
    </div>
  );
};

export default DocumentsList;

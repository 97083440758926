import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

import {
  fetchBalanceLogsAction,
  setDataTableSortingAction,
  onInputResetAction,
  showBalanceModal,
  showErrorToast,
  showSuccessToast,
} from "../../Store/Actions";
import moment from "moment";
import swal from "sweetalert";
import HelperFns from "../../Helpers/HelperFns";
import Constants from "../../Helpers/Constants";
import Privilages from "../../Constants/Privilages";
import * as REQUESTS from "../../Constants/Requests";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { deleteBalanceModalMutation } from "../../Graphql/mutation";

import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import { Link } from "react-router-dom";
import BalanceModal from "./BalanceModal";
import Loader from "../../Components/Loader";
import ShowMoreText from "react-show-more-text";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { BSelect, InputForm, RadioboxForm } from "form-builder";

const LEAVE_ADJUSTMENT_ID = "5";
const dataTableRef = `balanceLogsList`;

const BalanceLogsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteBalance] = useMutation(deleteBalanceModalMutation);

  // Reducer State
  const balanceLogsList = useSelector((state) => state.super[dataTableRef]);
  const balanceModalActions = useSelector(
    (state) => state.super.balanceModalActions
  );

  // Constants
  const columns = [
    {
      name: t("employee"),
      wrap: true,
      selector: "name",
      sortable: true,
      grow: 2,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[
            Privilages.VIEW_EMPLOYEE_PROFILES,
            Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION,
            Privilages.VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS,
            Privilages.VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE,
            Privilages.VIEW_EMPLOYEE_DOCUMENTS,
            Privilages.VIEW_EMPLOYEE_SCHEDULES,
          ]}
          allowBP
          altChildren={<>{row?.employee?.user?.name}</>}
        >
          <Link to={`/employees/employee-profile/${row?.employee?.user?.id}`}>
            {row?.employee?.user?.name}
          </Link>
        </HasPrivileges>
      ),
    },
    {
      name: t("actions"),
      selector: "action",
      wrap: true,
      sortable: false,
      grow: 1,
      cell: (row) => (row?.quantity > 0 ? t("additions") : t("dedcutions")),
    },
    {
      name: t("type"),
      selector: "balanceType",
      wrap: true,
      sortable: false,
      grow: 1,
      cell: (row) => t(row?.balanceType?.name?.toLowerCase()),
    },
    {
      name: t("Added By"),
      wrap: true,
      sortable: false,
      grow: 1,
      cell: (row) => (
        <>
          {row?.added_by?.id === "0" || row?.added_by?.id == null ? (
            <>{t("By System")}</>
          ) : (
            <>{row?.added_by?.name}</>
          )}
        </>
      ),
    },
    {
      name: t("cause"),
      wrap: true,
      sortable: false,
      grow: 1.25,
      cell: (row) => <>{t(row?.balanceCause?.name)}</>,
    },
    {
      name: t("reason"),
      selector: "reason",
      wrap: true,
      sortable: false,
      grow: 1.5,
      cell: (row) => (
        <ShowMoreText
          lines={1}
          more="Show more"
          less="Show less"
          expanded={false}
          width={280}
        >
          {t(row?.reason)}
        </ShowMoreText>
      ),
    },
    {
      name: t("quantity"),
      selector: "quantity",
      sortable: false,
      wrap: true,
      grow: 1.5,
      cell: (row) => (
        <span style={{ direction: "ltr" }}>
          {row?.quantity}{" "}
          <small>
            (
            {row?.balanceType?.id === REQUESTS.PERMISSIONS
              ? t("minutes")
              : t("days")}
            )
          </small>
        </span>
      ),
    },
    {
      name: t("emergency credit"),
      selector: "emergency_quantity",
      wrap: true,
      sortable: false,
      grow: 1.25,
    },
    {
      name: t("period"),
      selector: "year",
      sortable: true,
      wrap: true,
      grow: 1,
      ignoreRowClick: true,
      cell: (row) =>
        `${
          row?.month_year_payroll?.split("-")[0] > 0
            ? row?.month_year_payroll?.split("-")[0]
            : ""
        }  ${row?.month_year_payroll?.split("-")[1] ?? ""}`,
    },
    {
      name: t("created at"),
      selector: "created_at",
      sortable: true,
      wrap: true,
      grow: 1,
      cell: (row) =>
        row?.created_at ? moment(row.created_at).format("DD/MM/yyyy") : "",
    },
    {
      wrap: true,
      grow: 0.3,
      ignoreRowClick: true,
      selector: "action",
      omit: !HelperFns.checkPrivileges({
        privileges: [Privilages.ADD_DEDUCT_LEAVE_BALANCE],
        allowBP: true,
      }),
      cell: (row) =>
        row.balanceCause.id === LEAVE_ADJUSTMENT_ID && (
          <div className="cards_table_actions">
            <EditIconButton onClick={() => handleEdit(row)} />
            <RemoveIconButton onClick={() => handleDelete(row)} />
          </div>
        ),
    },
  ];

  /* ↓ State Effects ↓ */

  useEffect(() => {
    dispatch(
      fetchBalanceLogsAction({
        ref: dataTableRef,
        [dataTableRef]: balanceLogsList,
      })
    );
    return () => {
      dispatch(onInputResetAction(dataTableRef, "name"));
      dispatch(onInputResetAction(dataTableRef, "month"));
      dispatch(onInputResetAction(dataTableRef, "year"));
      dispatch(onInputResetAction(dataTableRef, "type"));
      dispatch(onInputResetAction(dataTableRef, "operation"));
    };
  }, []);

  /* ↓ Helpers ↓ */

  const handleFilter = () => {
    dispatch(
      fetchBalanceLogsAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...balanceLogsList,
          pagination: {
            ...balanceLogsList.pagination,
            currentPage: 1,
          },
        },
      })
    );
  };

  const handleEdit = (row) => {
    let data = {
      id: row.id,
      type: "edit",
      year: row.year,
      reason: row.reason,
      emp_id: row.employee.user.id,
      balance_type_id: row.balanceType?.id,
      operation: row.quantity >= 0 ? "1" : "2",
      quantity: String(Math.abs(row.quantity)),
      apply_on_emergency: row.emergency_quantity ? 1 : 0,
      month: row.month?.length === 1 ? "0" + row.month : row.month,
      emergency_quantity: String(Math.abs(row.emergency_quantity)),
    };
    dispatch(
      showBalanceModal({
        data,
        ref: "balanceModalActions",
        formName: "balanceModal",
      })
    );
  };

  const handleDelete = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), { text: t("OK"), closeModal: false }],
    }).then((isDelete) => {
      if (isDelete) {
        deleteBalance({
          variables: { id: row.id },
          onCompleted: (data) => {
            if (data?.delete_balance?.status === "success") {
              dispatch(
                fetchBalanceLogsAction({
                  ref: dataTableRef,
                  [dataTableRef]: balanceLogsList,
                })
              );
              dispatch(showSuccessToast(data?.delete_balance?.message));
            } else {
              dispatch(showErrorToast(data?.delete_balance?.message));
            }
          },
          onError: (err) => {
            dispatch(showErrorToast(err?.message));
          },
        });
      }
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    balanceLogsList.name,
    balanceLogsList.month,
    balanceLogsList.year,
    balanceLogsList.type,
    balanceLogsList.operation,
  ]);

  const handlePaginate = (page = balanceLogsList.pagination.currentPage) => {
    dispatch(
      fetchBalanceLogsAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...balanceLogsList,
          pagination: {
            ...balanceLogsList.pagination,
            currentPage: page,
          },
        },
      })
    );
  };

  const handleSorting = (field, dir) => {
    dispatch(
      fetchBalanceLogsAction({
        [dataTableRef]: {
          ...balanceLogsList,
          pagination: {
            ...balanceLogsList.pagination,
            currentPage: 1,
          },
          sorting: {
            dir,
            key: field.selector,
          },
        },
        ref: dataTableRef,
      })
    );
    dispatch(setDataTableSortingAction(dataTableRef, field.selector, dir));
  };

  return (
    <div className="col-md-12 my-2 my-requests px-0">
      <div className="row mb-2 pb-1 align-items-baseline">
        <div className="col-12 col-xl-3 mb-3 mb-xl-0">
          <RadioboxForm
            formName={dataTableRef}
            options={Constants.balanceLogsActionsOptions?.map(
              ({ label, value }) => ({ label: t(label), key: value, value })
            )}
            name="operation"
            type="radio"
            containerStyle="mt-1"
            labelStyle="label-style"
            optionsContainerStyle="optionsContainerStyle"
            optionItemStyle="optionItemStyle"
            optionInputStyle=" "
            optionLabelStyle="optionLabelStyle"
          />
        </div>

        <div className="col-xl-4 mb-3 mb-xl-0">
          <InputForm
            name="name"
            formName={dataTableRef}
            placeholder={t("search")}
            inputContainerStyle=" "
            containerStyle=" "
            icon="person"
          />
        </div>

        <div className="col mb-3 mb-xl-0">
          <BSelect
            name="type"
            optionLabel="label"
            optionValue="value"
            options={Constants.balanceLogsTypeOptions}
            formName={dataTableRef}
            placeholder={t("type")}
            keepDefaultStyle
            containerStyle=" "
            isClearable
            icon="type"
          />
        </div>

        <div className="col px-1">
          <BSelect
            name="month"
            optionLabel="value"
            optionValue="key"
            options={Constants.MonthData}
            formName={dataTableRef}
            placeholder={t("month")}
            keepDefaultStyle
            containerStyle=" "
            isClearable
            getOptionLabel={(option) => t(option.value)}
            icon="calendar"
          />
        </div>

        <div className="col px-1">
          <BSelect
            name="year"
            optionLabel="value"
            optionValue="value"
            options={Constants.YearData}
            formName={dataTableRef}
            placeholder={t("year")}
            keepDefaultStyle
            containerStyle=" "
            isClearable
            icon="calendar"
          />
        </div>

        <div className="col">
          <HasPrivileges
            reqireMain={[Privilages.ADD_DEDUCT_LEAVE_BALANCE]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <div style={{ opacity: "0.65" }}>
                <button
                  className="btn add_new_btn_style"
                  type="button"
                  disabled
                >
                  {t("add balance")}
                </button>
              </div>
            }
          >
            <div className="mb-2 text-right">
              <button
                className="btn add_new_btn_style text-nowrap"
                onClick={() =>
                  dispatch(
                    showBalanceModal({
                      ref: "balanceModalActions",
                      formName: "balanceModal",
                      data: { type: "add" },
                    })
                  )
                }
              >
                {t("add balance")}
              </button>
            </div>
          </HasPrivileges>
        </div>
      </div>

      {/* (Start) Balance Modal */}
      {balanceModalActions.isVissible && <BalanceModal />}
      {/* (End) Balance Modal */}

      <DataTable
        noDataComponent={t("no_records")}
        className="cards_table"
        columns={columns}
        data={balanceLogsList.data}
        noHeader
        persistTableHead
        sortServer
        onSort={handleSorting}
        defaultSortAsc={balanceLogsList.sorting.dir == "asc"}
        defaultSortField={balanceLogsList.sorting.key}
        paginationComponent={() => (
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
            reducer="super"
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={balanceLogsList.isLoading}
        progressComponent={<Loader />}
        direction="auto"
        customStyles={{
          tableWrapper: { style: { minWidth: 1500 } },
        }}
      />
    </div>
  );
};

export default BalanceLogsList;

import React from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";

const ExpandedCostCenterReport = ({ data: { json } }) => {
  const { t } = useTranslation();
  const employees = JSON.parse(json);

  const columns = [
    {
      name: t("cost center"),
      // wrap: true,
      sortable: false,
      width: "150px",
      style: {
        backgroundColor: "#fff",
      },
      cell: (row) => (
        <>{row?.NAME}</>
        // <div className="d-flex w-100 align-items-center justify-content-between">
        //   <CanViewEmployeeProfile
        //       allowBP
        //       directManger={row?.user?.manager?.id}
        //       copiedManagers={row?.user?.copied_managers?.map((cp) => cp?.id)}
        //       altChildren={<div className="mr-2">{row?.user?.name}</div>}
        //     >
        //       <div className="mr-2">
        //         <Link
        //           className="employee-name"
        //           to={`/employees/employee-profile/${row?.user?.id}`}
        //         >
        //           {row?.user?.name}
        //         </Link>
        //       </div>
        //     </CanViewEmployeeProfile>
        // </div>
      ),
    },
    {
      name: t("base"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(35, 170, 235, 0.14) !important",
      },
      cell: (row) => <>{row?.SALARY}</>,
    },
    {
      name: t("allowances"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18) !important",
      },

      cell: (row) => <>{row?.ALLOWANCE}</>,
    },

    {
      name: t("overtime"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18) !important",
      },

      cell: (row) => <>{row?.OVERTIME}</>,
    },
    {
      name: t("bonuses"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18) !important",
      },

      cell: (row) => <>{row?.BONUS}</>,
    },
    {
      name: t("claims"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18) !important",
      },

      cell: (row) => <>{row?.CLAIM}</>,
    },
    {
      name: t("penalties"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },
      cell: (row) => <>{row?.PENALTY}</>,
    },
    {
      name: t("social"),
      wrap: true,
      sortable: false,
      grow: 0.5,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },
      cell: (row) => <>{row?.SOCIAL}</>,
    },
    {
      name: t("medical"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },
      cell: (row) => <>{row?.MEDICAL}</>,
    },
    {
      name: t("taxes"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },

      cell: (row) => (
        <div className="d-flex align-items-center justify-content-center">
          {row?.TAX}
        </div>
      ),
    },
    {
      name: t("others"),
      wrap: true,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },

      cell: (row) => <div>{row?.OTHER_DEDUCTION}</div>,
    },
    {
      name: t("total"),
      wrap: true,
      sortable: false,
      grow: 1.4,

      cell: (row) => <div>{row?.TOTAL ?? "total"}</div>,
    },
  ];

  return (
    <div>
      <DataTable
        className="requests-profile-data-list payroll_datatable_wrapper_style cost-center-report-styles"
        columns={columns}
        data={employees}
        noHeader
        noTableHead
        expandableRows
        expandableIcon={<></>}
        expandableRowDisabled={() => true}
      />
    </div>
  );
};

export default ExpandedCostCenterReport;

import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailOutlined from "@mui/icons-material/EmailOutlined";
import VpnKey from "@mui/icons-material/VpnKey";
import {
  loginAction,
  onInputChangeAction,
  onInputResetAction,
} from "../../Store/Actions";
import { CheckboxBooleanForm } from "form-builder";
import { Spinner } from "reactstrap";
import LoginLayout from "../../Components/LoginLayout";
import Loader from "../../Components/Loader";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import IntlTelInput from "react-intl-tel-input";
// import "../../Assets/sass/phone-input.scss";
import Grid from "@mui/material/Grid";
import { Avatar, MenuItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import HelperFns from "../../Helpers/HelperFns";
import { useHistory } from "react-router-dom";

const LoginPage = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  // Start of Handling the show and hide password function
  const [showPassword, setShowPassword] = useState(false);
  const [isPhoneLogin, setIsPhoneLogin] = useState(false);

  const [authForm, setAuthForm] = useState({
    username: "",
    password: "",
    device_token: "desktop",
    device_type: "pc",
  });

  const [loginFormVisible, setLoginFormVisible] = useState(false);

  const usersList = useSelector((state) => state.LoginUserList?.usersList);
  const id = useSelector((state) => state.auth.userProfile.id);

  const handleSwitchChange = (event) => {
    setIsPhoneLogin(event.target.checked);
    setAuthForm((prevState) => ({
      ...prevState,
      username: "",
    }));
    // props.onInputResetAction(formName, "username");
  };

  const onInputChangeAction = (e) => {
    setAuthForm((prevState) => ({
      ...prevState,
      [e?.target?.name]: e?.target?.value,
    }));
  };

  const handleLoginInputChange = (e) => {
    onInputChangeAction(e);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  // End of Handling the show and hide password function

  const handleLogin = (e) => {
    e.preventDefault();

    if (isPhoneLogin) {
      let actualPhone =
        phoneRef.current?.selectedCountryData?.dialCode == "20"
          ? authForm?.username?.replace(/^0/, "")
          : authForm?.username;

      let authData = {
        ...authForm,
        username: `+${phoneRef.current?.selectedCountryData?.dialCode}${actualPhone}`,
      };
      props.loginAction(authData);
    } else {
      props.loginAction(authForm);
    }
  };

  const phoneRef = React.useRef(null);

  const handlePhoneChange = (
    isValid,
    value,
    seletedCountryData,
    fullNumber,
    extension,
    event
  ) => {
    let e = {
      target: {
        value,
        name: "username",
      },
    };
    onInputChangeAction(e);
  };

  return (
    <LoginLayout>
      {props.sendPhoneVerificationLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}
      {loginFormVisible || usersList?.length == 0 ? (
        <form method="POST" onSubmit={handleLogin}>
          <div className="flex-row d-flex justify-content-between">
            <h4>{t("login to your account")}</h4>
            <div dir="ltr">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>{t("email")}</Grid>
                <Grid item>
                  <Switch
                    checked={isPhoneLogin}
                    onChange={handleSwitchChange}
                    name="isPhoneLogin"
                    classes={{ switchBase: "switchBase" }}
                  />
                </Grid>
                <Grid item>{t("phone")}</Grid>
              </Grid>
            </div>
          </div>

          {isPhoneLogin ? (
            <div
              dir="ltr"
              className="d-flex flex-column login_input_wrapper_style"
            >
              <label className="mb-2 validity-label-style">{t("phone")}</label>
              <IntlTelInput
                ref={phoneRef}
                inputClassName="input-style-default login-body-wrapper custom_outlined_input_style input"
                style={{ display: "flex" }}
                fieldName={"username"}
                telInputProps={{
                  dir: "ltr",
                }}
                preferredCountries={["eg", "ae", "sa"]}
                onPhoneNumberChange={(
                  isValid,
                  value,
                  seletedCountryData,
                  fullNumber,
                  extension,
                  event
                ) => {
                  handlePhoneChange(
                    isValid,
                    value,
                    seletedCountryData,
                    fullNumber,
                    extension,
                    event
                  );
                }}
                separateDialCode
                format={false}
                formatOnInit={false}
                value={authForm.username}
              />
            </div>
          ) : (
            <div className="d-flex flex-column login_input_wrapper_style">
              <label>{t("email")}</label>
              <OutlinedInput
                type="text"
                name="username"
                onChange={handleLoginInputChange}
                value={authForm.username}
                placeholder={t("email")}
                classes={{
                  root: "custom_outlined_input_style",
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton>
                      <EmailOutlined />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          )}

          <div className="my-3 d-flex flex-column login_input_wrapper_style">
            <label>{t("password")}</label>
            <OutlinedInput
              type={showPassword ? "text" : "password"}
              value={authForm.password}
              name="password"
              onChange={handleLoginInputChange}
              placeholder={t("password")}
              classes={{
                root: "custom_outlined_input_style",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton tabIndex="-1">
                    <VpnKey />
                  </IconButton>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    tabIndex="-1"
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>

          <div className="d-flex mt-4 pt-2">
            <button
              className="btn btn-block login_btn_style mt-0 text-capitalize"
              onClick={handleLogin}
              type="submit"
              disabled={props.loggingInLoading}
            >
              {props.loggingInLoading ? (
                <Spinner
                  style={{ width: "1rem", height: "1rem" }}
                  size="sm"
                  color="#fff"
                />
              ) : (
                t("login")
              )}
            </button>
          </div>
          {usersList?.length ? (
            <button
              className="btn btn-block choose-from-my-accounts-button mt-3 text-capitalize"
              onClick={() => setLoginFormVisible(false)}
              type="button"
            >
              {t("choose from my accounts")}
            </button>
          ) : null}

          <div className="d-flex mb-5 align-items-center mt-3">
            {/* <CheckboxBooleanForm
            formName={formName}
            reducer="auth"
            name="remember_me"
            value={authForm.remember_me}
            options={[t("remember me")]}
            type="checkbox"
            containerStyle="custom_checkbox_style"
          /> */}

            <span className="ml-auto">
              <Link exact={true} to="/forgot-password">
                <span className="forgot_pass_style text-capitalize">
                  {t("forgot password")}
                </span>
              </Link>
            </span>
          </div>
        </form>
      ) : (
        <div>
          {usersList?.map((account, i) => (
            <MenuItem
              key={account?.user?.id}
              onClick={() => {
                dispatch({
                  type: "SWITCH_ACCOUNT",
                  payload: { account },
                });
                history.push("/");
              }}
              className="d-flex flex-row justify-content-between align-items-center"
              disabled={id === account?.user?.id}
              style={{
                opacity: id === account?.user?.id ? 0.5 : 1,
                paddingInline: 10,
              }}
            >
              <div
                className={`d-flex flex-row align-items-center justify-content-between mr-1 ${
                  account?.user?.id == id ? "border-bottom pb-2 w-100" : ""
                } ${usersList?.length === 0 ? "mb-3 " : ""}`}
              >
                <div className="d-flex flex-row align-items-center gap-5">
                  <span className="user-img accounts-list-avatar-style">
                    <Avatar
                      src={account?.user?.profile_picture?.path}
                      className="rounded_circle_user_img blackish-avatar"
                    >
                      {HelperFns.getIntials(account?.user?.name)}
                    </Avatar>
                  </span>
                  <div className="accounts-list-user-name-container">
                    <span className="align-middle gray-color text-ellipsis">
                      {account?.user?.name}
                    </span>
                  </div>
                </div>
              </div>
              {id !== account?.user?.id ? (
                <FontAwesomeIcon
                  size="md"
                  icon={faSignOutAlt}
                  className="red-color login-users-list-logout-btn-style"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch({
                      type: "REMOVE_ACCOUNT",
                      payload: account?.user?.id,
                    });
                  }}
                />
              ) : null}
            </MenuItem>
          ))}

          <MenuItem
            className="d-flex flex-row justify-content-between align-items-center mt-3"
            onClick={() => {
              setLoginFormVisible(true);
            }}
          >
            <FontAwesomeIcon
              size="lg"
              icon={faPlusSquare}
              className="blue-color"
            />
            <span className="dropdown-item add-new-account-button blue-color">
              {t("add another account")}
            </span>
          </MenuItem>
        </div>
      )}
    </LoginLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    loggingInLoading: state.user.loggingInLoading,
    sendPhoneVerificationLoading: state.user.sendPhoneVerificationLoading,
  };
};

export default connect(mapStateToProps, {
  loginAction,
  onInputChangeAction,
  onInputResetAction,
})(LoginPage);

import React, { useEffect, useState } from "react";
import { InputForm } from "form-builder";
import { useTranslation } from "react-i18next";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import AddIcon from "@mui/icons-material/Add";
import UpsertAssetTypeModal from "./UpsertAssetTypeModal";
import { useDispatch, useSelector } from "react-redux";
import {
  onFormResetAction,
  toggleAssetTypeModal,
} from "../../../Store/Actions";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_ASSET_TYPES } from "../../../Graphql/query";
import DataTable from "react-data-table-component";
import Loader from "../../../Components/Loader";
import Pagination from "../../../Components/Pagination";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../../Components/IconButtonWithTooltip";
import Swal from "sweetalert2";
import { DELETE_ASSET_TYPE } from "../../../Graphql/mutation";
import { showToast } from "../../../Helpers/HelperFns";
import Privilages from "../../../Constants/Privilages";

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const AssetTypes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state?.assets?.assetTypeModal?.isOpen);

  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState(paginationInitState);

  const [fetchAssetTypes, { loading, data, refetch }] = useLazyQuery(
    GET_ASSET_TYPES,
    {
      variables: {
        page: pagination?.currentPage,
        input: {
          ...(!!search
            ? {
                filter: {
                  name: search,
                },
              }
            : {}),
        },
      },
      onCompleted: (data) => {
        setPagination(data?.assetTypes?.paginatorInfo);
      },
    }
  );

  useEffect(() => {
    fetchAssetTypes();
  }, []);

  const assetTypes = data?.assetTypes?.data ?? [];

  const handleRefetchData = () => {
    fetchAssetTypes();
  };

  const [attemptDeleteAssetType, { loading: deleteAssetTypeLoading }] =
    useMutation(DELETE_ASSET_TYPE, {
      onCompleted: (data) => {
        if (data?.deleteAssetType?.__typename == "GeneralException") {
          showToast("error", data?.deleteAssetType?.message);
          return;
        }
        handleRefetchData();
      },
      onError(err) {
        showToast(
          "error",
          err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
        );
      },
    });

  const handleChange = (e) => {
    setSearch(e?.target?.value ?? "");
  };

  const handleAddNew = () => {
    dispatch(toggleAssetTypeModal({ isOpen: true }));
  };

  const handlePaginate = (page = pagination?.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleEdit = (assetType) => {
    dispatch(toggleAssetTypeModal({ isOpen: true }));
    dispatch(onFormResetAction("upsertAssetTypeForm", { ...assetType }));
  };

  const handleDelete = (assetType) => {
    Swal.fire({
      icon: "warning",
      title: t("are you sure"),
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      customClass: {
        popup: "swal-warning-style",
        icon: "swal-icon",
        denyButton: "order-2",
        confirmButton: "order-3",
        htmlContainer: "attendance-type-alert-text",
      },
    }).then(async (values) => {
      if (values.isConfirmed) {
        attemptDeleteAssetType({
          variables: {
            id: assetType?.id,
          },
        });
      }
    });
  };

  const columns = [
    { name: t("name"), selector: "name" },
    {
      width: "100px",
      cell: (assetType) => (
        <div className="d-flex align-items-center gap-5">
          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_DELETE_ASSET_TYPES]}
            allowBP
          >
            <EditIconButton onClick={() => handleEdit(assetType)} />
            <RemoveIconButton onClick={() => handleDelete(assetType)} />
          </HasPrivileges>
        </div>
      ),
    },
  ];

  return (
    <div className="py-3">
      <div className="d-flex align-items-center gap-10">
        <InputForm
          placeholder={t("search")}
          containerStyle="w-100"
          icon="search"
          value={search}
          onChange={handleChange}
          rootStyle="w-100"
        />
        <HasPrivileges
          reqireMain={[Privilages.ADD_EDIT_DELETE_ASSET_TYPES]}
          allowBP
        >
          <button
            className="btn add_new_btn_style text-nowrap"
            onClick={handleAddNew}
          >
            <AddIcon className="add_btn_icon" />
            {t("add new")}
          </button>
        </HasPrivileges>
      </div>

      <DataTable
        noHeader
        data={assetTypes}
        columns={columns}
        className="cards_table my-3"
        progressPending={loading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {isOpen ? (
        <UpsertAssetTypeModal handleRefetch={handleRefetchData} />
      ) : null}
    </div>
  );
};

export default AssetTypes;

import React from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import gql from "graphql-tag";
import { showToast } from "../../Helpers/HelperFns";

import Loader from "../../Components/Loader";
import { CheckboxBooleanForm, BSelect } from "../../Builder/Form";

const GET_OPTIONS = gql`
  query getOptions {
    positions: company_positions(first: 9999) {
      data {
        id
        name
      }
    }
    employees: company_users(
      first: 9999
      input: { page_flag: null, status: ["Active"], include_auth: 1 }
    ) {
      data {
        id
        name
      }
    }
  }
`;

const AccessLevelAssignment = ({ FormProps, ...props }) => {
  const { t } = useTranslation();

  // Local State
  const [options, setOptions] = React.useState({
    positions: [],
    employees: [],
  });

  // Server
  const { loading } = useQuery(GET_OPTIONS, {
    variables: {},
    onCompleted: ({ positions, employees }) => {
      setOptions({
        positions: positions?.data || [],
        employees: employees?.data || [],
      });
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors[0]?.extensions?.reason ||
          err?.graphQLErrors[0]?.message ||
          err?.message
      );
    },
  });

  return (
    <>
      {props?.isEdit && loading ? <Loader fixed /> : null}

      <CheckboxBooleanForm
        {...FormProps}
        {...props}
        name="assign_all"
        options={["Anyone can assign this access level"]}
      />

      <BSelect
        {...FormProps}
        {...props}
        isMulti
        name="positions"
        label={t("allowed positions")}
        keepDefaultStyle
        options={options?.positions}
        isLoading={loading}
        labelStyle="my-2"
        containerStyle="containerStyle flex-column align-items-start py-0"
        inputContainerStyle="flex-grow-1 mr-0 w-100"
        skipLocalization
        icon="position"
        dependOn="assign_all"
        dependancyType="equal"
        dependancyValue={[0]}
      />
      <BSelect
        {...FormProps}
        {...props}
        isMulti
        name="employees"
        label={t("allowed employees")}
        keepDefaultStyle
        options={options?.employees}
        isLoading={loading}
        labelStyle="my-2"
        containerStyle="containerStyle flex-column align-items-start py-0"
        inputContainerStyle="flex-grow-1 mr-0 w-100"
        icon="employees"
        dependOn="assign_all"
        dependancyType="equal"
        dependancyValue={[0]}
      />
    </>
  );
};

export default AccessLevelAssignment;

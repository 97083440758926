import gql from "graphql-tag";

export const upsertDocumentMutation = gql`
  mutation upsertDocument($input: UpdateOrCreateDocumentInput) {
    updateOrCreateDocument(input: $input) {
      id
      # name
      # country_id
      company {
        documentPresets {
          id
          name
          country_id
          country {
            short_name
          }
          fields {
            id
            name
          }
        }
      }
    }
  }
`;

export const storeDocumentableMutation = gql`
  mutation storeDocumentable($input: DocumentableInput) {
    store_documentable(input: $input) {
      required_documentables {
        document {
          id
          name
          country_id
          country {
            short_name
          }
          fields {
            id
            name
          }
        }
      }
      optional_documentables {
        document {
          id
          name
          country_id
          country {
            short_name
          }
          fields {
            id
            name
          }
        }
      }
    }
  }
`;

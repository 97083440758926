import React, { useEffect } from "react";
import { Col } from "reactstrap";
import { BSelect } from "form-builder";
import Constants from "../../../Helpers/Constants";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchOpenPayrollMonthsAttempt,
  onFormResetAction,
} from "../../../Store/Actions";
import Skeleton from "@mui/material/Skeleton";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import Privilages from "../../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import moment from "moment";

const OpenPayrollMonths = (props) => {
  const { t } = useTranslation();
  const pickCurrencySymbol = (currencyId) => {
    return props.companyCurrencies?.find((element) => element.id === currencyId)
      ?.symbol;
  };

  useEffect(() => {
    return () => {
      props.onFormResetAction("openPayrollMonths");
    };
  }, []);

  useEffect(() => {
    props.fetchOpenPayrollMonthsAttempt({
      ...props.openPayrollMonths,
      year: props.openPayrollMonths.year.toString(),
    });
  }, [props.openPayrollMonths.year, props.openPayrollMonths.office_id]);
  if (props.dashboardViewLoading) {
    return <Skeleton variant="text" width={"100%"} height={280} />;
  }
  const modifyCurrencyArray = (mainArr) => {
    var modifiedCurencyArray = [];
    let keysArray = mainArr.map((el) => el.currency_id);
    const filteredKeysArray = keysArray.filter(
      (element, index) => keysArray.indexOf(element) == index
    );
    for (let i = 0; i < filteredKeysArray.length; i++) {
      let totalPerSameCurrency = 0;
      mainArr.forEach((element) => {
        if (element.currency_id == filteredKeysArray[i]) {
          totalPerSameCurrency = +totalPerSameCurrency + +element.total;
        }
      });
      modifiedCurencyArray.push({
        currency_id: filteredKeysArray[i],
        total: (+totalPerSameCurrency).toFixed(2),
      });
    }
    return modifiedCurencyArray;
  };

  const selectPayrollContent = () => {
    if (props?.openPayrollMonthsLoading)
      return (
        <div className="payroll-skelton-loader">
          <Skeleton variant="text" width={"100%"} height={280} />
        </div>
      );
    if (props?.openPayrollMonthsData?.length === 0) {
      return (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <span> {t("There are no open payroll monthes at this moment")}</span>
        </div>
      );
    } else {
      return props.openPayrollMonthsData?.map((element, i) => {
        // const month = Number(element?.date?.toString().split("-")[0]) - 1;
        const ModifiedCurrencyArrayToMap = modifyCurrencyArray(
          element?.totalWithCurrencies
        );
        return (
          <div
            key={i}
            className="row d-flex w-100 align-items-center statistics-bar mx-0 overflow-auto flex-nowrap"
          >
            <Col className="text-left text-nowrap col-lg-2">
              {moment(element?.date, "MM-YYYY").format("MMMM") + " "}
              <b>{moment(element?.date, "MM-YYYY").format("YYYY")}</b>
            </Col>
            <Col className="hr text-center "></Col>
            <Col md={2} xl={2} className="text-nowrap">
              {t("total unpaid")}
            </Col>
            <Col
              md={4}
              lg={2}
              xl={4}
              className="font-weight-bold text-left d-flex flex-row flex-wrap align-items-center "
            >
              {ModifiedCurrencyArrayToMap?.map((amount, i) => (
                <React.Fragment key={i}>
                  <p className="my-0">
                    <span>{pickCurrencySymbol(amount.currency_id)}</span>
                    {amount?.total}
                  </p>
                </React.Fragment>
              ))}
            </Col>
            <HasPrivileges reqireMain={[Privilages.VIEW_PAYROLL_LIST]} allowBP>
              <Col>
                <Link
                  to={`/financials?tab=payroll&month=${Number(
                    moment(element?.date, "MM-YYYY").format("M")
                  )}&year=${props?.openPayrollMonths?.year}`}
                >
                  <div className="view-months-btn d-flex justify-content-center align-items-center text-right mr-xl-2">
                    {t("view month")}
                  </div>
                </Link>
              </Col>
            </HasPrivileges>
          </div>
        );
      });
    }
  };

  return (
    <div className="open-payroll-months-container card">
      <div className="card-body">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center chart-title">
          <h4>{t("open payroll months")}</h4>
          <div className="d-flex flex-row justify-content-center align-items-center selectors">
            <div className="mr-2">
              <BSelect
                name="office_id"
                optionLabel="name"
                optionValue="id"
                options={[
                  { name: t("All Offices"), id: null },
                  ...props?.OpenPayrollMonthsOfficesAccordingToScope,
                ]}
                formName="openPayrollMonths"
                keepDefaultStyle
                containerStyle="office-select"
                icon="office"
                skipLocalization
              />
            </div>
            <BSelect
              name="year"
              optionLabel="value"
              optionValue="value"
              options={Constants.YearData}
              formName="openPayrollMonths"
              keepDefaultStyle
              containerStyle="year-picker"
              icon="calendar"
            />
          </div>
        </div>
        {selectPayrollContent()}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    openPayrollMonths: state?.super?.openPayrollMonths,
    openPayrollMonthsData: state?.super?.openPayrollMonthsData,
    companyCurrencies: state?.super?.companyCurrencies,
    openPayrollMonthsLoading: state?.super?.openPayrollMonthsLoading,
    dashboardViewLoading: state?.super?.dashboardViewLoading,
    OpenPayrollMonthsOfficesAccordingToScope:
      state?.super?.OpenPayrollMonthsOfficesAccordingToScope || [],
  };
};

export default connect(mapStateToProps, {
  fetchOpenPayrollMonthsAttempt,
  onFormResetAction,
})(OpenPayrollMonths);

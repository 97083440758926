import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  GET_EMPLOYEE_ASSETS_REQUESTS,
  GET_MY_ASSET_REQUESTS,
} from "../../../Graphql/query";
import DataTable from "react-data-table-component";
import Loader from "../../../Components/Loader";
import Pagination from "../../../Components/Pagination";
import { useTranslation } from "react-i18next";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { IconButton } from "@mui/material";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import {
  AcceptIconButton,
  TimesIconButton,
} from "../../../Components/IconButtonWithTooltip";
import { useDispatch, useSelector } from "react-redux";
import EmployeeAssetRequestModal from "./EmployeeAssetRequestModal";
import AcceptAssetRequestModal from "./AcceptAssetRequestModal";
import { toggleAcceptAssetRequestModal } from "../../../Store/Actions";
import { showToast } from "../../../Helpers/HelperFns";
import {
  CANCEL_ASSET_REQUEST,
  REJECT_ASSET_REQUEST,
} from "../../../Graphql/mutation";
import Swal from "sweetalert2";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import Privilages from "../../../Constants/Privilages";
import AssignRetriveAssetsModal from "../CompanyAssets/AssignRetriveAssetsModal";

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const EmployeeAssetsRequests = ({ employeeId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const authId = useSelector((state) => state?.auth?.userProfile?.id);

  const [pagination, setPagination] = useState(paginationInitState);

  const [attemptRejectAssetRequest] = useMutation(REJECT_ASSET_REQUEST, {
    onCompleted: (data) => {
      if (data?.rejectAssetRequest?.__typename == "GeneralException") {
        showToast("error", data?.rejectAssetRequest?.message);

        return;
      }

      handleRefetch();
    },
    onError(err) {
      showToast(
        "error",
        err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
      );
    },
  });

  const [attemptCancelAssetRequest] = useMutation(CANCEL_ASSET_REQUEST, {
    onCompleted: (data) => {
      if (data?.CancelAssetRequest?.__typename == "GeneralException") {
        showToast("error", data?.CancelAssetRequest?.message);
        return;
      }

      handleRefetch();
    },
    onError(err) {
      showToast(
        "error",
        err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
      );
    },
  });

  const isRequestModalOpen = useSelector(
    (state) => state?.assets?.employeeAssetsRequestModal?.isOpen
  );

  const isAcceptRequestModalOpen = useSelector(
    (state) => state?.assets?.acceptAssetRequestModal?.isOpen
  );

  const isAssignRetrieveAssetModalOpen = useSelector(
    (state) => state?.assets?.assignRetriveAssetModal?.isOpen
  );

  const [attemptGetAssetsRequests, { loading, data }] = useLazyQuery(
    GET_EMPLOYEE_ASSETS_REQUESTS,
    {
      variables: {
        page: pagination?.currentPage ?? 1,
        employeeId: employeeId ?? authId,
      },
      onCompleted: (data) => {
        setPagination(data?.employeeAssetRequests?.paginatorInfo ?? {});
      },
    }
  );

  const [
    attemptGetMyRequests,
    { loading: myRequestsLoading, data: myRequestsData },
  ] = useLazyQuery(GET_MY_ASSET_REQUESTS, {
    variables: {
      page: pagination?.currentPage ?? 1,
    },
    onCompleted: (data) => {
      setPagination(data?.viewMyAssetRequests?.paginatorInfo ?? {});
    },
  });

  const handleRefetch = () => {
    if (!!employeeId) {
      attemptGetAssetsRequests();
    } else {
      attemptGetMyRequests();
    }
  };

  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  useEffect(() => {
    if (!!employeeId) {
      attemptGetAssetsRequests();
    } else {
      attemptGetMyRequests();
    }
  }, [employeeId]);

  const handleAccept = (request) => {
    dispatch(toggleAcceptAssetRequestModal({ isOpen: true, request }));
  };

  const handleReject = (request) => {
    Swal.fire({
      icon: "warning",
      title: t("are you sure"),
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      customClass: {
        popup: "swal-warning-style",
        icon: "swal-icon",
        denyButton: "order-2",
        confirmButton: "order-3",
        htmlContainer: "attendance-type-alert-text",
      },
    }).then(async (values) => {
      if (values.isConfirmed) {
        attemptRejectAssetRequest({
          variables: {
            id: request?.id,
          },
        });
      }
    });
  };

  const handleCancel = (request) => {
    Swal.fire({
      icon: "warning",
      title: t("are you sure"),
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      customClass: {
        popup: "swal-warning-style",
        icon: "swal-icon",
        denyButton: "order-2",
        confirmButton: "order-3",
        htmlContainer: "attendance-type-alert-text",
      },
    }).then(async (values) => {
      if (values.isConfirmed) {
        attemptCancelAssetRequest({
          variables: {
            id: request?.id,
          },
        });
      }
    });
  };

  const columns = [
    {
      name: t("type"),
      cell: (request) => <p className="m-0">{request?.assetType?.name}</p>,
    },

    {
      name: t("note"),
      selector: "description",
    },
    {
      width: "100px",
      cell: (request) => {
        if (request?.status?.toLowerCase() == "pending") {
          if (employeeId) {
            return (
              <div className="d-flex align-items-center gap-5">
                <HasPrivileges
                  allowBP
                  reqireMain={[Privilages.MANAGE_ASSET_REQUEST]}
                >
                  <AcceptIconButton onClick={() => handleAccept(request)} />
                  <TimesIconButton
                    label="reject"
                    onClick={() => handleReject(request)}
                  />
                </HasPrivileges>
              </div>
            );
          } else {
            return (
              <TimesIconButton
                label="cancel"
                onClick={() => handleCancel(request)}
              />
            );
          }
        } else {
          return (
            <p
              className={`request-badge request-${
                request?.status == "accepted" ? "approved" : request?.status
              }-status m-0`}
            >
              {request?.status}
            </p>
          );
        }
      },
    },
  ];

  return (
    <div>
      <DataTable
        noHeader
        data={
          !!employeeId
            ? data?.employeeAssetRequests?.data ?? []
            : myRequestsData?.viewMyAssetRequests?.data ?? []
        }
        columns={columns}
        className="cards_table my-3"
        progressPending={loading || myRequestsLoading}
        progressComponent={<Loader />}
        pagination={true}
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {isRequestModalOpen ? (
        <EmployeeAssetRequestModal handleRefetch={handleRefetch} />
      ) : null}

      {isAcceptRequestModalOpen ? (
        <AcceptAssetRequestModal handleRefetch={handleRefetch} />
      ) : null}

      {isAssignRetrieveAssetModalOpen ? (
        <AssignRetriveAssetsModal employeeId={employeeId} />
      ) : null}
    </div>
  );
};

export default EmployeeAssetsRequests;

import MainModal from "../../Components/MainModal";
import Select from "react-select";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import { showPreviewRolePrivilagesModalAction } from "../../Store/Actions";
import PreviewRolePrivilagesModal from "../../Components/PreviewRolePrivilagesModal";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, AlertTitle } from '@mui/material';
import { PreviewIconButton } from "../../Components/IconButtonWithTooltip";

const BatchActionModal = (props) => {
    const toggle = () => {
        props?.dismiss();
        setValue(null);
        setAccessLevelsOptions([]);
    }

    const { isOpen, type } = props?.batchActions;
    const [value, setValue] = useState(null)
    const [accessLevelsOptions, setAccessLevelsOptions] = useState([])
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isPrivilagesModalOpen = useSelector(
        (state) => state.super.previewRolePrivilagesModalActions.isVissible
    );

    
const FETCH_ROLES = gql`
{
    profile {
        company {
          roles {
            id
            name
            roleable {
                type: __typename
              }
          }
          currentSubscription {
            plan {
                id
              roles {
                id
                name
                roleable {
                    type: __typename
                  }
              }
            }
          }
        }
      }
            
}
`


    const { loading, error, data } = useQuery(FETCH_ROLES, {
        skip: !isOpen,
        fetchPolicy: "network-only",
        onCompleted: (res) => {
            let planRoles = res.profile.company.currentSubscription.plan.roles || [];
            let companyRoles = res.profile.company.roles || [];
            console.log(planRoles, companyRoles);
            setAccessLevelsOptions([...planRoles, ...companyRoles])
        }
    })
    const ASSIGN_ROLE = gql`
    mutation Mutation($input: assignRoleTemplateToEmployeesInput) {
        assignRoleTemplateToEmployees(input: $input) {
          status
          message
        }
      }
    `
    const [assignRole, { loading: submitLoading, data: submitData, error: submitError, reset }] = useMutation(ASSIGN_ROLE, {
        onCompleted(res) {
            if (res?.assignRoleTemplateToEmployees?.status === "success") {
                props?.resetSelection();
                toggle();
            }
            showToast(res?.assignRoleTemplateToEmployees?.status, res?.assignRoleTemplateToEmployees.message)
        },
        onError(error) {

            // do something
            console.log({ error });
            showToast("error", error?.graphQLErrors?.[0]?.extensions?.reason)

        }
    })

    const handleSubmit = () => {
        reset();
        if (!!value) {
            HelperFns.checkPassword(
                "defaut_warning_messsage",
                "",
                t(`Confirm, assign Role to employee`, { count: props?.selectedEmployees?.length }),
                "",
                () => assignRole({
                    variables: {
                        "input": {
                            "employeesIds": props?.selectedEmployees,
                            "role_template_id": value
                        },
                    }
                })
            );

        }
    }

    const handleShowPreviewRolePrivilagesModal = () => {
        let payload = {
            planId: data?.profile?.company?.currentSubscription?.plan?.id,
            roleId: value,
        };
        dispatch(
            showPreviewRolePrivilagesModalAction({
                data: payload,
                ref: "previewRolePrivilagesModalActions",
                formName: "previewRolePrivilagesModal",
            })
        );
    };

    return (
        <MainModal
            isOpen={isOpen}
            toggle={toggle}
            className="leaves_breaks_modal"
            btnOnClick={handleSubmit}
            btnSubmitLoading={submitLoading}
            btnOnCancelClick={toggle}
            modalTitle={`${t("Bulk Actions")} - ${t("Assign Access Level")}`}
        >
            <Alert severity="info">
                <AlertTitle>
                    {t("Assigning Access Level")}
                </AlertTitle>
                <strong>{t("Employees will be effected", { count: props?.selectedEmployees?.length })}</strong>
            </Alert>
            <h5 className="mt-3">{t("Access Level")}</h5>
            <div className="d-flex flex-row justify-content-between align-items-center mt-1" >
                <Select
                    className={
                        "select-def-input-containe b-select-style flex-grow-1 px-0"
                    }
                    classNamePrefix={"b-select-style"}
                    value={accessLevelsOptions?.find((opt) => opt?.id === value) || null}
                    onChange={(val) => {
                        setValue(val ? val?.id : null)
                    }}
                    getOptionLabel={(opt) => opt?.name}
                    getOptionValue={(opt) => opt?.id}
                    isLoading={loading}
                    isClearable={true}
                    isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
                    isSearchable
                    placeholder={t("select option")}
                    options={accessLevelsOptions || []}
                />
                {value ? (
                    <PreviewIconButton className="ml-2" onClick={handleShowPreviewRolePrivilagesModal} />
                ) : (
                    null
                )}
            </div>
            {
                submitError?.graphQLErrors?.[0]?.extensions?.validation?.["input.role_template_id"] || submitError?.graphQLErrors?.[0]?.extensions?.validation?.["input.employeesIds"] ? (
                    <span className="error_message_style">
                        {submitError?.graphQLErrors?.[0]?.extensions?.validation?.["input.role_template_id"]?.toString()}
                        {submitError?.graphQLErrors?.[0]?.extensions?.validation?.["input.employeesIds"]?.toString()}
                    </span>) : null
            }

            {isPrivilagesModalOpen ? <PreviewRolePrivilagesModal /> : null}
        </MainModal >

    );
}

export default BatchActionModal
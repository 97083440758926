import React from "react";
import { useTranslation } from "react-i18next";

import { Section, Unit } from "./SectionBase";

const OvertimeSection = (props) => {
  const { t } = useTranslation();
  const typeOptions = {
    default: "Actual Hours",
    up: "Rounded Up",
    down: "Rounded Down",
  };
  const roundOptions = {
    0: "0",
    5: "5 minutes",
    10: "10 minutes",
    15: "15 minutes",
    20: "20 minutes",
    25: "25 minutes",
    30: "30 minutes",
  };

  return (
    <Section
      title="overtime"
      units={[
        <Unit
          colStyles="col-12"
          label="Calculate Overtime After calculated sign out time by"
          val={props?.min_overtime_cuttoff + " " + t("Minutes")}
        />,
        // <Unit
        //   colStyles="col-12 col-lg"
        //   label="max. overtime hours per month"
        //   val={props?.max_overtime_per_month + " " + t("Hours")}
        // />,
        <Unit
          colStyles="col-12"
          label="max. overtime hours per day"
          val={props?.max_overtime_per_day + " " + t("Hours")}
        />,
        <Unit
          colStyles="col-12"
          label="Display payable overtime hours as"
          val={t(typeOptions?.[props?.overtime_type])}
        />,
        ...(props?.overtime_type !== "default"
          ? [
              <Unit
                colStyles="col-12"
                label="Rounded to the nearest"
                val={t(roundOptions?.[props?.overtime_value])}
              />,
            ]
          : []),
      ]}
    />
  );
};

export default OvertimeSection;

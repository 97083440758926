import React from "react";
import { InputForm, CheckboxBooleanForm } from "form-builder";

const BreaksSection = ({ formProps }) => {
  return (
    <>
      <CheckboxBooleanForm
        {...formProps}
        options={["Allow Employees To Take Breaks"]}
        name="allow_break"
        validationName="input.allow_break"
        type="checkbox"
        containerStyle="leaves_checkbox_container"
      />

      <CheckboxBooleanForm
        {...formProps}
        dependOn="allow_break"
        dependancyType="equal"
        dependancyValue={[1]}
        options={["Deduct break time from the employee's total work hours"]}
        name="deduct_from_work_hours"
        validationName="input.deduct_from_work_hours"
        type="checkbox"
        setWithValue
      />

      <div className="sub-container-style">
        <InputForm
          {...formProps}
          dependOn="allow_break"
          dependancyType="equal"
          dependancyValue={[1]}
          validateBy="textRequired"
          label="Number Of Allowed Break Sessions/Day"
          name="number_of_allowed_break_sessions_per_day"
          validationName="input.number_of_allowed_break_sessions_per_day"
          type="text"
          hasSuffix
          suffixTitle="sessions"
          containerStyle="leaves_field_container mt-3"
          inputContainerStyle="d-flex align-items-center"
        />

        <InputForm
          {...formProps}
          dependOn="allow_break"
          dependancyType="equal"
          dependancyValue={[1]}
          validateBy="textRequired"
          label="Maximum Individual Break Session Duration"
          name="maximum_break_session_duration"
          validationName="input.maximum_break_session_duration"
          hasSuffix
          suffixTitle="Minutes"
          containerStyle="leaves_field_container mt-3"
          inputContainerStyle="d-flex align-items-center"
        />

        <InputForm
          {...formProps}
          dependOn="allow_break"
          dependancyType="equal"
          dependancyValue={[1]}
          validateBy="textRequired"
          label="Maximum Break Duration Per Day"
          name="maximum_break_duration"
          validationName="input.maximum_break_duration"
          hasSuffix
          suffixTitle="Minutes"
          containerStyle="leaves_field_container mt-3"
          inputContainerStyle="d-flex align-items-center"
        />
      </div>
    </>
  );
};

export default BreaksSection;

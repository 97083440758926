import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import {
  meetingsListQuery,
  meetingsListOptionsQuery,
} from "../../Graphql/query";
import moment from "moment";
import Privileges from "../../Constants/Privilages";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import { dateFormat, dateTimeListFormat } from "../../Constants";

import IconButtonWithTooltip, {
  CopyIconButton,
  EditIconButton,
} from "../../Components/IconButtonWithTooltip";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { AddButton } from "../../Components/Buttons";
import { LeadAgentSelect, LeadsServerRestSelect } from "../../Components/CRM";
import { BSelect, DateTimePickerForm } from "form-builder";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { UpsertMeetingModal } from "../../Components/CRMFormModals";

const modalInitState = { isOpen: false, data: null };
const modals = [{ name: "upsertMeeting", Modal: UpsertMeetingModal }];
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};
const sendFilters = (filters) => {
  return {
    page: 1,
    input: {
      user_id: filters?.user?.id,
      lead_id: filters?.lead?.id,
      company_id: filters?.company?.id,
      date: filters?.date ? moment(filters.date).format(dateFormat) : undefined,
    },
  };
};

const MeetingsList = ({ isLeadProfile, isCompanyProfile, data }) => {
  const { t } = useTranslation();

  // Local State
  const [meetings, setMeetings] = useState([]);
  const [pagination, setPagination] = useState(paginationInitState);
  const [modalsState, setModalsState] = useState(
    modals.map(({ name }) => ({ name, ...modalInitState }))
  );
  const [filters, setFilters] = useState({
    date: null,
    user: null,
    lead: isLeadProfile ? { id: data?.id } : null,
    company: isCompanyProfile ? { id: data?.companyId } : null,
  });

  // Server State
  const { data: options, loading } = useQuery(meetingsListOptionsQuery);
  const { refetch: refetchList, loading: isListLoading } = useQuery(
    meetingsListQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        first: pagination.perPage,
        ...sendFilters(filters),
      },
      onCompleted: ({ meetings }) => {
        setMeetings(meetings?.data || []);
        setPagination(meetings?.paginatorInfo || paginationInitState);
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    }
  );

  // Constants
  const columns = [
    { name: t("name"), selector: "name", grow: 2 },
    {
      name: t("date"),
      grow: 2,
      cell: (row) => moment(row?.date).format(dateTimeListFormat),
    },
    { name: t("agent"), selector: "user.name", grow: 2 },
    {
      name: t("agent URL"),
      cell: (row) =>
        row?.start_url ? (
          <IconButtonWithTooltip
            isExternalLink
            href={row?.start_url}
            buttonProps={{ target: "_blank" }}
            icon={<ArrowOutwardIcon fontSize="small" />}
          />
        ) : (
          ""
        ),
    },
    {
      name: t("client URL"),
      cell: (row) =>
        row?.join_url ? (
          <div className="d-flex gap-10">
            <CopyIconButton
              onClick={() => {
                navigator.clipboard.writeText(row?.join_url);
                showToast("success", t("copied"));
              }}
            />
            <IconButtonWithTooltip
              isExternalLink
              href={row?.join_url}
              icon={<ArrowOutwardIcon fontSize="small" />}
            />
          </div>
        ) : (
          ""
        ),
    },
    {
      name: t("meeting URL"),
      cell: (row) =>
        row?.directory ? (
          <IconButtonWithTooltip
            isExternalLink
            href={row?.directory}
            buttonProps={{ target: "_blank" }}
            icon={<ArrowOutwardIcon fontSize="small" />}
          />
        ) : (
          ""
        ),
    },
    {
      cell: (row) =>
        row?.directory ? null : (
          <>
            <HasPrivileges
              reqireMain={[
                Privileges.SUPER_PRIVILEGE,
                Privileges.ADD_EDIT_MEETING,
              ]}
            >
              <EditIconButton
                onClick={() =>
                  handleOpenModal("upsertMeeting", { id: row?.id })
                }
              />
            </HasPrivileges>
          </>
        ),
    },
  ];

  /* ↓ Helpers ↓ */

  const handleSelectFilters = (val, { name }) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleDateFiltersChange = (name, val) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleOpenModal = (name, data = null) => {
    const modalState = { name, data, isOpen: true };

    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? modalState : p))
    );
  };

  const handleCloseModal = (name) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, ...modalInitState } : p))
    );
  };

  const handlePaginate = (page = pagination.currentPage) => {
    refetchList({ page });
  };

  return (
    <>
      {/* Filters & Actions */}
      <div className="d-flex align-items-end justify-content-between gap-20 mt-3 mb-2">
        {isLeadProfile ? null : (
          <LeadsServerRestSelect
            isClearable
            type="Meetings"
            name="lead"
            label="lead"
            value={filters.lead}
            onChange={handleSelectFilters}
            rootStyle="flex-1"
          />
        )}
        {isCompanyProfile ? null : (
          <BSelect
            isClearable
            label="company"
            name="company"
            value={filters.company}
            onChange={handleSelectFilters}
            options={options?.companies || []}
            rootStyle="flex-1"
            isLoading={loading}
          />
        )}
        <HasPrivileges scope="all" requireScope={[Privileges.VIEW_MEETINGS]}>
          <LeadAgentSelect
            isClearable
            label="agent"
            name="user"
            page="MEETINGS"
            value={filters.user}
            onChange={handleSelectFilters}
            rootStyle="flex-1"
            isLoading={loading}
          />
        </HasPrivileges>
        <DateTimePickerForm
          hasIcon
          isClearable
          label="date"
          placeholder="date"
          value={filters.date}
          rootStyle="flex-1"
          labelStyle="mb-2"
          datePickerContainer="w-100"
          onChange={(val) => handleDateFiltersChange("date", val)}
        />
        <HasPrivileges
          reqireMain={[Privileges.SUPER_PRIVILEGE, Privileges.ADD_EDIT_MEETING]}
        >
          <AddButton
            label="meeting"
            onClick={() => handleOpenModal("upsertMeeting")}
          />
        </HasPrivileges>
      </div>

      {/* List */}
      <DataTable
        noHeader
        data={meetings}
        columns={columns}
        className="cards_table"
        progressPending={isListLoading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {modals.map(({ name, Modal }, i) =>
        modalsState[i].isOpen ? (
          <Modal
            key={name}
            refetchList={refetchList}
            data={modalsState[i]?.data}
            onClose={() => handleCloseModal(name)}
          />
        ) : null
      )}
    </>
  );
};

export default MeetingsList;

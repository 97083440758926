import React from "react";
import { makeStyles } from "tss-react/mui";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import HelperFns from "../../Helpers/HelperFns";
import { useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import i18n from "i18next";
import { useSelector } from "react-redux";
import Constants, { assignmentTabPrivileges } from "../../Constants";
import { Config } from "../../Config";

const verticalNavbarStyles = makeStyles()(() => Constants.verticalNavbarStyles);
const horizontalNavbarStyles = makeStyles()(
  () => Constants.horizontalNavbarStyles
);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const LogsTabs = (props) => {
  const companyId = useSelector(
    (state) => state?.user?.userProfile?.company?.id
  );
  const { classes } = props?.isRouteSidebar
    ? verticalNavbarStyles()
    : horizontalNavbarStyles();

  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    history.push(`/attendance?tab=${newValue}`);
  };
  const history = useHistory();
  let query = useQuery();
  const activeValue = query?.get("tab")?.includes("work-calendar") ? "work-calendar" : query?.get("tab");

  const isRtl =
    i18n.dir(useSelector((state) => state.user.userProfile.lng)) === "rtl"
      ? true
      : false;

  const possibleValues = [
    "work-calendar",
    "attendance-history",
    "penalties",
    "assignments",
    "monthly-report",
    "custom-report",
    "yearly-report",
    "facial-recognition-report",
    "leave-adjustments"
  ];

  return (
    <div>
      <Tabs
        variant="scrollable"
        value={possibleValues.includes(activeValue) ? activeValue : false}
        onChange={handleChange}
        scrollButtons="auto"
        orientation={props?.isRouteSidebar ? "vertical" : "horizontal"}
        classes={{
          root: classes.root,
          indicator: classes.indicator,
          flexContainer: classes.flexContainer,
          scrollButtons: classes.scrollButtons,
        }}
      >
      {HelperFns.checkPrivileges({
        allowBP: true,
        privileges: [ 
          Privilages.VIEW_EMPLOYEE_SCHEDULES,
          Privilages.PLAN_NEW_SCHEDULES_FOR_MANAGED_WORKGROUPS,
        ],
      }) && (
        <Tab
          classes={{
            root: classes.tabRoot,
            wrapper: "top-bar-label",
            selected: isRtl ? classes.selectedRtl : classes.selected,
          }}
          value="work-calendar"
          label={
            <div className="routeBar-text">
              <FontAwesomeIcon
                icon={Icons["faCalendarAlt"]}
                className="mx-2 routeBar-icon"
              />
              {t("work calendar")}
            </div>
          }
        />
      )}
        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [
            Privilages.VIEW_ATTENDANCE_HISTORY_LOGS,
            Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
          ],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value="attendance-history"
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faFileAlt"]}
                  className="mx-2 routeBar-icon"
                />
                {t("attendance history")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [
            Privilages.VIEW_EMPLOYEE_PENALTIES,
            Privilages.APPLY_REVERT_PENALTIES,
          ],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value="penalties"
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faHandHoldingUsd"]}
                  className="mx-2 routeBar-icon"
                />
                {t("penalties")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: assignmentTabPrivileges,
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value="assignments"
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faFileAlt"]}
                  className="mx-2 routeBar-icon"
                />
                {t("assignments")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_ATTENDANCE_MONTHLY_REPORT],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value="monthly-report"
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faFileAlt"]}
                  className="mx-2 routeBar-icon"
                />
                {t("monthly report")}
              </div>
            }
          />
        )}
        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_ATTENDANCE_MONTHLY_REPORT],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value="custom-report"
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faFileAlt"]}
                  className="mx-2 routeBar-icon"
                />
                {t("custom report")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_ATTENDANCE_MONTHLY_REPORT],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value="yearly-report"
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faFileAlt"]}
                  className="mx-2 routeBar-icon"
                />
                {t("yearly report")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_FACIAL_RECOGNITION_REPORT],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            value="facial-recognition-report"
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faFileAlt"]}
                  className="mx-2 routeBar-icon"
                />
                {t("facial recognition report")}
              </div>
            }
          />
        )}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT],
        }) && (
          <Tab
            classes={{
                root: classes.tabRoot,
                wrapper: "top-bar-label",
                selected: isRtl ? classes.selectedRtl : classes.selected,
              }}
            value={"leave-adjustments"}
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  rotation={180}
                  icon={Icons["faSignOutAlt"]}
                  className="mx-2 routeBar-icon"
                />
                {t("leave adjustments")}
              </div>
            }
          />
        )}

        {/* {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_EMPLOYEE_PAYABLE_OVERTIME],
        }) && (
          <Tab
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: classes.selected,
            }}
            value={2}
            label={t("overtime")}
          />
        )} */}

        {/* <Tab
          classes={{
            root: classes.tabRoot,
            wrapper: "top-bar-label",
            selected: classes.selected,
          }}
          value={3}
          label="SUSPENSION"
        /> */}
      </Tabs>
    </div>
  );
};

export default LogsTabs;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import {
  hideEditAttRequestModal,
  editAttRequestModalAttempt,
} from "../../Store/Actions";

import {
  CheckboxBooleanForm,
  DateTimePickerForm,
  RadioboxForm,
} from "form-builder";
import MainModal from "../MainModal";

const formName = "editAttRequestModalData";
const formNameValidation = "editAttRequestModalValidation";

const EditAttRequestModal = ({ isMyProfileHistory, isEmpProfileHistory }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Reducer State
  const {
    id,
    date,
    sign_in_time,
    sign_out_time,
    from_home,
    sign_in_date,
    sign_out_date,
    ...modalData
  } = useSelector((state) => state.user[formName]);
  const modalValidation = useSelector(
    (state) => state.user[formNameValidation]
  );
  const {
    isLoading,
    modalMessage,
    isVissible: isModalVissible,
  } = useSelector((state) => state.user.editAttRequestModal);

  /* ↓ State Effects ↓ */

  useEffect(() => {
    setIsSubmitting(false);
  }, [isModalVissible]);

  /* ↓ Helpers ↓ */

  // days options for edit attendance
  let Daysopts = [
    {
      value: moment(date, "YYYY-MM-DD")
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
      label: moment(date).subtract(1, "days").format("dddd (DD/MM)"),
    },
    {
      value: moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"),
      label: moment(date).format("dddd (DD/MM)"),
    },
    {
      value: moment(date, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD"),
      label: moment(date, "YYYY-MM-DD").add(1, "days").format("dddd (DD/MM)"),
    },
  ];

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    !modalValidation.length &&
      dispatch(
        editAttRequestModalAttempt({
          id,
          emp_id: modalData?.employee?.id,
          home: from_home || 0,
          sign_in_time: !!sign_in_time
            ? `${sign_in_date} ${sign_in_time}`
            : null,
          sign_out_time: !!sign_out_time
            ? `${sign_out_date} ${sign_out_time}`
            : null,
          date,
          ref: getPageRef(),
        })
      );
  };

  const getPageRef = () => {
    if (isMyProfileHistory) return "myHistory";
    if (isEmpProfileHistory) return "empHistory";
    return "empLogs";
  };

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={() => dispatch(hideEditAttRequestModal())}
      modalTitle={
        t("Edit Attendance Request") +
        " - " +
        moment(date).format("dddd DD-MM-YYYY")
      }
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
    >
      <div className="mb-2">
        <div className="row align-items-start mb-3">
          <div className="col-sm-6 mb-2">
            <RadioboxForm
              formName={formName}
              formNameValidation={formNameValidation}
              formSubmitting={isSubmitting}
              name="sign_in_date"
              label="sign in"
              labelStyle="mb-2"
              options={Daysopts}
              optionsContainerStyle="row"
              containerStyle="my-2"
              optionItemStyle=""
              optionInputStyle=" "
              reducer="user"
            />

            <DateTimePickerForm
              formName={formName}
              formNameValidation={formNameValidation}
              formSubmitting={isSubmitting}
              name="sign_in_time"
              containerStyle=" "
              labelStyle="mb-2"
              validateBy="textRequired"
              hasIcon
              timeOnly
              requestFormat="HH:mm"
              // openToDate={date}
              reducer="user"
              validationName="input.sign_in_time"
            />
          </div>

          <div className="col-sm-6 mb-2">
            <RadioboxForm
              formName={formName}
              formNameValidation={formNameValidation}
              formSubmitting={isSubmitting}
              name="sign_out_date"
              label={"sign out"}
              labelStyle="mb-2"
              options={Daysopts}
              optionsContainerStyle="row"
              containerStyle="my-2"
              optionItemStyle=""
              optionInputStyle=" "
              reducer="user"
            />

            <DateTimePickerForm
              formName={formName}
              formNameValidation={formNameValidation}
              formSubmitting={isSubmitting}
              name="sign_out_time"
              containerStyle=" "
              labelStyle="mb-2"
              // validateBy="textRequired"
              hasIcon
              timeOnly
              requestFormat="HH:mm"
              // openToDate={date}
              reducer="user"
              validationName="input.sign_out_time"
            />
          </div>
        </div>

        <CheckboxBooleanForm
          formName={formName}
          formNameValidation={formNameValidation}
          formSubmitting={isSubmitting}
          options={[t("employee is working from home")]}
          name="from_home"
          type="checkbox"
          optionLabelStyle="content-header-sub-label"
          reducer="user"
        />

        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

export default EditAttRequestModal;

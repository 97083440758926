import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

const AttchartPlaceholder = () => {
  return (
    <div className="card-body">
      <Grid item>
        <Skeleton variant="rectangular" width={"100%"} height={180} />
      </Grid>
    </div>
  );
};

export default AttchartPlaceholder;

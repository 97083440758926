import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { useTranslation } from "react-i18next";
import { InputForm } from "form-builder";
import { useMutation } from "@apollo/client";
import { EDIT_PAYROLL_DEDUCTIBLES } from "../../Graphql/mutation";
import { weeklyPayrollQuery } from "../../Graphql/query";
import { useDispatch } from "react-redux";
import { showErrorToast } from "../../Store/Actions";

const EditWeeklyDeductiblesModal = ({
  isOpen,
  title,
  userId,
  paidAt,
  from,
  deductibleType,
  oldAmount,
  handleCloseModal,
  payrollType,
  refetchQueries = [],
  currency
}) => {
  console.log("currency", currency)
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isReverting, setIsReverting] = useState(false);

  const [newAmount, setNewAmount] = useState("");

  const [attemptEditPayrollDeductible, { loading: editLoading }] = useMutation(
    EDIT_PAYROLL_DEDUCTIBLES
  );

  useEffect(() => {
    return () => {
      handleCloseModal();
    };
  }, []);

  const handleSubmitBtn = (isRevert = false) => {
    if (!isRevert) {
      setIsSubmitting(true);

      if (!!!newAmount.trim()) return;
    } else {
      setIsReverting(true);
    }

    attemptEditPayrollDeductible({
      variables: {
        paidAt,
        userId,
        deductibleType,
        payrollType,
        quantity: parseFloat(newAmount),
        isRevert: isRevert ? 1 : 0,
        from,
      },
      onCompleted: (_) => {
        handleCloseModal();
        setIsReverting(false);
      },
      onError: (err) => {
        setIsReverting(false);
        dispatch(
          showErrorToast(
            err.graphQLErrors?.[0]?.extensions?.reason ?? "Something went wrong"
          )
        );
      },
      refetchQueries,
      awaitRefetchQueries: true,
    });
  };

  return (
    <MainModal
      toggle={handleCloseModal}
      isOpen={isOpen}
      modalTitle={t(title)}
      btnOnClick={() => handleSubmitBtn(false)}
      btnSubmitLoading={editLoading && !isReverting}
      className="edit_amount_modal_wrapper_style"
      btnLabelCancel={t("Revert")}
      btnOnCancelClick={() => handleSubmitBtn(true)}
      btnCancelLoading={editLoading && isReverting}
    >
      <div>
        <div className="row">
          <div className="d-flex align-items-baseline justify-content-between col-12">
            <div className="d-flex align-items-baseline">
              <label className="mr-4 pr-3">{t("calculated amount")}</label>
              <div className="d-flex align-items-center">
                <p className="mr-3">{currency?.symbol}</p>
                <p>{!!oldAmount ? oldAmount?.toFixed(2) : "---"}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="d-flex align-items-baseline col-12">
            <label className="mr-5 pr-4">{t("new amount")}</label>
            <p className="d-flex align-items-baseline amount_wrapper_style mb-0">
              <p className="mr-3">{currency?.symbol}</p>
              <InputForm
                name="new_amount"
                value={newAmount}
                onChange={(e) => setNewAmount(e.target.value)}
                formSubmitting={isSubmitting}
                labelStyle="mb-2"
                containerStyle=""
                inputContainerStyle="w-100"
                validateBy="textRequired"
              />
            </p>
          </div>
        </div>

        {/* (Start) Error Message */}
        {/* {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )} */}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

export default EditWeeklyDeductiblesModal;

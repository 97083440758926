import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setInputValidateAction,
  setInputInValidateAction,
} from "../../Store/Actions";
import Validation from "../Validations";

export const useFormValidation = (
  inputVal,
  name,
  formNameValidation,
  formSubmitting,
  validateBy,
  disabled,
  dependVal
) => {
  const [validity, setValidity] = useState(true);
  const [validityMessage, setValidityMessage] = useState("");
  const dispatch = useDispatch();

  const { t } = useTranslation();


  useEffect(() => {
    if (validateBy && !disabled) {
      runValidation();
    }
  }, [inputVal, formSubmitting, dependVal, disabled]);

  // Validate the Input When its unMounted
  useEffect(() => {
    return () => {
      if (validateBy && !disabled) {
        dispatch(setInputValidateAction(formNameValidation, name));
      }
    };
  }, [disabled]);

  const runValidation = () => {
    switch (validateBy) {
      case "end_time":
        Validation.validationSchema
          .validateAt(
            validateBy,
            { end_time: inputVal },
            { context: { startTime: dependVal } }
          )
          .then((valid) => {
            dispatch(setInputValidateAction(formNameValidation, name));
            if (formSubmitting) {
              setValidity(true);
              setValidityMessage("");
            }
          })
          .catch((err) => {
            dispatch(setInputInValidateAction(formNameValidation, name));
            if (formSubmitting) {
              setValidity(false);
              setValidityMessage(err.message);
            }
          });

        break;
      case "start_time":
        Validation.validationSchema
          .validateAt(
            validateBy,
            { start_time: inputVal },
            { context: { endTime: dependVal } }
          )
          .then((valid) => {
            dispatch(setInputValidateAction(formNameValidation, name));
            if (formSubmitting) {
              setValidity(true);
              setValidityMessage("");
            }
          })
          .catch((err) => {
            dispatch(setInputInValidateAction(formNameValidation, name));
            if (formSubmitting) {
              setValidity(false);
              setValidityMessage(err.message);
            }
          });
        break;
      case "end_date":
        Validation.validationSchema
          .validateAt(
            validateBy,
            { end_date: inputVal },
            { context: { startDate: dependVal } }
          )
          .then((valid) => {
            dispatch(setInputValidateAction(formNameValidation, name));
            if (formSubmitting) {
              setValidity(true);
              setValidityMessage("");
            }
          })
          .catch((err) => {
            dispatch(setInputInValidateAction(formNameValidation, name));
            if (formSubmitting) {
              setValidity(false);
              setValidityMessage(err.message);
            }
          });

        break;
      case "start_date":
        Validation.validationSchema
          .validateAt(
            validateBy,
            { start_date: inputVal },
            { context: { endDate: dependVal } }
          )
          .then((valid) => {
            dispatch(setInputValidateAction(formNameValidation, name));
            if (formSubmitting) {
              setValidity(true);
              setValidityMessage("");
            }
          })
          .catch((err) => {
            dispatch(setInputInValidateAction(formNameValidation, name));
            if (formSubmitting) {
              setValidity(false);
              setValidityMessage(err.message);
            }
          });
        break;

      default:
        Validation.validationSchema
          .validateAt(validateBy, { [validateBy]: inputVal })
          .then((valid) => {
            dispatch(setInputValidateAction(formNameValidation, name));
            if (formSubmitting) {
              setValidity(true);
              setValidityMessage("");
            }
          })
          .catch((err) => {
            dispatch(setInputInValidateAction(formNameValidation, name));
            if (formSubmitting) {
              setValidity(false);
              setValidityMessage(err.message);
            }
          });

        break;
    }
  };
  return { validity, validityMessage: t(validityMessage) };
};

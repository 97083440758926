import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailOutlined from "@mui/icons-material/EmailOutlined";
import VpnKey from "@mui/icons-material/VpnKey";

import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import IntlTelInput from "react-intl-tel-input";
import Grid from "@mui/material/Grid";
import { authUserMutation } from "../../Graphql/mutation";
import { useMutation } from "@apollo/client";

import { useDispatch, useSelector } from "react-redux";
import {
  sendEmailVerificationAction,
  sendPhoneVerificationAction,
  showErrorToast,
} from "../../Store/Actions";
import swal from "sweetalert";
import HelperFns from "../../Helpers/HelperFns";

const AddNewAccount = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const phoneRef = React.useRef(null);

  // Start of Handling the show and hide password function
  const [showPassword, setShowPassword] = useState(false);
  const [isPhoneLogin, setIsPhoneLogin] = useState(false);

  const [authForm, setAuthForm] = useState({
    username: "",
    password: "",
    device_token: "desktop",
    device_type: "pc",
  });

  const usersList = useSelector((state) => state.LoginUserList?.usersList);

  const auth = useSelector((state) => state?.auth?.userProfile);
  const token = useSelector((state) => state?.auth?.token);

  const authAccount = {
    access_token: token,
    user: auth,
  };

  const actualPhone =
    phoneRef.current?.selectedCountryData?.dialCode == "20"
      ? authForm?.username?.replace(/^0/, "")
      : authForm?.username;

  const username = isPhoneLogin
    ? `+${phoneRef.current?.selectedCountryData?.dialCode}${actualPhone}`
    : authForm?.username;

  const [login, { loading }] = useMutation(authUserMutation, {
    onCompleted(res) {
      // to handle first login case
      // since auth was not in the users list at first
      const isPreviousUserNotExistInUsersList = !!!usersList?.find(
        (account) => account?.user?.id == auth?.id
      );

      if (isPreviousUserNotExistInUsersList) {
        dispatch({
          type: "ADD_ACCOUNT_TO_LIST",
          payload: {
            account: authAccount,
          },
        });
      }
      dispatch({
        type: "ADD_ACCOUNT_TO_LIST",
        payload: {
          account: res?.login,
        },
      });
      dispatch({
        type: "SWITCH_ACCOUNT",
        payload: { account: res?.login },
      });
      props?.dismiss();
    },
    onError(error) {
      if (
        error?.graphQLErrors?.[0]?.extensions.reason === "phone_verification"
      ) {
        swal({
          title: HelperFns.localizeFns("verify phone number"),
          text: HelperFns.localizeFns(
            error?.graphQLErrors?.[0]?.extensions.reason
          ),
          icon: "warning",
          className: "swal-warning-style",
          buttons: true,
        }).then((confirm) => {
          if (confirm) {
            dispatch(
              sendPhoneVerificationAction(username, null, false, null) // username is a phone number in this case
            );
          }
        });
        return;
      }
      if (
        error?.graphQLErrors?.[0]?.extensions.reason === "email_verification"
      ) {
        swal({
          title: HelperFns.localizeFns("verify email"),
          text: HelperFns.localizeFns(
            error?.graphQLErrors?.[0]?.extensions.reason
          ),
          icon: "warning",
          className: "swal-warning-style",
          buttons: true,
        }).then((confirm) => {
          if (confirm) {
            dispatch(sendEmailVerificationAction(username));
          }
        });
        return;
      } else {
        dispatch(showErrorToast(error?.graphQLErrors?.[0]?.extensions?.reason));
      }
      // do something
    },
  });

  const handleSwitchChange = (event) => {
    setIsPhoneLogin(event.target.checked);
    setAuthForm((prevState) => ({
      ...prevState,
      username: "",
    }));
    // props.onInputResetAction(formName, "username");
  };

  const onInputChangeAction = (e) => {
    setAuthForm((prevState) => ({
      ...prevState,
      [e?.target?.name]: e?.target?.value,
    }));
  };

  const handleLoginInputChange = (e) => {
    onInputChangeAction(e);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  // End of Handling the show and hide password function

  const handleLogin = (e) => {
    e.preventDefault();

    if (isPhoneLogin) {
      let authData = {
        ...authForm,
        username,
      };
      login({ variables: { ...authData } });
    } else {
      login({ variables: { ...authForm } });
    }
  };

  const handlePhoneChange = (
    isValid,
    value,
    seletedCountryData,
    fullNumber,
    extension,
    event
  ) => {
    let e = {
      target: {
        value,
        name: "username",
      },
    };
    onInputChangeAction(e);
  };

  return (
    <>
      <form className="login-body-wrapper" onSubmit={handleLogin}>
        <Grid
          component="label"
          container
          alignItems="center"
          justifyContent={"flex-end"}
          spacing={1}
          dir="ltr"
        >
          <Grid item>{t("email")}</Grid>
          <Grid item>
            <Switch
              checked={isPhoneLogin}
              onChange={handleSwitchChange}
              name="isPhoneLogin"
              classes={{ switchBase: "switchBase" }}
            />
          </Grid>
          <Grid item>{t("phone")}</Grid>
        </Grid>

        {isPhoneLogin ? (
          <div
            dir="ltr"
            className="d-flex flex-column login_input_wrapper_style"
          >
            <label className="mb-2 validity-label-style">{t("phone")}</label>
            <IntlTelInput
              ref={phoneRef}
              inputClassName="input-style-default login-body-wrapper custom_outlined_input_style input"
              style={{ display: "flex" }}
              fieldName={"username"}
              telInputProps={{
                dir: "ltr",
              }}
              preferredCountries={["eg", "ae", "sa"]}
              onPhoneNumberChange={(
                isValid,
                value,
                seletedCountryData,
                fullNumber,
                extension,
                event
              ) => {
                handlePhoneChange(
                  isValid,
                  value,
                  seletedCountryData,
                  fullNumber,
                  extension,
                  event
                );
              }}
              separateDialCode
              format={false}
              formatOnInit={false}
              value={authForm.username}
            />
          </div>
        ) : (
          <div className="d-flex flex-column login_input_wrapper_style">
            <label>{t("email")}</label>
            <OutlinedInput
              type="text"
              name="username"
              onChange={handleLoginInputChange}
              value={authForm.username}
              placeholder={t("email")}
              classes={{
                root: "custom_outlined_input_style",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton>
                    <EmailOutlined />
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
        )}

        <div className="my-3 d-flex flex-column login_input_wrapper_style">
          <label>{t("password")}</label>
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            value={authForm.password}
            name="password"
            onChange={handleLoginInputChange}
            placeholder={t("password")}
            classes={{
              root: "custom_outlined_input_style",
            }}
            startAdornment={
              <InputAdornment position="start">
                <IconButton tabIndex="-1">
                  <VpnKey />
                </IconButton>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  tabIndex="-1"
                  // onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>

        <div className="d-flex mt-4 pt-2">
          <button
            className="btn btn-block login_btn_style mt-0 text-capitalize"
            // onClick={handleLogin}
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                size="sm"
                color="#fff"
              />
            ) : (
              t("login")
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default AddNewAccount;

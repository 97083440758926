import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import HelperFns from "../../Helpers/HelperFns";
import AssetsTabs from "./AssetsTabs";
import CompanyAssets from "./CompanyAssets/CompanyAssets";
import AssetRequests from "./AssetRequests/AssetRequests";
import AssetTypes from "./AssetTypes/AssetTypes";
import StorageLocations from "./StorageLocations/StorageLocations";
import ScrappedAssets from "./ScrappedAssets/ScrappedAssets";
import Privilages from "../../Constants/Privilages";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AssetsPage = (props) => {
  const history = useHistory();
  const query = useQuery();
  const activeValue = query.get("tab");

  useEffect(() => {
    if (!activeValue) {
      renderfirstTab();
    }
  }, [activeValue]);

  const renderfirstTab = () => {
    if (
      HelperFns.checkPrivileges({
        privileges: [],
        allowBP: true,
      })
    ) {
      return history.push(`/assets?tab=company-assets`);
    }

    return null;
  };

  const renderSelectedTab = () => {
    switch (activeValue?.toLowerCase()) {
      case "company-assets":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_COMPANY_ASSETS]} allowBP>
            <CompanyAssets />
          </HasPrivileges>
        );

      case "asset-requests":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_ASSET_REQUEST]}
            allowBP={true}
          >
            <AssetRequests />
          </HasPrivileges>
        );

      case "asset-types":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_ASSET_TYPES]}
            allowBP={true}
          >
            <AssetTypes />
          </HasPrivileges>
        );

      case "storage-location":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_STORAGE_LOCATIONS]}
            allowBP={true}
          >
            <StorageLocations />
          </HasPrivileges>
        );

      case "scrapped-assets":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_SCRAPPED_ASSETS]}
            allowBP={true}
          >
            <ScrappedAssets />
          </HasPrivileges>
        );

      default:
        break;
    }
  };
  return (
    <div className="content pt-3">
      <AssetsTabs />
      {renderSelectedTab()}
    </div>
  );
};

export default AssetsPage;

import { checkCompanyPrivileges } from ".";
import Constants from "../../Constants";
import Privilages from "../../Constants/Privilages";

export const getProgressSteps = () => 6; // data steps length

export const getWizardAttType = (data) => {
  const isCompanyHasShift = checkCompanyPrivileges({
    privileges: [Privilages.VIEW_EMPLOYEE_WORK_GROUPS],
  });

  if (
    !isCompanyHasShift ||
    data?.company_attendance_profiles?.paginatorInfo?.count
  ) {
    return Constants.attendanceTypes.OFFICE_BASED;
  }

  if (data?.workGroups?.paginatorInfo?.count) {
    return Constants.attendanceTypes.SHIFT_BASED;
  }

  return null;
};

export const getWizardDataSteps = (data) => {
  const wizardAttType = getWizardAttType(data);
  const steps = {
    company_offices: data?.company_offices,
    company_departments: data?.company_departments,
    company_positions: data?.company_positions,
    // wizardAttType step (UI)
    work_timings: data?.work_timings,
    company_leaves_breaks: data?.company_leaves_breaks,
    ...(wizardAttType === Constants.attendanceTypes.OFFICE_BASED
      ? { company_attendance_profiles: data?.company_attendance_profiles }
      : { workGroups: data?.workGroups }),
  };

  return steps;
};

export const getWizardDataStepsCounts = (data) => {
  const steps = getWizardDataSteps(data);
  return Object.values(steps).filter((stepData) => !!stepData)?.map((a) => a?.paginatorInfo?.count);
};

export const getInitialActiveStep = (data) => {
  const stepsCounts = getWizardDataStepsCounts(data);
  const beforeAttTypeStepsCounts = stepsCounts.slice(0, 3);

  if (beforeAttTypeStepsCounts.every((b) => b)) {
    const ATT_TYPE_UI_STEP = 4;

    if (
      !data?.company_attendance_profiles?.paginatorInfo?.count ||
      !data?.workGroups?.paginatorInfo?.count
    ) {
      return ATT_TYPE_UI_STEP;
    } else {
      return stepsCounts.indexOf(0) + 2;
    }
  } else {
    return beforeAttTypeStepsCounts.indexOf(0) + 1;
  }
};

export const getNextActiveStep = (data, wizardAttType, currentStep) => {
  const stepsCounts = getWizardDataStepsCounts(data);
  const beforeAttTypeStepsCounts = stepsCounts.slice(0, 3);
  const isWizardAttTypeStep = currentStep === "wizardAttType";
  const ATT_TYPE_UI_STEP = 4;

  switch (true) {
    case !isWizardAttTypeStep && !wizardAttType && beforeAttTypeStepsCounts.every((b) => b):
      return ATT_TYPE_UI_STEP; // wizardAttType step

    default:
      return stepsCounts.indexOf(0) > 2
        ? stepsCounts.indexOf(0) + 2 // after att type step
        : stepsCounts.indexOf(0) + 1; // before att type step
  }
};

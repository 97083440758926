import React, { useEffect } from "react";
import MainModal from "../../Components/MainModal";
import { connect } from "react-redux";
import {
  dismissAddDocumentsModalAction,
  fetchHistoryDocModalAttempt,
  fetchHistoryDocModalSuccess,
  hideMainsModalWithData,
} from "../../Store/Actions";
import "react-dropzone-uploader/dist/styles.css";

import Loader from "../../Components/Loader";
import Documents from "./Documents";
import HelperFns from "../../Helpers/HelperFns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const ref = "historyDocumentsModalActions";
const formName = "historyDocumentsModal";

const HistoryDocumentsModal = (props) => {
  const { t } = useTranslation();

  const {
    handleshowAddDocumentsModal,
    handleshowEditDocumentModal,
    page_flag,
  } = props;

  const { isVissible, modalMessage, isLoading } = props.modalActions;
  const { employee_id, document_id, document_fields, document_name, country } =
    props.modalData;

  const closeModal = () => {
    props?.hideMainsModalWithData({
      ref,
      formName,
    });
    // Reset Fetched Data
    props?.fetchHistoryDocModalSuccess({
      ref,
    });
  };

  useEffect(() => {
    isVissible &&
      props.fetchHistoryDocModalAttempt({
        ref,
        employee_id,
        document_id,
      });
  }, [isVissible]);

  return (
    <MainModal
      isOpen={isVissible}
      modalTitle={HelperFns.getCountryShortName({
        name: document_name,
        country_id: country?.id,
        country: {
          short_name: country?.short_name,
        },
      })}
      modalFooterComponent={() => (
        <div className="w-100 d-flex justify-content-between">
          {/* (Start) Close Modal Button */}
          <button
            className="btn pervious_btn_style"
            type="button"
            onClick={closeModal}
          >
            {t("close")}
          </button>
          {/* (End) Close Modal Button */}

          {/* (Start) Add Document Button */}
          <button
            className="btn add_new_btn_style"
            type="button"
            onClick={() =>
              handleshowAddDocumentsModal(
                document_id,
                document_fields,
                employee_id,
                document_name,
                country
              )
            }
          >
            <FontAwesomeIcon className="mr-2" icon={faPlus} />
            {t("add new document")}
          </button>
          {/* (End) Add Document Button */}
        </div>
      )}
    >
      <div>
        {isLoading ? (
          <div className="loader_wrapper_style">
            <Loader />
          </div>
        ) : null}

        {/* (Start) History Card */}
        {props?.upsertDocsHistory?.map((doc) => (
          // console.log("doc" , doc)
          <Documents
            key={doc?.id}
            user_document={doc}
            document={doc?.document}
            editAction="upsert"
            userId={employee_id}
            handleshowAddDocumentsModal={handleshowAddDocumentsModal}
            handleshowEditDocumentModal={handleshowEditDocumentModal}
            containerClass="w-100 mb-3"
            document_files={doc.files}
            page_flag={page_flag}
          />
        ))}
        {/* (End) History Card */}

        {/* (Start) Error Message */}
        {modalMessage && <div className="warnig-msg-style">{modalMessage}</div>}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalActions: state.documents[ref],
  modalData: state.documents[formName],
  upsertDocsHistory: state.documents.upsertDocsHistory,
});

export default connect(mapStateToProps, {
  dismissAddDocumentsModalAction,
  fetchHistoryDocModalAttempt,
  fetchHistoryDocModalSuccess,
  hideMainsModalWithData,
})(HistoryDocumentsModal);

import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { DateTimePickerForm } from "form-builder";
import { connect } from "react-redux";
import {
  dismissEditLeavesModalAction,
  cancelRequestAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";

const formName = "editLeavesModal";
const formNameValidation = "editLeavesModalValidation";

const EditLeavesModal = (props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FormProps = {
    formName,
    formNameValidation,
    formSubmitting: isSubmitting,
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    if (
      props.modalValidation.length ||
      props?.editLeavesModal?.time_from > props?.editLeavesModal?.end_date ||
      props?.editLeavesModal?.time_to < props?.editLeavesModal?.end_date
    ) {
      return;
    }

    // empty string for page_flag attr in this action
    props.cancelRequestAction(
      props?.editLeavesModal?.id,
      props?.inboundRequests ? "inbound-requests" : "",
      props?.editLeavesModal?.end_date,
      props?.editLeavesModal?.employeeId
    );
  };

  const toggleModal = () => {
    props.dismissEditLeavesModalAction({
      ref: "editLeavesModalActions",
      formName,
    });
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, [props?.isModalVissible]);

  return (
    <MainModal
      isOpen={props?.isModalVissible}
      toggle={toggleModal}
      modalTitle={t("Edit Leave Request")}
      btnOnClick={handleSubmitBtn}
      btnOnCancelClick={toggleModal}
      btnSubmitLoading={props?.isLoading}
    >
      <>
        <div className="row mb-2">
          <div className="col-12">
            <DateTimePickerForm
              {...FormProps}
              containerStyle="custom_datepicker_wrapper_style"
              name="end_date"
              label="end date"
              labelStyle="mb-2"
              validateBy="textRequired"
              hasIcon
              reducer="user"
              placeholder={t("select date")}
              requestFormat="yyyy-MM-DD"
            />
          </div>
        </div>

        {/* (Start) Error Message for choosing end date before start date of request */}
        {props?.editLeavesModal?.time_from > props?.editLeavesModal?.end_date &&
          isSubmitting &&
          props?.editLeavesModal?.end_date != "" && (
            <div className="warnig-msg-style">
              {t("edit_leave_start_date_validation_message")}
            </div>
          )}
        {/* (End) Error Message for choosing end date before start date of request */}

        {/* (Start) Error Message for choosing end date after end date of request */}
        {props?.editLeavesModal?.time_to < props?.editLeavesModal?.end_date &&
          isSubmitting &&
          props?.editLeavesModal?.end_date != "" && (
            <div className="warnig-msg-style">
              {t("edit_leave_end_date_validation_message")}
            </div>
          )}
        {/* (End) Error Message for choosing end date before end date of request */}

        {/* (Start) Error Message */}
        {props?.modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{props?.modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalValidation: state.user[formNameValidation],
  editLeavesModal: state.user[formName],
  isModalVissible: state.user.editLeavesModalActions?.isVissible,
  isLoading: state.user.editLeavesModalActions?.isLoading,
  modalMessage: state.user.editLeavesModalActions?.modalMessage,
});

export default connect(mapStateToProps, {
  dismissEditLeavesModalAction,
  cancelRequestAction,
})(EditLeavesModal);

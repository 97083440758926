import React from "react";
import { useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import {
  editFetchWorkTimeSuccess,
  dismissWorkTimingModalAction,
} from "../../Store/Actions";
import { wizardWorkTimingQuery } from "../../Graphql/query";
import HelperFns, { showToast } from "../../Helpers/HelperFns";

import Loader from "../../Components/Loader";
import WorkTimingForm from "../../Routes/WorkTimingsListPage/WorkTimingForm";

const SetupWizardWorkTimings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [isLoading, setIsLoading] = React.useState(true);

  // Server State
  const [getWorkTiming] = useLazyQuery(wizardWorkTimingQuery);

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    getWorkTiming({
      onCompleted: ({ workTimings }) => {
        if (workTimings?.data.length)
          dispatch(
            editFetchWorkTimeSuccess(
              HelperFns.serializeFetchedWorkTime(workTimings?.data[0])
            )
          );
        setIsLoading(false);
      },
      onError: (err) => {
        setIsLoading(false);
        showToast("error", err?.message);
      },
    });

    return () => {
      dispatch(dismissWorkTimingModalAction());
    };
  }, []);

  return (
    <section className="setup-wizard-step">
      <h2 className="setup-wizard-title mb-4">
        {t("create your work timings")}
      </h2>

      {isLoading ? <Loader fixed /> : <WorkTimingForm setupWizard={true} />}
    </section>
  );
};

export default SetupWizardWorkTimings;

import React from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { Tooltip, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faTimes,
  faCheck,
  faFileAlt,
  faExclamationCircle,
  faBars,
  faDatabase,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import { Edit, Delete, Visibility } from "@mui/icons-material";
import HelperFns from "../../Helpers/HelperFns";

/**
 *
 * start of IconButtonWithTooltip
 *
 */

const IconButtonPropTypes = {
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
  buttonProps: PropTypes.object,
  iconProps: PropTypes.object,
  tooltipProps: PropTypes.object,
};

/**
 * @type IconButtonWithTooltip
 * @type {React.FC<PropTypes.InferProps<IconButtonPropTypes>>}
 * @returns {React.ReactElement}
 */
const IconButtonWithTooltip = ({
  href,
  onClick,
  label,
  disabled,
  disabledLabel,
  className,
  sx,
  ...props
}) => {
  const { t } = useTranslation();
  const { isDisable = false, ...tooltipProps } = props?.tooltipProps || {};

  return onClick || href ? (
    <Tooltip
      arrow
      title={isDisable ? "" : t(disabled ? disabledLabel : label)}
      {...tooltipProps}
    >
      <IconButton
        to={href}
        size="small"
        onClick={onClick}
        disabled={disabled}
        className={className}
        component={href && Link}
        sx={{
          ...sx,
          ...(disabled &&
            disabledLabel && {
              pointerEvents: "all !important",
            }),
          "&.Mui-disabled": {
            opacity: 0.3,
            svg: { color: "initial !important" },
          },
        }}
        {...(props?.isExternalLink && {
          target: "_blank",
          rel: "noreferrer",
          component: "a",
          href,
        })}
        {...props?.buttonProps}
      >
        {props?.icon}
      </IconButton>
    </Tooltip>
  ) : (
    props?.icon ?? null
  );
};

IconButtonWithTooltip.propTypes = IconButtonPropTypes;
export default IconButtonWithTooltip;

/**
 *
 * end of IconButtonWithTooltip
 *
 * start of OutlineIconButton
 *
 */

/** @type {IconButtonWithTooltip} */
export const OutlineIconButton = ({ sx, ...props }) => (
  <IconButtonWithTooltip
    sx={{
      border: "1px solid #ccc",
      borderRadius: 2,
      height: 28,
      width: 32,
      ...sx,
    }}
    {...props}
  />
);

/**
 *
 * end of OutlineIconButton
 *
 * start of DownloadFileIconButton
 *
 */

/** @type {IconButtonWithTooltip} */
export const DownloadFileIconButton = ({
  buttonProps,
  iconProps,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <IconButtonWithTooltip
      label="download file"
      buttonProps={{
        target: "_blank",
        rel: "noreferrer",
        download: t("Attachment"),
        ...buttonProps,
      }}
      onClick={() => HelperFns.downloadFile(props.href)}
      icon={
        <FontAwesomeIcon
          size="sm"
          icon={faFileAlt}
          color="#5c6974"
          {...iconProps}
        />
      }
      {...props}
    />
  );
};

/**
 *
 * end of DownloadFileIconButton
 *
 * start of CopyIconButton
 *
 */

/** @type {IconButtonWithTooltip} */
export const CopyIconButton = ({
  iconColor = "#009ce7",
  iconProps,
  ...props
}) => {
  return (
    <IconButtonWithTooltip
      label="copy"
      // {console.log(iconProps, props)}
      icon={
        <FontAwesomeIcon
          size="sm"
          icon={faCopy}
          color={iconColor}
          {...iconProps}
        />
      }
      {...props}
    />
  );
};

/**
 *
 * end of CopyIconButton
 *
 * start of AcceptIconButton
 *
 */

/** @type {IconButtonWithTooltip} */
export const AcceptIconButton = ({ iconProps, ...props }) => (
  <IconButtonWithTooltip
    label="accept"
    icon={
      <FontAwesomeIcon
        size="sm"
        icon={faCheck}
        color="#27b40c"
        {...iconProps}
      />
    }
    {...props}
  />
);

/**
 *
 * end of AcceptIconButton
 *
 * start of EditIconButton
 *
 */

/** @type {IconButtonWithTooltip} */
export const EditIconButton = ({ iconProps, ...props }) => {
  const { sx = {}, ...icoProps } = iconProps || {};
  return (
    <IconButtonWithTooltip
      label="edit"
      icon={
        <Edit fontSize="small" sx={{ color: "#27b40c", ...sx }} {...icoProps} />
      }
      {...props}
    />
  );
};

/**
 *
 * end of EditIconButton
 *
 * start of TimesIconButton
 *
 */

/** @type {IconButtonWithTooltip} */
export const TimesIconButton = ({ iconProps, ...props }) => (
  <IconButtonWithTooltip
    label="remove"
    icon={
      <FontAwesomeIcon
        size="sm"
        icon={faTimes}
        color="#ff6a6a"
        {...iconProps}
      />
    }
    {...props}
  />
);

/**
 *
 * end of TimesIconButton
 *
 *
 *  * start of RefreshIconButton
 *
 */

/** @type {IconButtonWithTooltip} */
export const RefreshIconButton = ({ iconProps, ...props }) => (
  <IconButtonWithTooltip
    label="refresh"
    icon={
      <FontAwesomeIcon
        size="sm"
        icon={faDatabase}
        color="#38970a"
        {...iconProps}
      />
    }
    {...props}
  />
);

/**
 *
 * end of RefreshIconButton
 *
 *
 *  start of ExportIconButton
 *
 */

/** @type {IconButtonWithTooltip} */
export const ExportIconButton = ({ iconProps, ...props }) => (
  <IconButtonWithTooltip
    label="Export"
    icon={
      <FontAwesomeIcon
        size="sm"
        icon={faFileExport}
        color="#555"
        {...iconProps}
      />
    }
    {...props}
  />
);

/**
 *
 * end of ExportIconButton
 *
 * start of RemoveIconButton
 *
 */

/** @type {IconButtonWithTooltip} */
export const RemoveIconButton = ({ iconProps, ...props }) => {
  const { sx = {}, ...icoProps } = iconProps || {};
  return (
    <IconButtonWithTooltip
      label="remove"
      icon={
        <Delete
          fontSize="small"
          sx={{ color: "#ff6a6a", ...sx }}
          {...icoProps}
        />
      }
      {...props}
    />
  );
};

/**
 *
 * end of RemoveIconButton
 *
 * start of InfoIconButton
 *
 */

/** @type {IconButtonWithTooltip} */
export const InfoIconButton = ({ iconProps, ...props }) => (
  <IconButtonWithTooltip
    label="view details"
    icon={
      <FontAwesomeIcon
        size="sm"
        color="#ddd"
        icon={faExclamationCircle}
        style={{
          fontSize: 13,
          color: "#444",
        }}
        {...iconProps}
      />
    }
    {...props}
  />
);

/**
 *
 * end of InfoIconButton
 *
 * start of BarsIconButton
 *
 */

/** @type {IconButtonWithTooltip} */
export const BarsIconButton = ({ iconProps, sx, ...props }) => (
  <IconButtonWithTooltip
    label="toggle columns"
    icon={<FontAwesomeIcon icon={faBars} color="#707070" {...iconProps} />}
    sx={{
      border: "1px solid #ccc",
      borderRadius: 2,
      height: 28,
      width: 32,
      ...sx,
    }}
    {...props}
  />
);

/**
 *
 * end of BarsIconButton
 *
 * start of PreviewIconButton
 *
 */

/** @type {IconButtonWithTooltip} */
export const PreviewIconButton = ({ iconProps, ...props }) => (
  <IconButtonWithTooltip
    label="Preview"
    icon={<Visibility {...iconProps} />}
    {...props}
  />
);

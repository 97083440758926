import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BSelect, DateTimePickerForm } from "form-builder";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import Chart from "chart.js";

import {
  fetchTotalExpensesAttempt,
  fetchSubCategoryExpensesAttempt,
  setIsThisQueryTotalExpenses,
  onFormResetAction,
  setOfficeCurrency,
} from "../../../Store/Actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import Privilages from "../../../Constants/Privilages";

let Bar;

const ExpensesChart = (props) => {
  const [categoryName, setCategoryName] = useState("");
  const { t } = useTranslation();
  const [subCategoryId, setSubCategoryId] = useState(null);

  const handleFetchExpensesCharts = () => {
    if (props.expensesChartSelectInfo?.currency_id !== "") {
      if (props.isTotalExpensesCurrentQuery) {
        props.fetchTotalExpensesAttempt({
          ...props.expensesChartSelectInfo,
          office_id: props?.expensesChartSelectInfo?.office?.id ?? null,
        });
      } else {
        props.fetchSubCategoryExpensesAttempt({
          ...props.expensesChartSelectInfo,
          category_id: subCategoryId,
          office_id: props?.expensesChartSelectInfo?.office?.id ?? null,
        });
      }
    }
  };

  useEffect(() => {
    if (props?.expensesChartSelectInfo?.currency_id) {
      handleFetchExpensesCharts();
    }
  }, [
    props.expensesChartSelectInfo?.office,
    props.expensesChartSelectInfo?.currency_id,
    props.expensesChartSelectInfo?.to,
    props.expensesChartSelectInfo?.from,
    props.isTotalExpensesCurrentQuery,
  ]);

  const colorsArray = ["#44c2ff", "#23aaeb", "#2b99c9", "#3c80b5", "#2764ac"];
  const chooseBgColors = (labelsNumber) => {
    let selectedColorArr = [];
    for (let i = 0; i < labelsNumber; i++) {
      selectedColorArr.push(colorsArray[i % 5]);
    }
    return selectedColorArr;
  };

  const handleChartRequest = (e, item) => {
    if (item[0]?._index || item[0]?._index == 0) {
      if (props.isTotalExpensesCurrentQuery) {
        setSubCategoryId(props?.fetchTotalExpensesData[item[0]?._index]?.id);
        setCategoryName(props?.fetchTotalExpensesData[item[0]?._index]?.name);
        props.setIsThisQueryTotalExpenses(false);
      } else return;
    } else return;
  };

  const handleReturn = () => {
    props.setIsThisQueryTotalExpenses(true);
    setSubCategoryId(null);
  };

  //=============================================//
  const [mQuery, setMQuery] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleWindowChange);
    props.setOfficeCurrency(props.user);
    // this is the cleanup function to remove the listener
    return () => {
      window.removeEventListener("resize", handleWindowChange);
      props.onFormResetAction("expensesChartSelectInfo");
    };
  }, []);

  const handleWindowChange = (query) => {
    setMQuery(query.currentTarget.innerWidth);
  };

  useEffect(() => {
    // start of rounded bar charts draw function
    // draws a rectangle with a rounded top
    Chart.helpers.drawRoundedTopRectangle = function (
      ctx,
      x,
      y,
      width,
      height,
      radius
    ) {
      ctx.beginPath();
      ctx.moveTo(x + radius, y);
      // top right corner
      ctx.lineTo(x + width - radius, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
      // bottom right	corner
      ctx.lineTo(x + width, y + height);
      // bottom left corner
      ctx.lineTo(x, y + height);
      // top left
      ctx.lineTo(x, y + radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
      ctx.closePath();
    };

    Chart.elements.RoundedTopRectangle = Chart.elements.Rectangle.extend({
      draw: function () {
        var ctx = this._chart.ctx;
        var vm = this._view;
        var left, right, top, bottom, signX, signY, borderSkipped;
        var borderWidth = vm.borderWidth;

        if (!vm.horizontal) {
          // bar
          left = vm.x - vm.width / 2;
          right = vm.x + vm.width / 2;
          top = vm.y;
          bottom = vm.base;
          signX = 1;
          signY = bottom > top ? 1 : -1;
          borderSkipped = vm.borderSkipped || "bottom";
        } else {
          // horizontal bar
          left = vm.base;
          right = vm.x;
          top = vm.y - vm.height / 2;
          bottom = vm.y + vm.height / 2;
          signX = right > left ? 1 : -1;
          signY = 1;
          borderSkipped = vm.borderSkipped || "left";
        }

        // Canvas doesn't allow us to stroke inside the width so we can
        // adjust the sizes to fit if we're setting a stroke on the line
        if (borderWidth) {
          // borderWidth shold be less than bar width and bar height.
          var barSize = Math.min(
            Math.abs(left - right),
            Math.abs(top - bottom)
          );
          borderWidth = borderWidth > barSize ? barSize : borderWidth;
          var halfStroke = borderWidth / 2;
          // Adjust borderWidth when bar top position is near vm.base(zero).
          var borderLeft =
            left + (borderSkipped !== "left" ? halfStroke * signX : 0);
          var borderRight =
            right + (borderSkipped !== "right" ? -halfStroke * signX : 0);
          var borderTop =
            top + (borderSkipped !== "top" ? halfStroke * signY : 0);
          var borderBottom =
            bottom + (borderSkipped !== "bottom" ? -halfStroke * signY : 0);
          // not become a vertical line?
          if (borderLeft !== borderRight) {
            top = borderTop;
            bottom = borderBottom;
          }
          // not become a horizontal line?
          if (borderTop !== borderBottom) {
            left = borderLeft;
            right = borderRight;
          }
        }

        // calculate the bar width and roundess
        var barWidth = Math.abs(left - right);
        var roundness = this._chart.config.options.barRoundness || 0.5;
        var radius = barWidth * roundness * 0.5;

        // keep track of the original top of the bar
        var prevTop = top;

        // move the top down so there is room to draw the rounded top
        top = prevTop + radius;
        var barRadius = top - prevTop;

        ctx.beginPath();
        ctx.fillStyle = vm.backgroundColor;
        ctx.strokeStyle = vm.borderColor;
        ctx.lineWidth = borderWidth;

        // draw the rounded top rectangle
        Chart.helpers.drawRoundedTopRectangle(
          ctx,
          left,
          top - barRadius + 1,
          barWidth,
          bottom - prevTop,
          barRadius
        );

        ctx.fill();
        if (borderWidth) {
          ctx.stroke();
        }

        // restore the original top value so tooltips and scales still work
        top = prevTop;
      },
    });

    Chart.defaults.roundedBar = Chart.helpers.clone(Chart.defaults.bar);

    Chart.controllers.roundedBar = Chart.controllers.bar.extend({
      dataElementType: Chart.elements.RoundedTopRectangle,
    });
    // End of rounded bar charts draw function

    // intiliazing Bar Chart and its options
    if (!props.dashboardViewLoading && !props.fetchExpensesDataLoading) {
      var ctx = document.getElementById("expensesChart").getContext("2d");
      if (typeof Bar !== "undefined") Bar.destroy();

      Bar = new Chart(ctx, {
        type: "roundedBar",
        data: {
          labels: props.fetchTotalExpensesData?.map((element) => {
            const enhancedNameStyle = element?.name?.split(" ");
            for (var i = 0; i < enhancedNameStyle.length; i++) {
              enhancedNameStyle[i] =
                enhancedNameStyle[i].charAt(0).toUpperCase() +
                enhancedNameStyle[i].slice(1);
            }
            const finalName = enhancedNameStyle.join(" ");
            return finalName;
          }),
          datasets: [
            {
              data: props.fetchTotalExpensesData?.map(
                (element) => element.total
              ),
              backgroundColor: chooseBgColors(
                props.fetchTotalExpensesData.length
              ),
              borderWidth: 1,
              barThickness: "20",
            },
          ],
        },
        options: {
          barRoundness: 1,
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          onClick: (e, item) => handleChartRequest(e, item),
          hover: {
            onHover: function (e) {
              var point = this.getElementAtEvent(e);
              if (point.length) e.target.style.cursor = "pointer";
              else e.target.style.cursor = "default";
            },
          },
          scales: {
            xAxes: [
              {
                barThickness: 22,
                ticks: {
                  fontColor: "#5c6974",
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontColor: "#313030",
                },
                gridLines: {
                  display: true,
                },
              },
            ],
          },
        },
      });
    }
  }, [props.dashboardViewLoading, props.fetchExpensesDataLoading]);
  //================================================//

  var arr = [2, 3, 5, 6, 6, 2, 6, 1, 4, 2, 4, 6, 5, 6];

  if (props.dashboardViewLoading) {
    return (
      <div className="card">
        <div className="card-body">
          <div className="outer-skeleton-expenses-chart d-flex flex-row justify-content-evenly align-items-end">
            {arr?.map((el, i) => (
              <Skeleton
                key={i}
                className="mr-5"
                variant=""
                width="3.5%"
                height={el * 50}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  const selectedOfficeCurrency =
    props?.expensesChartSelectInfo?.office?.currenciesWithDefaultCurrency?.find(
      (currency) => currency?.defaultCurrencyOffice === true
    );

  return (
    <div className="expenses-chart-container card overflow-auto">
      <div className="card-body">
        <div className="chart-title d-flex justify-content-between align-items-center">
          <h4>
            {t("total expenses")}{" "}
            <span className="sub_title_style">
              {props.isTotalExpensesCurrentQuery ? null : `(${categoryName})`}
            </span>
          </h4>
          <BSelect
            name="currency_id"
            optionLabel="name"
            optionValue="id"
            placeholder={t("select currency")}
            options={props.dashboardCurrencies}
            formName="expensesChartSelectInfo"
            keepDefaultStyle
            containerStyle="currency-expenses-picker"
            icon="currency"
            preSelectValue={selectedOfficeCurrency?.id}
          />
        </div>
        <div className="d-flex flex-column flex-xl-row w-100 justify-content-center justify-content-xl-end align-items-end align-items-xl-center">
          <HasPrivileges
            reqireMain={[Privilages.VIEW_THE_EXPENSES_CHART_OF_ALL_OFFICES]}
            allowBP
          >
            <BSelect
              formName="expensesChartSelectInfo"
              placeholder={t("select office")}
              name="office"
              keepDefaultStyle
              options={[{ name: t("all offices"), id: null }, ...props.offices]}
              validateBy="textRequired"
              inputContainerStyle="w-200 expenses-office-picker"
              rootStyle="w-100"
              icon="office"
              isValueObject
              skipLocalization
            />
          </HasPrivileges>
          <div className="filter-containers d-flex justify-content-center align-items-center">
            {!props.isTotalExpensesCurrentQuery ? (
              <div
                className="back-btn-expenses-chart d-flex justify-content-center align-items-center"
                onClick={handleReturn}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </div>
            ) : null}
            <DateTimePickerForm
              name="from"
              placeholder="from"
              formName="expensesChartSelectInfo"
              requestFormat="YYYY-MM-DD"
            />
            <DateTimePickerForm
              name="to"
              placeholder="to"
              formName="expensesChartSelectInfo"
              requestFormat="YYYY-MM-DD"
            />
          </div>
        </div>
        <div className="w-100 mt-2">
          {props.fetchExpensesDataLoading ? (
            <div className="inner-skeleton d-flex flex-row justify-content-evenly align-items-end">
              {arr?.map((el, i) => (
                <Skeleton
                  key={i}
                  className="mr-5"
                  variant=""
                  width="3.5%"
                  height={el * 30}
                />
              ))}
            </div>
          ) : (
            <canvas id="expensesChart"></canvas>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.auth?.userProfile,
    offices: state?.user?.userProfile?.offices || [],
    dashboardCurrencies: state?.super?.dashboardCurrencies,
    expensesChartSelectInfo: state?.super?.expensesChartSelectInfo,
    fetchTotalExpensesData: state?.super?.fetchTotalExpensesData,
    fetchExpensesDataLoading: state?.super?.fetchExpensesDataLoading,
    isTotalExpensesCurrentQuery: state?.super?.isTotalExpensesCurrentQuery,
    dashboardViewLoading: state?.super?.dashboardViewLoading,
  };
};

export default connect(mapStateToProps, {
  fetchTotalExpensesAttempt,
  fetchSubCategoryExpensesAttempt,
  setIsThisQueryTotalExpenses,
  onFormResetAction,
  setOfficeCurrency,
})(ExpensesChart);

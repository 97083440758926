import React from "react";
import { useTranslation } from "react-i18next";

import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import TuneIcon from "@mui/icons-material/Tune";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const floatingMenuPropType = {
  sx: PropTypes.object,
  label: PropTypes.string,
  labelIcon: PropTypes.element,
  list: PropTypes.element.isRequired,
};

/**
 * @type {React.FC<PropTypes.InferProps<floatingMenuPropType>>}
 * @returns {React.ReactElement}
 */
const FloatingMenu = ({ list, label, labelIcon, sx, ...props }) => {
  const { t } = useTranslation();
  const anchorRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);

  // Constants
  const btnId = "btn-" + uuid();
  const listId = "list-" + uuid();

  /* ↓ Helpers ↓ */

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setIsOpen(false);
  };

  return (
    <Box sx={{ position: "relative", zIndex: 1 }}>
      <Button
        id={btnId}
        size="medium"
        ref={anchorRef}
        onClick={handleToggle}
        aria-haspopup="true"
        aria-expanded={String(isOpen)}
        aria-controls={isOpen ? listId : undefined}
        endIcon={
          labelIcon || (
            <TuneIcon sx={{ color: isOpen && "#23aaeb", fontSize: 8 }} />
          )
        }
        sx={{
          pb: 0.25,
          fontWeight: 400,
          color: "#8f9da9",
          backgroundColor: "#fff",
          textTransform: "capitalize",
          boxShadow: "0 3px 6px 0 rgba(0,0,0,.16)",
          ...sx,
        }}
        {...props}
      >
        {t(label || "filter")}
      </Button>
      <Popper
        transition
        id={listId}
        open={isOpen}
        disablePortal
        role={undefined}
        placement="bottom-start"
        anchorEl={anchorRef.current}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {list}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

FloatingMenu.propTypes = floatingMenuPropType;
export default FloatingMenu;

import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { connect } from "react-redux";
import {
  dismissRequestCommentsModalAction,
  upsertRequestCommentAction,
  fetchRequestCommentsAction,
  onInputChangeAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import Loader from "../Loader";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import Helper from "../../Helpers/HelperFns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import * as REQUESTS from "../../Constants/Requests";

const formServerValidation = "requestCommentModalServerValidation";

const RequestCommentsModal = (props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [commentText, setCommentText] = useState("");

  // handel text area change
  const handleTextArea = (e) => {
    setCommentText(e?.target?.value);
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    props.upsertRequestCommentAction(
      props?.requestCommentModal?.id,
      commentText,
      props?.refetchQueries ?? []
    );
    setCommentText("");
  };

  const toggleModal = () => {
    props.dismissRequestCommentsModalAction({
      ref: "requestCommentModalActions",
    });
  };

  useEffect(() => {
    setIsSubmitting(false);

    if (props?.isModalVissible) {
      props?.fetchRequestCommentsAction(props?.requestCommentModal?.id);
    }
    return () => {
      setCommentText("");
    };
  }, [props?.isModalVissible]);

  if (props?.isRequestCommentsModalLoading && props?.isFirstRender) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }

  return (
    <Modal
      isOpen={props?.isModalVissible}
      toggle={toggleModal}
      centered
      fade={false}
      className="request_comments_form_wrapper_style"
    >
      <ModalHeader toggle={toggleModal}>
        <div>
          <h1 className="mb-0">
            {props?.requestCommentModal?.employee?.user?.name}
          </h1>
        </div>

        <div className="d-flex">
          <div>
            {props?.requestCommentModal?.add_info != null ? (
              <h2 className="mb-0 px-3">
                {t(
                  props?.requestCommentModal?.requestType?.name?.toLowerCase()
                )}{" "}
                ({t(props?.requestCommentModal?.add_info)})
              </h2>
            ) : (
              <h2 className="mb-0 px-3">
                {t(
                  props?.requestCommentModal?.requestType?.name?.toLowerCase()
                )}
              </h2>
            )}
          </div>

          {props?.requestCommentModal?.time_from ||
          props?.requestCommentModal?.time_to != null ? (
            <>
              <div>
                {props?.requestCommentModal?.requestType?.id ==
                REQUESTS.PERMISSIONS || props?.requestCommentModal?.requestType?.id ==
                REQUESTS.UNPAID_PERMISSIONS ? (
                  <h3 className="mb-0">
                    {moment(props?.requestCommentModal?.time_from).format(
                      "HH:mm"
                    )}
                  </h3>
                ) : (
                  <h3 className="mb-0">
                    {moment(props?.requestCommentModal?.time_from).format(
                      "DD/MM/yyyy"
                    )}
                  </h3>
                )}
              </div>

              <div>
                <h2 className="mb-0 px-2">{t("to")}</h2>
              </div>

              <div>
                {props?.requestCommentModal?.requestType?.id ==
                REQUESTS.PERMISSIONS || props?.requestCommentModal?.requestType?.id ==
                REQUESTS.UNPAID_PERMISSIONS ? (
                  <h3 className="mb-0">
                    {moment(props?.requestCommentModal?.time_to).format(
                      "HH:mm"
                    )}
                  </h3>
                ) : (
                  <h3 className="mb-0">
                    {moment(props?.requestCommentModal?.time_to).format(
                      "DD/MM/yyyy"
                    )}
                  </h3>
                )}
              </div>
            </>
          ) : (
            <div>
              <h3 className="mb-0">
                {moment(props?.requestCommentModal?.req_day).format(
                  "DD/MM/yyyy"
                )}
              </h3>
            </div>
          )}
        </div>
      </ModalHeader>

      <ModalBody>
        <div className="row mb-3">
          <div className="col-12">
            <label className="font-weight-bold">{t("comments")}</label>
          </div>
        </div>

        {props?.requestCommentModal?.comments?.length > 0 ? (
          <>
            {/* Start of comments section */}
            <div className="mb-4">
              {props?.requestCommentModal?.comments?.map((comnt) => (
                <div
                  className={
                    comnt?.user_id != props?.authUser?.id
                      ? "row mb-4 other_user_comment_style"
                      : "row mb-4 auth_user_comment_style"
                  }
                >
                  <div className="col-12">
                    <div
                      className={
                        comnt?.user_id != props?.authUser?.id
                          ? "d-flex align-items-center show_all_modal_avatar_style user-img mb-2"
                          : "d-flex align-items-center justify-content-end show_all_modal_avatar_style user-img mb-2"
                      }
                    >
                      {comnt?.user_id != props?.authUser?.id ? (
                        <>
                          <Avatar
                            src={comnt?.user?.profile_picture?.path}
                            className="rounded-circle blackish-avatar"
                          >
                            {Helper.getIntials(comnt?.user?.name)}
                          </Avatar>

                          <span className="comment_user_name_style align-middle px-2">
                            {comnt?.user?.name}
                          </span>

                          <span className="comment_date_time_style align-middle ml-3">
                            {moment(comnt?.created_at).format("DD/MM/yyyy")}{" "}
                            {" - "}
                            {moment(comnt?.created_at).format("HH:mm A")}
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="comment_user_name_style align-middle px-3">
                            {comnt?.user?.name}
                          </span>

                          <span className="comment_date_time_style align-middle mx-2">
                            {moment(comnt?.created_at).format("DD/MM/yyyy")}{" "}
                            {" - "}
                            {moment(comnt?.created_at).format("HH:mm A")}
                          </span>

                          <Avatar
                            src={comnt?.user?.profile_picture?.path}
                            className="rounded-circle blackish-avatar"
                          >
                            {Helper.getIntials(comnt?.user?.name)}
                          </Avatar>
                        </>
                      )}
                    </div>

                    <div
                      className={
                        comnt?.user_id != props?.authUser?.id
                          ? "other_user_message_content_wrapper_style"
                          : "auth_user_message_content_wrapper_style d-flex justify-content-end"
                      }
                    >
                      <p className="mb-0 fit-width">{comnt?.comment}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* End of comments section */}
          </>
        ) : (
          <div className="p-4 text-center">
            {t("there are no comments yet")}
          </div>
        )}

        <div className="row mt-5 align-items-center">
          <HasPrivileges
            reqireMain={
              props?.isInboundRequestsPage || props?.employeeId
                ? [Privilages.COMMENT_ON_REQUESTS]
                : [Privilages.COMMENT_ON_MY_REQUESTS]
            }
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <>
                <div>
                  <textarea
                    rows="4"
                    name="comment"
                    placeholder={t("Type Comment Here")}
                  />
                </div>

                <div className="px-3">
                  <Button
                    type="submit"
                    className="send_btn_style w-101 align-items-center btn btn-primary d-flex justify-content-center"
                  >
                    <div>
                      <FontAwesomeIcon className="mr-2" icon={faShare} />
                      <span>{t("send")}</span>
                    </div>
                  </Button>
                </div>
              </>
            }
          >
            <>
              <div
                className={`col ${
                  "input.comment" in props?.serverVaildation
                    ? "invalid-container-style"
                    : ""
                }`}
              >
                <textarea
                  rows="4"
                  name="comment"
                  placeholder={t("Type Comment Here")}
                  onChange={handleTextArea}
                  value={commentText}
                />
              </div>

              <div className="px-3">
                <Button
                  disabled={
                    props?.isLoading || props?.isRequestCommentsModalLoading
                  }
                  type="submit"
                  onClick={handleSubmitBtn}
                  className="send_btn_style w-101 align-items-center btn btn-primary d-flex justify-content-center"
                >
                  {props?.isLoading || props?.isRequestCommentsModalLoading ? (
                    <Spinner
                      style={{ width: "1rem", height: "1rem", color: "#fff" }}
                    />
                  ) : (
                    <div>
                      <FontAwesomeIcon className="mr-2" icon={faShare} />
                      <span>{t("send")}</span>
                    </div>
                  )}
                </Button>
              </div>
            </>
          </HasPrivileges>
        </div>

        {/* (Start) Error Message */}
        {props?.modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{props?.modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isModalVissible: state.super.requestCommentModalActions.isVissible,
  isFirstRender: state.super.requestCommentModalActions.isFirstRender,
  modalMessage: state.super.requestCommentModalActions.modalMessage,
  isLoading: state.super.requestCommentModalActions.isLoading,
  requestCommentModal: state.super.requestCommentModal,
  serverVaildation: state.super[formServerValidation],
  isRequestCommentsModalLoading: state?.super?.isRequestCommentsModalLoading,
  authUser: state.auth.userProfile,
});

export default connect(mapStateToProps, {
  dismissRequestCommentsModalAction,
  upsertRequestCommentAction,
  fetchRequestCommentsAction,
  onInputChangeAction,
})(RequestCommentsModal);

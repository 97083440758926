import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Select, { components as selectComponent } from "react-select";
import Picker from "rc-picker";
import generateConfig from "rc-picker/lib/generate/moment";
import "rc-picker/assets/index.css";
import en from "rc-picker/lib/locale/en_US";
import ar from "rc-picker/lib/locale/ar_EG";
import {
    AcceptIconButton,
    EditIconButton,
    RemoveIconButton,
    TimesIconButton,
} from "../../Components/IconButtonWithTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlusOne } from "@mui/icons-material";
import { Spinner } from "reactstrap";
import { showErrorToast } from "../../Store/Actions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";

const workTeamInitState = {
    workTeam: null,
    start: null,
    end: null,
};

const EDIT_WORK_TEAM_MUTATION = gql`
  mutation EditWorkTeamEmployee($input: EditWorkTeamEmployeeInput) {
    editWorkTeamEmployee(input: $input) {
      ... on WorkTeam {
        __typename
        name
        id
      }
      ... on GeneralException {
        __typename
        message
      }
    }
  }
`;

const DELETE_WORK_TEAM_MUTATION = gql`
  mutation DeleteWorkTeamEmployee($input: DeleteWorkTeamEmployeeInput) {
    deleteWorkTeamEmployee(input: $input) {
      ... on WorkTeam {
        __typename
        id
        name
      }
      ... on GeneralException {
        __typename
        message
      }
    }
  }
`;

const ATTACH_EMPLOYEE_TO_WORK_TEAM_MUTATION = gql`
  mutation AttachEmployeesToWorkTeam($input: AttachEmployeesToWorkTeamInput) {
    attachEmployeesToWorkTeam(input: $input) {
      ... on WorkTeam {
        id
        name
      }
      ... on GeneralException {
        __typename
        message
      }
    }
  }
`;

const WorkTeamDetails = (props) => {
    const [workTeam, setWorkTeam] = useState(props?.workTeam);
    const [isEdited, setIsEdited] = useState(props?.newWorkTeam);

    const reset = () => {
        resetEditWorkTeamEmployee();
        resetDeleteWorkTeamEmployee();
        resetAttachEmployeeToWorkTeam();
    }

    useEffect(() => {
        console.log("mount WorkTeamDetails");
        setWorkTeam(props?.workTeam);

        return () => {
            reset();
            console.log("unmount WorkTeamDetails");

        }
    }, [props?.workTeam])


    const dispatch = useDispatch();
    const [
        submitEditWorkTeamEmployee,
        {
            loading: submitEditWorkTeamLoading,
            data: editWorkTeamData,
            error: editWorkTeamError,
            reset: resetEditWorkTeamEmployee
        },
    ] = useMutation(EDIT_WORK_TEAM_MUTATION, {
        onCompleted(res) {
            // do someting
            if (res?.editWorkTeamEmployee !== null && res?.editWorkTeamEmployee?.__typename !== "GeneralException") {
                props?.refetch();
                setIsEdited(false);
                console.log({ res });
            }
        },
        onError(error) {
            // do something
            console.log({ error });
        },
    });

    const [
        deleteWorkTeamEmployee,
        {
            loading: deleteLoading,
            data: deleteWorkTeamEmployeeData,
            error: deleteWorkTeamEmployeeError,
            reset: resetDeleteWorkTeamEmployee
        },
    ] = useMutation(DELETE_WORK_TEAM_MUTATION, {
        onCompleted(res) {
            // do someting
            if (res?.deleteWorkTeamEmployee !== null && res?.deleteWorkTeamEmployee?.__typename !== "GeneralException") {
                props?.refetch();
                setIsEdited(false);
                console.log({ res });
            }
        },
        onError(error) {
            // do something
            console.log({ error });
        },
    });

    const [
        attachEmployeeToWorkTeam,
        {
            loading: attachEmployeeLoading,
            data: attachEmployeeWorkTeamEmployeeData,
            error: attachEmployeeWorkTeamEmployeeError,
            reset: resetAttachEmployeeToWorkTeam
        },
    ] = useMutation(ATTACH_EMPLOYEE_TO_WORK_TEAM_MUTATION, {
        onCompleted(res) {
            if (res?.attachEmployeesToWorkTeam !== null && res?.attachEmployeesToWorkTeam?.__typename !== "GeneralException") {
                props?.refetch();
                setIsEdited(false);
                console.log({ res });
            }
        },
        onError(error) {
            // do something
            console.log({ error });
        },
    });

    const cancelEdit = () => {
        reset();
        if (props?.newWorkTeam) {
            props?.handleDeleteNewWorkTeam(props?.workTeam?.id);
        } else {
            setWorkTeam(props?.workTeam);
            setIsEdited(false);
        }
    };
    const startEdit = () => {
        setWorkTeam(props?.workTeam);
        setIsEdited(true);
    };

    const handleChangeWorkTeam = (value = null, d) => {
        console.log("====================================");
        console.log(value, d);
        // const { name, value } = e.target;
        console.log("====================================");
        setWorkTeam((prevState) => ({
            ...prevState,
            workTeam: value,
        }));
    };

    const handleChangeDate = (name, value = null, d, s) => {
        console.log("====================================");
        console.log(name, value, d, s);
        // const { name, value } = e.target;
        console.log("====================================");
        setWorkTeam((prevState) => ({
            ...prevState,
            [name]: moment(value).isValid()
                ? moment(value).format("YYYY-MM-DD")
                : null,
        }));
    };

    const submitEdit = () => {
        if (!workTeam?.workTeam?.id) {
            dispatch(showErrorToast("work team is Required"));
            return;
        }
        if (!workTeam?.start) {
            dispatch(showErrorToast("start field is Required"));
            return;
        }
        if (!props?.newWorkTeam) {
            let editVariables = {
                input: {
                    id: workTeam?.id,
                    workTeamId: workTeam?.workTeam?.id,
                    start: workTeam?.start,
                    end: workTeam?.end,
                },
            };
            submitEditWorkTeamEmployee({
                variables: editVariables,
            });
        } else {
            let attachVariables = {
                input: {
                    workTeamId: workTeam?.workTeam?.id,
                    start: workTeam?.start,
                    end: workTeam?.end,
                    employeeId: props?.employeeId,
                    attendanceTypeConfigurationId: props?.attendanceTypeConfigurationId,
                },
            };
            attachEmployeeToWorkTeam({
                variables: attachVariables,
            });
        }
    };

    const handleDelete = (workTeamId) => {
        let deleteVariables = {
            input: {
                id: workTeamId,
            },
        };
        deleteWorkTeamEmployee({
            variables: { ...deleteVariables },
        });
    };

    const { t } = useTranslation();
    return (
        <>
            <div className="row my-2 align-items-center">
                {isEdited ? (
                    <>
                        <div className="col-lg">
                            <Select
                                className={`${"select-def-input-container"} "b-select-style"`}
                                classNamePrefix={"b-select-style"}
                                // isDisabled={isDisabled}
                                // isLoading={isLoading}
                                // isClearable={isClearable}
                                // isRtl={isRtl}
                                getOptionLabel={(opt) => opt.name}
                                getOptionValue={(opt) => opt.id}
                                options={props?.options}
                                name={"workTeamId"}
                                onChange={handleChangeWorkTeam}
                                value={workTeam?.workTeam}
                                placeholder={t("select option")}
                            />
                        </div>

                        <div className="col-lg">
                            <Picker
                                allowClear={true}
                                className={`${"date-picker-input-default"} validity-input-style`}
                                dropdownClassName="def-date-picker-dropdown"
                                onChange={(val) => handleChangeDate("start", val)}
                                onSelect={(val) => handleChangeDate("start", val)}
                                showSecond={false}
                                generateConfig={{ ...generateConfig }}
                                locale={
                                    document.documentElement.lang?.includes("ar")
                                        ? { ...ar, locale: "ar-custome" }
                                        : en
                                }
                                format={"DD/MM/yyyy"}
                                value={
                                    Boolean(workTeam?.start)
                                        ? moment(workTeam?.start, "YYYY-MM-DD")
                                        : null
                                }
                                placeholder={t("select date")}
                            />
                        </div>
                        <div className="col-lg">
                            <Picker
                                allowClear={true}
                                className={`${"date-picker-input-default"} validity-input-style`}
                                dropdownClassName="def-date-picker-dropdown"
                                onChange={(val) => handleChangeDate("end", val)}
                                onSelect={(val) => handleChangeDate("end", val)}
                                showSecond={false}
                                generateConfig={{ ...generateConfig }}
                                locale={
                                    document.documentElement.lang?.includes("ar")
                                        ? { ...ar, locale: "ar-custome" }
                                        : en
                                }
                                format={"DD/MM/yyyy"}
                                value={
                                    Boolean(workTeam?.end)
                                        ? moment(workTeam?.end, "YYYY-MM-DD")
                                        : null
                                }
                                placeholder={t("select date")}
                            />
                        </div>
                        <div className="align-items-center d-flex gap-10 gap-20 w-101">
                            {submitEditWorkTeamLoading || attachEmployeeLoading ? (
                                <Spinner
                                    style={{ width: "1rem", height: "1rem", color: "#27b40c" }}
                                />
                            ) : (
                                <AcceptIconButton onClick={submitEdit} label="submit" />
                            )}
                            <TimesIconButton
                                onClick={() => cancelEdit(props?.workTeam)}
                                label="cancel"
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col-lg">
                            <label>{t("Work Team")}</label>
                            <p>{workTeam?.workTeam?.name}</p>
                        </div>
                        <div className="col-lg">
                            <label>{t("start")}</label>
                            <p>{workTeam?.start}</p>
                        </div>
                        <div className="col-lg">
                            <label>{t("end")}</label>
                            <p>{workTeam?.end ?? "-----"}</p>
                        </div>
                        <HasPrivileges reqireMain={[Privilages.ADD_REMOVE_EMPLOYEES_FROM_TEAMS]} allowBP>
                            <div className="align-items-center d-flex gap-10 gap-20 w-101">
                                {workTeam?.canEdit ? (
                                    <EditIconButton onClick={startEdit} />
                                ) : null}
                                {workTeam?.canDelete ? (
                                    deleteLoading ? (
                                        <Spinner
                                            style={{ width: "1rem", height: "1rem", color: "#ff6a6a" }}
                                        />
                                    ) : (
                                        <RemoveIconButton onClick={(e) => handleDelete(workTeam?.id)} />
                                    )

                                ) : null}

                            </div>
                        </HasPrivileges>
                    </>
                )}
            </div>
            {editWorkTeamData?.editWorkTeamEmployee?.message ||
                attachEmployeeWorkTeamEmployeeData?.attachEmployeesToWorkTeam?.message ||
                deleteWorkTeamEmployeeData?.deleteWorkTeamEmployee?.message ||
                deleteWorkTeamEmployeeError?.graphQLErrors?.[0]?.extensions?.reason ? (
                <span className="error-color">
                    {editWorkTeamData?.editWorkTeamEmployee?.message}
                    {deleteWorkTeamEmployeeData?.deleteWorkTeamEmployee?.message}
                    {
                        attachEmployeeWorkTeamEmployeeData?.attachEmployeesToWorkTeam
                            ?.message
                    }
                    {deleteWorkTeamEmployeeError?.graphQLErrors?.[0]?.extensions?.reason}
                </span>
            ) : null}
        </>
    );
};

export default WorkTeamDetails;

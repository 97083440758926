import React, { useEffect } from "react";
import { connect } from "react-redux";
import Constants from "../../Helpers/Constants";
import moment from "moment";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import {
  fetchAllCompaniesAction,
  onInputResetAction,
  deleteCompaniesAction,
  activateCompaniesAction,
  suspendCompaniesAction,
  showCompanyModalAction,
  setDataTableSortingAction,
} from "../../Store/Actions";
import { RadioboxForm, InputForm } from "form-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPowerOff,
  faPlus,
  faPauseCircle,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import CompanyModal from "./CompanyModal";
import { Link } from "react-router-dom";
import IconButtonWithTooltip, {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import LoginOnBehalfButton from "./LoginOnBehalfButton";

const dataTableRef = "companiesList";

const Companies = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchAllCompaniesAction({
      ref: dataTableRef,
      [dataTableRef]: props.data,
    });
    return () => {
      props.onInputResetAction(dataTableRef, "status");
      props.onInputResetAction(dataTableRef, "filter");
    };
  }, []);

  const handleFilter = () => {
    props.fetchAllCompaniesAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data,
        pagination: {
          ...props.data.pagination,
          currentPage: 1,
        },
      },
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data.status,
    props.data.filter,
  ]);

  const handlePaginate = (
    page = props.data.pagination.currentPage
  ) => {
    props.fetchAllCompaniesAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data,
        pagination: {
          ...props.data.pagination,
          currentPage: page,
        },
      },
    });
  };

  const handleSorting = (field, dir) => {
    props.fetchAllCompaniesAction({
      [dataTableRef]: {
        ...props.data,
        pagination: {
          ...props.data.pagination,
          currentPage: 1,
        },
        sorting: {
          dir,
          key: field.selector,
        },
      },
      ref: dataTableRef,
    });
    props.setDataTableSortingAction(dataTableRef, field.selector, dir);
  };

  // to delete company record
  const handleDeleteCompanies = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteCompaniesAction(row?.id);
      }
    });
  };

  // to activate companies
  const handleActivateCompanies = (row) => {
    swal({
      title: t("are you sure"),
      text: t("default_warning_activation_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willActivate) => {
      if (willActivate) {
        props.activateCompaniesAction(row?.id);
      }
    });
  };

  // to suspend companies
  const handleSuspendCompanies = (row) => {
    swal({
      title: t("are you sure"),
      text: t("default_warning_suspension_message"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willSuspend) => {
      if (willSuspend) {
        props.suspendCompaniesAction(row?.id);
      }
    });
  };

  // handleing edit company modal
  const handleEditCompany = (row) => {
    let data = {
      id: row?.id,
      company_name: row?.name,
      primarySAAS: row?.primarySAAS?.user?.id,
      secondarySAAS: row?.secondarySAAS?.map((sass) => sass?.user?.id),
      allow_am_access: row?.allow_am_access,
      week_day: row?.week_day,
      month_start_day: String(row?.month_start_day),
      year_start_month: row?.year_start_month,
      allow_multiple_offices_start: row?.allow_multiple_offices_start,
      partners_input: row?.businessPartners?.map(
        ({
          user: { id, company, name, email, phone, phone_country_code },
        }) => ({
          id,
          company_id: company?.id,
          name,
          email,
          phone,
          phone_country_code,
        })
      ),
      type: "Edit",
      canAddNewEmployee: row.canAddNewEmployee,
      numberOfEmployees: row.numberOfEmployees,
      employees_limit: row?.activeSubscription?.employees_limit,
      plan_id: row.activeSubscription?.plan?.id,
      canEditMonthStartDay: row?.canEditMonthStartDay,
      week_start_day: row?.week_start_day,
      logs: row?.logs,
      viewLogs: false,
      new_start_year:  null,
      new_start_of_month:  null,
      new_start_of_week: null,
      change_action: 0,
      report_status: row?.report_status,
      ratio: row?.ratio,
      
    };
    props.showCompanyModalAction({
      data,
      ref: "companiesModalActions",
      formName: "companiesModal",
    });
  };

  // handleing add company modal
  const handleAddCompany = () => {
    let data = {
      id: null,
      allow_am_access: true,
      company_name: "",
      employees_limit: "",
      primarySAAS: null,
      secondarySAAS: [],
      plan_id: "",
      payment_amount: "",
      start_date: "",
      end_date: "",
      type: "Add",
      week_day: null,
      month_start_day: null,
      year_start_month: null,
      allow_multiple_offices_start: false,
      report_status: "default",
      ratio: null,
    };
    props.showCompanyModalAction({
      data,
      ref: "companiesModalActions",
      formName: "companiesModal",
    });
  };

  const columns = [
    {
      name: t("company"),
      wrap: true,
      sortable: true,
      selector: "name",
      grow: 1.2,
      cell: (row) => {
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.VIEW_COMPANY_PROFILE,
            ]}
            altChildren={<>{row?.name}</>}
          >
            <Link
              to={`/admin-panel/company-profile/${row?.id}`}
              className="cursor-pointer"
            >
              {row?.name}
            </Link>
          </HasPrivileges>
        );
      },
    },
    {
      name: t("business partners"),
      wrap: true,
      sortable: false,
      grow: 1.5,
      cell: (row) =>
        !row?.businessPartners.length ? (
          <></>
        ) : (
          <div>
            {row?.businessPartners?.map((partner) => (
              <div className="d-flex gap-5 align-items-center">
                {partner?.user?.name}
                {row?.allow_am_access ? (
                  <HasPrivileges
                    reqireMain={[Privilages.SIGN_IN_AS_BUSINESS_PARTNER]}
                  >
                    <LoginOnBehalfButton id={partner?.user?.id} />
                  </HasPrivileges>
                ) : null}
              </div>
            ))}
          </div>
        ),
    },
    {
      name: t("primary account manager"),
      wrap: true,
      sortable: false,
      grow: 1.5,
      cell: ({ primarySAAS = {} }) => primarySAAS?.user?.name,
    },
    {
      name: t("secondary account managers"),
      wrap: true,
      sortable: false,
      grow: 1.5,
      cell: ({ secondarySAAS = [] }) =>
        secondarySAAS?.map((sass) => sass?.user?.name).join(", "),
    },
    {
      name: t("subscription end date"),
      wrap: true,
      sortable: true,
      selector: "subscriptions",
      grow: 1.5,
      cell: (row) => (
        <>
          {row?.activeSubscription?.end_date != null
            ? moment(row?.activeSubscription?.end_date).format("DD - MM - YYYY")
            : ""}
        </>
      ),
    },
    {
      name: t("last_sign_in_created_at"),
      sortable: true,
      selector: "activations",
      grow: 1.5,
      cell: (row) => <>{row?.last_sign_in_created_at}</>,
    },
    {
      name: t("No. Of Employees / Limit"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) =>{ 
        const $company_employees_count = row?.usersCount;
        const $limit = row?.activeSubscription?.employees_limit;
        return <>
          {$company_employees_count+" / "+$limit}
        </>
      },
    },
    {
      name: t("status"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) =>
        {
          const $company_status =  row?.getCurrentSubcriptionStatus;
          return row?.status === "suspended" ? t("inactive / "+$company_status) : t(row?.status)+" / "+$company_status ;
        },
    },
    {
      name: t("actions"),
      wrap: true,
      sortable: false,
      grow: 1.1,
      cell: (row) => (
        <div className="cards_table_actions">
          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.ADD_EDIT_COMPANY,
            ]}
          >
            <EditIconButton onClick={() => handleEditCompany(row)} />
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.SUSPEND_ACTIVATE_COMPANY,
            ]}
          >
            {row?.status === "active" ? (
              <IconButtonWithTooltip
                label="suspend"
                onClick={() => handleSuspendCompanies(row)}
                icon={
                  <FontAwesomeIcon
                    size="sm"
                    color="#ffb700"
                    icon={faPauseCircle}
                  />
                }
              />
            ) : (
              <IconButtonWithTooltip
                label="activate"
                onClick={() => handleActivateCompanies(row)}
                icon={
                  <FontAwesomeIcon
                    size="sm"
                    color="#ffb700"
                    icon={faPowerOff}
                  />
                }
              />
            )}
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[Privilages.SUPER_PRIVILEGE, Privilages.DELETE_COMPANY]}
          >
            <RemoveIconButton onClick={() => handleDeleteCompanies(row)} />
          </HasPrivileges>
        </div>
      ),
    },
  ];

  return (
    <div className="extra_bonus_wrapper_style">
      {props?.isActivateCompanyLoading ||
      props?.isDeleteCompanyLoading ||
      props?.isSuspendCompanyLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="row my-3 align-items-baseline">
        <div className="col-12 col-lg-5">
          <RadioboxForm
            formName={dataTableRef}
            options={Constants.companiesStatusFilter?.map(
              ({ label, value }) => ({
                label: t(label),
                value,
              })
            )}
            name="status"
            type="radio"
            containerStyle="my-2 my-lg-0"
            labelStyle="label-style"
            optionsContainerStyle="optionsContainerStyle"
            optionItemStyle="optionItemStyle"
            optionInputStyle=" "
            optionLabelStyle="optionLabelStyle"
          />
        </div>

        <div className="col-12 col-lg-7">
          <div className="row align-items-baseline">
            <div className="d-flex col-12 gap-10">
              <div className="col pl-0">
                <InputForm
                  name="filter"
                  formName={dataTableRef}
                  // label={<FontAwesomeIcon icon={faSearch} />}
                  labelStyle="focus-label"
                  placeholder={t("search")}
                  inputStyle="form-control search_field_style "
                  containerStyle=" "
                  inputContainerStyle="form-group mb-2 d-flex "
                />
              </div>

              <HasPrivileges
                reqireMain={[
                  Privilages.SUPER_PRIVILEGE,
                  Privilages.ADD_EDIT_COMPANY,
                ]}
              >
                <div>
                  <button
                    className="btn add_new_btn_style mt-1-4"
                    type="button"
                    onClick={handleAddCompany}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faPlus} />
                    <span>{t("add new")}</span>
                  </button>
                </div>
              </HasPrivileges>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        noDataComponent={t("no_records")}
        className="cards_table"
        columns={columns}
        data={props.data.data}
        noHeader
        persistTableHead
        sortServer
        onSort={handleSorting}
        defaultSortAsc={props.data.sorting.dir === "asc"}
        defaultSortField={props.data.sorting.key}
        paginationComponent={() => (
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={props.data.isLoading}
        progressComponent={<Loader />}
      />

      {/* Start of company modal */}
      <CompanyModal />
      {/* End of company modal */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super[dataTableRef],
    isActivateCompanyLoading: state?.super?.activiateCompanyLoading,
    isDeleteCompanyLoading: state?.super?.deleteCompanyLoading,
    isSuspendCompanyLoading: state?.super?.suspendCompanyLoading,
  };
};

export default connect(mapStateToProps, {
  fetchAllCompaniesAction,
  setDataTableSortingAction,
  onInputResetAction,
  deleteCompaniesAction,
  activateCompaniesAction,
  suspendCompaniesAction,
  showCompanyModalAction,
})(Companies);

import React, {useState } from "react";
import MainModal from "../../Components/MainModal";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { showToast } from "../../Helpers/HelperFns";
import { Alert, AlertTitle } from '@mui/material';
import "./employeesActionsStyles.scss";
import { bulkAddResetBalanceMutaion } from "../../Graphql/mutation";
import {
  RadioboxForm
} from "form-builder";
import Constants from "../../Constants";
import Select, { components as selectComponent } from "react-select";
import { renderIcon } from "../../Builder/Form/formIcons";
import * as yup from 'yup';

const AddResetBalanceModal = ({isAddBalance, ...props}) => {
    const { t } = useTranslation();
    const [validator, setValidator] = useState({})

    const initState = {
      employees_ids: null,
      balance_type_id: "1", // Annual Leaves
      year: "",
      month:"",
      operation: "1", // add
      quantity: 1,
      apply_on_emergency:0,
      emergency_quantity: null
    }
  
    const [input, setInput] = useState(initState);

    // validation schema
    const addInputSchema =  yup.object().shape({
      emergency_quantity: yup.number().when('apply_on_emergency',{
        is:1,
        then:yup.number().required(),
        otherwise: yup.number().nullable().notRequired(),
      }),
      quantity: yup.number(t("This Field Is Required.")).required(t("This Field Is Required.")),
      year: yup.number().required(),
      month: yup.number().when('balance_type_id',{
        is:"2",
        then:yup.number().required(),
        otherwise: yup.number().nullable().notRequired(),
      }),
    });

    const resetInputSchema =  yup.object().shape({
      year: yup.number().required(),
      month: yup.number().when('balance_type_id',{
        is:"2",
        then:yup.number().required(),
        otherwise: yup.number().nullable().notRequired(),
      }),
    });
    
   

  const [addResetBalance, { loading: addResetBalanceLoading, error:addBalanceError }] =
  useMutation(bulkAddResetBalanceMutaion, {
    onCompleted: (res) => {
        let key = isAddBalance ? "addBalanceForMultipleEmployees" : "resetBalanceForMultipleEmployees" ;
        if(res?.[key]?.status==="success"){
          showToast(res?.[key]?.status, res?.[key]?.message)
          props.resetSelection()
          props.refetch()
          props.close()
          return
        }
      showToast("error", res?.[key]?.message)
     },
    onError: (err) => {
      if(err?.graphQLErrors[0]?.extensions?.validation){
        let validation = err?.graphQLErrors[0]?.extensions?.validation
        Object.keys(validation).forEach(key => {
          setValidator(prev => (
          {...prev,
          [key.substring(6)]: // remove "input." so it matches Yup validator state key names
            validation[key].toString()
          }))
        })
      }
      else showToast("error", err?.graphQLErrors[0]?.extensions?.reason || err.message)
    },
  });


  const handleSubmit =  () => {

    // shape input
    if (isAddBalance) {
        let tempInput = {...input}
        tempInput.employees_ids = props.selectedEmployees
        tempInput.year = "" + tempInput.year.value
        tempInput.quantity = "" + tempInput.quantity
        tempInput.month =  tempInput.balance_type_id === "2" ? "" + tempInput.month.key : null
        tempInput.apply_on_emergency = tempInput.apply_on_emergency && tempInput.balance_type_id === "1"? 1 : 0
        tempInput.emergency_quantity = tempInput.emergency_quantity && tempInput.balance_type_id === "1" ? ""+tempInput.emergency_quantity : null
    
    
        addInputSchema
          .validate(tempInput, { abortEarly: false })
          .then((valid) => {
            setValidator({})
            addResetBalance({
              variables: {
                addBalanceInput: tempInput ,
                resetBalanceInput:null,
                isAddBalance
              }
            })
          })
          .catch((err) => {
            let yupErrors = {};
            err.inner.forEach((error) => {
              yupErrors[error.path] =t("This Field Is Required.");
            });
            setValidator(yupErrors)
            return false
          });
     }
    else {
      let tempInput = {}
      tempInput.employees_ids = props.selectedEmployees
      tempInput.balance_type_id = "" + input.balance_type_id
      tempInput.year = "" + input.year?.value
      tempInput.month =   tempInput.balance_type_id === "2" ? "" + input.month.key : null
      
      
      resetInputSchema
      .validate(tempInput, { abortEarly: false })
      .then((valid) => {
        setValidator({})
        addResetBalance({
          variables: {
            addBalanceInput:  null,
            resetBalanceInput: tempInput,
            isAddBalance
          }
          })
        })
        .catch((err) => {
          let yupErrors = {};
          err.inner.forEach((error) => {
            yupErrors[error.path] =t("This Field Is Required.");
          });
          setValidator(yupErrors)
          return false
        });
          
    }
    
  }

  const handleRadioChange = (e,val) => {
    setValidator({})
    setInput((prevState) => {
      return { ...prevState, [e.target.name]: val };
    });
  };

  const handleYearSelect = (v) => {
    if (v) {
      setInput(prev => ({ ...prev, year: v }))
    } else {
      setInput(prev => ({ ...prev, year: null }))
    }
  }
  
  const handleMonthSelect = (v) => {
    if (v) {
      setInput(prev => ({ ...prev, month: v }))
    } else {
      setInput(prev => ({ ...prev, month: null }))
    }
  }


  const handleInputChange = (e) => {
    setInput(prev=>({...prev, [e.target.name]:(e.target.checked? 1 : 0)}))
  };

  
  const Control = ({ children, ...props }) => (
    <selectComponent.Control {...props}>
        <div className={`bselect_icon`}>
          {renderIcon("calendar")}
        </div>
      {children}
    </selectComponent.Control>
  );

  return (
    <MainModal
      isOpen={props.isOpen}
      modalTitle={t("Bulk Actions")}
      btnSubmitLoading={addResetBalanceLoading}
      btnOnClick={handleSubmit}
      toggle={props.close}
      className="p-5 "
    >
      <div className="p-2">
        <Alert severity="info">
                <AlertTitle>
                  <div>
                    <div>{isAddBalance ? t("Add Balance") : t("reset balance") }</div>
                    <strong>{t("Employees will be effected", { count: props.selectedEmployees.length })}</strong>
                  </div>
                </AlertTitle>
          </Alert>
      </div>

        <div className=""> 
            <RadioboxForm
                labelStyle="label-style font-weight-bold p-2 "
                label="Balance Type"
                name={"balance_type_id"}
                value={input.balance_type_id}
                interceptChange={handleRadioChange}
                validateContainerStyle={""}
                optionInputStyle="text-black-100"
                containerStyle="mb-0"
                optionLabelStyle="optionLabelStyle"
                optionsContainerStyle="d-flex  gap-10 -mx-12 pr-3"
                options={
                  [
                    {
                    label: t("Annual Leaves"),
                    value: "1"
                    },
                    {
                    label: t("Sick Leaves"),
                    value: "6",
                    },
                    {
                      label: t("Permissions"),
                      value: "2",
                    },
                ]}
            />
        </div>

      <div className={input.balance_type_id!=="2" ? "d-flex justify-content-between w-100" : ""}>
        {isAddBalance && <div className={"gap-2 pt-4 w-50 "}>
            <div className={"px-2 "+(validator["quantity"]? "invalid-container-style" : "")}>
                <label className={"label-style font-weight-bold select-def-label mb-2 validity-label-style"}>
                  {input.balance_type_id === "2" ? t("permission minutes") : t("no. of days") }
                </label>
                <div className={"d-flex align-items-end justify-content-start mb-md-0 pr-4"}>
                    <input
                        dir="auto"
                        name="quantity"
                        value={input.quantity}
                        onChange={(e)=>{
                            setInput(prev => ({
                            ...prev,
                            quantity:e.target.value
                        }))}}
                        className={"border-left-0 border-right-0 border-top-0  border-bottom w-100 "+(validator["quantity"]?.isValid? "" : " validity-input-style")+ " text-dark"}
                        type={"number"}
                        style={{ resize: "none" }}
                    />
                </div>
                <div className="validity-msg-style text-left">
                  { t(validator?.["quantity"])}
                </div>
             </div>
            </div>}

          <div className={`flex-grow-1 mt-4 px-2 ${input.balance_type_id!=="2"? "w-50" : ""}`}>
            <label className={"label-style font-weight-bold select-def-label mb-1 validity-label-style"}>
              {t("period") }
            </label>

            <div className={"d-flex justify-content-between mb-4"}>

              <div className= {`${input.balance_type_id!=="2"? "w-100" : "w-50"  } mr-4  justify-content-center align-items-end  ${validator["year"]? "invalid-container-style" : ""}`}>
                {input.balance_type_id==="2" && 
                  <label className={"label-style select-def-label mb-1 validity-label-style "}>
                    {t("year") }
                  </label>}
                  <Select
                      className={"select-def-input-containe b-select-style flex-grow-1"}
                      classNamePrefix={"b-select-style"}
                      value={input.year}
                      onChange={(v) => handleYearSelect(v)}
                      options={Constants.YearData}
                      isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
                      isSearchable
                      placeholder={t("select year")}
                      getOptionLabel={(opt) => opt.value}
                      getOptionValue={(opt) => opt?.value}
                      name={"year"}
                      components={{ Control }}
                  />
                  <div className="validity-msg-style text-left">
                    { t(validator["year"]) }
                  </div>
              </div>

              {
                input.balance_type_id === "2" &&
                <div className={"w-50 justify-content-center align-items-end  "+(validator["month"]? "invalid-container-style" : "")}>
                  <label className={"label-style select-def-label mb-1 validity-label-style"}>
                  {t("month") }
                  </label>
                  <Select
                      className={"select-def-input-containe b-select-style flex-grow-1"}
                      classNamePrefix={"b-select-style"}
                      value={input.month}
                      options={Constants.MonthsData}
                      onChange={(v) => handleMonthSelect(v)}
                      isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
                      isSearchable
                      placeholder={t("select month")}
                      getOptionLabel={(opt) => opt.value}
                      getOptionValue={(opt) => opt?.key}
                      name={"month"}
                      components={{ Control }}
                  />
                  <div className="validity-msg-style text-left">
                    { t(validator["month"]) }
                  </div>
                </div>
              }
          </div>
          
        </div>

        </div>

        { (input.balance_type_id === "1" && isAddBalance) &&
          <div className={`d-flex align-items-center px-2 w-100`}>
            <label className={`checkbox mt-4 w-50 ${document?.body?.dir?.toLowerCase()==="rtl"? "pr-4" : " pr-5"}`}>
              <input
              type="checkbox"
              name={"apply_on_emergency"}
              value={input.apply_on_emergency}
              onChange={(e) => handleInputChange(e)}
              checked={input.apply_on_emergency}
              className={` ${document?.body?.dir?.toLowerCase()==="rtl"? "mr-5" : ""}`}
              />
              <span></span>

              <div className={`booleanInputLabelStyle ${ Boolean(input.apply_on_emergency) ? "lightActiveColor" : ""}`}>
                  {t("Include Credit For Emergency")}
              </div>
            </label>
            {
              (Boolean(input.apply_on_emergency) && isAddBalance) && 
              <div className={"px-2 w-50 "+(validator["emergency_quantity"]? "invalid-container-style" : "")}>
                <label className={"label-style font-weight-bold select-def-label mb-2 validity-label-style"}>
                  {input.balance_type_id === "2" ? t("permission minutes") : t("no. of days") }
                </label>
                <div className={"d-flex align-items-end justify-content-start mb-md-0"}>
                    <input
                        dir="auto"
                        name="emergency_quantity"
                        value={input.emergency_quantity}
                        onChange={(e)=>{
                            setInput(prev=>({
                            ...prev,
                            emergency_quantity:parseFloat(e.target.value)
                        }))}}
                        className={"border-left-0 border-right-0 border-top-0  border-bottom w-100 "+(validator["emergency_quantity"]?.isValid? "" : " validity-input-style")+ " text-dark"}
                        type={"number"}
                        style={{ resize: "none" }}
                    />
                </div>
                <div className="validity-msg-style text-left">
                  { t(validator?.["emergency_quantity"])}
                </div>
            </div>
            }
          </div>
                }

                {validator?.balance_type_id && 
                  <div className={"invalid-container-style"}>
                        
                      <div className="validity-msg-style text-left">
                      { t(validator?.balance_type_id)}
                      </div>
                  </div>
                }
    </MainModal>
  );
};

export default AddResetBalanceModal;
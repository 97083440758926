import React, { useState, useEffect, useRef, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

// import AddHolidayModal from "../../Components/AddHolidayModal";
import AddLocationModal from "../../Components/AddLocationModal";
import { components } from "react-select";
import Constant from "../../Constants";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  CheckboxBooleanForm,
  InputForm,
  BSelect,
  BCRSelect,
  DateTimePickerForm,
} from "form-builder";
import {
  showOfficeLocationModalAction,
  showOfficeHolidayModalAction,
  updateObjectMultiSelectAction,
  updateOfficeAction,
  resetOfficeFormAction,
  fetchOfficeAction,
  initOfficeAction,
  resetOfficePassword,
  onInputChangeAction,
  onInputResetAction,
  setCountryCurrenciesAction,
  updateValueAction,
  updateWizardOfficeAction,
  onInputResetWithValueAction,
} from "../../Store/Actions";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import { v4 as uuid, validate } from "uuid";
import { Spinner } from "reactstrap";
import { useParams } from "react-router-dom";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import { renderIcon } from "../../Builder/Form/formIcons";
import AntSwitch from "../../Components/AntSwitch";
import { RegulationTypes } from "../../Constants/RegulationTypes";
import { useLazyQuery } from "@apollo/client";
import {
  GET_RELEVANT_REGULATION_TYPES,
  GET_WEEK_OPTIONS,
} from "../../Graphql/query";
import { EditIconButton } from "../../Components/IconButtonWithTooltip";

import moment from "moment";
import useAllowCostCenter from "../../Helpers/Hooks/useAllowCostCenter";

const EgyptId = "64";

const formName = `officeForm`;
const formNameValidation = `officeFormValidation`;

const OfficeForm = ({ officeId, ...props }) => {
  const dispatch = useDispatch();
  const [isLogsOpen, setIsLogsOpen] = useState(false);
  const [isEditingMonthStart, setIsEditingMonthStart] = useState(false);
  const [isEditingFirstMonth, setIsEditingFirstMonth] = useState(false);
  const [isEditingWeekStart, setIsEditingWeekStart] = useState(false);
  const [paymentIntervalsMessage, setPaymentIntervalsMessage] = useState("");

  const { allowCostCenter } = useAllowCostCenter();

  // Reducer State
  const officeForm = useSelector((state) => state.super[formName]);
  const formValidation = useSelector(
    (state) => state.super?.[formNameValidation]
  );
  const officeFormStatus = useSelector(
    (state) => state.super?.officeFormStatus
  );
  const officeIpsOptions = useSelector(
    (state) => state.super?.officeIpsOptions
  );
  const officeLocationsOptions = useSelector(
    (state) => state.super?.officeLocationsOptions
  );
  const officeCountriesOptions = useSelector(
    (state) => state.super?.officeCountriesOptions
  );
  const officeCurrenciesOptions = useSelector(
    (state) => state.super?.officeCurrenciesOptions
  );
  const officeCostCenterOptions = useSelector(
    (state) => state.super?.officeCostCenterOptions
  );

  // const attProfileHolidaysOptions = useSelector(
  //   (state) => state.super.attProfileHolidaysOptions
  // );

  // Server State
  const [
    attemptGetRelevantRegulationTypes,
    { loading: regulationTypesLoading, data: relevantRegulationTypesData },
  ] = useLazyQuery(GET_RELEVANT_REGULATION_TYPES);

  const officeFormData = useSelector((state) => state.super?.[formName]);

  useEffect(() => {
    const countryId = officeFormData?.country?.id;

    if (countryId) {
      attemptGetRelevantRegulationTypes({
        variables: {
          countryId,
        },
      });
    }
  }, [officeFormData?.country]);

  const handleCountryIntercept = () => {
    dispatch(onInputResetAction(formName, "taxation"));
  };

  const handleDefaultRegulationIntercept = () => {
    dispatch(onInputResetAction(formName, "tax_settlement_interval"));
  };

  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <div className={`bselect_icon`}>{renderIcon("ip")}</div>

      {children}
    </components.Control>
  );

  const isNewOffice = !officeId;
  const isWorkPlace = officeForm?.is_workplace;
  const isWorkPlaceRef = officeForm?.isWorkPlaceRef;
  const [formSubmitting, setFormSubmitting] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchOfficeAction(officeId)); // to fetch form options (include office data if have office id)

    if (isNewOffice) {
      dispatch(initOfficeAction()); // init office to generate office id
    }

    return () => {
      setFormSubmitting(false);
      dispatch(resetOfficeFormAction());
      setPaymentIntervalsMessage("");
    };
  }, []);

  useEffect(() => {
    dispatch(resetOfficePassword());
    return () => {
      dispatch(resetOfficePassword());
    };
  }, [officeForm.reset_password]);

  // useEffect(() => {
  //   if (
  //     !(officeForm?.allow_monthly_interval || officeForm?.allow_weekly_interval)
  //   ) {
  //     setPaymentIntervalsMessage("validation.filled");
  //   } else {
  //     setPaymentIntervalsMessage("");
  //   }
  // }, [officeForm?.allow_monthly_interval, officeForm?.allow_weekly_interval]);

  const handleSubmitOfficeForm = () => {
    setPaymentIntervalsMessage("");
    setFormSubmitting(true);
    // if (
    //   !(officeForm?.allow_monthly_interval || officeForm?.allow_weekly_interval)
    // ) {
    //   setPaymentIntervalsMessage("validation.filled");
    // }

    if (
      !formValidation?.length
      // &&
      // (officeForm?.allow_monthly_interval || officeForm?.allow_weekly_interval)
    ) {
      const {
        country,
        locations,
        isWorkPlaceRef,
        // new keys
        first_month,
        // allow_monthly_interval,
        // allow_weekly_interval,
        month_start_day,
        first_day_of_month,
        week_start_day,
        first_day_of_week,
        payment_intervals,
        first_day_of_month_selected_month,
        first_day_of_week_selected_week,
        monthYearPayroll,
        configurations,
        canEditStartOfMonthOrWeek, // extract to not submit it
        canEditMonthStartDay, // extract to not submit it
        Leaves,
        Permissions,
        Salary,
        Grace,
        Overtime,
        getYearStartMonth,
        getMonthStartDay,
        cost_center_id,
        costCenter,
        ...officeData
      } = officeForm;

      let submittedWorkPlace = {
        id: officeData.id,
        is_workplace: 1,
        name: officeData.name,
        password: officeData.password,
        time_zone: officeData.time_zone,
        reset_password: officeData.reset_password,
        locations_id: locations.map((loc) => loc.id),
        allow_sign_in_using_office_account:
          officeData.allow_sign_in_using_office_account,
      };

      let submittedOffice = isWorkPlace
        ? submittedWorkPlace
        : {
            ...officeData,
            is_workplace: 0,
            locations_id: officeForm?.locations?.map(
              (location) => location?.id
            ),
            holidays: officeForm?.holidays?.map(({ id, ...holiday }) =>
              validate(id)
                ? {
                    ...holiday,
                  }
                : { ...holiday, id }
            ),
            country_id: country?.id,
            ...(officeForm?.canEditStartOfMonthOrWeek
              ? {
                  ...(isNewOffice
                    ? {
                        first_month: String(moment(first_month).format("MM")),
                        // payment_intervals: [
                        //   ...(allow_monthly_interval ? ["Month"] : []),
                        //   ...(allow_weekly_interval ? ["Week"] : []),
                        // ],
                        month_start_day: +month_start_day,
                        week_start_day: +week_start_day,
                        // month_start_day: allow_monthly_interval
                        //   ? +month_start_day
                        //   : null,
                      }
                    : {
                        year_start_month: first_month,
                        // payment_intervals: [
                        //   ...(allow_monthly_interval ? ["Month"] : []),
                        //   ...(allow_weekly_interval ? ["Week"] : []),
                        // ],
                        first_day_of_month: first_day_of_month,
                        first_day_of_week: first_day_of_week,
                        prorate: [
                          ...(Leaves ? ["Leaves"] : []),
                          ...(Salary ? ["Salary"] : []),
                          ...(Permissions ? ["Permissions"] : []),
                          ...(Grace ? ["Grace"] : []),
                          ...(Overtime ? ["Overtime"] : []),
                        ],
                      }),
                }
              : {
                  // payment_intervals: [
                  //   ...(allow_monthly_interval ? ["Month"] : []),
                  //   ...(allow_weekly_interval ? ["Week"] : []),
                  // ],
                }),
            cost_center_id,
          };

      if (props.setupWizard) {
        dispatch(
          updateWizardOfficeAction({
            ...submittedOffice,
            ...(isNewOffice && { head_quarter: 1 }),
          })
        );
      } else {
        dispatch(updateOfficeAction(submittedOffice));
      }
    }
  };

  const FormProps = {
    formName,
    formNameValidation,
    formSubmitting,
  };

  const handleAddOfficeLocation = () => {
    dispatch(showOfficeLocationModalAction());
  };

  const handleEditOfficeLocation = (data) => {
    dispatch(
      showOfficeLocationModalAction({
        data,
      })
    );
  };

  const handleAddOfficeHoliday = () => {
    let data = {
      id: uuid(),
      name: "",
      start_date: "",
      end_date: "",
    };
    dispatch(
      showOfficeHolidayModalAction({
        data,
        ref: "officeHolidayModalActions",
        formName: "holidayForm",
      })
    );
  };
  const handleEditOfficeHoliday = (data) => {
    dispatch(
      showOfficeHolidayModalAction({
        data,
        ref: "officeHolidayModalActions",
        formName: "holidayForm",
      })
    );
  };

  const handlechange = (value, e) => {
    dispatch(
      updateObjectMultiSelectAction({
        data: value ?? [],
        formName,
        name: e.name,
      })
    );
  };

  // This fucntion for the multi select open modal for each chosen option and to edit it
  const MultiValueContainer = (props) => {
    return (
      <components.MultiValueLabel
        {...props}
        innerProps={{
          ...props.innerProps,
          onClick: (e) => {
            if (!(e.target instanceof SVGElement)) {
              switch (props.selectProps.name) {
                case "holidays":
                  handleEditOfficeHoliday(props.data);
                  break;
                case "locations":
                  handleEditOfficeLocation(props.data);
                  break;

                default:
                  break;
              }
            }
          },
        }}
      />
    );
  };

  const filterOptions = (candidate) => {
    const isSelected = officeForm?.locations?.some(
      (loc) => loc.id === candidate.data.id
    );

    // return in menu if not selected
    return !isSelected;
  };

  const handleResetPassword = () => {
    let e = {
      target: {
        name: "reset_password",
        value: +!officeForm.reset_password,
      },
    };
    dispatch(onInputChangeAction(formName, e));
  };

  const formatOptionLabel = ({ label, countryCode }) => (
    <>
      <img
        src={`assets/img/${countryCode}.svg`}
        className="mr-2"
        alt={`${countryCode} flag`}
        width="17"
        height="13"
      />
      {t(label)}
    </>
  );

  // Start of handling to get selected country currencies
  const handleSelectedCountryCurrenies = () => {
    if (isWorkPlace || props?.data?.officeForm?.currencies?.length) {
      return;
    }
    let selectedOfficeCountryCurrencies = officeCountriesOptions?.find(
      (obj) => obj?.id == props?.data?.officeForm?.country_id
    )?.currency_id;
    if (
      selectedOfficeCountryCurrencies &&
      !props?.data?.officeForm?.currencies?.includes(
        selectedOfficeCountryCurrencies
      )
    ) {
      dispatch(setCountryCurrenciesAction(selectedOfficeCountryCurrencies));
    }
  };

  const useDidSelectedCountryCurriensesUpdate = useDidUpdateEffect(
    () => !isWorkPlace && handleSelectedCountryCurrenies,
    [props?.data?.officeForm?.country_id, isWorkPlace]
  );
  // End of handling to get selected country currencies

  const showTaxSettlementInterval =
    props?.data?.officeForm?.country?.id == EgyptId;

  const monthBefore = moment(
    officeForm?.first_day_of_month_selected_month,
    "MM-YYYY"
  )
    .subtract(1, "month")
    .format("MM-YYYY");

  const currentMonth = moment(
    officeForm?.first_day_of_month_selected_month,
    "MM-YYYY"
  ).format("MM-YYYY");

  const handleEditFirstMonth = () => {
    setIsEditingFirstMonth(true);
  };

  const handleEditMonthStartDay = () => {
    setIsEditingMonthStart(true);
  };

  const handleEditWeekStartDay = () => {
    setIsEditingWeekStart(true);

    attemptFetchWeekOptions({
      variables: {
        from: moment().startOf("year").format("YYYY-MM-DD"),
        to: moment().endOf("year").format("YYYY-MM-DD"),
        userId: +officeForm?.id,
        type: "Office",
      },
    });
  };

  const allowedWeekOptions = () => {
    let result = [];

    const weeksSortedDescending = data?.weeks?.sort(
      (a, b) => new Date(b.paidAt) - new Date(a.paidAt)
    );

    for (let i = 0; i < weeksSortedDescending?.length; i++) {
      if (weeksSortedDescending[i].status !== "Pending") break;
      result.push(weeksSortedDescending[i]);
    }

    return result;
  };

  const [attemptFetchWeekOptions, { loading, data }] =
    useLazyQuery(GET_WEEK_OPTIONS);

  const handleInterceptChangeOfPayrollMonth = (value) => {
    dispatch(
      onInputResetWithValueAction(
        formName,
        "first_day_of_month",
        `${moment(value).subtract(1, "month").format("YYYY/MM")}/20`
      )
    );

    return false;
  };

  return (
    <>
      <div className="card-box container-fluid mb-3 py-0 shadow-none">
        {!props?.setupWizard && (isNewOffice || isWorkPlaceRef) ? (
          <div
            className={`d-flex align-items-center mt-4${
              document.documentElement.lang.includes("ar")
                ? " flex-row-reverse justify-content-end"
                : ""
            }`}
          >
            <span>{t("Office")}</span>
            <AntSwitch
              className="mx-2"
              checked={isWorkPlace}
              onChange={() =>
                dispatch(
                  updateValueAction(formName, "is_workplace", !isWorkPlace)
                )
              }
            />
            <span>{t("work place")}</span>
          </div>
        ) : null}

        <div className="row align-items-baseline">
          <div className={`col-12${!isWorkPlace ? " col-md-6" : ""}`}>
            <InputForm
              {...FormProps}
              validateBy="textRequired"
              label="name"
              name="name"
              type="text"
              placeholder="Name"
              labelStyle="mb-2"
              containerStyle="mt-4 mb-3"
              inputContainerStyle=" "
              icon="person"
            />
          </div>
          {!isWorkPlace ? (
            <div className="col-12 col-md-6">
              <BSelect
                {...FormProps}
                containerStyle=" "
                name="email_notification_locale"
                label="language"
                keepDefaultStyle
                placeholder={t("select option")}
                options={Constant.languageOptions}
                validateBy="textRequired"
                optionLabel="label"
                optionValue="value"
                labelStyle="mb-2"
                skipLocalization
                formatOptionLabel={formatOptionLabel}
                icon="globe"
              />
            </div>
          ) : null}
        </div>

        <div className="row">
          <HasPrivileges
            reqireMain={[Privilages.RESET_OFFICE_PASSWORD]}
            allowBP
          >
            <>
              <div className="col-12">
                <CheckboxBooleanForm
                  {...FormProps}
                  name="allow_sign_in_using_office_account"
                  options={[
                    `allow sign in using ${
                      isWorkPlace ? "work place" : "office"
                    } account`,
                  ]}
                  type="checkbox"
                  containerStyle="pt-3"
                />
              </div>
              <div className="row col-12 p-0 m-0 align-items-center">
                <InputForm
                  rootStyle="col-12 col-md-5"
                  {...FormProps}
                  label="username"
                  labelStyle="mb-2"
                  containerStyle="mt-3"
                  inputContainerStyle=" "
                  disabled={true}
                  value={`Off-${officeId || officeForm?.id}`}
                  dependOn="allow_sign_in_using_office_account"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  icon="person"
                />
                <InputForm
                  rootStyle="col-12 col-md-5"
                  {...FormProps}
                  label="password"
                  name="password"
                  type="password"
                  placeholder={
                    !officeForm?.reset_password && !isNewOffice
                      ? `\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022`
                      : t("password")
                  }
                  labelStyle="mb-2"
                  containerStyle="mt-3"
                  inputContainerStyle=" "
                  readOnly={!officeForm.reset_password && !isNewOffice}
                  validateBy={
                    officeForm.reset_password ? "textRequired" : "text"
                  }
                  dependOn="allow_sign_in_using_office_account"
                  dependancyType="equal"
                  dependancyValue={[1]}
                  icon="key"
                />

                {!isNewOffice &&
                officeForm?.allow_sign_in_using_office_account &&
                +officeForm?.user_id ? (
                  <span
                    onClick={handleResetPassword}
                    role="button"
                    className="col-md-2 col-lg-2 align-self-end reset_btn"
                  >
                    {officeForm.reset_password && +officeForm?.user_id
                      ? t("Cancel")
                      : t("reset password")}
                  </span>
                ) : null}

                <div className="col-12">
                  <CheckboxBooleanForm
                    {...FormProps}
                    name="ignore_location"
                    options={[
                      "signing in from this account does not require a location",
                    ]}
                    type="checkbox"
                    containerStyle="pt-3"
                    dependOn="allow_sign_in_using_office_account"
                    dependancyType="equal"
                    dependancyValue="1"
                  />
                </div>
              </div>
            </>
          </HasPrivileges>
        </div>

        <div className="row py-3">
          <div
            className={`${
              isWorkPlace
                ? "col-12"
                : `col-12 col-md-${props.setupWizard ? "12" : "8"} col-lg-${
                    props?.setupWizard ? "12" : "9"
                  }`
            }`}
          >
            <BSelect
              {...FormProps}
              containerStyle=" "
              name="time_zone"
              label="time zone"
              keepDefaultStyle
              placeholder={t("select option")}
              options={Constant.timeZones}
              validateBy="textRequired"
              optionLabel="label"
              optionValue="value"
              labelStyle="mb-2"
              skipLocalization
              icon="globe"
            />
          </div>

          {!isWorkPlace && !props.setupWizard ? (
            <div className="col-12 col-lg-3 col-md-4">
              <CheckboxBooleanForm
                {...FormProps}
                name="head_quarter"
                options={[t("Set Headquarter Office")]}
                type="checkbox"
                containerStyle="pt-4 mt-2"
              />
            </div>
          ) : null}
        </div>

        {!isWorkPlace ? (
          <>
            <div className="row mb-3">
              <div className="col-12">
                <InputForm
                  {...FormProps}
                  validateBy="textRequired"
                  label="address"
                  name="address"
                  type="text"
                  placeholder={t("address")}
                  labelStyle="mb-2"
                  containerStyle=" "
                  inputContainerStyle=" "
                  icon="location"
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-6">
                <BSelect
                  {...FormProps}
                  containerStyle=""
                  name="country"
                  label="country"
                  keepDefaultStyle
                  placeholder={t("select option")}
                  options={officeCountriesOptions}
                  value={officeForm?.country}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option}
                  labelStyle="mb-2 validity-label-style"
                  validateBy="textRequired"
                  icon="globe"
                  isValueObject
                  onInterceptInputOnChange={handleCountryIntercept}
                />
              </div>

              <div className="col-12 col-md-6">
                <BSelect
                  {...FormProps}
                  containerStyle=""
                  name="currency_id"
                  label="default currency"
                  keepDefaultStyle
                  placeholder={t("select option")}
                  options={officeCurrenciesOptions}
                  optionLabel="name"
                  optionValue="id"
                  labelStyle="mb-2 validity-label-style"
                  validateBy="textRequired"
                  preSelectValue={officeForm?.country?.currency_id}
                  icon="money"
                />
              </div>
            </div>

            <BCRSelect
              {...FormProps}
              containerStyle="mb-3"
              name="ips"
              label={t("IP Addresses")}
              keepDefaultStyle
              placeholder={t("select option")}
              options={officeIpsOptions}
              optionLabel="value"
              optionValue="value"
              isMulti={true}
              labelStyle="mb-2"
              classNamePrefix="b-select-style"
              components={{ Control }}
            />
          </>
        ) : null}

        {/* <div className="row">
          <div className="col-12 d-flex">
            <div className="mb-3 w-100">
              <BSelect
                {...FormProps}
                name="holidays"
                label="holidays"
                keepDefaultStyle
                placeholder={t("select option")}
                options={attProfileHolidaysOptions}
                // validateBy="textRequired"
                components={{ MultiValueContainer }}
                isMulti
                openMenuOnClick={false}
                labelStyle="mb-2"
                classNamePrefix="custom_select_style"
                value={officeForm.holidays}
                onChange={handlechange}
                getOptionLabel={(option) => option["name"]}
                getOptionValue={(option) => option}
              />
            </div>

            <div className="pt-2 px-0 margin-top-26px">
              <button
                className="add-btn float-right btn ml-3"
                onClick={handleAddOfficeHoliday}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-12 d-flex">
            <div className="w-100">
              <BSelect
                {...FormProps}
                name="locations"
                label={t("locations")}
                keepDefaultStyle
                placeholder={t("select option")}
                options={officeLocationsOptions}
                validateBy="textRequired"
                components={{ MultiValueContainer }}
                isMulti
                openMenuOnClick={false}
                labelStyle="mb-2"
                value={officeForm.locations}
                onChange={handlechange}
                getOptionLabel={(option) => option["name"]}
                getOptionValue={(option) => option}
                filterOption={filterOptions}
                skipLocalization
                icon="location"
              />
            </div>

            <HasPrivileges
              reqireMain={[
                Privilages.ADD_EDIT_DELETE_LOCATIONS,
                Privilages.ADD_EDIT_DELETE_OFFICES,
              ]}
              allowBP
              avalibleOnExpire={false}
            >
              <div className="pt-2 px-0 margin-top-26px">
                <button
                  className="add-btn float-right btn ml-3"
                  onClick={handleAddOfficeLocation}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </HasPrivileges>
          </div>
        </div>

        {officeForm?.canEditStartOfMonthOrWeek ? (
          <>
            {!isWorkPlace ? (
              <div className="row">
                <div className="col-12 col-md-6">
                  <p className="office-form-label-style">
                    {t("first month of the year")}
                  </p>
                  {isNewOffice || isEditingFirstMonth ? (
                    <DateTimePickerForm
                      {...FormProps}
                      containerStyle="custom_datepicker_wrapper_style mr-3"
                      name="first_month"
                      validateBy="textRequired"
                      hasIcon
                      mode="month"
                      picker="month"
                      format="YYYY-MM"
                      requestFormat="YYYY-MM"
                      rootStyle="w-100 mr-3"
                    />
                  ) : (
                    <div className="d-flex align-items-center gap-10">
                      <p className="mb-0">
                        {moment(
                          officeForm?.getYearStartMonth ?? undefined,
                          "M"
                        ).format("MMMM")}
                      </p>
                      {officeForm?.canEditStartOfMonthOrWeek ? (
                        <EditIconButton onClick={handleEditFirstMonth} />
                      ) : null}
                    </div>
                  )}
                </div>
                {/* <div className="col-12 col-md-6 mb-3">
                  <p className="office-form-label-style">
                    {t("applicable payment intervals")}
                  </p>
                  <div className="d-flex align-items-center">
                    <CheckboxBooleanForm
                      {...FormProps}
                      name="allow_monthly_interval"
                      options={["monthly"]}
                      type="checkbox"
                      containerStyle="pt-3 mr-5"
                      disabled={
                        !isNewOffice &&
                        officeForm?.payment_intervals?.includes("Month")
                      }
                    />
                    <CheckboxBooleanForm
                      {...FormProps}
                      name="allow_weekly_interval"
                      options={["weekly"]}
                      type="checkbox"
                      containerStyle="pt-3"
                      disabled={
                        !isNewOffice &&
                        officeForm?.payment_intervals?.includes("Week")
                      }
                    />
                  </div>
                  {!!paymentIntervalsMessage && formSubmitting ? (
                    <div className="invalid-container-style">
                      <p className="mb-0 validity-msg-style">
                        {t(paymentIntervalsMessage)}
                      </p>
                    </div>
                  ) : null}
                </div> */}

                {/* {officeForm?.allow_monthly_interval ? ( */}
                <div className="col-12 col-md-6 mb-3">
                  <p className="office-form-label-style">
                    {t("month start day")}
                  </p>
                  {isNewOffice ||
                  (!isNewOffice && !officeForm?.getMonthStartDay) ? (
                    <BSelect
                      {...FormProps}
                      containerStyle=" "
                      name="month_start_day"
                      keepDefaultStyle
                      placeholder={t("select option")}
                      options={Constant.payrollStartOptions}
                      validateBy="textRequired"
                      optionLabel="name"
                      optionValue="id"
                    />
                  ) : isEditingMonthStart ? (
                    <div className="d-flex align-items-center">
                      <DateTimePickerForm
                        {...FormProps}
                        label="payroll month"
                        containerStyle="custom_datepicker_wrapper_style mr-3"
                        name="first_day_of_month_selected_month"
                        validateBy="textRequired"
                        hasIcon
                        mode={"month"}
                        picker={"month"}
                        format={"MM-YYYY"}
                        requestFormat={"MM-YYYY"}
                        rootStyle="w-100 mr-3"
                        onIntercept={handleInterceptChangeOfPayrollMonth}
                      />
                      <DateTimePickerForm
                        {...FormProps}
                        label="date"
                        containerStyle="custom_datepicker_wrapper_style mr-3"
                        name="first_day_of_month"
                        validateBy="textRequired"
                        hasIcon
                        disabledDate={(date) => {
                          const shouldDisable =
                            moment(date).isBefore(
                              moment(`20-${monthBefore}`, "DD-MM-YYYY"),
                              "days"
                            ) ||
                            moment(date).isAfter(
                              moment(`10-${currentMonth + 1}`, "DD-MM-YYYY"),
                              "days"
                            ) ||
                            moment(date).format("DD") === "29" ||
                            moment(date).format("DD") === "30" ||
                            moment(date).format("DD") === "31" ||
                            !officeForm?.first_day_of_month_selected_month;
                          return shouldDisable;
                        }}
                        rootStyle="w-100"
                      />
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <p className="m-0">{officeForm?.getMonthStartDay}</p>
                      {officeForm?.canEditStartOfMonthOrWeek ? (
                        <EditIconButton onClick={handleEditMonthStartDay} />
                      ) : null}
                    </div>
                  )}
                </div>
                {/* ) : null} */}

                {/* {officeForm?.allow_weekly_interval ? ( */}
                <div className="col-12 col-md-6 mb-3">
                  <p className="office-form-label-style">
                    {t("week start day")}
                  </p>
                  {isNewOffice ||
                  (!isNewOffice && !officeForm?.week_start_day) ? (
                    <BSelect
                      {...FormProps}
                      containerStyle="mb-3"
                      name="week_start_day"
                      keepDefaultStyle
                      placeholder={t("select option")}
                      options={Constant.WeekDays}
                      validateBy="textRequired"
                      optionLabel="label"
                      optionValue="value"
                    />
                  ) : isEditingWeekStart ? (
                    <div className="d-flex align-items-center">
                      <BSelect
                        {...FormProps}
                        label="payroll week"
                        name="first_day_of_week_selected_week"
                        optionLabel="from"
                        optionValue="from"
                        isOptionDisabled={(option) =>
                          !allowedWeekOptions()?.find(
                            (allowedWeek) =>
                              JSON.stringify(allowedWeek) ===
                              JSON.stringify(option)
                          )
                        }
                        options={data?.weeks ?? []}
                        isLoading={loading}
                        isClearable
                        placeholder={t("select week")}
                        validateBy="textRequired"
                        rootStyle="w-100 mr-3"
                        labelStyle="mb-0"
                        containerStyle=" "
                      />
                      <DateTimePickerForm
                        {...FormProps}
                        label={"date"}
                        containerStyle="custom_datepicker_wrapper_style mr-3"
                        name="first_day_of_week"
                        validateBy="textRequired"
                        hasIcon
                        rootStyle="w-100"
                        disabledDate={(date) => {
                          const shouldDisable =
                            moment(date).isBefore(
                              moment(
                                officeForm?.first_day_of_week_selected_week,
                                "YYYY-MM-DD"
                              ),
                              "days"
                            ) ||
                            moment(date).isAfter(
                              moment(
                                allowedWeekOptions()?.find(
                                  (week) =>
                                    week?.from ===
                                    officeForm?.first_day_of_week_selected_week
                                )?.paidAt,
                                "YYYY-MM-DD"
                              ),
                              "days"
                            ) ||
                            !officeForm?.first_day_of_week_selected_week;
                          return shouldDisable;
                        }}
                      />
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <p className="m-0">
                        {
                          Constant.WeekDays?.find(
                            (day) => day?.value == officeForm?.week_start_day
                          )?.label
                        }
                      </p>

                      {officeForm?.canEditStartOfMonthOrWeek ? (
                        <EditIconButton onClick={handleEditWeekStartDay} />
                      ) : null}
                    </div>
                  )}
                </div>
                {/*  ) : null} */}

                <div className="col-6 mb-3 d-flex align-items-end">
                  <BSelect
                    {...FormProps}
                    rootStyle="w-100"
                    containerStyle=" "
                    name="taxation"
                    label="default regulation"
                    keepDefaultStyle
                    placeholder={t("select option")}
                    options={
                      relevantRegulationTypesData?.relevantRegulationTypes
                    }
                    // options={props?.data?.taxationsOptions}
                    validateBy="textRequired"
                    optionLabel="name"
                    optionValue="id"
                    labelStyle="mb-2"
                    onInterceptInputOnChange={handleDefaultRegulationIntercept}
                    isLoading={loading}
                    isDisabled={!officeForm?.country?.id}
                  />
                </div>

                {showTaxSettlementInterval ? (
                  <div className="col-6">
                    <BSelect
                      {...FormProps}
                      containerStyle=" "
                      name="tax_settlement_interval"
                      label="tax settlement interval"
                      keepDefaultStyle
                      placeholder={t("select option")}
                      options={[
                        { label: "quarter", value: "3" },
                        { label: "half", value: "6" },
                        { label: "annual", value: "12" },
                      ]}
                      validateBy="textRequired"
                      optionLabel="label"
                      optionValue="value"
                      labelStyle="mb-2"
                      // dependOn="taxation"
                      // dependancyType="equal"
                      // dependancyValue={[RegulationTypes.REGULATION_EGP]}
                    />
                  </div>
                ) : null}

                {isNewOffice ? null : (
                  <div className="d-flex align-items-center gap-20 mt-4 col-12">
                    <b>
                      {t(
                        "Prorate Current week/month/year according to the changes made"
                      )}
                    </b>
                    <CheckboxBooleanForm
                      {...FormProps}
                      setWithValue
                      name="Leaves"
                      options={["Leaves"]}
                    />
                    <CheckboxBooleanForm
                      {...FormProps}
                      setWithValue
                      name="Permissions"
                      options={["Permissions"]}
                    />
                    <CheckboxBooleanForm
                      {...FormProps}
                      setWithValue
                      name="Salary"
                      options={["Salary"]}
                    />
                    <CheckboxBooleanForm
                      {...FormProps}
                      setWithValue
                      name="Grace"
                      options={["Grace"]}
                    />
                    <CheckboxBooleanForm
                      {...FormProps}
                      setWithValue
                      name="Overtime"
                      options={["Overtime"]}
                    />
                  </div>
                )}
              </div>
            ) : null}

            {!isNewOffice && props?.data?.officeForm?.configurations?.length ? (
              <>
                <button
                  className="view-change-logs-button-style"
                  onClick={() => setIsLogsOpen((prev) => !prev)}
                >
                  {t("view change logs")}
                </button>

                {isLogsOpen ? (
                  <div className="border rounded">
                    <div className="d-flex align-items-center p-2 border-bottom">
                      <p className="flex-1 mb-0 font-weight-bold">{t("on")}</p>
                      <p className="flex-1 mb-0 font-weight-bold">
                        {t("field")}
                      </p>
                      <p className="flex-1 mb-0 font-weight-bold">
                        {t("old value")}
                      </p>
                      <p className="flex-1 mb-0 font-weight-bold">
                        {t("new value")}
                      </p>
                      <p className="flex-1 mb-0 font-weight-bold">
                        {t("start from")}
                      </p>
                      <p className="flex-1 mb-0 font-weight-bold">
                        {t("changed by")}
                      </p>
                    </div>

                    {props?.data?.officeForm?.configurations?.map(
                      (config, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-center p-2"
                        >
                          <p className="flex-1 mb-0">
                            {moment(config?.created_at).format("YYYY-MM-DD")}
                          </p>
                          <p className="flex-1 mb-0">
                            {config?.type === "Week"
                              ? t("week start day")
                              : t("month start day")}
                          </p>
                          <p className="flex-1 mb-0">------</p>
                          <p className="flex-1 mb-0">
                            {config?.type === "Week"
                              ? moment(config?.from).format("DD-MM-YYYY")
                              : moment(config?.from).format("dddd")}
                          </p>
                          <p className="flex-1 mb-0">------</p>
                          <p className="flex-1 mb-0">{config?.user?.name}</p>
                        </div>
                      )
                    )}
                  </div>
                ) : null}
              </>
            ) : null}
          </>
        ) : (
          <></>
          // <div className="col-12 col-md-6 mb-3">
          //   <p className="office-form-label-style">
          //     {t("applicable payment intervals")}
          //   </p>
          //   <div className="d-flex align-items-center">
          //     <CheckboxBooleanForm
          //       {...FormProps}
          //       name="allow_monthly_interval"
          //       options={["monthly"]}
          //       type="checkbox"
          //       containerStyle="pt-3 mr-5"
          //       disabled={
          //         !isNewOffice &&
          //         officeForm?.payment_intervals?.includes("Month")
          //       }
          //     />
          //     <CheckboxBooleanForm
          //       {...FormProps}
          //       name="allow_weekly_interval"
          //       options={["weekly"]}
          //       type="checkbox"
          //       containerStyle="pt-3"
          //       disabled={
          //         !isNewOffice &&
          //         officeForm?.payment_intervals?.includes("Week")
          //       }
          //     />
          //   </div>
          //   {!!paymentIntervalsMessage && formSubmitting ? (
          //     <div className="invalid-container-style">
          //       <p className="mb-0 validity-msg-style">
          //         {t(paymentIntervalsMessage)}
          //       </p>
          //     </div>
          //   ) : null}
          // </div>
        )}

        {!isWorkPlace && allowCostCenter ? (
          <BSelect
            {...FormProps}
            name="cost_center_id"
            validationName="input.cost_center_id"
            placeholder={t("select cost center")}
            options={officeCostCenterOptions ?? []}
            label="Cost center"
            labelStyle="mt-3 mb-2"
            containerStyle="d-flex align-items-start flex-column"
            inputContainerStyle="w-100"
            icon="money"
            rootStyle="flex-1 mb-3"
            skipLocalization
          />
        ) : null}

        {officeFormStatus.error && formSubmitting && (
          <div className="warnig-msg-style">{officeFormStatus.error}</div>
        )}
      </div>

      <div className="mt-1 mb-1 text-right">
        <button
          disabled={officeFormStatus.isLoading}
          type="submit"
          onClick={handleSubmitOfficeForm}
          className="btn btn-primary submit_btn_style"
        >
          {officeFormStatus.isLoading ? (
            <Spinner style={{ width: "1rem", height: "1rem", color: "#fff" }} />
          ) : (
            t("save")
          )}
        </button>
      </div>

      {/* Add Holiday Modal */}
      {/* <AddHolidayModal type="office" /> */}

      {/* Add Location Modal */}
      <AddLocationModal
        formName={formName}
        optionsName="officeLocationsOptions"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
  };
};

export default connect(mapStateToProps, {})(OfficeForm);

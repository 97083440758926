import React, { useEffect } from "react";
import { InputForm } from "form-builder";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { faUserLock, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  setDataTableSortingAction,
  fetchAdminUsersAction,
  showAdminUserModalAction,
  deleteAdminUserAction,
  fetchAdminUserCompaniesAction,
  editAdminUserPrivilegesAction,
  showAdminUserProfileModalAction,
} from "../../Store/Actions";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import AdminUserModal from "./AdminUserModal";
import swal from "sweetalert";
import Loader from "../../Components/Loader";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import EditAdminPrivilagesModal from "./EditAdminPrivilagesModal";
import AdminUserProfile from "./AdminUserProfile";
import IconButtonWithTooltip, {
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import { useState } from "react";
import ChangeAdminUsersModal from "./ChangeAdminUsersModal";

const dataTableRef = "adminUsersList";
const initReplaceAdmin = {
  isOpen: false,
  oldAdmin: null,
};

const AdminUsers = (props) => {
  const { t } = useTranslation();

  const columns = [
    {
      name: t("employee"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[
            Privilages.SUPER_PRIVILEGE,
            Privilages.VIEW_ADMIN_USER_PROFILE,
          ]}
          altChildren={<>{row?.name}</>}
        >
          <a
            className="cursor-pointer"
            onClick={() => toggleShowAdminUserProfileModal(row)}
          >
            {row?.name}
          </a>
        </HasPrivileges>
      ),
    },
    {
      name: t("phone number"),
      wrap: true,
      selector: "phone",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <div>
          ({row?.phoneCode}) {row?.phone}
        </div>
      ),
    },
    {
      name: t("email"),
      wrap: true,
      selector: "email",
      sortable: false,
      grow: 1,
    },
    {
      name: t("actions"),
      wrap: true,
      selector: "action",
      grow: 0.5,
      cell: (row) => (
        <div className="cards_table_actions">
          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.EDIT_ADMIN_USERS_PRIVILEGES,
            ]}
          >
            <IconButtonWithTooltip
              label="Edit User Privileges"
              onClick={() => handleManagePrivilagesModal(row)}
              icon={
                <FontAwesomeIcon
                  size="sm"
                  icon={faUserLock}
                  color="#8997a4"
                  {...props?.iconProps}
                />
              }
            />
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.REMOVE_ADMIN_USERS,
            ]}
          >
            <RemoveIconButton onClick={() => handleDelete(row)} />
          </HasPrivileges>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  const toggleShowAdminUserProfileModal = (row) => {
    let adminUserDetails = {
      name: row?.name,
      phone_country_code: row?.phone_country_code,
      phone: row?.phone,
      email: row?.email,
      id: row?.saas?.id,
      user_id: row?.saas?.user_id,
      role_id: row?.saas?.role_id,
      company_ids: row?.saas?.companies?.map(
        (companiesDetails) => companiesDetails?.id
      ),
      company_names: row?.saas?.companies?.map(
        (companiesDetails) => companiesDetails?.name
      ),
    };

    props.showAdminUserProfileModalAction({
      adminUserDetails,
      ref: "adminUsersProfileModalActions",
      formName: "adminUsersProfileModal",
    });
    props.fetchAdminUserCompaniesAction();
  };

  const handleAdd = () => {
    props.showAdminUserModalAction({
      ref: "adminUsersModalActions",
      formName: "adminUsersModal",
    });
    props.fetchAdminUserCompaniesAction();
  };

  const [replaceAdminUsersModal, setReplaceAdminUsersModal] =
    useState(initReplaceAdmin);

  const handleDelete = (row) => {
    let primaryCompaniesCount = row?.saas?.primaryCompaniesCount;
    if (primaryCompaniesCount > 0) {
      setReplaceAdminUsersModal({
        isOpen: true,
        oldAdmin: row?.saas?.id,
      });
    } else {
      swal({
        title: t("are you sure"),
        text: t("defaut_warning_messsage"),
        icon: "warning",
        className: "swal-warning-style",
        dangerMode: true,
        buttons: [t("Cancel"), t("OK")],
      }).then((willDelete) => {
        if (willDelete) {
          props.deleteAdminUserAction({ saas_id: row?.saas?.id });
        }
      });
    }
  };

  useEffect(() => {
    props.fetchAdminUsersAction({
      ref: dataTableRef,
      [dataTableRef]: props.data,
    });
  }, []);

  const refetch = () => {
    props.fetchAdminUsersAction({
      ref: dataTableRef,
      [dataTableRef]: props.data,
    });
  };

  const handleFilter = () => {
    props.fetchAdminUsersAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data,
        pagination: {
          ...props.data.pagination,
          currentPage: 1,
        },
      },
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data.filter,
  ]);

  const handlePaginate = (
    page = props.data.pagination.currentPage
  ) => {
    console.log("@pagination effect");
    props.fetchAdminUsersAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data,
        pagination: {
          ...props.data.pagination,
          currentPage: page,
        },
      },
    });
  };

  const handleSorting = (field, dir) => {
    props.fetchAdminUsersAction({
      [dataTableRef]: {
        ...props.data,
        pagination: {
          ...props.data.pagination,
          currentPage: 1,
        },
        sorting: {
          dir,
          key: field.selector,
        },
      },
      ref: dataTableRef,
    });
    props.setDataTableSortingAction(dataTableRef, field.selector, dir);
  };

  // for manage privilages modal
  const handleManagePrivilagesModal = (row) => {
    props.editAdminUserPrivilegesAction(row?.id, row?.name);
  };

  const resetChangeAdminModal = () => {
    setReplaceAdminUsersModal(initReplaceAdmin);
  };

  return (
    <div className="content pt-3">
      {props?.isDeleteAdminUserLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div>
        <div className="d-flex justify-content-between">
          <div className="mb-3 col px-0">
            <InputForm
              name="filter"
              formName={dataTableRef}
              // label={<FontAwesomeIcon icon={faSearch} />}
              labelStyle="focus-label"
              placeholder={t("search")}
              inputStyle="form-control search_field_style "
              containerStyle=" "
              inputContainerStyle="form-group mb-2 d-flex "
            />
          </div>

          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.ADD_ADMIN_USERS,
            ]}
          >
            <div className="mb-3 pl-3 text-right pt-1px">
              <button
                className="btn add_new_btn_style"
                type="button"
                onClick={handleAdd}
              >
                <FontAwesomeIcon className="mr-2" icon={faPlus} />
                {t("add new")}
              </button>
            </div>
          </HasPrivileges>
        </div>

        <DataTable
          className="cards_table"
          columns={columns}
          data={props.data.data}
          noHeader
          persistTableHead
          sortServer
          onSort={handleSorting}
          defaultSortAsc={props.data.sorting.dir == "asc"}
          defaultSortField={props.data.sorting.key}
          paginationComponent={() => (
            <Pagination
              tableRef={dataTableRef}
              styleWraper=""
              onPaginate={handlePaginate}
            />
          )}
          pagination={true}
          paginationServer={true}
          progressPending={props.data.isLoading}
          progressComponent={<Loader />}
        />
      </div>

      {/* Start of Admin user modal */}
      <AdminUserModal />
      {/* End of Admin user modal */}

      {/* Start of Edit Admin user privilages modal */}
      <EditAdminPrivilagesModal />
      {/* End of Edit Admin user privilages modal */}

      {/* Start of Admin User Profile Modal */}
      <AdminUserProfile />
      {/* End of Admin User Profile Modal  */}

      {/* Start Replace Admin USers Modal */}
      <ChangeAdminUsersModal
        modalData={replaceAdminUsersModal}
        reset={resetChangeAdminModal}
        refetch={refetch}
      />

      {/* End Replace Admin USers Modal */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super[dataTableRef],
    isDeleteAdminUserLoading: state?.super?.deleteAdminUserLoading,
  };
};

export default connect(mapStateToProps, {
  setDataTableSortingAction,
  fetchAdminUsersAction,
  showAdminUserModalAction,
  deleteAdminUserAction,
  fetchAdminUserCompaniesAction,
  editAdminUserPrivilegesAction,
  showAdminUserProfileModalAction,
})(AdminUsers);

import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import Helper from "../../Helpers/HelperFns";

import AttendanceActionModal from "./AttendanceActionModal";
import { useTranslation } from "react-i18next";

import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";

import {
  toggleAttendanceActionModal,
  toggleSuspensionModal,
  addEmployeeIdToSuspension,
  deleteUserAttempt,
  toggleActivationModal,
  addEmployeeIdToActivation,
  editEmployeeAction,
  showManagePrivilagesModalAction,
  editEmployeePrivilegesAction,
  fetchEmployeeProfileSuccess,
  sendPhoneVerificationAction,
  sendEmailVerificationAction,
  resendCredentailsAction,
  fetchInitDeleteManagerAction,
  fetchInitSuspendManagerAction,
  toggleActivationSuspensionRecordsModal,
} from "../../Store/Actions";

import Tooltip from "@mui/material/Tooltip";
import {
  Edit,
  PlayCircleOutline,
  PauseCircleOutline,
  DeleteForever,
  VpnKey,
} from "@mui/icons-material";
import InfoTooltip from "../../Containers/EmployeeProfile/InfoTooltip";
import RenderMultipleSignIns from "../RenderMultipleSignIns";
import ProfileCardPlaceholder from "../../LoadingSection/ProfileCardPlaceholder";

const ProfileCard = (props) => {
  const canAddNewEmployee = props.selectedProfile?.company?.canAddNewEmployee;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAttendanceActionModalOpen = useSelector(
    (state) => state.user?.attendanceModalActions?.isVissible
  );

  const renderBalanceAnnualData = (type) => {
    if (!props?.isEmployee) {
      return true;
    }
    if (
      props?.employee?.on_probation &&
      !props?.employee?.permission_leaves_break_setting
        ?.probationPeriodConfiguration?.request_leaves
    ) {
      return false;
    }
    if (
      props?.employee?.permission_leaves_break_setting?.submitRequestAbility?.[
        type
      ] !== "both"
    ) {
      return false;
    }
    return true;
  };

  // to approximate the numbers of remaning balance
  function remaningBalanceCalcualtion(num) {
    let roundNumToSeven = (+num)?.toFixed(7);
    let roundNumToTwo = (+roundNumToSeven)?.toFixed(2);
    return Math.abs(roundNumToTwo) == 0 ? 0 : roundNumToTwo;
  }

  // handle open activation and suspension modal fucntion
  const handleToggleActivationSuspensionModal = (modalData) => {
    console.log("modalData", modalData);

    dispatch(
      toggleActivationSuspensionRecordsModal({ isOpen: true, modalData })
    );
  };

  const handleSuspensionBtn = () => {
    // props.fetchInitSuspendManagerAction(props?.id);

    // handle open activation suspension modal
    handleToggleActivationSuspensionModal(props);
  };

  const handleActivationBtn = () => {
    // props.toggleActivationModal(
    //   props?.attendance_profile?.activeWorkTiming?.overtime_permissible,
    //   props?.office?.country?.id
    // );
    // props.addEmployeeIdToActivation(
    //   props.id,
    //   props?.office,
    //   props?.office?.country?.id
    // );

    // handle open activation suspension modal
    handleToggleActivationSuspensionModal(props);
  };

  const handleDeleteUser = () => {
    props.fetchInitDeleteManagerAction(props.id);
  };

  const handleEditBtn = () => {
    props.editEmployeeAction(props.id,props?.starts_at);
  };

  // for resend credentails action
  const handleResendCredentials = () => {
    props.resendCredentailsAction(props?.id);
  };

  // for manage privilages modal
  const handleManagePrivilagesModal = () => {
    props.editEmployeePrivilegesAction(props.id, props?.planId, props?.name);
  };

  // CleanUp - Reset Employee Profile
  useEffect(() => {
    return () => props.fetchEmployeeProfileSuccess({});
  }, []);

  return (
    <div
      className={
        props?.employee?.on_probation || props?.employee?.probationPeriod
          ? "probation_wrapper_style"
          : ""
      }
    >
      <div
        className={
          props?.isEmployee
            ? "profile_card-box_style profile-header px-4 px-md-5 mb-md-3 padding-top-3"
            : "employee_profile_card-box_style profile_card-box_style profile-header px-4 px-md-5 mb-md-3 padding-top-3"
        }
      >
        {props.isLoading ? (
          <ProfileCardPlaceholder />
        ) : (
          <div className="row">
            <div className="col-md-12 px-0">
              <div className="d-flex flex-column flex-md-row profile-view text-left align-items-center">
                <div className="d-flex flex-column ml-2 mr-4">
                  <div className="profile-img-wrap">
                    <div className="profile-img">
                      <Avatar
                        src={props?.profile_picture?.path}
                        className="avatar blackish-avatar"
                      >
                        {Helper.getIntials(props?.name)}
                      </Avatar>
                    </div>
                  </div>

                  <div className="d-flex employee_profile_tooltips_wrapper_style justify-content-md-center pt-2">
                    <InfoTooltip data={props} />
                  </div>
                </div>

                <div className="profile-basic col">
                  {/* Start of Probation Section */}

                  <div className="d-flex justify-content-end mb-2 align-items-baseline">
                    {props?.employee?.on_probation ? (
                      <div className="on_probation_status_style mx-2 text-nowrap">
                        {t("On Probation")}
                      </div>
                    ) : (
                      ""
                    )}

                    {props?.employee?.on_probation ? (
                      <div className="probation_date_text_style mt-0">
                        {t("Probation ends on")}
                        <span className="pl-1">
                          {moment(
                            props?.employee?.probationPeriod?.end_of_probation
                          ).format("DD - MM - YYYY")}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* End of Probation Section */}
                  <div className="row">
                    <div className="col-md-12">
                      {props.isEmployee ? (
                        <HasPrivileges
                          reqireMain={[
                            Privilages.APPLY_FOR_LEAVE_REQUESTS,
                            Privilages.APPLY_FOR_REQUESTS,
                            Privilages.APPLY_FOR_CLAIM_REQUESTS,
                            Privilages.APPLY_FOR_OPEN_REQUESTS,
                          ]}
                          avalibleOnExpire={false}
                          altExpireChildren={
                            <button
                              disabled
                              type="button"
                              className="btn btn-prim btn-sm ml-2 attendance_btn_style"
                            >
                              {t("new request")}
                            </button>
                          }
                        >
                          <button
                            type="button"
                            className="btn btn-prim btn-sm ml-2 attendance_btn_style"
                            onClick={props.toggleAttendanceActionModal}
                          >
                            {t("new request")}
                          </button>
                        </HasPrivileges>
                      ) : (
                        <HasPrivileges
                          allowBP
                          reqireMain={[
                            Privilages.SUBMIT_REQUEST_ON_BEHALF_OF_EMPLOYEES,
                          ]}
                          avalibleOnExpire={false}
                          altExpireChildren={
                            <button
                              type="button"
                              className="btn btn-prim btn-sm ml-2 attendance_btn_style"
                            >
                              {t("new request")}
                            </button>
                          }
                        >
                          <button
                            type="button"
                            className="btn btn-prim btn-sm ml-2 attendance_btn_style"
                            onClick={props.toggleAttendanceActionModal}
                          >
                            {t("new request")}
                          </button>
                        </HasPrivileges>
                      )}
                      <div className="profile_info_sperator_style w-100">
                        <h3 className="user-name m-t-0 mb-0">{props?.name}</h3>

                        <small className="user-title">
                          {props?.position?.name}
                        </small>

                        <small className="user-title">
                          {" - "} {props?.department?.name}
                        </small>

                        {Boolean(+props?.from_home) ? (
                          <FontAwesomeIcon icon={faHome} color="#27b40c" />
                        ) : null}

                        {props?.active ? (
                          <div className="multiple-sign-ins-container mt-2">
                            {props?.emp_multiple_sign_ins?.map(
                              (signIn, index) => (
                                <div className="profile-card-sign-ins-style">
                                  <RenderMultipleSignIns
                                    signInTime={props?.sign_in_time}
                                    signIn={signIn}
                                    index={index}
                                    showLocations={false}
                                    showActiveSignInColor={false}
                                  />
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          <div className="suspend_status_style my-2 fit-width pb-0">
                            {t("suspended")}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-between col">
                      <div className="row">
                        {props?.employee?.permission_leaves_break_setting
                          ?.annualLeaveSetting?.allow_annual_leaves &&
                        renderBalanceAnnualData("annual_emergency_leave") ? (
                          <div className="px-3">
                            <p className="count-name">{t("annual leaves")}</p>
                            <h3 id="count">
                              {props?.balance?.remaining_annual_leaves !==
                              undefined
                                ? remaningBalanceCalcualtion(
                                    props?.balance?.remaining_annual_leaves
                                  )
                                : ""}
                            </h3>
                            {props?.balance?.remaining_emergency_leaves !==
                              undefined &&
                            props?.employee?.permission_leaves_break_setting
                              ?.annualLeaveSetting?.allowed_emergency_leaves &&
                            renderBalanceAnnualData(
                              "annual_emergency_leave"
                            ) ? (
                              <span className="h2 notes-style fit-width">
                                {t("emergency credit")} :{" "}
                                {remaningBalanceCalcualtion(
                                  Math.max(
                                    Math.min(
                                      +props?.balance
                                        ?.remaining_emergency_leaves,
                                      +props?.balance?.remaining_annual_leaves
                                    ),
                                    0
                                  )
                                )}
                              </span>
                            ) : null}
                          </div>
                        ) : null}

                        {props?.employee?.permission_leaves_break_setting
                          ?.permissionSetting?.allow_permissions ? (
                          <div>
                            <p className="count-name"> {t("permissions")}</p>
                            <h3 id="count">
                              {props?.balance?.remaining_permissions !==
                              undefined
                                ? remaningBalanceCalcualtion(
                                    props?.balance?.remaining_permissions
                                  )
                                : ""}
                            </h3>
                          </div>
                        ) : null}

                        {+props?.employee?.permission_leaves_break_setting
                          ?.sickLeaveSetting?.sick_leaves &&
                        renderBalanceAnnualData("sick_leave") ? (
                          <div className="pl-3">
                            <p className="count-name"> {t("sick leaves")}</p>
                            <h3 id="count">
                              {props?.balance?.remaining_sick_leaves !==
                              undefined
                                ? remaningBalanceCalcualtion(
                                    props?.balance?.remaining_sick_leaves
                                  )
                                : ""}
                            </h3>
                          </div>
                        ) : null}
                        {Boolean(props?.balance?.remaining_grace) ? (
                          <div className="pl-3">
                            <p className="count-name"> {t("grace minutes")}</p>
                            <h3 id="count">
                              {props?.balance?.remaining_grace !== undefined
                                ? remaningBalanceCalcualtion(
                                    props?.balance?.remaining_grace
                                  )
                                : ""}
                            </h3>
                          </div>
                        ) : null}
                      </div>

                      <div>
                        {!props.isEmployee &&
                          !!props?.shouldRenderComponent([
                            Privilages.VIEW_EMPLOYEES,
                          ]) && (
                            <div className="dropdown hr_employee_icons_wrapper_style hr_employee_profile_icons_wrapper_style pt-2 text-right">
                              <HasPrivileges
                                reqireMain={[
                                  Privilages.ADD_EDIT_DELETE_EMPLOYEES,
                                ]}
                                allowBP
                                avalibleOnExpire={false}
                                altExpireChildren={
                                  <Edit className="edit-icon-style mx-1" />
                                }
                              >
                                {!props?.checkPassword &&
                                props?.has_credentials ? (
                                  <VpnKey
                                    className="resend-icon-style mx-1"
                                    onClick={handleResendCredentials}
                                  />
                                ) : null}

                                <Edit
                                  className="edit-icon-style mx-1"
                                  onClick={handleEditBtn}
                                />
                              </HasPrivileges>

                              <HasPrivileges
                                reqireMain={[
                                  Privilages.ACTIVATE_SUSPEND_EMPLOYEES,
                                ]}
                                allowBP
                                avalibleOnExpire={false}
                                altExpireChildren={
                                  props.active ? (
                                    <PauseCircleOutline className="pause-icon-style mx-1" />
                                  ) : (
                                    <PlayCircleOutline className="play-icon-style" />
                                  )
                                }
                              >
                                {!(
                                  props?.saas &&
                                  props?.saas?.primaryCompaniesCount
                                ) ? (
                                  props.active ? (
                                    <PauseCircleOutline
                                      className="pause-icon-style mx-1"
                                      onClick={handleSuspensionBtn}
                                    />
                                  ) : (
                                    <Tooltip
                                      title={
                                        !canAddNewEmployee
                                          ? t(
                                              "you have reached the maximum number of employees"
                                            )
                                          : ""
                                      }
                                    >
                                      <div className="d-inline-block">
                                        <button
                                          className={`border-0 outline-none m-0 p-0 bg-transparent ${
                                            !canAddNewEmployee ? "pe-none" : ""
                                          }`}
                                          onClick={handleActivationBtn}
                                          disabled={!canAddNewEmployee}
                                        >
                                          <PlayCircleOutline className="play-icon-style" />
                                        </button>
                                      </div>
                                    </Tooltip>
                                  )
                                ) : (
                                  ""
                                )}
                              </HasPrivileges>

                              <HasPrivileges
                                reqireMain={[
                                  Privilages.ADD_EDIT_DELETE_EMPLOYEES,
                                ]}
                                allowBP
                                avalibleOnExpire={false}
                                altExpireChildren={
                                  <DeleteForever className="delete-icon-style mx-1" />
                                }
                              >
                                {!(
                                  props?.saas &&
                                  props?.saas?.primaryCompaniesCount
                                ) ? (
                                  <DeleteForever
                                    className="delete-icon-style mx-1"
                                    onClick={handleDeleteUser}
                                  />
                                ) : (
                                  ""
                                )}
                              </HasPrivileges>

                              <HasPrivileges
                                reqireMain={[Privilages.EDIT_USER_PRIVILEGE]}
                                allowBP
                                avalibleOnExpire={false}
                                altExpireChildren={
                                  <span>
                                    <FontAwesomeIcon
                                      className="manage-icon-style mx-1"
                                      icon={faLockOpen}
                                    />
                                  </span>
                                }
                              >
                                <FontAwesomeIcon
                                  className="manage-icon-style mx-1"
                                  onClick={handleManagePrivilagesModal}
                                  icon={faLockOpen}
                                />
                              </HasPrivileges>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {isAttendanceActionModalOpen ? (
        <AttendanceActionModal isEmployee={props?.isEmployee} />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedProfile: state.super.selectedEmployeeProfile,
  planId: state.auth?.userProfile?.company?.activeSubscription?.plan?.id,
});

export default connect(mapStateToProps, {
  toggleAttendanceActionModal,
  toggleSuspensionModal,
  addEmployeeIdToSuspension,
  deleteUserAttempt,
  toggleActivationModal,
  addEmployeeIdToActivation,
  editEmployeeAction,
  showManagePrivilagesModalAction,
  editEmployeePrivilegesAction,
  fetchEmployeeProfileSuccess,
  sendPhoneVerificationAction,
  sendEmailVerificationAction,
  resendCredentailsAction,
  fetchInitDeleteManagerAction,
  fetchInitSuspendManagerAction,
})(ProfileCard);

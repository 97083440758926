import {
  getNextActiveStep,
  getWizardDataSteps,
  getWizardDataStepsCounts,
} from "../../Helpers/HelperFns/SetupWizard";
import { put, call, takeEvery, select } from "redux-saga/effects";
import history from "../../Helpers/Navigations/history";
import HelperFns from "../../Helpers/HelperFns";
import * as client from "../../Services/Client";
import * as Action from "../Actions";
import Types from "../Actions/types";

const getWizardReducer = (state) => state.wizard;

// Fetch
function* handleFetchSetupWizardEssentialData({ cb }) {
  try {
    const response = yield call(client.fetchSetupWizardEssentialData);
    if (!response.error) {
      yield put(Action.fetchSetupWizardEssentialDataSuccess(response?.data));
      const steps = Object.values(getWizardDataSteps(response?.data));

      if (steps.every((s) => s?.paginatorInfo?.count) && cb) {
        yield call(cb);
        yield put(
          Action.showSuccessToast("you finished setting up wizard redirecting")
        );
      }
    } else {
      yield put(Action.fetchSetupWizardEssentialDataFailed());
    }
  } catch (error) {
    yield put(Action.fetchSetupWizardEssentialDataFailed());
  }
}
function* onFetchSetupWizardEssentialData() {
  yield takeEvery(
    Types.FETCH_SETUP_WIZARD_ESSENTIAL_DATA_ATTEMPT,
    handleFetchSetupWizardEssentialData
  );
}

// Office
function* handleCreateOfficeSaga({ payload }) {
  try {
    const response = yield call(client.updateOffice, payload);
    if (!response.errors) {
      yield put(Action.updateWizardOfficeSuccess(response));
      yield put(Action.EditSetupWizardEssentialData("company_offices"));
    } else {
      yield put(
        Action.updateWizardOfficeFailed(
          payload.ref,
          response.errors[0]?.extensions?.reason
            ? response.errors[0]?.extensions?.reason
            : "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      Action.updateWizardOfficeFailed(
        payload.ref,
        error.message ? error.message : "Something went wrong!"
      )
    );
  }
}
function* onUpdateWizardOffice() {
  yield takeEvery(Types.CREATE_WIZARD_OFFICE_ATTEMPT, handleCreateOfficeSaga);
}

// Departments
function* handleCreateDepartmentsSaga({ payload }) {
  try {
    const response = yield call(client.createDepartments, payload);
    if (!response.errors) {
      yield put(Action.createDepartmentsSuccess());
      yield put(Action.EditSetupWizardEssentialData("company_departments"));
    } else {
      yield put(Action.createDepartmentsFailed());
      yield put(Action.showErrorToast(response.errors[0]?.extensions?.reason));
    }
  } catch (error) {
    yield put(Action.createDepartmentsFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}
function* onAddDepartments() {
  yield takeEvery(
    Types.CREATE_DEPARTMENTS_ATTEMPT,
    handleCreateDepartmentsSaga
  );
}

// Positions
function* handleCreatePositionsSaga({ payload }) {
  try {
    const response = yield call(client.createPositions, payload);
    if (!response.errors) {
      yield put(Action.createPositionsSuccess());
      yield put(Action.EditSetupWizardEssentialData("company_positions"));
    } else {
      yield put(Action.createPositionsFailed());
      yield put(Action.showErrorToast(response.errors[0]?.extensions?.reason));
    }
  } catch (error) {
    yield put(Action.createPositionsFailed());
    yield put(Action.showErrorToast(error?.message));
  }
}
function* onAddPositions() {
  yield takeEvery(Types.CREATE_POSITIONS_ATTEMPT, handleCreatePositionsSaga);
}

// Work Timings
function* handleUpsertSetupWizardWorkTimingSaga({ payload }) {
  try {
    const response = yield call(client.upsertWorkTimings, payload);
    if (!response.errors) {
      yield put(Action.upsertSetupWizardWorkTimingSuccess());
      yield put(Action.EditSetupWizardEssentialData("work_timings"));
    } else {
      yield put(
        Action.upsertSetupWizardWorkTimingFailed(
          HelperFns.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          )
        )
      );
    }
  } catch (error) {
    yield put(Action.upsertSetupWizardWorkTimingFailed({}));
    yield put(Action.showErrorToast(error?.message));
  }
}
function* onUpsertSetupWizardWorkTimings() {
  yield takeEvery(
    Types.UPSERT_SETUP_WIZARD_WORK_TIMING_ATTEMPT,
    handleUpsertSetupWizardWorkTimingSaga
  );
}

// Auth
function* handleFetchSetupWizardDataInAuth() {
  try {
    const response = yield call(client.fetchSetupWizardEssentialData);
    if (!response.errors) {
      const steps = Object.values(getWizardDataSteps(response?.data));

      if (steps.some((s) => !s?.paginatorInfo?.count)) {
        history.push("/setup-wizard");
      }
    } else {
      yield put(Action.showErrorToast());
    }
  } catch (error) {
    yield put(Action.showErrorToast(error?.message));
  }
}
function* onFetchSetupWizardDataInAuth() {
  yield takeEvery(
    Types.FETCH_SETUP_WIZARD_DATA_IN_AUTH,
    handleFetchSetupWizardDataInAuth
  );
}

// Edit
function* EditSetupWizard({ payload }) {
  const { setupWizardEssentialInfo, wizardAttType } = yield select(
    getWizardReducer
  );

  // Handle remaining steps
  const counts = getWizardDataStepsCounts(setupWizardEssentialInfo);
  const remainingSteps = counts.filter((count) => !Boolean(count)).length;
  yield put(Action.setSetupWizardRemainingSteps(remainingSteps));

  // Handle redirecting in last step
  if (!remainingSteps) {
    history.push("/employees");
    yield put(
      Action.showSuccessToast(
        "you have just finished all the necessary steps for your company"
      )
    );
  } else {
    // Set next step
    const activeTab = getNextActiveStep(
      setupWizardEssentialInfo,
      wizardAttType,
      payload
    );
    yield put(Action.setSetupWizardActiveTab(activeTab));
  }
}
function* onEditSetupWizard() {
  yield takeEvery(Types.EDIT_SETUP_WIZARD_ESSENTIAL_DATA, EditSetupWizard);
}

const sagaWatchers = {
  onAddDepartments,
  onAddPositions,
  onUpdateWizardOffice,
  onFetchSetupWizardEssentialData,
  onUpsertSetupWizardWorkTimings,
  onFetchSetupWizardDataInAuth,
  onEditSetupWizard,
};
export default sagaWatchers;

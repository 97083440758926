import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { Section, Unit } from "./SectionBase";

const TardinessPolicySection = (props) => {
  const { t } = useTranslation();

  const absentTime = () => {
    if (!props?.apply_minor_tardiness) {
      return moment(props?.sign_in_end_time, "hh:mm a")
        .format("hh:mm a")
        .toString();
    }
    if (
      props?.apply_minor_tardiness &&
      !props?.apply_major_tardiness &&
      props?.minor_tardiness_range
    ) {
      return moment(props?.minor_tardiness_range, "hh:mm a")
        .format("hh:mm a")
        .toString();
    }

    let getMajorsList = Object.entries(props).filter(([key, value]) =>
      key?.includes("apply_major_tardiness")
    );
    let sortedMajorsList = getMajorsList?.sort(
      (a, b) =>
        b[0].replace(/apply_major_tardiness/g, "") -
        a[0].replace(/apply_major_tardiness/g, "")
    );
    let lastAppliedMajorOrder =
      sortedMajorsList.findIndex((major) => major[1] == 1) > -1
        ? 10 - sortedMajorsList.findIndex((major) => major[1] == 1)
        : -1;
    let suffix =
      lastAppliedMajorOrder == 0
        ? ""
        : lastAppliedMajorOrder > -1
        ? lastAppliedMajorOrder
        : null;
    if (
      lastAppliedMajorOrder !== null &&
      props?.[`apply_major_tardiness${suffix}`] &&
      props?.[`major_tardiness_range${suffix}`]
    ) {
      return moment(
        props?.[`major_tardiness_range${suffix}`],
        "hh:mm a"
      ).format("hh:mm A");
    }
  };

  return (
    <Section
      title="Tardiness Policy"
      units={[
        <Unit
          label="deduct from"
          val={t(props?.late_tardiness_penalty_type)}
        />,
        <Unit
          label="reset every"
          val={t(props?.late_reset_period)}
          isHidden={!props?.apply_minor_tardiness}
        />,
        <Unit
          label="tardiness type"
          val={t(props?.tardiness_type_sign_in?.replace("_", " "))}
        />,
        <Unit
          label="annual leave hours"
          val={t(props?.annual_leave_hours_sign_in)}
          isHidden={!props?.annual_leave_hours_sign_in}
        />,
      ]}
    >
      {/* Minor Tardiness */}
      <br />
      <Section
        subSection
        title="Tardiness Tier 1"
        violations={props?.minor_violations}
        units={[
          <Unit
            label="from"
            val={moment(props?.max_lateness_permissibility, "HH:mm").format(
              "hh:mm A"
            )}
          />,
          <Unit
            label="to"
            val={moment(props?.minor_tardiness_range, "HH:mm").format(
              "hh:mm A"
            )}
          />,
        ]}
      />
      <hr />

      {/* Major Tardiness */}
      {Array.from({ length: 11 }, (val, i) => i + 2).map((num, i) => {
        const isTardinessExist =
          props?.["apply_major_tardiness" + (i ? i : "")];
        const toTime = props?.["major_tardiness_range" + (i ? i : "")];
        const fromTime =
          props?.[
            i
              ? "major_tardiness_range" + (i === 1 ? "" : i - 1)
              : "minor_tardiness_range"
          ];

        return isTardinessExist ? (
          <>
            <Section
              subSection
              title={`Tardiness Tier ${num}`}
              violations={props?.["major_violations" + (i ? i : "")]}
              units={[
                <Unit
                  label="from"
                  val={moment(fromTime, "HH:mm").format("hh:mm A")}
                />,
                <Unit
                  label="to"
                  val={moment(toTime, "HH:mm").format("hh:mm A")}
                />,
              ]}
            />
            <hr />
          </>
        ) : null;
      })}

      <div className="d-flex justify-content-between red-color font-size-15">
        <b>{t("Employee will be considered Absent After")}</b>
        <b>{absentTime()}</b>
      </div>
    </Section>
  );
};

export default TardinessPolicySection;

import MainModal from "../../Components/MainModal";
import { BSelect } from "form-builder";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import { onFormResetAction } from "../../Store/Actions";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { Alert, AlertTitle } from "@mui/material";
import { FETCH_MENU_LEAVE_AND_BREAK_SETTINGS } from "../../Graphql/query";
import { upsert_bulk_permission_leave_break_settings_user, UPSERT_EMPLOYEE_LEAVE_BREAK_PROFILE } from "../../Graphql/mutation";
import Constants from "../../Constants";

const AddBulkLeaveBreakProfile = (props) => {
  const { t } = useTranslation();

  const [formInput, setFormInput] = useState({
    permission_leaves_break_setting_id: null,
    main_permission_leaves_break_setting_id: null,
    year: null,
    permission_leaves_break_setting: null,
  });

  const dispatch = useDispatch();
  const [upsertEmployeeLeaveProfile, { loading: submitLoading, reset }] =
    useMutation(upsert_bulk_permission_leave_break_settings_user, {
      onCompleted(res) {
        // do someting
        if (res?.upsert_bulk_permission_leave_break_settings_user?.status == 'success') {
          props?.refetch();
          toggle();
        }else{
          showToast(
            res?.upsert_bulk_permission_leave_break_settings_user?.status,
            res?.upsert_bulk_permission_leave_break_settings_user?.message,
            false
          );
        }
      },
      onError(error) {
        // do something
        if (error?.graphQLErrors?.[0]?.extensions?.validation) {
          dispatch(
            onFormResetAction(
              "employeeLeaveProfileServerValidation",
              error?.graphQLErrors?.[0]?.extensions?.validation
            )
          );
          return;
        }
        if (error?.graphQLErrors?.[0]?.extensions?.reason) {
          showToast(
            "error",
            error?.graphQLErrors?.[0]?.extensions?.reason,
            false
          );
          return;
        }
      },
    });

  const handleSubmit = () => {
    reset();
    HelperFns.checkPassword(
      "defaut_warning_messsage",
      "",
      t(`Confirm, bulk edit Leave Break Profile for`, {
        count: props?.selectedEmployees?.length,
      }),
      "",
      () =>
        upsertEmployeeLeaveProfile({
          variables: {
            input: {
              permission_leaves_break_setting_id:
                formInput?.permission_leaves_break_setting_id,
              year: +formInput?.year,
              employee_ids: props?.selectedEmployees,
            },
          },
        })
    );
  };

  const { loading, error, data } = useQuery(
    FETCH_MENU_LEAVE_AND_BREAK_SETTINGS,
    {
      notifyOnNetworkStatusChange: true,
      skip: !props?.isOpen,
    }
  );

  const handleChangeYear = (val, e) => {
    setFormInput((prevState) => ({
      ...prevState,
      year: val?.value || null,
    }));
  };
  const handleChangeLeaveProfile = (val, e) => {
    if (e.name === "main_permission_leaves_break_setting_id") {
      setFormInput((prevState) => ({
        ...prevState,
        main_permission_leaves_break_setting_id: val?.id || null,
        permission_leaves_break_setting_id: val?.id || null,
      }));
    } else {
      setFormInput((prevState) => ({
        ...prevState,
        permission_leaves_break_setting_id: val?.id || null,
      }));
    }
  };

  let selectedMainLeave =
    data?.company_leaves_breaks?.data?.find(
      (lv) => lv.id == formInput?.main_permission_leaves_break_setting_id
    ) || {};

  const versionsLeaveBreakOpts = [
    selectedMainLeave,
    ...(selectedMainLeave?.children || []),
  ];

  const toggle = () => {
    props?.dismiss();
    reset();
    setFormInput({
      permission_leaves_break_setting_id: null,
      main_permission_leaves_break_setting_id: null,
      year: null,
    });
  };

  return (
    <MainModal
      isOpen={props?.isOpen}
      toggle={toggle}
      className="leaves_breaks_modal"
      btnOnClick={handleSubmit}
      btnSubmitLoading={submitLoading || loading}
      btnOnCancelClick={toggle}
      modalTitle={`${t("Bulk Actions")} - ${t("Edit Attendance")}`}
    >
      <Alert severity="info">
        <AlertTitle>{t("Bulk Edit Attendance")}</AlertTitle>
        <strong>
          {t("Employees will be effected", {
            count: props?.selectedEmployees?.length,
          })}
        </strong>
      </Alert>

      <BSelect
        name="year"
        label="year"
        optionLabel="value"
        optionValue="value"
        options={Constants.YearData}
        keepDefaultStyle
        containerStyle="year-picker my-1"
        icon="calendar"
        bValue={formInput?.year}
        rootStyle="col-md-12 px-0"
        onChange={handleChangeYear}
        validationName="input.year"
        formServerValidation="employeeLeaveProfileServerValidation"
        isDisabled={Boolean(props?.modalData?.id)}
      />
      <div className="row">
        <BSelect
          name="main_permission_leaves_break_setting_id"
          validationName="input.permission_leaves_break_setting_id"
          optionLabel="name"
          optionValue="id"
          options={data?.company_leaves_breaks?.data || []}
          keepDefaultStyle
          containerStyle="year-picker my-1"
          icon="calendar"
          bValue={formInput?.main_permission_leaves_break_setting_id}
          rootStyle="col-md-6"
          onChange={handleChangeLeaveProfile}
          formServerValidation="employeeLeaveProfileServerValidation"
          isLoading={loading}
          label="main"
        />

        <BSelect
          name="permission_leaves_break_setting_id"
          validationName="input.permission_leaves_break_setting_id"
          optionLabel="name"
          optionValue="id"
          options={versionsLeaveBreakOpts || []}
          keepDefaultStyle
          containerStyle="year-picker my-1"
          icon="calendar"
          bValue={formInput?.permission_leaves_break_setting_id}
          rootStyle="col-md-6"
          onChange={handleChangeLeaveProfile}
          formServerValidation="employeeLeaveProfileServerValidation"
          isLoading={loading}
          label="version"
        />
      </div>
    </MainModal>
  );
};

export default AddBulkLeaveBreakProfile;

import {
  SET_CRM_VALIDATION,
  CLEAR_CRM_VALIDATION,
} from "../Actions/CRMActions/types";
import moment from "moment";

const year = new Date().getFullYear();

export const initState = {
  // Leads
  leadClientValidation: [],
  leadServerValidation: {},
  leadStatusClientValidation: [],
  setLeadsAgentClientValidation: [],
  assignLeadsTagsClientValidation: [],

  // Activity
  activityClientValidation: [],
  activityServerValidation: {},

  // Reminders
  reminderClientValidation: [],
  reminderServerValidation: {},

  // Trials
  trialClientValidation: [],
  trialServerValidation: {},

  // Meetings
  meetingClientValidation: [],

  // Contacts
  contactServerValidation: [],

  // Reports
  leadsBreakdownReportReport: {
    to: "",
    from: "",
    minNo: null,
    maxNo: null,
    source: [],
    tagIds: [],
    status: [],
    sub_status: null,
    country: null,
    campaign: null,
    assigned_to: null,
    last_activity_at: "",
  },
  pipelineReportFilters: {
    view: "custom",
    fromMonth: { key: "01", value: "January" },
    toMonth: { key: "12", value: "December" },
    fromQuarter: { id: 1, name: "Q1" },
    toQuarter: { id: 4, name: "Q4" },
    fromYear: { id: year, name: year },
    toYear: { id: year, name: year },
    from: moment(),
    to: moment(),
  },
  agentPipelineReportFilters: {
    agents: [],
    type: "active",
    view: "custom",
    fromMonth: { key: "01", value: "January" },
    toMonth: { key: "12", value: "December" },
    fromQuarter: { id: 1, name: "Q1" },
    toQuarter: { id: 4, name: "Q4" },
    fromYear: { id: year, name: year },
    toYear: { id: year, name: year },
    from: moment(),
    to: moment(),
  },
  agentLeadsStatusReport: {
    agents: [],
    to: moment().endOf("month"),
    from: moment().startOf("month"),
  },
  agentActivitiesReport: {
    agents: [],
    to: moment().endOf("month"),
    from: moment().startOf("month"),
  },
  agentOverdueActivitiesReport: {
    agents: [],
    ex_days: { id: 2, name: 2 },
    to: moment().endOf("month"),
    from: moment().startOf("month"),
  },
  agentActionsReport: {
    agents: [],
    ex_days: { id: 2, name: 2 },
    to: moment().endOf("month"),
    from: moment().startOf("month"),
  },
};

const CRMReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_CRM_VALIDATION: {
      return {
        ...state,
        [action.payload.ref]: action.payload.validations,
      };
    }
    case CLEAR_CRM_VALIDATION: {
      return {
        ...state,
        [action.payload.ref]: initState[action.payload.ref],
      };
    }

    default:
      return state;
  }
};

export default CRMReducer;

import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { connect } from "react-redux";
import { dismissEditRoleAction, upsertRoleAction } from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader";
import { InputForm } from "../../Builder/Form";

import { useParams } from "react-router-dom";
import RolePrivilegesTreeView from "../PlanRoleModal/RolePrivilegesTreeView";

const formName = "roleForm";
const formNameValidation = "roleFormValidation";
const formServerValidation = "roleServerValidation";

const EditRoleModal = (props) => {
  const { t } = useTranslation();
  const { planId } = useParams();
  const isModalVissible = props.modalData.isEditVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const handleSubmitBtn = () => {
    setFormSubmitting(true);
    if (props.roleFormValidation.length) {
      return;
    }

    props.upsertRoleAction({
      plan_id: planId,
      privileges: props.managePrivilagesModal.main,
      PrivilegesWithScopeAll: props.managePrivilagesModal.all,
      PrivilegesWithScopeManaged: props.managePrivilagesModal.managed,
      name: props.managePrivilagesModal.name,
      id: props.managePrivilagesModal.id,
      isNewPlan: props.isNewPlan,
    });
  };

  const [formSubmitting, setFormSubmitting] = useState(false);

  const formProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting,
  };

  const toggleModal = () => {
    props.dismissEditRoleAction();
  };

  useEffect(() => {
    setFormSubmitting(false);
  }, [isModalVissible]);

  if (props?.loadingEditRolePrivileges) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }
  return (
    <MainModal
      isOpen={isModalVissible}
      btnLabelCancel="Cancel"
      btnOnCancelClick={toggleModal}
      modalTitle={`${t("edit Role Privileges")} - ${
        props.isNewPlan ? props.newPlan?.name : props.viewedPlan?.name
      } `}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="manage_privilages_modal_wrapper_style"
    >
      <div>
        <InputForm
          {...formProps}
          name="name"
          reducer="admin"
          inputContainerStyle="w-100"
          validateBy="textRequired"
          placeholder={t("name")}
          validationName="input.name"
        />
        <RolePrivilegesTreeView parents={JSON.parse(props?.plan_privileges)} />
        <div className="warnig-msg-style">
          {props?.roleServerValidation?.["input.privileges"]?.toString() ?? ""}
          <br />
          {props?.roleServerValidation?.["input.plan_id"]?.toString() ?? ""}
        </div>

        {/* (Start) Error Message */}
        {modalMessage && formSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  managePrivilagesModal: state.admin[formName],
  roleFormValidation: state.admin?.roleFormValidation,
  roleServerValidation: state.admin?.roleServerValidation,
  modalData: state.admin?.systemRolePlanActions,
  loadingEditRolePrivileges: state.admin?.fetchEditRole,
  plan_privileges: state.admin?.plan_privileges,
  newPlan: state.admin.newPlanRoles,
  viewedPlan: state.admin.viewedPlan,
});

export default connect(mapStateToProps, {
  dismissEditRoleAction,
  upsertRoleAction,
})(EditRoleModal);

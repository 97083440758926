import Types from "../types";

// Start of edit check ins modal actions
export const showEditCheckInsModalAction = (data) => {
  return {
    type: Types.SHOW_EDIT_CHECK_INS_MODAL,
    payload: data,
  };
};

export const dismissEditCheckInsModalAction = (data) => {
  return {
    type: Types.HIDE_EDIT_CHECK_INS_MODAL,
    payload: data,
  };
};

export const updateCheckInsAction = ({ data }) => ({
  type: Types.UPDATE_CHECK_INS_ATTEMPT,
  payload: {
    ...data,
  },
});

export const updateCheckInsSuccess = (ref) => ({
  type: Types.UPDATE_CHECK_INS_SUCCESS,
  payload: { ref },
});

export const updateCheckInsFailed = (error) => ({
  type: Types.UPDATE_CHECK_INS_FAILED,
  payload: {
    ...error,
  },
});
// End of edit check ins modal actions

// Start of check in and out locations options actions
export const fetchCheckInOutsLocationsOptionsAction = () => {
  return {
    type: Types.FETCH_CHECK_OUT_LOCATIONS_OPTIONS,
  };
};

export const fetchCheckInOutsLocationsOptionsSuccess = (data) => {
  return {
    type: Types.FETCH_CHECK_OUT_LOCATIONS_OPTIONS_SUCCESS,
    payload: data,
  };
};

export const fetchCheckInOutsLocationsOptionsFailed = () => {
  return {
    type: Types.FETCH_CHECK_OUT_LOCATIONS_OPTIONS_FAILED,
  };
};
// End of check in and out locations options actions

// Start of View check ins/outs locations modal actions
export const showViewCheckInsOutsLocationModalAction = (data) => {
  return {
    type: Types.SHOW_VIEW_CHECK_INS_OUTS_LOCATIONS_MODAL,
    payload: data,
  };
};

export const dismissViewCheckInsOutsLocationModalAction = (data) => {
  return {
    type: Types.HIDE_VIEW_CHECK_INS_OUTS_LOCATIONS_MODAL,
    payload: data,
  };
};
// End of View check ins/outs locations modal actions

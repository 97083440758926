// handle update check ins actions
const SHOW_EDIT_CHECK_INS_MODAL = "SHOW_EDIT_CHECK_INS_MODAL";
const HIDE_EDIT_CHECK_INS_MODAL = "HIDE_EDIT_CHECK_INS_MODAL";
const UPDATE_CHECK_INS_ATTEMPT = "UPDATE_CHECK_INS_ATTEMPT";
const UPDATE_CHECK_INS_SUCCESS = "UPDATE_CHECK_INS_SUCCESS";
const UPDATE_CHECK_INS_FAILED = "UPDATE_CHECK_INS_FAILED";

// to fetch check in and out locations options actions
const FETCH_CHECK_OUT_LOCATIONS_OPTIONS = "FETCH_CHECK_OUT_LOCATIONS_OPTIONS";
const FETCH_CHECK_OUT_LOCATIONS_OPTIONS_SUCCESS =
  "FETCH_CHECK_OUT_LOCATIONS_OPTIONS_SUCCESS";
const FETCH_CHECK_OUT_LOCATIONS_OPTIONS_FAILED =
  "FETCH_CHECK_OUT_LOCATIONS_OPTIONS_FAILED";

// View check ins/outs locations modal actions
const SHOW_VIEW_CHECK_INS_OUTS_LOCATIONS_MODAL =
  "SHOW_VIEW_CHECK_INS_OUTS_LOCATIONS_MODAL";
const HIDE_VIEW_CHECK_INS_OUTS_LOCATIONS_MODAL =
  "HIDE_VIEW_CHECK_INS_OUTS_LOCATIONS_MODAL";

export default {
  // handle update check ins actions
  SHOW_EDIT_CHECK_INS_MODAL,
  HIDE_EDIT_CHECK_INS_MODAL,
  UPDATE_CHECK_INS_ATTEMPT,
  UPDATE_CHECK_INS_SUCCESS,
  UPDATE_CHECK_INS_FAILED,

  // to fetch check in and out locations options actions
  FETCH_CHECK_OUT_LOCATIONS_OPTIONS,
  FETCH_CHECK_OUT_LOCATIONS_OPTIONS_SUCCESS,
  FETCH_CHECK_OUT_LOCATIONS_OPTIONS_FAILED,

  // View check ins/outs locations modal actions
  SHOW_VIEW_CHECK_INS_OUTS_LOCATIONS_MODAL,
  HIDE_VIEW_CHECK_INS_OUTS_LOCATIONS_MODAL,
};

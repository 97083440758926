import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { DateTimePickerForm, BSelect } from "form-builder";
import { useDispatch, useSelector } from "react-redux";
import { Config } from "../../Config";
import moment from "moment";
import {
  dismissEditCheckInsModalAction,
  updateCheckInsAction,
  fetchCheckInOutsLocationsOptionsAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader";
import { Avatar } from "@mui/material";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const MapComponent = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  const onToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <GoogleMap
      defaultZoom={15}
      center={{
        lat: parseFloat(
          props?.type == "check_in"
            ? props?.editCheckInsModal?.check_in_lat
            : props?.editCheckInsModal?.check_out_lat
        ),
        lng: parseFloat(
          props.type == "check_in"
            ? props?.editCheckInsModal?.check_in_lng
            : props?.editCheckInsModal?.check_out_lng
        ),
      }}
      defaultCenter={{
        lat: parseFloat(
          props?.type == "check_in"
            ? props?.editCheckInsModal?.check_in_lat
            : props?.editCheckInsModal?.check_out_lat
        ),
        lng: parseFloat(
          props.type == "check_in"
            ? props?.editCheckInsModal?.check_in_lng
            : props?.editCheckInsModal?.check_out_lng
        ),
      }}
    >
      <Marker
        position={{
          lat: parseFloat(
            props?.type == "check_in"
              ? props?.editCheckInsModal?.check_in_lat
              : props?.editCheckInsModal?.check_out_lat
          ),
          lng: parseFloat(
            props.type == "check_in"
              ? props?.editCheckInsModal?.check_in_lng
              : props?.editCheckInsModal?.check_out_lng
          ),
        }}
        draggable={false}
        onClick={onToggleOpen}
      ></Marker>
    </GoogleMap>
  );
};

const MapWithAMarker = withScriptjs(withGoogleMap(MapComponent));

const reducer = "checkIns";
const formName = "editCheckInsModal";
const formNameValidation = "editCheckInsModalValidation";
const formServerValidation = "editCheckInsModalServerValidation";

const EditCheckInsModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [faces, setFaces] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Reducer State
  const editCheckInsModal = useSelector((state) => state[reducer][formName]);
  const modalValidation = useSelector(
    (state) => state[reducer][formNameValidation]
  );
  const checkInOutsLocationsOptions = useSelector(
    (state) => state[reducer]?.checkInOutsLocationsOptions
  );
  const fetchLocationsOptionsLoading = useSelector(
    (state) => state[reducer]?.fetchLocationsOptionsLoading
  );
  const modalData = useSelector(
    (state) => state[reducer].editCheckInsModalActions
  );

  /* ↓ State Effects ↓ */

  useEffect(() => {
    setIsSubmitting(false);
    // to fetch locations options
    if (modalData?.isVissible) {
      dispatch(fetchCheckInOutsLocationsOptionsAction());
    }
  }, [modalData?.isVissible]);

  /* ↓ Helpers ↓ */

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
    reducer,
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    if (modalValidation.length) {
      return;
    }

    dispatch(
      updateCheckInsAction({
        data: editCheckInsModal,
      })
    );
  };

  const toggleModal = () => {
    dispatch(
      dismissEditCheckInsModalAction({
        ref: "editCheckInsModalActions",
        formName,
      })
    );
  };

  if (fetchLocationsOptionsLoading) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }

  return (
    <MainModal
      isOpen={modalData?.isVissible}
      toggle={toggleModal}
      modalTitle={t("Edit Check-in")}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={modalData?.isLoading}
      className="edit_check_in_form_wrapper_style"
    >
      <div>
        <div className="row mb-2">
          <div className="col-12">
            <span className="mr-2 sec-color">{editCheckInsModal?.name}</span>
            <span>
              {moment(editCheckInsModal?.date).format("dddd DD-MM-YYYY")}
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <DateTimePickerForm
              {...FormProps}
              label="Check In"
              labelStyle="mb-2"
              name="check_in_date_time"
              validationName={`input.check_in_date_time`}
              containerStyle="mb-2"
              datePickerContainer="w-100"
              hasIcon
              dateTime
              requestFormat="yyyy-MM-DD HH:mm:ss"
              format="DD/MM/yyyy hh:mm A"
              use12Hours={true}
            />
          </div>

          <div className="col-12 col-md-6">
            <DateTimePickerForm
              {...FormProps}
              label="Check out"
              labelStyle="mb-2"
              name="check_out_date_time"
              validationName={`input.check_out_date_time`}
              containerStyle="mb-2"
              datePickerContainer="w-100"
              hasIcon
              dateTime
              requestFormat="yyyy-MM-DD HH:mm:ss"
              format="DD/MM/yyyy hh:mm A"
              use12Hours={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <BSelect
              {...FormProps}
              label="check-in location"
              labelStyle="mb-2"
              name="check_in_space_id"
              validationName={`input.check_in_space_id`}
              optionLabel="name"
              optionValue="id"
              options={checkInOutsLocationsOptions}
              placeholder={t("select option")}
              keepDefaultStyle
              containerStyle=" "
            />
          </div>

          <div className="col-12 col-md-6">
            <BSelect
              {...FormProps}
              label="check-out location"
              labelStyle="mb-2"
              name="check_out_space_id"
              validationName={`input.check_out_space_id`}
              optionLabel="name"
              optionValue="id"
              options={checkInOutsLocationsOptions}
              placeholder={t("select option")}
              keepDefaultStyle
              containerStyle=" "
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="col-6">
            <label className="my-2">{t("check-in notes")}</label>
            <div className="check_in_description_style">
              {editCheckInsModal?.check_in_description}
            </div>
          </div>

          <div className="col-6">
            <label className="my-2">{t("check-out notes")}</label>
            <div className="check_in_description_style">
              {editCheckInsModal?.check_out_description}
            </div>
          </div>
        </div>

        <div className="row">
          {editCheckInsModal?.check_in_lat && (
            <div
              className={
                editCheckInsModal?.check_in_lat ? "col mt-2" : "col-12 mt-2"
              }
            >
              <div className="position-relative" style={{ height: "250px" }}>
                <MapWithAMarker
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Config.googleMapKey}`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={
                    <div style={{ height: `250px`, width: `100%` }} />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                  type="check_in"
                  editCheckInsModal={editCheckInsModal}
                />

                {editCheckInsModal.checkInFace ? (
                  <div>
                    <Avatar
                      src={editCheckInsModal.checkInFace.path}
                      className="avatar blackish-avatar position-absolute"
                      style={{
                        width: 62.5,
                        height: 62.5,
                        bottom: 10,
                        left: 10,
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          )}

          {editCheckInsModal?.check_out_lat && (
            <div
              className={
                editCheckInsModal?.check_out_lat ? "col mt-2" : "col-12 mt-2"
              }
            >
              <div className="position-relative" style={{ height: "250px" }}>
                <MapWithAMarker
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Config.googleMapKey}`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={
                    <div style={{ height: `250px`, width: `100%` }} />
                  }
                  mapElement={<div style={{ height: `100%` }} />}
                  type="check_out"
                  editCheckInsModal={editCheckInsModal}
                />

                {editCheckInsModal.checkOutFace ? (
                  <Avatar
                    src={editCheckInsModal.checkOutFace.path}
                    className="avatar blackish-avatar position-absolute"
                    style={{ width: 62.5, height: 62.5, bottom: 10, left: 10 }}
                  />
                ) : null}
              </div>
            </div>
          )}
        </div>

        {/* (Start) Error Message */}
        {modalData?.modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalData?.modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

export default EditCheckInsModal;

import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { InputForm } from "form-builder";
import { StandaloneSearchBox } from "react-google-maps/lib/components/places/StandaloneSearchBox";
import { withScriptjs } from "react-google-maps";

// Actions
import {
  onInputChangeAction,
  updatePositionLocationAction,
} from "../../Store/Actions";

const PlacesInput = (props) => {
  const { t } = useTranslation();
  const searchBoxRef = useRef(null);

  //   redux hooks
  const dispatch = useDispatch();

  const onPlacesChanged = () => {
    const place = searchBoxRef.current.getPlaces()[0];

    const nameEvent = {
      target: {
        name: props.name,
        value: place?.name,
      },
    };

    dispatch(onInputChangeAction(props?.formProps?.formName, nameEvent));
    dispatch(
      updatePositionLocationAction(
        props?.formProps?.formName,
        place?.geometry?.location?.lng().toString(),
        place?.geometry?.location?.lat().toString()
      )
    );
  };

  return (
    <StandaloneSearchBox ref={searchBoxRef} onPlacesChanged={onPlacesChanged}>
      <InputForm
        {...props?.formProps}
        //validateBy="textRequired"
        name={props.name}
        type="text"
        label={props.name}
        placeholder={t(props.name)}
        labelStyle="mb-2"
        containerStyle="mt-0 mb-3"
        inputContainerStyle=" "
        icon="map"
      />
    </StandaloneSearchBox>
  );
};

export default withScriptjs(PlacesInput);

import React, { useEffect } from "react";
import { RadioboxForm, InputForm } from "form-builder";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import swal from "sweetalert";

import {
  fetchSystemPlansListAction,
  updatePlanStatusAction,
  deletePlanAction,
  onInputResetAction,
} from "../../Store/Actions";

import Loader from "../../Components/Loader";

import { useTranslation } from "react-i18next";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import AntSwitch from "../../Components/AntSwitch";

import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";

const dataTableRef = `systemPlansList`;

const SystemPlansList = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    props.fetchSystemPlansListAction({
      ref: dataTableRef,
      [dataTableRef]: props.data[dataTableRef],
    });

    return () => {
      props.onInputResetAction(dataTableRef, "type");
      props.onInputResetAction(dataTableRef, "name");
    };
  }, []);

  const handleFilter = () => {
    props.fetchSystemPlansListAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
    });
  };

  // eslint-disable-next-line no-unused-vars
  const onFilterChange = useDidUpdateEffect(handleFilter, [
    props?.data[dataTableRef]?.type,
    props?.data[dataTableRef]?.name,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    props.fetchSystemPlansListAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };

  const handleDeletePlan = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deletePlanAction(row?.id, true);
      }
    });
  };

  const handleUpdatePlanStatus = (row, active) => {
    props.updatePlanStatusAction(row.id, active ? "active" : "inactive", true);
  };

  const handleNewPlan = () => {
    history.push("/admin-panel/system-plans/new");
  };

  const columns = [
    {
      name: t("name"),
      wrap: true,
      selector: "name",
      grow: 1,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[
            Privilages.SUPER_PRIVILEGE,
            Privilages.VIEW_PLAN_PROFILE,
          ]}
          altChildren={<span>{row?.name}</span>}
        >
          <Link to={`/admin-panel/system-plans/view-plan/${row.id}`}>
            {row?.name}
          </Link>
        </HasPrivileges>
      ),
    },
    {
      name: t("type"),
      selector: "trial_value",
      wrap: true,
      center: true,

      grow: 1,
      cell: (row) => (row?.trial_value !== null ? t("Paid") : t("Free")),
    },
    {
      name: t("free trial period"),
      selector: "",
      wrap: true,
      center: true,
      grow: 1,

      cell: (row) => (!!row?.trial_value ? row?.trial_value : "_________"),
    },
    {
      name: t("active subscribers"),
      selector: "activeSubscribtions",
      wrap: true,
      center: true,
      grow: 1,
    },
    {
      name: t("active"),
      selector: "status",
      wrap: true,
      center: true,
      grow: 1,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[
            Privilages.SUPER_PRIVILEGE,
            Privilages.ACTIVATE_DEACTIVATE_PLANS,
          ]}
          altChildren={
            <AntSwitch
              inputProps={{ "aria-label": "Switch demo" }}
              checked={row?.status?.toLowerCase() === "active"}
              // onChange={(e, val) => handleUpdatePlanStatus(row, val)}
              color="primary"
              disabled
            />
          }
        >
          <AntSwitch
            inputProps={{ "aria-label": "Switch demo" }}
            checked={row?.status?.toLowerCase() === "active"}
            onChange={(e, val) => handleUpdatePlanStatus(row, val)}
            color="primary"
          />
        </HasPrivileges>
      ),
    },
    {
      name: "",
      wrap: true,
      grow: 1,
      ignoreRowClick: true,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[
            Privilages.SUPER_PRIVILEGE,
            Privilages.ADD_EDIT_DELETE_PLANS,
          ]}
        >
          <div className="cards_table_actions">
            <EditIconButton
              onClick={() =>
                history.push(`/admin-panel/system-plans/edit-plan/${row.id}`)
              }
            />
            <RemoveIconButton onClick={() => handleDeletePlan(row)} />
          </div>
        </HasPrivileges>
      ),
    },
  ];

  return (
    <div className="col-md-12 mt-3 attendance-profile-list px-0">
      <div className="col pl-0 row align-items-baseline justify-content-between pr-0 mx-0">
        <div className="flex-wrap col-12 d-flex flex-column flex-md-row align-items-start row p-0 m-0">
          <RadioboxForm
            formName={dataTableRef}
            containerStyle="mb-1 col-12 col-md-4"
            optionsContainerStyle=""
            // optionItemStyle="mx-3"
            name="type"
            optionInputStyle
            optionItemStyle="form-check pl-0 pr-3"
            reducer="admin"
            options={[
              {
                label: "active",
                value: "active",
              },
              {
                label: "inactive",
                value: "inactive",
              },
              {
                label: "all",
                value: null,
              },
            ]}
          />
          <InputForm
            name="name"
            reducer="admin"
            formName={dataTableRef}
            // label={<FontAwesomeIcon icon={faSearch} />}
            labelStyle="focus-label"
            placeholder={t("search")}
            rootStyle="flex-fill col-12 col-md-6 p-0"
            inputStyle="form-control search_field_style "
            containerStyle="col-md-12 pr-0"
            inputContainerStyle="form-group d-flex "
          />
          <div className="d-flex flex-row justify-content-end pl-3 ml-auto">
            <HasPrivileges
              reqireMain={[
                Privilages.SUPER_PRIVILEGE,
                Privilages.ADD_EDIT_DELETE_PLANS,
              ]}
            >
              <button
                type="button"
                className={`btn py-1 px-3 add_new_btn_style `}
                onClick={handleNewPlan}
              >
                {t("add new")}
              </button>
            </HasPrivileges>
          </div>
        </div>
      </div>

      {props?.planActionLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <DataTable
        className="cards_table"
        columns={columns}
        data={props.data[dataTableRef].data}
        noHeader
        persistTableHead
        paginationComponent={() => (
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
            reducer="admin"
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={props.data[dataTableRef].isLoading}
        progressComponent={<Loader />}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.admin,
    planActionLoading: state.admin.planActionLoading,
  };
};

export default connect(mapStateToProps, {
  fetchSystemPlansListAction,
  updatePlanStatusAction,
  deletePlanAction,
  onInputResetAction,
})(SystemPlansList);

import Types from "../types";

// Office
export const updateWizardOfficeAction = (data) => ({
  type: Types.CREATE_WIZARD_OFFICE_ATTEMPT,
  payload: data,
});
export const updateWizardOfficeSuccess = (ref) => ({
  type: Types.CREATE_WIZARD_OFFICE_SUCCESS,
  payload: { ref },
});
export const updateWizardOfficeFailed = (ref, message) => ({
  type: Types.CREATE_WIZARD_OFFICE_FAILED,
  payload: { ref, message },
});

// Departments
export const createDepartmentsAttempt = (departments) => {
  return {
    type: Types.CREATE_DEPARTMENTS_ATTEMPT,
    payload: departments,
  };
};
export const createDepartmentsSuccess = () => {
  return {
    type: Types.CREATE_DEPARTMENTS_SUCCESS,
  };
};
export const createDepartmentsFailed = () => {
  return {
    type: Types.CREATE_DEPARTMENTS_FAILED,
  };
};

// Positions
export const createPositionsAttempt = (positions) => {
  return {
    type: Types.CREATE_POSITIONS_ATTEMPT,
    payload: positions,
  };
};
export const createPositionsSuccess = () => {
  return {
    type: Types.CREATE_POSITIONS_SUCCESS,
  };
};
export const createPositionsFailed = () => {
  return {
    type: Types.CREATE_POSITIONS_FAILED,
  };
};

// Work Timings
export const upsertSetupWizardWorkTimingAttempt = (workTimingForm) => {
  return {
    type: Types.UPSERT_SETUP_WIZARD_WORK_TIMING_ATTEMPT,
    payload: { workTimingForm },
  };
};
export const upsertSetupWizardWorkTimingSuccess = () => {
  return {
    type: Types.UPSERT_SETUP_WIZARD_WORK_TIMING_SUCCESS,
  };
};
export const upsertSetupWizardWorkTimingFailed = (errors) => {
  return {
    type: Types.UPSERT_SETUP_WIZARD_WORK_TIMING_FAILED,
    payload: { errors },
  };
};

// Att Profile
// export const upsertSetupWizardAttendanceProfileAction = (data) => {
//   return {
//     type: Types.UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_ATTEMPT,
//     payload: {
//       normal: data.normal,
//       firstHalf: data.firstHalf,
//       secondHalf: data.secondHalf,
//     },
//   };
// };
export const upsertSetupWizardAttendanceProfileSuccess = () => ({
  type: Types.UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_SUCCESS,
});
export const upsertSetupWizardAttendanceProfileFailed = (serverError = {}) => {
  return {
    type: Types.UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_FAILED,
    payload: serverError,
  };
};

// Fetch
export const fetchSetupWizardEssentialDataAttempt = (cb = null) => {
  return {
    type: Types.FETCH_SETUP_WIZARD_ESSENTIAL_DATA_ATTEMPT,
    cb,
  };
};
export const EditSetupWizardEssentialData = (key) => {
  return {
    type: Types.EDIT_SETUP_WIZARD_ESSENTIAL_DATA,
    payload: key,
  };
};
export const fetchSetupWizardEssentialDataSuccess = (data) => {
  return {
    type: Types.FETCH_SETUP_WIZARD_ESSENTIAL_DATA_SUCCESS,
    payload: data,
  };
};
export const fetchSetupWizardEssentialDataFailed = () => {
  return {
    type: Types.FETCH_SETUP_WIZARD_ESSENTIAL_DATA_FAIL,
  };
};

// Allow Shifts
export const setWizardAttType = (payload) => ({
  type: Types.SET_WIZARD_ATT_TYPE,
  payload,
});

// Tabs
export const setSetupWizardActiveTab = (activeTab) => {
  return {
    type: Types.SET_SETUP_WIZARD_ACTIVE_TAB,
    payload: activeTab,
  };
};
export const setSetupWizardRemainingSteps = (payload) => {
  return {
    type: Types.SET_SETUP_WIZARD_REMAINING_STEPS,
    payload,
  };
};

import React from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import { Add } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import SvgIcon from "@mui/material/SvgIcon";
import MuiButton from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const handleColorStyles = (variant, color) => {
  switch (variant) {
    case "contained":
      return {
        color: "#fff !important",
        backgroundColor: color,
        "&:hover": {
          backgroundColor: color,
        },
      };
    case "outlined":
      return {
        color,
        borderColor: color,
        "&:hover": {
          borderColor: color,
        },
      };

    default:
      break;
  }
};

/**
 *
 * start of ColorButton
 *
 */

const ColorButtonPropTypes = {
  sx: PropTypes.object,
  icon: PropTypes.element,
  color: PropTypes.string,
  label: PropTypes.string,
  prefix: PropTypes.string,
  children: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  isIconOnly: PropTypes.bool,
  loadingButton: PropTypes.bool,
  disabledLabel: PropTypes.string,
  variant: PropTypes.oneOf(["text", "outlined", "contained"]),
};

/**
 * @typedef ColorButton
 * @type {React.FC<PropTypes.InferProps<ColorButtonPropTypes>>}
 * @returns {React.ReactElement}
 */
export const ColorButton = ({
  children,
  isIconOnly,
  loadingButton,
  disabledLabel = "",
  prefix = "",
  label = "",
  icon,
  sx,
  color = "#2764ac",
  ...props
}) => {
  const { t } = useTranslation();

  // Constants
  const title = t(children || `${prefix}${prefix ? " " + label : label}`);
  const renderTooltip = isIconOnly || (props?.disabled && disabledLabel);
  const Wrapper = renderTooltip ? Tooltip : React.Fragment;
  const Button = loadingButton ? LoadingButton : MuiButton;

  return (
    <Wrapper
      {...(renderTooltip && {
        arrow: true,
        title: props?.disabled ? disabledLabel : title,
      })}
    >
      <Button
        size="small"
        disableElevation
        disableTouchRipple
        variant="contained"
        startIcon={icon}
        sx={{
          fontWeight: 400,
          textTransform: "capitalize",
          ...(!props?.disabled &&
            handleColorStyles(props?.variant || "contained", color)),
          ...(isIconOnly && {
            p: 1,
            width: 15,
            height: 15,
            minWidth: "initial",
            ".MuiButton-startIcon": { m: 0 },
          }),
          ...(props?.disabled &&
            disabledLabel && { pointerEvents: "all !important" }),
          ...sx,
        }}
        {...props}
      >
        {!isIconOnly ? title : null}
      </Button>
    </Wrapper>
  );
};

ColorButton.propTypes = ColorButtonPropTypes;

/**
 *
 * end of ColorButton
 *
 * start of AddButton
 *
 */

/** @type {ColorButton} */
export const AddButton = ({ children, ...props }) => (
  <ColorButton
    prefix="add"
    label="new"
    color="#2764ac"
    icon={
      <Add
        fontSize="small"
        sx={props?.isIconOnly ? { fontSize: "12px !important" } : {}}
      />
    }
    {...props}
  >
    {children}
  </ColorButton>
);

/**
 *
 * end of AddButton
 *
 * start of ExportButton
 *
 */

/** @type {ColorButton} */
export const ExportButton = ({ children, sx, ...props }) => (
  <ColorButton
    color="#313030"
    icon={
      <SvgIcon
        sx={{
          fontSize: props?.isIconOnly ? "15px !important" : "14px !important",
        }}
      >
        <FontAwesomeIcon icon="fas fa-file-export" />
      </SvgIcon>
    }
    sx={{
      ...(props?.isIconOnly && {
        p: 1,
        width: 35,
        height: 30,
        minWidth: "initial",
        ".MuiButton-startIcon": { m: 0 },
      }),
      ...sx,
    }}
    {...props}
  >
    {children || "Export"}
  </ColorButton>
);

/**
 *
 * end of ExportButton
 *
 * start of FilterButton
 *
 */

export const FilterButton = ({ children, sx, ...props }) => (
  <ColorButton
    label="filters"
    color="#009efb"
    variant="outlined"
    icon={
      <SvgIcon
        sx={{
          fontSize: props?.isIconOnly ? "15px !important" : "14px !important",
        }}
      >
        <FontAwesomeIcon icon="fas fa-sliders-h" />
      </SvgIcon>
    }
    sx={{
      ...(props?.isIconOnly && {
        p: 1,
        width: 35,
        height: 30,
        minWidth: "initial",
        ".MuiButton-startIcon": { m: 0 },
      }),
      ...sx,
    }}
    {...props}
  />
);

/**
 *
 * end of FilterButton
 *
 * start of
 *
 */

import React, { useState } from "react";
import { InputForm, RadioboxForm } from "form-builder";
import { useTranslation } from "react-i18next";
import { AddButton } from "../../Components/Buttons";
import CostCenterForm from "./CostCenterForm";
import DataTable from "react-data-table-component";
import Loader from "../../Components/Loader";
import { IconButton, Switch } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import Swal from "sweetalert2";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_COST_CENTER, GET_COST_CENTERS } from "../../Graphql/query";
import { useDispatch } from "react-redux";
import {
  onFormResetAction,
  toggleEditingCostCenterAllowanceConfigs,
} from "../../Store/Actions";
import { showToast } from "../../Helpers/HelperFns";
import ExpandedCostCenter from "./ExpandedCostCenter";
import {
  DELETE_COST_CENTER,
  TOGGLE_COST_CENTER_STATUS,
} from "../../Graphql/mutation";
import Pagination from "../../Components/Pagination";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";

const formProps = {
  formName: "costCenterForm",
  formNameValidation: "costCenterFormNameValidation",
  formNameServerValidation: "costCenterFormNameServerValidation",
};

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const CostCentersList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("all");
  const [pagination, setPagination] = useState(paginationInitState);

  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleChangeStatus = (e) => {
    setStatus(e?.target?.value);
  };

  const {
    data: costCenterQueryData,
    loading: costCentersLoading,
    refetch,
  } = useQuery(GET_COST_CENTERS, {
    variables: {
      name: search,
      page: pagination?.currentPage ?? 1,
      ...(status != "all"
        ? {
            isActive: status == "active",
          }
        : {}),
    },
    onCompleted: (data) => {
      setPagination({ ...data?.cost_centers?.paginatorInfo });
    },
  });

  const costCenters = costCenterQueryData?.cost_centers?.data ?? [];

  const [attemptGetCostCenter, { data: costCenterDetailsData }] =
    useLazyQuery(GET_COST_CENTER);

  const costCenterDetails = costCenterDetailsData?.cost_center ?? {};

  const [attemptDeleteCostCenter] = useMutation(DELETE_COST_CENTER, {
    onCompleted: () => {
      refetch();
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
      );
    },
  });

  const handleSearchChange = (e) => {
    setSearch(e?.target?.value);
  };

  const handleAddNewCostCenter = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    refetch();
    dispatch(onFormResetAction(formProps.formName));
    dispatch(onFormResetAction(formProps.formNameValidation));
    dispatch(onFormResetAction(formProps.formNameServerValidation));
    dispatch(toggleEditingCostCenterAllowanceConfigs(false));
  };

  const handleDelete = (id) => {
    Swal.fire({
      icon: "warning",
      text: t("defaut_warning_messsage"),
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      customClass: {
        popup: "swal-warning-style",
        icon: "swal-icon",
        denyButton: "order-1",
        confirmButton: "order-2",
      },
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        attemptDeleteCostCenter({
          variables: {
            id,
          },
        });
      }
    });
  };

  const handleEdit = (id) => {
    attemptGetCostCenter({
      variables: {
        id,
      },
      onCompleted: (data) => {
        dispatch(
          onFormResetAction(formProps.formName, { ...data?.cost_center })
        );
        setIsModalOpen(true);
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
        );
      },
    });
  };

  const [attemptToggleCostCenterStatus] = useMutation(
    TOGGLE_COST_CENTER_STATUS
  );

  const handleToggleCostCenterStatus = (costCenterId) => {
    attemptToggleCostCenterStatus({
      variables: {
        id: +costCenterId,
      },
      onCompleted: () => {
        refetch();
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
        );
      },
    });
  };

  const columns = [
    {
      selector: "name",
      grow: 5,
    },
    {
      grow: 5,
      cell: (costCenter) => {
        return (
          <Switch
            checked={!!costCenter?.is_active}
            onChange={() => handleToggleCostCenterStatus(costCenter?.id)}
            classes={{ switchBase: "switchBase" }}
          />
        );
      },
    },
    {
      cell: (row) => {
        return row?.canEdit || row?.canDelete ? (
          <div className="d-flex align-items-center justify-content-end w-100 gap-5">
            {row?.canEdit ? (
              <IconButton aria-label="edit" onClick={() => handleEdit(row?.id)}>
                <Edit color="success" />
              </IconButton>
            ) : null}
            {row?.canDelete ? (
              <IconButton
                aria-label="delete"
                onClick={() => handleDelete(row?.id)}
              >
                <Delete color="warning" />
              </IconButton>
            ) : null}
          </div>
        ) : null;
      },
    },
  ];

  return (
    <div>
      <div className="my-3 d-flex align-items-center justify-content-between w-100 gap-10">
        <InputForm
          name="search"
          placeholder={t("search")}
          onChange={handleSearchChange}
          value={search}
          containerStyle="w-100"
          rootStyle="w-100"
          inputStyle="p-2"
          icon="search"
        />

        <HasPrivileges
          allowBP
          reqireMain={[Privilages.ADD_EDIT_DELETE_COST_CENTER]}
        >
          <AddButton onClick={handleAddNewCostCenter} className="text-nowrap" />
        </HasPrivileges>
      </div>

      <RadioboxForm
        options={[
          { label: "All", value: "all" },
          { label: "active", value: "active" },
          { label: "inactive", value: "inactive" },
        ]}
        containerStyle="mt-2"
        labelStyle="d-flex mb-3"
        optionsContainerStyle="penalty-days-style fit-width flex-md-row flex-column w-100 "
        optionItemStyle="d-flex alignbaseline-center"
        optionLabelStyle="mb-0 mr-3"
        optionInputStyle=" "
        onChange={handleChangeStatus}
        value={status}
      />

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        responsive={true}
        data={costCenters}
        noHeader
        noTableHead
        persistTableHead
        paginationComponent={() => (
          <Pagination
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={costCentersLoading}
        progressComponent={<Loader />}
        customStyles={{ tableWrapper: { style: { minWidth: 1100 } } }}
        expandableRows={true}
        expandableRowsComponent={
          <ExpandedCostCenter
            handleOpenModal={handleAddNewCostCenter}
            refetch={refetch}
          />
        }
      />

      {isModalOpen ? (
        <CostCenterForm
          isOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          formProps={formProps}
        />
      ) : null}
    </div>
  );
};

export default CostCentersList;

import Types from "./types";

//Start Request Rules List

export const fetchRequestRulesListAction = (args) => {
  return {
    type: Types.FETCH_REQUEST_RULES_LIST_ATTEMPT,
    payload: { ...args },
  };
};

export const fetchRequestRulesListSuccess = (ref, list) => {
  return {
    type: Types.FETCH_REQUEST_RULES_LIST_SUCCESS,
    payload: {
      ref,
      list,
    },
  };
};

export const fetchRequestRulesListFailed = (ref) => {
  return {
    type: Types.FETCH_REQUEST_RULES_LIST_FAILED,
    payload: {
      ref,
    },
  };
};

//End Request Rules List

//Start Request Rules

export const upsertRequestRulesAction = (args) => {
  return {
    type: Types.UPSERT_REQUEST_RULES_ATTEMPT,
    payload: { ...args },
  };
};

export const upsertRequestRulesSuccess = () => {
  return {
    type: Types.UPSERT_REQUEST_RULES_SUCCESS,
  };
};

export const upsertRequestRulesFailed = (error = {}) => {
  return {
    type: Types.UPSERT_REQUEST_RULES_FAILED,
    payload: error,
  };
};

export const deleteRequestRuleAction = (id) => {
  return {
    type: Types.DELETE_REQUEST_RULES_ATTEMPT,
    payload: { id },
  };
};

export const deleteRequestRuleSuccess = () => {
  return {
    type: Types.DELETE_REQUEST_RULES_SUCCESS,
  };
};

export const deleteRequestRulesFailed = (error = {}) => {
  return {
    type: Types.DELETE_REQUEST_RULES_FAILED,
  };
};

//End Request Rules

//Start Request Rules Modal
export const showRequestRuleAction = (data = null) => {
  return {
    type: Types.SHOW_REQUEST_RULE_MODAL,
    payload: data,
  };
};

export const dismissRequestRuleAction = () => {
  return {
    type: Types.DISMISS_REQUEST_RULE_MODAL,
  };
};

export const fetchRequestRulesUsersOptsSuccess = (usersOptions) => {
  return {
    type: Types.FETCH_REQUEST_RULES_USERS_OPTS_SUCCESS,
    payload: {
      usersOptions,
    },
  };
};

export const fetchRequestRulesUsersOptsFailed = () => {
  return {
    type: Types.FETCH_REQUEST_RULES_USERS_OPTS_FAILED,
  };
};

export const fetchRequestRulesOptsAction = () => {
  return {
    type: Types.FETCH_REQUEST_RULES_OPTS_ATTEMPT,
  };
};

export const fetchDepartmentsSuccess = (departmentsOptions) => {
  return {
    type: Types.FETCH_DEPARTMENTS_OPTS_SUCCESS,
    payload: {
      departmentsOptions,
    },
  };
};

export const fetchDepartmentsFailed = () => {
  return {
    type: Types.FETCH_DEPARTMENTS_OPTS_FAILED,
  };
};

//End Request Rules Moda

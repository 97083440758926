import React from "react";
import {
  CheckboxBooleanForm,
  RadioboxForm,
  DateTimePickerForm,
  InputForm,
} from "form-builder";

import { useTranslation } from "react-i18next";
import Constants from "../../../Constants";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { updateValueAction } from "../../../Store/Actions";

const startBreak = [
  { label: "When the employee starts the break", value: "manual" },
  { label: "At the start of each break interval", value: "auto" },
];
const breakType = [
  { label: "Fixed", value: "fixed" },
  { label: "Flexible", value: "flexible" },
];

const WorkTimingBreakSettings = ({ onNext, onBack, ...commonFormProps }) => {
  //   const { commonFormProps } = props;

  const state = useSelector((state) => state.super?.workTimings);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onBreakTypeChange = (e, val) => {
    if (val == "fixed") {
      handleAddBreakInterval();
      dispatch(
        updateValueAction("workTimings", "allow_limit_total_break", true)
      );
    } else {
      dispatch({
        type: "RESET_BREAK_INTERVAL",
      });
    }
  };

  const handleAddBreakInterval = () => {
    dispatch({
      type: "ADD_BREAK_INTERVAL",
      payload: {
        ...commonFormProps,
      },
    });
  };
  const handleDeleteBreakInterval = (index) => {
    dispatch({
      type: "DELETE_BREAK_INTERVAL",
      payload: {
        index,
      },
    });
  };

  const handleChildDateInputChange = (val, name, index) => {
    let value = val ? moment(val).format("HH:mm:ss") : null;
    dispatch({
      type: "UPDATE_BREAK_INTERVAL",
      payload: {
        value,
        name,
        index,
      },
    });
  };

  return (
    <div className="pt-lg-4 pt-1 pb-1">
      <h4 className="my-2 sub-title-style">{t("breaks")}</h4>
      <CheckboxBooleanForm
        setWithValue
        dependOn="sign_in_req"
        dependancyType="equal"
        dependancyValue={[1]}
        {...commonFormProps}
        options={[
          "Override break settings set in the employees' leave and break profile",
        ]}
        name="override_break_setting"
        validationName="input.override_break_setting"
        type="checkbox"
      />

      {state?.override_break_setting ? (
        <>
          <div>
            <CheckboxBooleanForm
              setWithValue
              dependOn="override_break_setting"
              dependancyType="equal"
              dependancyValue={[true]}
              {...commonFormProps}
              options={["Allow Employees To Take Breaks"]}
              name="allow_break"
              validationName="input.allow_break"
              type="checkbox"
            />
            <CheckboxBooleanForm
              setWithValue
              dependOn="allow_break"
              dependancyType="equal"
              dependancyValue={[true]}
              {...commonFormProps}
              options={[
                "Deduct break time from the employee's total work hours",
              ]}
              name="deduct_from_work_hours"
              validationName="input.deduct_from_work_hours"
              type="checkbox"
            />

            <RadioboxForm
              {...commonFormProps}
              options={breakType}
              dependOn="allow_break"
              dependancyType="equal"
              dependancyValue={[true]}
              // validateBy="textRequired"
              labelStyle="mb-2"
              label="Break Time"
              name="break_type"
              validationName="input.break_type"
              optionsContainerStyle="d-flex justify-content-start"
              containerStyle="custom-tardiness-container justify-content-start mt-1 mb-2 flex-column align-items-start"
              optionItemStyle="option-item ml-0 mr-5"
              optionInputStyle=" "
              optionLabelStyle="optionLabelStyle"
              interceptChange={onBreakTypeChange}
            />

            {state?.break_type == "fixed" ? (
              <>
                <div className="align-items-baseline d-flex flex-row gap-20">
                  <h6 className="sub-title-style"> {t("Break Intervals")}</h6>
                  <button
                    className="border-info btn-add btn-info rounded"
                    type="button"
                    onClick={handleAddBreakInterval}
                  >
                    <FontAwesomeIcon icon={faPlus} size="sm" />
                  </button>
                </div>

                {state?.break_interval &&
                  state?.break_interval?.map((interval, index) => {
                    return (
                      <div className="d-flex gap-10 justify-content-between align-items-center my-2">
                        <label>
                          {t("interval")} #{index + 1}
                        </label>

                        <div className="align-items-center col row mx-1 gap-20">
                          <DateTimePickerForm
                            {...commonFormProps}
                            containerStyle="align-items-center d-flex flex-row gap-10"
                            inputStyle="date-picker-input-default rc-picker validity-input-style w-200"
                            timeOnly
                            requestFormat="HH:mm:ss"
                            name={"start"}
                            labelStyle="mr-3"
                            label="start"
                            onChange={(val) =>
                              handleChildDateInputChange(val, "start", index)
                            }
                            onSelect={(val) =>
                              handleChildDateInputChange(val, "start", index)
                            }
                            value={
                              interval?.start
                                ? moment(interval?.start, "HH:mm")
                                : null
                            }
                            validationName={`input.break_work_timing_setting.break_interval.${index}.start`}
                          />

                          <DateTimePickerForm
                            {...commonFormProps}
                            containerStyle="align-items-center d-flex flex-row gap-10"
                            inputStyle="date-picker-input-default rc-picker validity-input-style w-200"
                            timeOnly
                            requestFormat="HH:mm:ss"
                            name="end"
                            labelStyle="mr-3"
                            label="end"
                            onChange={(val) =>
                              handleChildDateInputChange(val, "end", index)
                            }
                            onSelect={(val) =>
                              handleChildDateInputChange(val, "end", index)
                            }
                            value={
                              interval?.end
                                ? moment(interval?.end, "HH:mm")
                                : null
                            }
                            validationName={`input.break_work_timing_setting.break_interval.${index}.end`}
                          />

                          {state?.break_interval &&
                          state?.break_interval?.length > 1 ? (
                            <button
                              className="btn delete_btn_style"
                              type="button"
                              onClick={() => handleDeleteBreakInterval(index)}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </button>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                <InputForm
                  validationName={`input.break_work_timing_setting`}
                  containerStyle="d-none"
                  inputContainerStyle="d-none"
                  {...commonFormProps}
                  name=""
                />
              </>
            ) : null}

            <RadioboxForm
              {...commonFormProps}
              options={startBreak}
              dependOn="break_type"
              dependancyType="equal"
              dependancyValue={["fixed"]}
              // validateBy="textRequired"
              labelStyle="mb-2"
              label="Break Starts"
              name="start_break"
              validationName="input.start_break"
              optionsContainerStyle="d-flex justify-content-start"
              containerStyle="custom-tardiness-container justify-content-start mt-1 mb-2 flex-column align-items-start"
              optionItemStyle="option-item ml-0 mr-5"
              optionInputStyle=" "
              optionLabelStyle="optionLabelStyle"
            />

            <CheckboxBooleanForm
              setWithValue
              dependOn="break_type"
              dependancyType="equal"
              dependancyValue={["fixed"]}
              {...commonFormProps}
              options={["Allow employees to end their breaks manually"]}
              name="can_end_break"
              validationName="input.can_end_break"
              type="checkbox"
            />
          </div>
          {state?.allow_break &&
          state?.break_type?.toLowerCase() === "fixed" &&
          state?.start_break?.toLowerCase() === "auto" &&
          !state?.can_end_break ? null : (
            <>
              <h6 className="my-2 sub-title-style">{t("Break Limits")}</h6>
              <CheckboxBooleanForm
                setWithValue
                dependOn="break_type"
                dependancyType="equal"
                dependancyValue={["fixed"]}
                {...commonFormProps}
                options={["Set a limit to the total break duration"]}
                name="allow_limit_total_break"
                validationName="input.allow_limit_total_break"
                type="checkbox"
              />

              <div className="sub-container-style">
                <InputForm
                  {...commonFormProps}
                  dependOn="allow_limit_total_break"
                  dependancyType="equal"
                  dependancyValue={[true]}
                  // validateBy="textRequired"
                  label="Number Of Allowed Break Sessions/Day"
                  name="number_of_allowed_break_sessions_per_day"
                  validationName="input.number_of_allowed_break_sessions_per_day"
                  type="text"
                  hasSuffix
                  suffixTitle="sessions"
                  containerStyle="align-items-center d-flex flex-row justify-content-between leaves_field_container mt-3 mt-3"
                  inputContainerStyle="d-flex align-items-center"
                />

                <InputForm
                  {...commonFormProps}
                  dependOn="allow_limit_total_break"
                  dependancyType="equal"
                  dependancyValue={[true]}
                  // validateBy="textRequired"
                  label="Maximum Individual Break Session Duration"
                  name="maximum_break_session_duration"
                  validationName="input.maximum_break_session_duration"
                  hasSuffix
                  suffixTitle="Minutes"
                  containerStyle="align-items-center d-flex flex-row justify-content-between leaves_field_container mt-3 mt-3"
                  inputContainerStyle="d-flex align-items-center"
                />

                <InputForm
                  {...commonFormProps}
                  dependOn="allow_limit_total_break"
                  dependancyType="equal"
                  dependancyValue={[true]}
                  // validateBy="textRequired"
                  label="Maximum Break Duration Per Day"
                  name="maximum_break_duration"
                  validationName="input.maximum_break_duration"
                  hasSuffix
                  suffixTitle="Minutes"
                  containerStyle="align-items-center d-flex flex-row justify-content-between leaves_field_container mt-3 mt-3"
                  inputContainerStyle="d-flex align-items-center"
                />
              </div>
            </>
          )}
        </>
      ) : null}

      <div className="mt-4 mb-1 text-right">
        <button
          type="submit"
          onClick={onBack}
          // onClick={()=>console.log(props.data.attendaceProfile)}

          className="btn btn-secondray pervious_btn_style mr-2"
        >
          {t("previous")}
        </button>
        <button onClick={onNext} className="btn btn-primary submit_btn_style">
          {t("next")}
        </button>
      </div>
    </div>
  );
};

export default WorkTimingBreakSettings;
